<div
  *ngIf="
    authServ.isUser.value && !authServ.isScreen.value && !isAgentsOrdersRoute()
  "
>
  <app-sidebar *ngIf="!isWarehouseOrdersRoute()"></app-sidebar>
  <!--<app-right-sidebar></app-right-sidebar>-->
</div>

<div
  [class.home-closed]="!sidebarService.isOpen && !isAgentsOrdersRoute()"
  [class.home]="sidebarService.isOpen && !isAgentsOrdersRoute()"
  [class.footer-fullwidth]="!authServ.isUser.value"
  [class.setWarehouseWidthScreen]="authServ.isScreen.value"
>
  <!-- [class.AgentHome]="isAgentsOrdersRoute()" -->
  <app-header></app-header>
  <section
    [class.scrollableContainer]="
      !isAgentsOrdersRoute() && !isLoginOrdersRoute()
    "
  >
    <div
      class="main-content"
      [class.main-content]="!isMessageRoute()"
      [class.margin-right]="!isAgentsOrdersRoute()"
    >
      <router-outlet></router-outlet>
    </div>
  </section>
  <!-- <app-footer class="mt-auto"></app-footer> -->
</div>
