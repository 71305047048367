import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Boxes } from 'src/app/models/Boxes.models';
import { Documents } from 'src/app/models/documents.models';
import { Orders } from 'src/app/models/Orders.model';
import { Status } from 'src/app/models/status';
import { IWsResult } from 'src/app/models/ws_result.model';
import { IconsService } from 'src/app/services/icons.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PagesService } from 'src/app/services/pages.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-controllo-bolla',
  templateUrl: './controllo-bolla.component.html',
  styleUrls: ['./controllo-bolla.component.scss'],
})
export class ControlloBollaComponent implements OnInit {
  @Input() openedBox = true;

  public documents: Documents.Document[] = [];
  public boxes: Boxes.BoxOrder[] = [];
  public orderToUpdate: Orders.OrderUpdate;
  public order: Orders.Order;
  public isEditable = false;
  public toggleText = 'Hide';
  public show = false;

  id: string;
  back: string = '';
  boxNu: number;
  firstEnteredOnPage: boolean = true;
  clickedBox: any;

  constructor(
    private router: Router,
    public orderService: OrdersService,
    private pagesService: PagesService,
    private activatedRoute: ActivatedRoute,
    private iconService: IconsService,
    public closeServ: OpenModalService,
    private dropdownServ: DropdownService
  ) {}

  public onToggle(): void {
    this.show = !this.show;
    this.toggleText = this.show ? 'Hidе' : 'Show';
  }

  showBoxDetails(box, boxNo) {
    this.clickedBox = box;
    this.boxNu = boxNo;
    this.closeServ.openDetBox();
  }

  async checkDocument(id: number) {
    this.documents[id].checked = !this.documents[id].checked;
    this.documents.sort(
      (a, b) =>
        +(a.checked ? a.checked : false) - +(b.checked ? b.checked : false)
    );
  }

  async removeBox(id) {
    this.orderToUpdate.boxes.splice(id, 1);
    this.orderService.updateOrder(this.orderToUpdate).subscribe((data) => {});
  }

  goToBox(box_type_id: any) {
    this.clickedBox = box_type_id;
    this.closeServ.openBox();
  }

  public goBackFunction() {
    this.router.navigate(['/prepare-order']);
  }

  updateCustomBox(dataInfo: any) {
    console.debug('Custom box: ', dataInfo);
    {
      const box: Boxes.IBoxOrder = {
        ts_box_type_id: null,
        ts_box_type_desc: '',
        ts_box_height: dataInfo.custom.ts_box_height,
        ts_box_length: dataInfo.custom.ts_box_length,
        ts_box_depth: dataInfo.custom.ts_box_depth,
        ts_box_weight: dataInfo.custom.ts_box_weight,
        ts_documents: 'N',
        ts_screws: 'N',
      };
      this.orderToUpdate.boxes[dataInfo.boxNo] = box;
    }
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  addDocumentsToBox(boxNo: number) {
    this.orderToUpdate.boxes[boxNo].ts_documents =
      this.orderToUpdate.boxes[boxNo].ts_documents?.toUpperCase() === 'S'
        ? 'N'
        : 'S';
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  addScrewsToBox(boxNo: number) {
    this.orderToUpdate.boxes[boxNo].ts_screws =
      this.orderToUpdate.boxes[boxNo].ts_screws?.toUpperCase() == 'S'
        ? 'N'
        : 'S';
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  printAllLabels() {
    this.orderService
      .getAllBoxLabels(
        this.order.order_id,
        this.order.boxes.map((box, index) => index + 1)
      )
      .subscribe((result) => {
        console.debug('result: ', result);
        if (!result) return;
        window.open(result.box_label_link_pdf);
      });
  }

  addBox(dataInfo: any) {
    console.debug('Add box: ', dataInfo);
    for (let i = 0; i < dataInfo.boxesNo; i++) {
      const box: Boxes.IBoxOrder = {
        ts_box_type_id: dataInfo.normal.box_type_id,
        ts_box_type_desc: dataInfo.normal.box_type_desc,
        ts_box_height: dataInfo.custom.ts_box_height,
        ts_box_length: dataInfo.custom.ts_box_length,
        ts_box_depth: dataInfo.custom.ts_box_depth,
        ts_box_weight: dataInfo.custom.ts_box_weight,
        ts_documents: 'N',

        ts_screws: 'N',
      };
      this.orderToUpdate.boxes.push(box);
    }
    console.debug(this.orderToUpdate);
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  close() {
    this.closeServ.closeCheckBox();
    this.closeServ.isColorConfirmed2();
  }

  saveStep(pageName) {
    let statusNo = 0;
    switch (pageName) {
      case 'boxes':
        console.log('CheckedProduct');
        statusNo = Status.StatusesUser.step3;
    }
    this.orderService
      .setStatus(this.order.order_id, statusNo)
      .subscribe((result: IWsResult) => {
        this.orderService.updateOrder(this.orderToUpdate).subscribe();
      });
    this.close();
    Swal.fire({
      icon: 'success',
      title: 'Aggiunte!',
      text: 'Colli selezionate con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

  checkSelectedAll(stepBack = false) {
    const stepFrw = 2;
    const stepBck = 1;
    if (this.documents)
      this.orderService.setDocumentsChecked(
        this.order.order_id,
        this.documents
      );
    if (
      this.order.status[0].os_status_order_cod > 3 &&
      this.order.status[0].os_status_order_cod < 7 &&
      this.firstEnteredOnPage
    ) {
      this.documents.forEach((element) => {
        element.checked = true;
      });
      this.firstEnteredOnPage = false;
    }
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.back = this.pagesService.getParentUrl();
    this.orderService.getOrder1(+this.id).subscribe(async (result) => {
      this.order = result;
      this.orderToUpdate = new Orders.OrderUpdate(this.order);
      if (this.orderToUpdate.or_carrier_id == 0)
        this.orderToUpdate.or_carrier_id = null;
      this.boxes = this.order.boxes.map((item) => {
        let box: Boxes.BoxOrder = {
          ts_box_type_id: item.ts_box_type_id,
          ts_box_type_desc: item.ts_box_type_desc,
          ts_box_height: item.ts_box_height,
          ts_box_length: item.ts_box_length,
          ts_box_depth: item.ts_box_depth,
          ts_box_weight: item.ts_box_weight,
          ts_documents: 'N',
          ts_screws: 'N',
          checkForNull: function (): boolean {
            throw new Error('Function not implemented!');
          },
        };
        return box;
      });
      this.orderService.getDocuments(+this.id).subscribe(async (result) => {
        this.documents = result;
        await this.orderService.getOrderDocuments(+this.id).then((result) => {
          if (result) this.documents = result;
        });
      });
    });
  }
}
