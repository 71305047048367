<div style="display: flex; flex-direction: column; justify-content: center">
  <h1 class="page-title">Prepara Ordine</h1>
  <form
    action=""
    class="search-bar text-center"
    style="
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      text-align: center !important;
      width: 200%;
    "
  >
    <h5 class="col-header">
      Totale Imponibile:<br />
      {{ netValue | currency : "EUR" : "symbol" : "1.2-2" }}
    </h5>
    <div
      class="progress-bar-div"
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-content: space-around;
        align-items: center;
      "
    >
      <kendo-progressbar
        [value]="100"
        [label]="setPercentLabel"
        style="width: 200px"
      ></kendo-progressbar>
    </div>
  </form>
</div>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: space-between"
>
  <form
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <div
      class="inputSearchContainer"
      style="display: flex; flex-direction: column; align-items: flex-end"
    >
      <i
        *ngIf="filterTerm"
        class="bx bxs-x-circle bx-md clearSearchIcon"
        style="
          transform: scale(0.8);
          color: red;
          /* background: #0c65e9; */
          position: relative;
          top: 42px;
          z-index: 10001;
        "
        (click)="clearSearch()"
      ></i>
      <input
        [(ngModel)]="filterTerm"
        (ngModelChange)="handleFilterChange()"
        id="search"
        name="search"
        pattern=".*\S.*"
        type="search"
        placeholder="Cerca ordine..."
        autofocus
        required
        (input)="this.onInputChanged($event)"
      />
    </div>
    <button type="submit" (click)="searchOrders()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>

  <!--<app-fiesta></app-fiesta>-->

  <!-- <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: column !important;
      justify-content: end !important;
      text-align: center !important;
    "
  >
    <h5 class="col-header">
      Imponibile Ordini in Lavorazione:<br />
      {{ netValueLavoration | currency : "EUR" : "symbol" : "1.2-2" }}
    </h5>
    <div
      class="progress-bar-div"
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-content: space-around;
        align-items: center;
      "
    >
      <kendo-progressbar
        [value]="percentageLavoration"
        [label]="setPercentLabel"
        style="width: 200px"
      ></kendo-progressbar>
    </div>
  </form> -->

  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
  </div>
</div>

<!-- <div>
  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      text-align: center !important;
    "
  >
    <h5 class="col-header">
      Imponibile Ordini in Lavorazione:<br />
      {{ netValueLavoration | currency : "EUR" : "symbol" : "1.2-2" }}
    </h5>
    <div
      class="progress-bar-div"
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-content: space-around;
        align-items: center;
      "
    >
      <kendo-progressbar
        [value]="percentageLavoration"
        [label]="setPercentLabel"
        style="width: 200px"
      ></kendo-progressbar>
    </div>
  </form>
</div> -->

<hr class="hr-newBlue" />

<div
  class="row k-display-flex justify-content-end align-items-center"
  style="padding-left: 20px"
>
  <div style="display: flex; flex-direction: row; justify-content: center">
    <h2 class="col-header">
      Totale ordini da preparare: {{ gridData.length }}
    </h2>
  </div>

  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <div class="grid-wrapper" *ngIf="!loading">
    <kendo-grid
      [kendoGridBinding]="
        gridData
          | byToDate
            : [
                filters.startingDate,
                filters.endDate,
                filters.dateOrginizedBy.value
              ]
      "
      [skip]="skip"
      [rowHeight]="36"
      [height]="700"
      [width]="1200"
      id="my-grid"
      [pageSize]="pageSize"
      scrollable="virtual"
      (pageChange)="pageChange($event)"
      [navigable]="true"
      [sortable]="true"
      [rowClass]="combinedRowClass"
      [reorderable]="true"
    >
      <kendo-grid-column
        [width]="100"
        field="or_order_number"
        title="N° Ordine"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
      >
      </kendo-grid-column>

      <kendo-grid-column
        [width]="420"
        field="or_customer"
        title="Cliente"
        [headerStyle]="{ 'justify-content': 'left' }"
        [style]="{
          display: 'grid',
          'justify-content': 'left',
          'tetx-align': 'left'
        }"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          style="justify-content: left; text-align: left !important"
        >
          {{ dataItem.or_customer }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="120"
        field="or_net_value"
        title="Totale Importo"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_net_value | currency : "EUR" : "symbol" : "1.2-2" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="80"
        field="tab_order_priority_desc"
        title="Priorità"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.tab_order_priority_desc }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="130"
        field="ac_last_name"
        title="Operatore"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{
          'tetx-align': 'center',
          display: 'flex',
          'justify-content': 'space-evenly'
        }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div style="display: flex; flex: 2; justify-content: start">
            {{ dataItem.ac_first_name }}
            {{ dataItem.ac_last_name?.substring(0, 1) }}.
          </div>
          <div style="display: flex; flex: 1; justify-content: end">
            <button
              class="btn"
              (click)="openChangeOperator(dataItem)"
              [disabled]="dataItem.tab_order_status_cod !== 101"
              style="padding: 0; margin: 0; border: none"
            >
              <i class="bx bx-down-arrow-circle bx-sm"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="220"
        field="tab_order_status_perc"
        title="Avanzamento"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="progress-bar-div">
            <kendo-progressbar
              [value]="dataItem.tab_order_status_perc"
              [label]="setPercentLabel"
            ></kendo-progressbar>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="120"
        field="or_order_date"
        title="Data ordine"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="160"
        field="or_assigned_date"
        title="Data assegnazione"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_assigned_date | date : "dd/MM/yyyy" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column
        [width]="80"
        title="Check"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="gotoDetails(dataItem.order_id)"
            *ngIf="dataItem.tab_order_status_perc < 100"
          >
            <div class="setDisplayToFlexRed btn-controlla">
              <i
                class="bx bx-check-circle bx-md"
                style="margin: 0; padding: 0; transform: scale(0.8)"
              >
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="gotoDetails(dataItem.order_id)"
            *ngIf="dataItem.tab_order_status_perc === 100"
          >
            <div class="setDisplayToFlexBlue">
              <i
                class="bx bx-sign bx-md"
                style="margin: 0; padding: 0; transform: scale(0.8)"
              >
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-command-column
        title="Canc"
        [width]="80"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            *ngIf="
              dataItem?.or_url_ddt == undefined ||
              dataItem?.or_url_ddt?.indexOf('.pdf') == -1
            "
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0"
            (click)="confirmCancel(dataItem)"
          >
            <div class="setDisplayToFlex btn-cancella">
              <i
                class="bx bx-trash bx-md"
                style="margin: 0; padding: 0; transform: scale(0.8)"
              >
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-column
        [width]="80"
        field="or_status"
        title="B/S"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
        [sortable]="true"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="
              dataItem?.tab_order_status_desc === 'Blocca'
                ? unblock(dataItem)
                : block(dataItem)
            "
            title="{{
              dataItem?.tab_order_status_desc === 'Blocca'
                ? 'Sblocca'
                : 'Blocca'
            }}"
          >
            <div
              class="{{
                dataItem?.tab_order_status_desc === 'Blocca'
                  ? 'setDisplayToFlex btn-sblocca'
                  : 'setDisplayToFlex btn-blocca'
              }}"
            >
              <i
                [class]="
                  dataItem?.tab_order_status_desc === 'Blocca'
                    ? 'bx bx-lock bx-md'
                    : 'bx bx-lock-open bx-md'
                "
                style="margin: 0; padding: 0; transform: scale(0.8)"
              ></i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridNoRecordsTemplate>
        <div class="no-records-message">
          <p>Nessun dato disponibile</p>
        </div>
      </ng-template>
    </kendo-grid>
  </div>
</div>

<app-preparazione-ordini-detaggli
  *ngIf="openModal.isOpened"
  [opened]="openModal.isOpened"
  [account_id]="clickedId"
  [net_value]="+order.or_net_value"
>
</app-preparazione-ordini-detaggli>

<app-confirm-cancel
  *ngIf="openModal.isConfirmCancel"
  [openConfirmCancel]="openModal.isConfirmCancel"
  [orderToGet]="orderToSend"
  (canceledSucces)="this.reloadFunction()"
></app-confirm-cancel>
