import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { Status } from 'src/app/models/status';
import {
  GridDataResult,
  PageChangeEvent,
  RowClassArgs,
} from '@progress/kendo-angular-grid';
import { Orders } from 'src/app/models/Orders.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { IconsService } from 'src/app/services/icons.service';
import Swal from 'sweetalert2';
import { BehaviorSubject, Subscription } from 'rxjs';
import { parseISO } from 'date-fns';
import { PopoverController } from '@ionic/angular';
import { ChangeOperatorComponent } from './change-operator/change-operator.component';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-preparazione-ordini',
  templateUrl: './preparazione-ordini.component.html',
  styleUrls: ['./preparazione-ordini.component.scss'],
})
export class PreparazioneOrdiniComponent implements OnInit {
  public gridData: Orders.IOrderOpenList[] = [];
  public loading: boolean = false;
  public gridView: GridDataResult;
  public value = 0;
  public indeterminate = false;
  public min = -10;
  public max = 10;
  public pageSize = 20;
  public skip = 0;
  public data: unknown[];
  public order: Orders.Order;
  public orders: Orders.OrderList[] = [];
  public setPercentLabel: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center',
  };

  openOrders: Orders.IOrderOpenList[] = [];
  sameIndex: boolean = true;
  prodNo: any;
  firstEnteredOnPage: boolean = true;
  checkedProd: boolean = false;
  checkedBox: boolean = false;
  checkedDocs: boolean = false;
  status_desc: string;
  id: number;
  clickedId: any;
  filteredOrders: Orders.IOrderOpenList[] = [];
  orderToSend: any;
  searchText: string = '';
  filterTerm: string | undefined;
  timer = null;
  refreshInterval: number = 60000;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
  });

  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  openGeneralSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  searchParam: string;
  subscriber: Subscription[] = [];
  netValue: any;
  netValueLavoration: number = 0;
  percentageLavoration: number = 0;
  booleanForSpecialSearch: boolean = false;
  searchTermLocalStorage: string = '';
  filterService = inject(SearchService);
  constructor(
    private openOrdersServ: OrdersService,
    private router: Router,
    public openModal: OpenModalService,
    private iconService: IconsService,
    private route: ActivatedRoute,
    private popoverController: PopoverController
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.filterTerm = this.filterService.getFilterText();
    console.log('Filter is', this.filterTerm);

    // console.log('Filterterm is', this.searchTermLocalStorage);
    if (this.filterTerm) {
      this.searchOrders(this.filterTerm);
    } else {
      this.subscriber.push(
        this.route.queryParams.subscribe((params) => {
          console.log('Params is', params);
          // Check if params is an empty object
          if (Object.keys(params).length === 0) {
            this.reloadFunction();
            console.log('Params is an empty object. Reloaded.');
          } else {
            this.searchParam = params['search'];
            const dateParam = params['data'];
            this.filters.startingDate = dateParam ? parseISO(dateParam) : null;
            console.log('Date param is', dateParam);
            console.log('Param is', this.searchParam);
            this.searchOrders(this.searchParam);
            //  this.loading = false;
          }
        })
      );
    }
  }

  handleFilterChange(): void {
    this.filterService.setFilterText(this.filterTerm);
    // Perform filtering logic here
  }

  reloadFunction() {
    this.loading = true;
    this.getOpenOrders();
    clearTimeout(this.timer);
    this.timer = setInterval(() => {
      this.getOpenOrders();
      this.loading = false;
      console.log('Interval set');
    }, this.refreshInterval);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadProducts();
  }

  private loadProducts(): void {
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length,
    };
  }

  clearSearch() {
    this.filterTerm = '';
    this.filters.startingDate = this.startDate;
    this.filterService.clearFilterText();
    this.reloadFunction();
  }

  getOpenOrders() {
    this.loading = true;
    this.openOrdersServ.getOpenOrders().subscribe((ordersData) => {
      this.openOrders = ordersData;
      this.filteredOrders = this.openOrders.filter(
        (orders) => orders.tab_order_status_cod !== 11
      );
      this.gridData = this.openOrders;
      this.loadProducts();
      this.loading = false;
      this.netValue = 0;
      let gridusData = this.gridData.map((net_value) => {
        this.netValue += net_value.or_net_value;
        return net_value.or_net_value;
      });
      this.netValueLavoration = this.gridData
        .filter((order) => order.tab_order_status_cod === 10)
        .reduce((acc, order) => acc + order.or_net_value, 0);
      this.calculatePercentage();
      console.debug('Data is: ', this.openOrders);
      console.debug('Status is: ', this.openOrders[0].tab_order_status_cod);
    });
  }

  calculatePercentage() {
    if (this.netValue > 0) {
      this.percentageLavoration =
        (this.netValueLavoration / this.netValue) * 100;
    } else {
      this.percentageLavoration = 0; // Gestione caso di divisione per zero, se necessario
    }
  }

  onInputChanged(event: Event) {
    console.log('Event date is', event);
    this.filters.startingDate = this.startDate;
    console.log('Event filter date is', this.filters.startingDate);
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getOpenOrders();
    }
  }

  gotoDetails(order_id: any) {
    this.gridData = [];
    //  this.ordiniParziali = [];
    // console.log('Accesing the page with no query param', this.dynamicLink);
    this.router.navigate(['/prepare-order', order_id]);
  }

  searchOrders(searchString?: string) {
    if (searchString) this.filterTerm = searchString;
    if (!this.filterTerm || this.filterTerm == '') {
      this.reloadFunction();
    }
    this.openOrdersServ
      .searchOpenOrders(this.filterTerm)
      .subscribe((openOrdersData: IWsResult) => {
        this.gridData = openOrdersData.data;
        this.loading = false;
      });
  }

  suspend(order) {
    this.iconService.changeIconToSuspended(order);
    this.openOrdersServ
      .setStatus(order.order_id, Status.StatusesUser.blocked)
      .subscribe((result: IWsResult) => {
        this.reloadFunction();
        if (result.success) console.log('SUSPENDED!');
      });
  }

  confirmCancel(order) {
    this.orderToSend = order;
    this.openModal.onConfirmCancel();
    console.log('MERGE!');
  }

  block(order) {
    this.openOrdersServ
      .setStatus(order.order_id, Status.StatusesUser.blocked)
      .subscribe((result: IWsResult) => {
        this.reloadFunction();
        Swal.fire({
          icon: 'success',
          title: 'Bloccato!',
          text: 'Ordine bloccato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        if (result.success) console.log('BLOCKED!');
      });
  }

  async unblock(order) {
    this.openOrdersServ.getOrder1(order.order_id).subscribe((orderData) => {
      this.openOrdersServ
        .setStatus(order.order_id, orderData.status[1].os_status_order_cod)
        .subscribe((result: IWsResult) => {
          this.reloadFunction();
        });
      Swal.fire({
        icon: 'success',
        title: 'Sbloccato!',
        text: 'Ordine sbloccato con successo!!!',
        timer: 1500,
        timerProgressBar: true,
      });
    });
  }

  sync() {}

  public ngOnDestroy() {
    clearTimeout(this.timer);
    console.log('I am leaving assegna list');
    console.log('DEINE SPECIAL BOOLEAN IST', this.booleanForSpecialSearch);
    if (!this.booleanForSpecialSearch) {
      localStorage.removeItem('query-search');
    }
    this.subscriber.forEach(
      (subscriber) => subscriber.unsubscribe(),
      console.log('I am destroyed sucesfully')
    );
  }

  public rowClass = (context: RowClassArgs) => {
    switch (context.dataItem.tab_order_status_cod) {
      case 2:
      case 3:
      case 4:
      case 5:
      case 10:
        return { fadeIn: true };
    }
  };

  public rowClassGrey = (context: RowClassArgs) => {
    switch (context.dataItem.tab_order_status_cod) {
      case 100:
        return { fadeInGrey: true };
    }
  };

  public combinedRowClass = (context: RowClassArgs) => {
    let classes = {};
    switch (context.dataItem.tab_order_status_cod) {
      case 1:
        classes['fadeInGreen'] = true;
        break;
      case 2:
      case 3:
      case 4:
      case 5:
      case 10:
        classes['fadeIn'] = true;
        break;
      case 100:
        classes['fadeInGrey'] = true;
        break;
      case 101:
        classes['fadeInRed'] = true;
        break;
    }
    return classes;
  };

  async openChangeOperator(item: Object) {
    const popover = await this.popoverController.create({
      component: ChangeOperatorComponent,
      componentProps: {
        itemSelected: item,
      },
      cssClass: 'semi-wide-popover',
    });
    console.log('ItemId is', item);
    await popover.present();
    return await popover.onDidDismiss().then(() => {
      this.reloadFunction();
    });
  }
}
