<div class="k-overlay dark-background" *ngIf="photoAcc || accPageOpen"></div>
<div class="k-form" *ngIf="photoAcc || accPageOpen">
  <div>
    <kendo-dialog
      title=""
      (close)="close()"
      *ngIf="(photoAcc && account) || (accPageOpen && account)"
      [minWidth]="minWidth"
      [width]="dialogWidth"
      [minHeight]="500"
      [maxWidth]="950"
      class="k-form"
      style="left: 6%"
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: end;
          margin-top: -15px;
          z-index: 1000;
          position: fixed;
          margin-left: -35px;
        "
      >
        <button
          (click)="close()"
          class="btn btn3"
          rounded="full"
          style="
            background-color: #e90000;
            color: #ffffff;
            width: 35px !important;
            height: 35px !important;
          "
        >
          <i
            class="bx bx-x bx-sm posRelBox2"
            style="
              color: #ffffff;
              bottom: 10px;
              position: relative !important;
              left: -80% !important;
              top: -1px !important;
            "
          ></i>
        </button>
      </div>
      <h1 class="page-title">
        {{ !isEditable ? "Dettagli Account" : "Modifica Account" }}
      </h1>
      <hr class="hr-dialog" />
      <form
        *ngIf="photoAcc || accPageOpen"
        class="k-form dialog-form"
        [formGroup]="accountDetailsForm"
      >
        <div
          class="col k-display-flex justify-content-center"
          *ngIf="!isEditable && !changedPhotoSuccess"
        >
          <img [src]="account.ac_photo_url" class="resize-img1" />
        </div>
        <div
          class="col k-display-flex justify-content-center"
          *ngIf="isEditable"
        >
          <div
            class="photoContainer k-display-flex justify-content-center flex-column align-items-center"
            *ngIf="isEditable && changedPhotoSuccess"
          >
            <i
              class="bx bx-user-pin k-cursor-pointer Avatar"
              style="color: #0d6efd; font-size: 70px"
              (click)="openFileInput()"
            ></i>
            <p style="padding: 0; margin: 0; color: #00b3ff">Aggiungi Avatar</p>
          </div>

          <img
            *ngIf="!account.ac_photo_url"
            [src]="account.ac_photo_url"
            class="imagePreview hover:cursor-pointer"
            style="height: 225px"
            (click)="openFileInput()"
          />

          <input
            type="file"
            (change)="onFileSelected($event)"
            accept="image/*"
            name="avatar"
            id="fileInput"
            style="display: none"
          />
        </div>

        <div class="row det mb-2">
          <kendo-formfield class="col-md setDisplay-Prep">
            <kendo-label
              [for]="ac_first_name"
              class="title"
              text="Nome"
            ></kendo-label>
            <input
              formControlName="ac_first_name"
              kendoTextBox
              #ac_first_name
              [readonly]="!isEditable"
              rounded="full"
              class="setBorder-Prepp"
            />
          </kendo-formfield>

          <kendo-formfield class="col-md setDisplay-Prep">
            <kendo-label
              [for]="ac_last_name"
              class="title"
              text="Cognome"
            ></kendo-label>
            <input
              formControlName="ac_last_name"
              kendoTextBox
              #ac_last_name
              [readonly]="!isEditable"
              rounded="full"
              class="setBorder-Prepp"
            />
          </kendo-formfield>
        </div>

        <div class="row det">
          <kendo-formfield class="col-md-3 setDisplay-Prep">
            <kendo-label
              [for]="ac_phone"
              class="title"
              text="Telefono"
            ></kendo-label>
            <input
              formControlName="ac_phone"
              kendoTextBox
              #ac_phone
              [readonly]="!isEditable"
              rounded="full"
              class="setBorder-Prepp"
            />
          </kendo-formfield>

          <kendo-formfield class="col-md setDisplay-Prep">
            <kendo-label
              [for]="ac_email"
              class="title"
              text="Email"
            ></kendo-label>
            <input
              formControlName="ac_email"
              kendoTextBox
              #ac_email
              [readonly]="true"
              rounded="full"
              class="setBorder-Preppp"
            />
          </kendo-formfield>

          <kendo-formfield class="col-md-3 setDisplay-Prep" *ngIf="!isEditable">
            <kendo-label
              [for]="tab_user_type_desc"
              class="title"
              text="Tipologia"
            ></kendo-label>
            <input
              formControlName="tab_user_type_desc"
              kendoTextBox
              #tab_user_type_desc
              [readonly]="!isEditable"
              rounded="full"
              class="setBorder-Prepp"
              placeholder="tipo di account (admin/operatore)"
            />
          </kendo-formfield>

          <kendo-formfield class="col-md setDisplay-Prep" *ngIf="isEditable">
            <kendo-label
              [for]="ac_user_type_cod"
              class="title"
              text="Tipologia"
            ></kendo-label>
            <kendo-combobox
              [data]="accountTypesSelect"
              formControlName="ac_user_type_cod"
              kendoTextBox
              #ac_user_type_cod
              (valueChange)="onAccountTypeSelected($event)"
              required
              textField="ac_typo"
              [valuePrimitive]="true"
              valueField="ac_number"
              class="dropdown-field setBorder-Prepp"
              placeholder="Tipo di account (admin/operatore)"
              [readonly]="!isEditable"
            >
            </kendo-combobox>
          </kendo-formfield>
        </div>

        <div class="row det">
          <kendo-formfield class="col-md-3 setDisplay-Prep">
            <kendo-label
              [for]="ac_expiration_date"
              class="title"
              text="Data di scadenza"
            ></kendo-label>
            <kendo-datepicker
              formControlName="ac_expiration_date"
              #ac_expiration_date
              (valueChange)="printNewDate($event)"
              [readonly]="!isEditable"
              class="dropdown-field setBorder-Preppp"
            ></kendo-datepicker>
          </kendo-formfield>
          <kendo-formfield class="col-md setDisplay-Prep">
            <kendo-label
              [for]="ac_note"
              class="title"
              text="Descrizione"
            ></kendo-label>
            <input
              formControlName="ac_note"
              kendoTextBox
              #ac_note
              [readonly]="!isEditable"
              rounded="full"
              class="setBorder-Prepp"
              placeholder="Descrizione a caso.."
            />
          </kendo-formfield>
        </div>
        <br />
        <div class="text-buttons">
          <div class="button-left">
            <button
              class="btn btn3 edit"
              (click)="editAccount()"
              style="
                color: #ffffff;
                justify-content: center !important;
                display: flex;
                width: 192px !important;
                height: 42px !important;
              "
              *ngIf="isEditable === false"
            >
              <i
                class="bx bx-edit-alt bx-sm posRelBox2"
                style="
                  color: #ffffff;
                  top: 0px;
                  right: 6px;
                  bottom: 10px;
                  position: relative;
                "
              ></i>
              <p class="posRelBox2">MODIFICA</p>
            </button>
            <button
              class="btn btn3 save"
              style="
                color: #ffffff;
                justify-content: center !important;
                display: flex;
                width: 192px !important;
                height: 42px !important;
              "
              *ngIf="isEditable === true"
              (click)="saveAccount(accountDetailsForm)"
            >
              <i
                class="bx bx-check-circle bx-sm posRelBox2"
                style="
                  color: #ffffff;
                  top: 0px;
                  right: 6px;
                  bottom: 10px;
                  position: relative;
                "
              ></i>
              <p class="posRelBox2">SALVA</p>
            </button>
          </div>
          <div class="button-left">
            <button
              (click)="goToChangePass(account_id)"
              class="btn btn3"
              rounded="full"
              style="
                background-color: #017efa;
                color: #ffffff;
                justify-content: center !important;
                display: flex;
                width: 292px !important;
                height: 42px !important;
              "
            >
              <i
                class="bx bx-lock bx-sm posRelBox2"
                style="
                  color: #ffffff;
                  top: 0px;
                  right: 6px;
                  bottom: 10px;
                  position: relative;
                "
              ></i>
              <p class="posRelBox2">CAMBIA PASSWORD</p>
            </button>
          </div>
          <div class="button-left">
            <button
              class="btn btn3"
              rounded="full"
              style="
                background: rgb(170, 0, 0);
                color: #ffffff;
                justify-content: center !important;
                display: flex;
                width: 292px !important;
                height: 42px !important;
              "
              *ngIf="isEditable === true"
              (click)="deleteAccount(accountDetailsForm)"
            >
              <i
                class="bx bx-trash bx-sm posRelBox2"
                style="
                  color: #ffffff;
                  top: 0px;
                  right: 6px;
                  bottom: 10px;
                  position: relative;
                "
              ></i>
              <p class="posRelBox2">ELIMINA L'ACCOUNT</p>
            </button>
          </div>
        </div>
      </form>
    </kendo-dialog>
    <app-profile
      *ngIf="closeSrv.isPassOpn"
      [passOpen]="closeSrv.isPassOpn"
      [account_id]="modifyBtn"
    >
    </app-profile>
  </div>
</div>
