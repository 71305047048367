import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Boxes } from 'src/app/models/Boxes.models';
import { Documents } from 'src/app/models/documents.models';
import { Orders } from 'src/app/models/Orders.model';
import { Products } from 'src/app/models/Product.model';
import { Status } from 'src/app/models/status';
import { IWsResult } from 'src/app/models/ws_result.model';
import { IconsService } from 'src/app/services/icons.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PagesService } from 'src/app/services/pages.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-prepare-order',
  templateUrl: './prepare-order.component.html',
  styleUrls: ['./prepare-order.component.scss'],
})

export class PrepareOrderComponent implements OnInit {

  public order: Orders.Order;
  public documents: Documents.Document[] = [];
  public boxes: Boxes.BoxOrder[] = [];
  public orderToUpdate: Orders.OrderUpdate;
  public formattedDate: string = '-';

  clickedBox: any;
  clickedProduct: any;
  productsToCheck: Products.Product[] = [];
  id: string;
  back: string = '';
  stepCount: number = 0;
  maxChild: number = 3;
  detailsBox: any;
  boxNu: number;
  productsi;
  boxDet: boolean = false;
  orderToCheck: string = null;
  numbers: number[] = [];
  text: string = 'details';

  constructor(
    private router: Router,
    public orderService: OrdersService,
    private pagesService: PagesService,
    private activatedRoute: ActivatedRoute,
    private iconService: IconsService,
    public closeServ: OpenModalService,
    private dropdownServ: DropdownService
  ) { }  

  opChecked(product: Products.IProductToSend, index: number) {
    this.productsToCheck[index].op_checked =
      !this.productsToCheck[index].op_checked;
    this.orderToCheck =
      this.productsToCheck.every((value) => value.op_checked == true) &&
      this.productsToCheck.every((value) => +value.op_qta === value.orQtaNeeded)
        ? 'N'
        : 'S';
  }

  async updateProduct(value, i) {
    this.productsToCheck[i].orQtaNeeded = value;
    this.orderToCheck =
      this.productsToCheck.every((value) => value.op_checked == true) &&
      this.productsToCheck.every((value) => +value.op_qta === value.orQtaNeeded)
        ? 'N'
        : 'S';
  }

  changeProductNumber(n) {
    this.numbers = Array.from({ length: n }, (_element, index) => index + 1);
    console.debug('Go bro!');
  }

  showBoxDetails(box, boxNo) {
    this.clickedBox = box;
    this.boxNu = boxNo;
    this.closeServ.openDetBox();
  }

  ngOnInit(): void {
    this.reloadFunction();
  }

  goToProductDetails(product_order_id) {
    this.clickedProduct = product_order_id;
    this.closeServ.openProd();
  }

  async stepAction(action: string, i) {
    if (this.orderService.pages.value < i) return;
    switch (action) {
      default:
        await this.goToPage(action);
        break;
    }
  }

  async checkDocument(id: number) {
    this.documents[id].checked = !this.documents[id].checked;
    this.documents.sort(
      (a, b) =>
        +(a.checked ? a.checked : false) - +(b.checked ? b.checked : false)
    );
  }

  async goToPage(action: string) {
    this.router.navigate([`./${action}/`], { relativeTo: this.activatedRoute });
  }

  goToBoxDetails(box: Boxes.IBoxOrder, boxNo: number) {
    this.detailsBox = box;
    if (box.ts_box_type_id) 
      return;
    this.closeServ.openDetBox();
  }

  showDetails(document) {
    if (!document || document.length < 0)
      Swal.fire({
        icon: 'error',
        title: 'Errore!',
        text: 'Nessun documento salvato!!!',
        timer: 1500,
        timerProgressBar: true,
      });
    window.open(document);
  }

  addBox(dataInfo: any) {
    console.debug('Add box: ', dataInfo);
    for (let i = 0; i < dataInfo.boxesNo; i++) {
      const box: Boxes.IBoxOrder = {
        ts_box_type_id: dataInfo.normal.box_type_id,
        ts_box_type_desc: dataInfo.normal.box_type_desc,
        ts_box_height: dataInfo.custom.ts_box_height,
        ts_box_length: dataInfo.custom.ts_box_length,
        ts_box_depth: dataInfo.custom.ts_box_depth,
        ts_box_weight: dataInfo.custom.ts_box_weight,
        ts_documents: 'N',
        ts_screws: 'N',
      };
      this.orderToUpdate.boxes.push(box);
    }
    console.debug(this.orderToUpdate);
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  async removeBox(id) {
    this.orderToUpdate.boxes.splice(id, 1);
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  goToBox(box_type_id: any) {
    this.clickedBox = box_type_id;
    this.closeServ.openBox();
  }

  updateCustomBox(dataInfo: any) {
    console.debug('Custom box: ', dataInfo);
    {
      const box: Boxes.IBoxOrder = {
        ts_box_type_id: null,
        ts_box_type_desc: '',
        ts_box_height: dataInfo.custom.ts_box_height,
        ts_box_length: dataInfo.custom.ts_box_length,
        ts_box_depth: dataInfo.custom.ts_box_depth,
        ts_box_weight: dataInfo.custom.ts_box_weight,
        ts_documents: 'N',
        ts_screws: 'N',
      };
      this.orderToUpdate.boxes[dataInfo.boxNo] = box;
    }
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  addDocumentsToBox(boxNo: number) {
    this.orderToUpdate.boxes[boxNo].ts_documents =
      this.orderToUpdate.boxes[boxNo].ts_documents?.toUpperCase() === 'S'
        ? 'N'
        : 'S';
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  addScrewsToBox(boxNo: number) {
    this.orderToUpdate.boxes[boxNo].ts_screws =
      this.orderToUpdate.boxes[boxNo].ts_screws?.toUpperCase() == 'S'
        ? 'N'
        : 'S';
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  printAllLabels() {
    this.orderService
      .getAllBoxLabels(
        this.order.order_id,
        this.order.boxes.map((box, index) => index + 1)
      )
      .subscribe((result) => {
        console.debug('result: ', result);
        if (!result) return;
        window.open(result.box_label_link_pdf);
      });
  }

  async done() {
    const step = 5;
    let text = `Confermi che l’${this.order.or_order_number}${
      this.order.or_partial === 'S' ? ' parziale' : ''
    } è stato completato?`;
    this.orderService.getDocuments(+this.id).subscribe(async (result) => {
      const orderPrepared = new Orders.OrderPrepared(this.order, result || []);
      this.orderService
        .prepareOrder(orderPrepared)
        .subscribe((result: IWsResult) => {
          if (!result.success) 
            return;
          this.order.stepCount = this.stepCount;
          if (this.stepCount <= step)
            this.orderService.pages.next(this.stepCount);
        });
    });
  }

  suspend() {
    this.iconService.changeIconToSuspended(this.order);
    this.orderService
      .setStatus(this.order.order_id, Status.StatusesUser.suspended)
      .subscribe((result: IWsResult) => {
        this.reloadFunction();
        if (result.success) 
          console.log('SUSPENDED!');
      });
  }

  reloadFunction() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.back = this.pagesService.getParentUrl();
    console.debug('back', this.back);
    this.orderService.getOrder1(+this.id).subscribe(async (result) => {
      this.order = result;
      this.orderToUpdate = new Orders.OrderUpdate(this.order);
      if (this.orderToUpdate.or_carrier_id == 0)
        this.orderToUpdate.or_carrier_id = null;
      this.boxes = this.order.boxes.map((item) => {
        let box: Boxes.BoxOrder = {
          ts_box_type_id: item.ts_box_type_id,
          ts_box_type_desc: item.ts_box_type_desc,
          ts_box_height: item.ts_box_height,
          ts_box_length: item.ts_box_length,
          ts_box_depth: item.ts_box_depth,
          ts_box_weight: item.ts_box_weight,
          ts_documents: 'N',
          ts_screws: 'N',
          checkForNull: function (): boolean {
            throw new Error('Function not implemented!');
          },
        };
        return box;
      });
      this.orderService.getDocuments(+this.id).subscribe(async (result) => {
        this.documents = result;
        await this.orderService.getOrderDocuments(+this.id).then((result) => {
          if (result) 
            this.documents = result;
        });
        this.orderService.getDocuments(+this.id).subscribe(async (result) => {
          this.documents = result;
          await this.orderService.getOrderDocuments(this.id).then((result) => {
            if (result) 
              this.documents = result;
          });
        });
      });
      this.productsToCheck = this.order.products.map((item) => {
        let product: Products.Product = {
          orQtaNeeded: +item.op_qta,
          op_positions: [],
          op_checked: false,
          op_code: item.op_code,
          op_description: item.op_description,
          op_qta: item.op_qta,
          op_qta_ordered: '',
          op_weigth: item.op_weigth,
          products_order_id: item.products_order_id,
          op_note: '',
          op_stock: item.op_stock,
          indexNo: 0,
          full_position: undefined,
          tab_order_status_cod: []
        };
        return product;
      });
      if (this.order.vs_details.carrier_id == 0)
        this.order.vs_details.carrier_id = null;
      this.stepCount = this.order.stepCount;
      console.debug('this.order.stepCount', this.order.stepCount);
      this.orderService.pages.next(this.order.stepCount);
    });
  }
  
}
