<div class="setWarehouseWidthScreen">
  <div class="k-display-flex justify-content-center">
    <h1 class="setBlueList1">DIAKONIX</h1>
  </div>
  <hr class="hr-newBlue" />
  <div class="k-display-flex justify-content-center">
    <h1 class="setBlueList">Lista Ordini</h1>
  </div>

  <!-- <div class="k-display-flex justify-content-center">
    <h1 class="setBlueList" *ngIf="objectiveArray && progressArray">
      Obiettivo del mese: {{ this.progressArray.tp_monthly_objective }}
    </h1>
  </div>

  <div style="position: relative">
    <kendo-progressbar
      *ngIf="objectiveArray && progressArray"
      [value]="this.objectiveArray.tl_monthly_orders"
      [max]="this.progressArray.tp_monthly_objective"
      [label]="false"
    ></kendo-progressbar>

    <div
      style="position: absolute; top: 0; right: 0; padding: 0 8px"
      *ngIf="objectiveArray && progressArray"
    >
      <span>{{ objectiveArray.tl_monthly_orders }}</span>
      <span> / {{ progressArray.tp_monthly_objective }}</span>
    </div>
  </div> -->
  <div
    class="row"
    *ngFor="let order of openOrders; let i = index"
    style="padding-top: 5px; padding-bottom: 5px"
  >
    <div
      class="col-sm-1"
      [class.setBgForMedium]="order"
      style="padding-top: 10px; padding-bottom: 10px"
    >
      <div
        class="k-display-flex justify-content-center buttonCircleWarehouse"
        style="background-color: white"
        [class.buttonCircleWarehouse3Dig]="order && i >= 98"
        [class.numberStyleWarehouse3Dig]="order && i >= 98"
      >
        <h1 class="numberStyleWarehouse">{{ i + 1 }}</h1>
      </div>
    </div>

    <div
      class="col-md-2 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForMedium]="order"
    >
      <h1
        class="setWareHouseText"
        style="padding-top: 10px; font-size: 28px !important"
      >
        {{ order.or_order_number }}
      </h1>
    </div>
    <div
      class="col-md-3 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForMedium]="order"
    >
      <h1
        class="setWareHouseText"
        style="padding-top: 10px; font-size: 28px !important"
      >
        {{ order.ac_first_name }} {{ order.ac_last_name }}
      </h1>
    </div>
    <div
      class="col k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForMedium]="order"
    >
      <kendo-progressbar
        style="position: relative; background: #f1f1f1; border-radius: 10px"
        class="cacat2555 jumari-prajite"
        [class.green-bg]="order && order.tab_order_status_perc == 100"
        [value]="
          order.tab_order_status_cod == 100
            ? order.tab_order_status_previous_perc
            : order.tab_order_status_perc
        "
        [label]="label_open"
      >
      </kendo-progressbar>
    </div>
    <div
      class="col-sm-2 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForMedium]="order"
    >
      <h1 style="padding-top: 10px" class="setWareHouseText">
        {{ order.or_order_date | date : "dd/MM/yyyy" }}
      </h1>
    </div>
  </div>

  <div
    *ngIf="ordersToSign.length > 0"
    class="row"
    style="padding-top: 5px; padding-bottom: 5px"
  >
    <div class="k-display-flex pt-3 justify-content-center">
      <p
        style="
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 48px;
          color: #017efa;
        "
      >
        Ordini da firmare: {{ ordersToSign.length }}
      </p>
    </div>
  </div>

  <div
    class="row"
    *ngFor="let cOrder of ordersToSign; let i = index"
    style="padding-top: 5px; padding-bottom: 5px"
  >
    <div
      class="col-sm-1"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForComplete]="ordersToSign"
    >
      <div
        class="k-display-flex justify-content-center buttonCircleWarehouse jumari-prajite"
        style="background-color: white"
        [class.buttonCircleWarehouse3Dig]="cOrder && i >= 99"
        [class.numberStyleWarehouse3Dig]="cOrder && i >= 99"
      >
        <h1 class="numberStyleWarehouse">{{ i + 1 }}</h1>
      </div>
    </div>

    <div
      class="col-md-2 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForComplete]="ordersToSign"
    >
      <h1 class="setWareHouseText" style="padding-top: 10px">
        {{ cOrder.or_order_number }}
      </h1>
    </div>
    <div
      class="col-md-3 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForComplete]="ordersToSign"
    >
      <h1 class="setWareHouseText" style="padding-top: 10px">
        {{ cOrder.ac_first_name }} {{ cOrder.ac_last_name }}
      </h1>
    </div>
    <div
      class="col k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForComplete]="ordersToSign"
    >
      <kendo-progressbar
        style="
          position: relative;
          background: #f1f1f1;
          border-radius: 10px;
          right: 10px;
        "
        class="cacat2555 jumari-prajite"
        [class.green-bg1]="cOrder && cOrder.tab_order_status_perc == 100"
        [value]="
          cOrder.tab_order_status_perc == 100
            ? cOrder.tab_order_status_perc
            : cOrder.tab_order_status_perc
        "
        [label]="label"
      >
      </kendo-progressbar>
    </div>
    <div
      class="col-sm-2 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForComplete]="ordersToSign"
    >
      <h1
        style="padding-top: 10px; font-size: 24px !important"
        class="setWareHouseText"
      >
        {{ today | date : "dd/MM/yyyy" }}
      </h1>
    </div>
  </div>

  <div
    class="row pt-3"
    style="
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: #001c37;
      margin-top: 10px;
    "
  >
    <div
      class="k-display-flex align-items-center"
      style="justify-content: space-around"
    >
      <div
        class="obiettivoDelGiorno"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <p
          style="
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            color: #ffffff;
          "
        >
          Obiettivo del giorno:
        </p>
      </div>

      <div
        class="progressGiornoInfo"
        style="
          color: white;

          display: flex;
          flex-direction: column;
          align-items: center;
          width: 40%;
        "
      >
        <p *ngIf="bigOrdersClosed >= objectives.tp_daily_objective">
          Sei stato così bravo che hai superato l'obiettivo di oggi! Ottimo
          lavoro!!!
        </p>
        <!-- <kendo-progressbar
          [class.green-bg1]="
            objectiveProgress.daily_percentage >=
            objectiveProgress.monthly_percentage
          "
          [value]="+objectiveProgress.daily_percentage"
          [label]="label"
          
        >
        </kendo-progressbar> -->
        <!-- <mat-progress-bar
          mode="determinate"
          [value]="+objectiveProgress.daily_percentage"
          style="border-radius: 10px; font-size: 20px"
          >{{ objectiveProgress.daily_percentage }}</mat-progress-bar
        > -->
        <mat-spinner *ngIf="booleanForObjectives" [diameter]="30"></mat-spinner>
        <mat-progress-bar
          *ngIf="!booleanForObjectives"
          mode="determinate"
          [value]="objectiveProgress.daily_percentage"
          style="border-radius: 10px; font-size: 20px"
        >
        </mat-progress-bar>
        <span
          *ngIf="!booleanForObjectives"
          class="k-relative"
          style="bottom: 23px; color: black !important"
        >
          {{ objectiveProgress.daily_percentage }}%</span
        >
        <!-- <span
            [ngStyle]="{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: objectiveProgress.daily_percentage + '%',
              color: 'white'
            }"
          >
            {{ objectiveProgress.daily_percentage }}%
          </span> -->
      </div>

      <!--<div
        class="col-sm-1"
        [class.setBgForComplete]="prog && prog.complete_state == 'Complete'"
        style="padding-top: 10px; padding-bottom: 10px"
      >
        <div
          class="k-display-flex justify-content-center buttonCircleWarehouse jumari-prajite"
          style="background-color: white"
          [class.buttonCircleWarehouse3Dig]="prog && i >= 99"
          [class.numberStyleWarehouse3Dig]="prog && i >= 99"
        >
          <h1 class="numberStyleWarehouse">{{ i + 1 }}</h1>
        </div>
      </div>
      <div
        class="col k-display-flex justify-content-center"
        style="padding-top: 10px; padding-bottom: 10px"
        [class.setBgForComplete]="prog && prog.complete_state == 'Complete'"
      >
        <kendo-progressbar
          style="
            position: relative;
            background: #f1f1f1;
            border-radius: 10px;
            right: 10px;
          "
          class="cacat2555 jumari-prajite"
          [class.green-bg1]="prog && prog.status_perc == 100"
          [value]="
            prog.status_perc == 100 ? prog.status_perc : prog.status_perc
          "
          [label]="label"
        >
        </kendo-progressbar>
      </div>-->
    </div>

    <div
      class="k-display-flex align-items-center"
      style="justify-content: space-around"
    >
      <div
        class="obiettivoDelGiorno"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <p
          style="
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            color: #ffffff;
          "
        >
          Obiettivo del mese:
        </p>
      </div>

      <div
        class="progressGiornoInfo"
        style="
          color: white;

          display: flex;
          flex-direction: column;
          align-items: center;
          width: 40%;
        "
      >
        <p *ngIf="bigOrdersClosed >= objectives.tp_monthly_objective">
          Sei stato così bravo che hai superato l'obiettivo di oggi! Ottimo
          lavoro!!!
        </p>
        <!-- <kendo-progressbar
          [class.green-bg1]="
            objectiveProgress.daily_percentage >=
            objectiveProgress.monthly_percentage
          "
          [value]="+objectiveProgress.daily_percentage"
          [label]="label"
          
        >
        </kendo-progressbar> -->
        <!-- <mat-progress-bar
          mode="determinate"
          [value]="+objectiveProgress.daily_percentage"
          style="border-radius: 10px; font-size: 20px"
          >{{ objectiveProgress.daily_percentage }}</mat-progress-bar
        > -->

        <mat-spinner *ngIf="booleanForObjectives" [diameter]="30"></mat-spinner>
        <mat-progress-bar
          *ngIf="!booleanForObjectives"
          mode="determinate"
          [value]="objectiveProgress.monthly_percentage"
          style="border-radius: 10px; font-size: 20px"
        >
        </mat-progress-bar>
        <span
          class="k-relative"
          style="bottom: 23px; color: black !important"
          *ngIf="!booleanForObjectives"
        >
          {{ objectiveProgress.monthly_percentage }}%</span
        >
        <!-- <span
            [ngStyle]="{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: objectiveProgress.daily_percentage + '%',
              color: 'white'
            }"
          >
            {{ objectiveProgress.daily_percentage }}%
          </span> -->
      </div>

      <!--<div
        class="col-sm-1"
        [class.setBgForComplete]="prog && prog.complete_state == 'Complete'"
        style="padding-top: 10px; padding-bottom: 10px"
      >
        <div
          class="k-display-flex justify-content-center buttonCircleWarehouse jumari-prajite"
          style="background-color: white"
          [class.buttonCircleWarehouse3Dig]="prog && i >= 99"
          [class.numberStyleWarehouse3Dig]="prog && i >= 99"
        >
          <h1 class="numberStyleWarehouse">{{ i + 1 }}</h1>
        </div>
      </div>
      <div
        class="col k-display-flex justify-content-center"
        style="padding-top: 10px; padding-bottom: 10px"
        [class.setBgForComplete]="prog && prog.complete_state == 'Complete'"
      >
        <kendo-progressbar
          style="
            position: relative;
            background: #f1f1f1;
            border-radius: 10px;
            right: 10px;
          "
          class="cacat2555 jumari-prajite"
          [class.green-bg1]="prog && prog.status_perc == 100"
          [value]="
            prog.status_perc == 100 ? prog.status_perc : prog.status_perc
          "
          [label]="label"
        >
        </kendo-progressbar>
      </div>-->
    </div>
  </div>
</div>
