<div class="k-overlay dark-background"></div>

<kendo-dialog
  title="{{
    !isEditable ? 'Dettagli Ordine Completato' : 'Dettagli Ordine Modifica'
  }}"
  (close)="goBackFunction()"
  [minWidth]="250"
  [width]="450"
  class="k-form"
  [formGroup]="orderDetailsForm"
>
  <hr class="hr-dialog" />
  <div>
    <form class="k-form dialog-form" [formGroup]="orderDetailsForm">
      <div class="column column-secret" *ngIf="order">
        <kendo-label
          class="order-text"
          text="N° / Nome ordine"
        ></kendo-label>
        <kendo-textbox
          #or_order_number
          [readonly]="!isEditable"
          rounded="full"
          [placeholder]="order.or_order_number"
          class="order-column"
        >
        </kendo-textbox>
      </div>

      <div class="row column-secret">
        <div class="col-md-6">
          <kendo-label class="order-text" text="Data ordine"></kendo-label>
          <kendo-textbox
            #or_order_date
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="order.or_assignment_date"
            class="order-column"
          >
          </kendo-textbox>
        </div>

        <div class="col-md-6">
          <kendo-label class="order-text" text="Data evasione"></kendo-label>
          <kendo-textbox
            #or_quote_date
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="order.or_closing_date"
            class="order-column"
          >
          </kendo-textbox>
        </div>
      </div>

      <div class="row column-secret">
        <div class="col-md">
          <kendo-label text="Cliente" class="order-text"></kendo-label>
          <kendo-textbox
            #or_customer
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="order.or_customer"
            class="order-column"
          >
          </kendo-textbox>
        </div>
        <div class="col-md-6">
          <kendo-label class="order-text" text="Corriere"></kendo-label>
          <kendo-textbox
            #carrier_desc
            [readonly]="!isEditable"
            rounded="full"
            placeholder=""
            class="order-column"
          >
          </kendo-textbox>
        </div>
      </div>

      <div class="row column-secret">
        <div class="col-md">
          <kendo-label class="order-text" text="N° articoli"></kendo-label>
          <kendo-textbox
            #boxes
            [readonly]="!isEditable"
            rounded="full"
            placeholder=""
            class="order-column"
          >
          </kendo-textbox>
        </div>

        <div class="col-md">
          <kendo-label
            [for]="boxes"
            class="order-text"
            text="N° colli"
          ></kendo-label>
          <kendo-textbox
            #boxes
            [readonly]="!isEditable"
            rounded="full"
            placeholder=""
            class="order-column"
          >
          </kendo-textbox>
        </div>
      </div>
      <div class="row column-secret">
        <div class="col-md" *ngIf="order">
          <kendo-label
            class="order-text"
            text="Visualizza prodotti"
          ></kendo-label>
          <kendo-dropdownlist
            [data]="order.products"
            textField="op_description"
            valueField="op_code"
            placeholder="Prodotti"
            class="dropdown-field"
          >
            <p class="order-column">{{ order.products }}</p>
            <p>{{ order.or_shipping_address }}</p>
          </kendo-dropdownlist>
        </div>
      </div>
      <div class="row column-secret">
        <div class="col-md">
          <kendo-label class="order-text" text="Via"></kendo-label>
          <kendo-textbox
            #or_shipping_address
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="order.or_shipping_address"
            class="order-column"
          >
          </kendo-textbox>
        </div>

        <div class="col-md">
          <kendo-label class="order-text" text="Città"></kendo-label>
          <kendo-textbox
            #or_shipping_city
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="order.or_shipping_city"
            class="order-column"
            placeholder="Città"
          >
          </kendo-textbox>
        </div>
      </div>

      <div class="row column-secret">
        <div class="col-md">
          <kendo-label class="order-text" text="CAP"></kendo-label>
          <kendo-textbox
            #or_shipping_zip
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="
              order.or_shipping_zip ? order.or_shipping_zip : 'cap'
            "
            class="order-column"
          >
          </kendo-textbox>
        </div>

        <div class="col-md">
          <kendo-label class="order-text" text="Provincia"></kendo-label>
          <kendo-textbox
            #or_shipping_prov
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="order.or_shipping_prov"
            class="order-column"
          >
          </kendo-textbox>
        </div>

        <div class="col-md">
          <kendo-label class="order-text" text="Nazione"></kendo-label>
          <kendo-textbox
            #or_shipping_country
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="order.or_shipping_country"
            class="order-column"
          >
          </kendo-textbox>
        </div>
      </div>
      <div class="text-buttons">
        <div class="button-right">
          <button kendoButton (click)="goBackFunction()" class="button-r">
            INDIETRO
          </button>
        </div>
        <div class="button-left">
          <button kendoButton class="button-l">MODIFICA</button>
        </div>
      </div>
    </form>
  </div>
</kendo-dialog>
