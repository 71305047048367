import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import packageJson from '../../../../package.json';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {

  @ViewChild('password') public textbox!: TextBoxComponent;
  invalidLogin = false;
  wrongLogin = '';
  ipAddress = '';

  public desktopVersion: string = packageJson.version;

  constructor(
    private authServ: AuthService,
    private router: Router,
    private http: HttpClient,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.getIPAddress();
  }

  public ngAfterViewInit(): void {
    this.textbox.input.nativeElement.type = 'password';
  }

  public toggleVisibility(): void {
    const inputEl = this.textbox.input.nativeElement;
    inputEl.type = inputEl.type === 'password' ? 'text' : 'password';
  }

  getIPAddress() {
    this.http.get('http://api.ipify.org/?format=json').subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  public loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,4}$'),
    ]),
    password: new FormControl('', Validators.required),
    keepLogged: new FormControl(),
  });

  public login(formValues: any): void {
    this.loginForm.markAllAsTouched();
    this.authServ.clearUser();
    this.authServ
      .login(formValues.email, formValues.password, this.ipAddress)
      .subscribe((res: any) => {
        switch (res?.error_message?.msg_code) {
          case 0:
            this.router.navigate([this.authServ.stateUrl]);
            Swal.fire({
              icon: 'success',
              title: `Diakonix ${this.desktopVersion}`,
              text: 'Buon lavoro! ;)',
              timer: 3000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false
            });
            break;
          case 9:
            this.showNotification('Le password non coincidono', 'error');

            return;
          case 12:
            this.showNotification('Utente non attivo', 'error');
            return;
          case 13:
            this.showNotification('Account scaduto', 'error');
            return;
          case 14:
            this.showNotification('Utente inesistente', 'error');
            return;
          default:
            this.showNotification('Si è verificato un problema', 'warning');
            return;
        }
      });
  }

  public clearForm(): void {
    this.loginForm.reset();
  }

  public showNotification(
    messageText: string,
    styleType: 'none' | 'success' | 'warning' | 'error' | 'info'
  ): void {
    this.notificationService.show({
      content: messageText,
      hideAfter: 600,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 2000 },
      type: { style: styleType, icon: true },
    });
  }
  
}
