import { Pipe, PipeTransform } from '@angular/core';
import { isSameMonth, parseISO, getMonth } from 'date-fns';

@Pipe({
  name: 'month',
})
export class MonthPipe implements PipeTransform {
  transform(value: any[], ...args: any[]): any[] | null {
    if (!value || !args || args.length === 0) {
      return value;
    }

    const [monthArg] = args[0];
    if (!monthArg) {
      return value;
    }

    const selectedMonth = parseInt(monthArg, 10); // Parse the month argument to integer

    if (monthArg === '00') return value;
    return value.filter((item) => {
      const itemDate = parseISO(item.or_order_date);
      if (isNaN(itemDate.getTime())) {
        return false; // Filter out invalid dates
      }

      const itemMonth = getMonth(itemDate) + 1; // getMonth() returns 0-based index

      return itemMonth === selectedMonth;
    });
  }
}
