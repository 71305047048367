import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Helpers } from 'src/app/models/helpters';
import { Orders, OrderSignature } from 'src/app/models/Orders.model';
import { Priority } from 'src/app/models/Priority.model';
import { NewProduct, Products } from 'src/app/models/Product.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { PopoverController } from '@ionic/angular';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';

export interface ImageInfo {
  src: string;
  width: number;
  height: number;
}

@Component({
  selector: 'order-progress',
  templateUrl: './order-progress.html',
  styleUrls: ['./order-progress.scss'],
})

export class OrderProgress implements OnInit {

  @Input() confirmed: boolean;
  @Input() net_value: number;
  @Output() public valueChange: EventEmitter<ImageInfo> =
    new EventEmitter<ImageInfo>();

  triggerFunctionSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public uploadSaveUrl =
    'http://salvatempo.connecta.ovh/api/services/put_signature';
  public isSignOn = false;
  public alertButtons = ['OK'];
  public dataSaved = false;
  public assignOrderForm: FormGroup;
  public orderNew: Orders.IOrderNew;
  public productsNew: Products.IProductToSend[] = [];
  public products: Products.Product[];
  public orderToSend: Orders.IOrderToSend;
  public formattedDate: string = '-';
  public priorityOptions: Priority.IPriority[] = [];
  public product: NewProduct[] = [];
  public addOrder: Orders.IOrder;
  public assignOrder: Orders.IAddOrder[] = [];
  public order: Orders.Order;
  public orders: Orders.OrderList[] = [];
  public dropProducts: Products.Product[];
  public value = '';
  public showUpload = false;
  public color = '';
  public backgroundColor = '';
  public strokeWidth = 3;
  public imageURL?: SafeUrl;
  public uploadRemoveUrl = '';
  public orderToUpdate: Orders.OrderUpdate;

  public sizes = [
    {
      text: 'Normal',
      click: () => (this.strokeWidth = 1),
    },
    {
      text: 'Wide',
      click: () => (this.strokeWidth = 3),
    },
  ];

  public signatureData: OrderSignature = {
    id: 0,
    signature_b64: '',
  };

  staticOrder = Helpers;
  id: number;
  pageName: string;
  pageChild: number = 0;
  maxChild: number = 4;
  sameIndex: boolean = true;
  clickedProduct: any;
  clickedBox: any;
  isCheckBox: any;
  stepCount: number = 0;
  isChecked = false;
  prodNo: any;
  firstEnteredOnPage: boolean = true;
  checkedProd: boolean = false;
  checkedBox: boolean = false;
  checkedDocs: boolean = false;
  status_desc: string;
  selectProduct = 'Select a product';
  step = 0;
  shortLink: string = '';
  loading: boolean = false;
  show: boolean = false;
  file: OrderSignature;
  selected = '';
  checkProduct: any;
  checkDoc: any;
  checkShip: any;
  productChecker: boolean = false;
  defaultItem: any;
  isDDT: any;
  messageFromSync: string;
  openMessageWarning: boolean = false;
  generalBehaviour: boolean = false;
  steps = [
    {
      index: 1,
      title: 'Raccolta prodotti',
      buttonText: 'SCEGLI I PRODOTTI',
      function: () => this.seeCheckProduct(this.checkProduct),
    },
    {
      index: 2,
      title: 'Raccolta documenti',
      buttonText: 'SCEGLI I DOCUMENTI',
      function: () => this.seeCheckDoc(this.checkProduct),
    },
    {
      index: 3,
      title: 'Imballaggio',
      buttonText: 'SCEGLI I COLLI',
      function: () => this.seeCheckBox(this.checkProduct),
    },
    {
      index: 4,
      title: 'Spedizione',
      buttonText: 'VERIFICA INFO',
      function: () => this.seeCheckShipment(this.checkProduct),
    },
  ];

  hiddenDisplay: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public ordersServ: OrdersService,
    private formBuilder: FormBuilder,
    private router: Router,
    public closeServ: OpenModalService,
    public popoverCtrl: PopoverController,
    public openModal: OpenModalService
  ) { }

  ngOnInit(): void {
    this.closeServ.generalSubject.subscribe((isOpen: boolean) => {
      this.generalBehaviour = isOpen;
    });
    this.triggerFunctionSubject.subscribe((value) => {
      if (value) {
        this.reloadFunction();
      }
    });
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.reloadFunction();
      }
    });
    this.reloadFunction();
    this.checkShippy();
    this.checkBoxy();
    this.checkProddi();
    this.CheckDochy();
    this.checkDony();
  }

  back() {
    this.router.navigate([`/prepare-order`]);
  }

  handleValueChange(event: boolean) {
    console.log('EVENTUS IS', event);
    if (event) {
      this.reloadFunction();
    }
  }

  onFileLoad(event: any) {
    console.debug('asta e eventul', event);
    {
      this.signatureData = {
        id: 0,
        signature_b64: '',
      };
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.signatureData.id = this.order.order_id;
        console.debug(reader);
        this.signatureData.signature_b64 = reader.result?.toString();
        this.ordersServ.signature = this.signatureData;
        this.signatureData.signature_b64 =
          this.signatureData.signature_b64?.substring(
            Helpers.base64Length + Helpers.base64PNGLength
          );
        return;
        this.ordersServ.saveSignature(this.signatureData).subscribe();
      };
      reader.onerror = (error) => {};
    }
  }

  onChange(event: any) {
    this.file = event.target.files[0];
    this.show = true;
    this.onUpload();
  }

  onUpload() {
    this.loading = !this.loading;
    console.log('This signature is: ', this.file.signature_b64);
    this.signatureData.signature_b64 = this.file.signature_b64?.toString();
    console.log(this.file);
    this.ordersServ
      .saveSignature(this.signatureData)
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          this.shortLink = event.link;
          this.loading = false;
        }
      });
  }

  public ngOnDestroy() {
    console.log('Sunt distrus');
  }

  public onSave() {
    console.debug(this.value);
    console.debug('Value: ', this.file);
    this.loading = !this.loading;
    this.signatureData.signature_b64 = this.value?.substring(22);
    console.log('This new sign is: ', this.signatureData.signature_b64);
    this.signatureData.id = this.order.order_id;
    this.ordersServ
      .saveSignature(this.signatureData)
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          this.shortLink = event.link;
          this.loading = false;
        }
      });
  }

  update(e) {
    this.selected = e.target.value;
  }

  seeCheckProduct(product_id) {
    this.checkProduct = product_id;
    this.closeServ.openCheck();
  }

  seeCheckBox(product_id) {
    this.isCheckBox = product_id;
    this.closeServ.openCheckBox();
  }

  seeCheckShipment(product_id) {
    this.checkShip = product_id;
    this.closeServ.shipOn();
  }

  checkProddi() {
    this.closeServ.proddi.subscribe((result) => {
      console.log('Boxy is: ', result);
      if (!result) {
        this.ordersServ
          .getOrder(`${this.id}`)
          .subscribe((orderData: IWsResult) => {
            this.order = new Orders.Order(orderData.data);
            this.dropProducts = this.order.products;
            console.log('The products are: ', this.dropProducts);
            if (
              this.order.status[0].os_status_order_cod > 1 &&
              this.order.status[0].os_status_order_cod < 7 &&
              this.firstEnteredOnPage
            ) {
              this.order.products.forEach((element) => {
                element.op_checked = true;
                element.orQtaNeeded = element.op_qta;
              });
              this.firstEnteredOnPage = false;
            }
            this.products = orderData.data;
            console.debug('This order neprocesat: ', this.order);
            this.order = new Orders.Order(orderData.data);
            console.debug('This order procesat: ', this.order);
            this.productsNew = this.order.products.map((item) => {
              let product: Products.IProductToSend = {
                op_note: '',
                op_checked: false,
                op_code: item.op_code,
                op_description: item.op_description,
                op_qta: item.op_qta,
                op_weigth: item.op_weigth,
                op_stock: item.op_stock,
                orQtaNeeded: +item.op_qta,
                op_qta_ordered: '',
                op_positions: [],
                tab_order_status_cod: [],
              };
              return product;
            });
            if (this.order.or_order_date)
              this.formattedDate = formatDate(
                this.order.or_order_date,
                'dd/MM/yyyy',
                'it'
              );
            this.assignOrderForm.patchValue({
              or_order_number: this.order.or_order_number,
              or_order_date: this.formattedDate,
              or_order_priority_cod: 'Select',
              carrier_id: 'random text',
              or_customer: this.order.or_customer,
              or_shipping_address: this.order.or_shipping_address,
              or_shipping_zip: this.order.or_shipping_zip,
              or_shipping_city: this.order.or_shipping_city,
              or_shipping_prov: this.order.or_shipping_prov,
              or_shipping_country: this.order.or_shipping_country,
            });
          });
        if (this.confirmed === true) {
          this.closeServ.confirmed;
        }
      }
    });
  }

  checkBoxy() {
    this.closeServ.boxy.subscribe((result) => {
      console.log('Boxy is: ', result);
      if (!result) {
        this.ordersServ
          .getOrder(`${this.id}`)
          .subscribe((orderData: IWsResult) => {
            console.log('Boxy result: ', result);
            this.order = new Orders.Order(orderData.data);
            if (
              this.order.status[0].os_status_order_cod > 1 &&
              this.order.status[0].os_status_order_cod < 7 &&
              this.firstEnteredOnPage
            ) {
              this.order.products.forEach((element) => {
                element.op_checked = true;
                element.orQtaNeeded = element.op_qta;
              });
              this.firstEnteredOnPage = false;
            }
            this.products = orderData.data;
            console.debug('This order neprocesat: ', this.order);
            console.debug('This order procesat: ', this.order);
            this.assignOrderForm.patchValue({
              or_order_number: this.order.or_order_number,
              or_order_date: this.formattedDate,
              or_order_priority_cod: 'Select',
              carrier_id: 'random text',
              or_customer: this.order.or_customer,
              or_shipping_address: this.order.or_shipping_address,
              or_shipping_zip: this.order.or_shipping_zip,
              or_shipping_city: this.order.or_shipping_city,
              or_shipping_prov: this.order.or_shipping_prov,
              or_shipping_country: this.order.or_shipping_country,
            });
          });
        if (this.confirmed === true) {
          this.closeServ.confirmed;
        }
      }
    });
  }

  checkShippy() {
    this.closeServ.shippy.subscribe((result) => {
      console.log('Shippy is: ', result);
      if (!result) {
        this.ordersServ
          .getOrder(`${this.id}`)
          .subscribe((orderData: IWsResult) => {
            this.order = new Orders.Order(orderData.data);
            if (
              this.order.status[0].os_status_order_cod > 1 &&
              this.order.status[0].os_status_order_cod < 7 &&
              this.firstEnteredOnPage
            ) {
              this.order.products.forEach((element) => {
                element.op_checked = true;
                element.orQtaNeeded = element.op_qta;
              });
              this.firstEnteredOnPage = false;
            }
            this.products = orderData.data;
            console.debug('This order neprocesat: ', this.order);
            this.order = new Orders.Order(orderData.data);
            console.debug('This order procesat: ', this.order);
            this.productsNew = this.order.products.map((item) => {
              let product: Products.IProductToSend = {
                op_note: '',
                op_checked: false,
                op_code: item.op_code,
                op_description: item.op_description,
                op_qta: item.op_qta,
                op_weigth: item.op_weigth,
                op_stock: item.op_stock,
                orQtaNeeded: +item.op_qta,
                op_qta_ordered: '',
                op_positions: [],
                tab_order_status_cod: [],
              };
              return product;
            });
            if (this.order.or_order_date)
              this.formattedDate = formatDate(
                this.order.or_order_date,
                'dd/MM/yyyy',
                'it'
              );
            this.assignOrderForm.patchValue({
              or_order_number: this.order.or_order_number,
              or_order_date: this.formattedDate,
              or_order_priority_cod: 'Select',
              carrier_id: 'random text',
              or_customer: this.order.or_customer,
              or_shipping_address: this.order.or_shipping_address,
              or_shipping_zip: this.order.or_shipping_zip,
              or_shipping_city: this.order.or_shipping_city,
              or_shipping_prov: this.order.or_shipping_prov,
              or_shipping_country: this.order.or_shipping_country,
            });
          });
        if (this.confirmed === true) {
          this.closeServ.confirmed;
        }
      }
    });
  }

  CheckDochy() {
    this.closeServ.dochy.subscribe((result) => {
      console.log('Shippy is: ', result);
      if (!result) {
        this.ordersServ
          .getOrder(`${this.id}`)
          .subscribe((orderData: IWsResult) => {
            this.order = new Orders.Order(orderData.data);
            if (
              this.order.status[0].os_status_order_cod > 1 &&
              this.order.status[0].os_status_order_cod < 7 &&
              this.firstEnteredOnPage
            ) {
              this.order.products.forEach((element) => {
                element.op_checked = true;
                element.orQtaNeeded = element.op_qta;
              });
              this.firstEnteredOnPage = false;
            }
            this.products = orderData.data;
            console.debug('This order neprocesat: ', this.order);
            this.order = new Orders.Order(orderData.data);
            console.debug('This order procesat: ', this.order);
            this.productsNew = this.order.products.map((item) => {
              let product: Products.IProductToSend = {
                op_note: '',
                op_checked: false,
                op_code: item.op_code,
                op_description: item.op_description,
                op_qta: item.op_qta,
                op_weigth: item.op_weigth,
                op_stock: item.op_stock,
                orQtaNeeded: +item.op_qta,
                op_qta_ordered: '',
                op_positions: [],
                tab_order_status_cod: [],
              };
              return product;
            });
            if (this.order.or_order_date)
              this.formattedDate = formatDate(
                this.order.or_order_date,
                'dd/MM/yyyy',
                'it'
              );
            this.assignOrderForm.patchValue({
              or_order_number: this.order.or_order_number,
              or_order_date: this.formattedDate,
              or_order_priority_cod: 'Select',
              carrier_id: 'random text',
              or_customer: this.order.or_customer,
              or_shipping_address: this.order.or_shipping_address,
              or_shipping_zip: this.order.or_shipping_zip,
              or_shipping_city: this.order.or_shipping_city,
              or_shipping_prov: this.order.or_shipping_prov,
              or_shipping_country: this.order.or_shipping_country,
            });
          });
        if (this.confirmed === true) {
          this.closeServ.confirmed;
        }
      }
    });
  }

  checkDony() {
    this.closeServ.compli.subscribe((result) => {
      console.log('Shippy is: ', result);
    });
  }

  goToProductDetails(product) {
    for (let prod of this.productsNew) {
      prod.op_code = this.clickedProduct;
      this.clickedProduct = product;
      this.closeServ.openProd();
      console.debug('Merge?');
    }
  }

  seeCheckDoc(product_id) {
    this.checkDoc = product_id;
    this.closeServ.openDoc();
  }

  goToAddBox(box_id) {
    this.clickedBox = box_id;
  }

  perc: any;

  reloadFunction() {
    console.log('Reloading...');
    this.assignOrderForm = this.formBuilder.group({
      or_order_number: [''],
      or_order_date: [''],
      or_order_priority_cod: ['', Validators.required],
      carrier_id: ['', Validators.required],
      or_customer: [''],
      or_shipping_address: [''],
      or_shipping_zip: [''],
      or_shipping_city: [''],
      or_shipping_prov: [''],
      or_shipping_country: [''],
      or_assigned_id: ['', Validators.required],
      op_code: [''],
    });
    this.ordersServ.getOrder1(this.id!).subscribe((orderData) => {
      this.order = orderData;
      console.log('This order is', this.order);
      console.log(
        "Let's see the perc ",
        this.order.vs_details.tab_order_status_perc
      );
      this.perc = this.order.vs_details.tab_order_status_perc;
      this.order.vs_details.tab_order_status_perc > 0 &&
      this.order.vs_details.tab_order_status_perc < 10
        ? (this.perc = '0.0' + this.perc)
        : (this.perc = '0.' + this.perc);
      // this.order.status[0].tab_order_status_perc > 0 &&
      // this.order.status[0].tab_order_status_perc < 10
      //   ? (this.perc = '0.0' + this.perc)
      //   : (this.perc = '0.' + this.perc);
      console.log('Perc is', this.perc);
      console.log('Type of perc is', typeof this.perc);
      console.log('Type of perc is', typeof +this.perc);
      this.ordersServ.pages.next(this.order.stepCount);
      if (
        this.order.status[0].os_status_order_cod > 1 &&
        this.order.status[0].os_status_order_cod < 7 &&
        this.firstEnteredOnPage
      ) {
        this.order.products.forEach((element) => {
          element.op_checked = true;
          element.orQtaNeeded = element.op_qta;
        });
        this.firstEnteredOnPage = false;
      }
      this.products = orderData.products;
      console.debug('This order procesat: ', this.order);
      this.isDDT = orderData.or_url_ddt?.indexOf('.pdf') > 0;
      this.productsNew = this.order.products.map((item) => {
        let product: Products.IProductToSend = {
          op_note: '',
          op_checked: false,
          op_code: item.op_code,
          op_description: item.op_description,
          op_qta: item.op_qta,
          op_weigth: item.op_weigth,
          op_stock: item.op_stock,
          orQtaNeeded: +item.op_qta,
          op_qta_ordered: '',
          op_positions: [],
          tab_order_status_cod: [],
        };
        return product;
      });
      if (this.order.or_order_date)
        this.formattedDate = formatDate(
          this.order.or_order_date,
          'dd/MM/yyyy',
          'it'
        );
      this.assignOrderForm.patchValue({
        or_order_number: this.order.or_order_number,
        or_order_date: this.formattedDate,
        or_order_priority_cod: this.order.or_order_priority_cod,
        carrier_id: this.order.or_carrier_id,
        or_customer: this.order.or_customer,
        or_shipping_address: this.order.or_shipping_address,
        or_shipping_zip: this.order.or_shipping_zip,
        or_shipping_city: this.order.or_shipping_city,
        or_shipping_prov: this.order.or_shipping_prov,
        or_shipping_country: this.order.or_shipping_country,
      });
    });
    if (this.confirmed === true) {
      this.closeServ.confirmed;
    }
  }

  showRaccoltaModal() {
    console.log('General subject is');
    this.openModal.openGeneralSubject();
  }

}
