<h1 class="page-title">Storico Ordini</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <form
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <div
      class="inputSearchContainer"
      style="display: flex; flex-direction: column; align-items: flex-end"
    >
      <i
        *ngIf="filterTerm"
        class="bx bxs-x-circle bx-md clearSearchIcon"
        style="
          transform: scale(0.8);
          color: red;
          /* background: #0c65e9; */
          position: relative;
          top: 42px;
          z-index: 10001;
        "
        (click)="clearSearch()"
      ></i>
      <input
        [(ngModel)]="filterTerm"
        id="search"
        name="search"
        pattern=".*\S.*"
        type="search"
        placeholder="Cerca ordine..."
        autofocus
        required
        (input)="this.onInputChanged($event)"
      />
    </div>
    <button type="submit" (click)="searchOrders()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: end !important;
      visibility: hidden !important
      
    "
  >
    <h5 class="col-header">
      Totale Fatturato:<br />
      {{ netValue | currency : "EUR" : "symbol" : "1.2-2" }}
    </h5>
  </form>

  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
  </div>
</div>

<hr class="hr-newBlue" />

<div
  class="row k-display-flex justify-content-end align-items-center"
  style="padding-left: 20px"
>
  <div style="display: flex; flex-direction: row; justify-content: center">
    <h2 class="col-header">
      Totale ordini completati: {{ ordersStock.length }}
    </h2>
  </div>

  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <div class="grid-wrapper" *ngIf="!loading">
    <kendo-grid
      [kendoGridBinding]="ordersStock"
      [data]="
        ordersStock
          | byCustomerName : customer
          | byDatePipe : date
          | byToClosingDate
            : [
                filters.startingDate,
                filters.endDate,
                filters.dateOrginizedBy.value
              ]
      "
      [rowHeight]="36"
      [height]="750"
      [pageSize]="pageSize"
      scrollable="virtual"
      [navigable]="true"
      [sortable]="true"
      [reorderable]="true"
      [rowClass]="roWow"
    >
      <kendo-grid-column
        [width]="100"
        field="or_order_number"
        title="N° Ordine"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
        filter="numeric"
      >
      </kendo-grid-column>

      <kendo-grid-column
        [width]="120"
        field="or_order_date"
        title="Data ordine"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem?.or_order_date | date : "dd/MM/yyyy" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="500"
        field="or_customer"
        title="Cliente"
        [headerStyle]="{ 'justify-content': 'left' }"
        [style]="{
          display: 'grid',
          'justify-content': 'left',
          'tetx-align': 'left'
        }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_customer }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="200"
        field="ac_last_name"
        title="Operatore"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.ac_first_name }} {{ dataItem.ac_last_name }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="130"
        field="or_closed_date"
        title="Data evasione"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_closed_date | date : "dd/MM/yyyy" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column
        title="Dettagli"
        [width]="100"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="goToDetails(dataItem.order_id)"
          >
            <div class="setDisplayToFlexBlue btn-det">
              <i class="bx bx-info-circle bx-md" style="margin: 0; padding: 0">
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>

      <ng-template kendoGridNoRecordsTemplate>
        <div class="no-records-message">
          <p>Nessun dato disponibile</p>
        </div>
      </ng-template>
    </kendo-grid>
  </div>
</div>

<app-storico-ordini-dettagli
  *ngIf="closeSrv.isStorico"
  [opened]="closeSrv.isStorico"
  [order_id]="clickedOrder"
>
</app-storico-ordini-dettagli>
