import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Orders } from 'src/app/models/Orders.model';
import { Products } from 'src/app/models/Product.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';
import { PreparazioneOrdiniDetaggliComponent } from '../../preparazione-ordini-detaggli.component';

@Component({
  selector: 'app-products-qrcodes',
  templateUrl: './products-qrcodes.component.html',
  styleUrls: ['./products-qrcodes.component.scss'],
})

export class ProductsQRCODESComponent implements OnInit {

  @Input() openedQRS: boolean = false;
  @Input() product: Products.Product;
  @Input() order: Orders.Order;
  @Output() public valueChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  barcodes_text: string = '';
  index: number;

  constructor(
    public closeServ: OpenModalService,
    private orderService: OrdersService,
    private parentComponent: PreparazioneOrdiniDetaggliComponent
  ) { }

  ngOnInit(): void {
    console.log('MAMA MEA E FLORAREASA', this.product);
    console.log('Order is deine', this.order);
    console.log('THE INDEX IS', this.order.products.indexOf(this.product));
    this.index = this.order.products.indexOf(this.product);
    console.log('Antoher index', this.index);
    this.order.products[this.index].barcodes.forEach((element) => {
      // if (element.barcode_text === null) {
      //   element.barcode_text = this.product.op_code.trim();
      //   console.log('Element is', element);
      // }
    });
    // this.product.barcodes.forEach((element) => {
    // });
  }

  close() {
    this.closeServ.seeQROFF();
  }

  deleteBarcode(barcode: Products.Barcodes) {
    console.log('Barcode is', barcode);
    barcode.barcode_text = null;
    console.log('The product is', this.product);
    console.log('This.products barcodes are', this.product.barcodes);
  }

  updateOrder() {
    // Step 1: Find the product that matches this.product.indexNo
    const foundIndex = this.order.products.findIndex(
      (element) => element.indexNo === this.product.indexNo
    );
    // Step 2: Check if the product was found
    if (foundIndex !== -1) {
      console.log('Found the element', this.order.products[foundIndex]);
      // Step 3: Replace the product in the array
      this.order.products[foundIndex] = this.product;
      // let orderToUpdate = new Orders.OrderUpdate(this.order);
      this.orderService.updateLazyOrder(this.order).subscribe((data) => {
        console.log('Data', data);
        if (data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Modifiche!',
            text: "Modifiche dell'QR con successo!",
            timer: 1500,
            timerProgressBar: true,
          });
          // console.log('EMITING VALUE', this.valueChange);
          this.parentComponent.triggerFunctionSubject.next(true);
          this.closeServ.seeQROFF();
        }
      });

      console.log('Updated order products are', this.order.products);
    } 
    else {
      console.log('Element not found');
    }
  }

}
