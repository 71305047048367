<h1 class="page-title">Dashboard</h1>
<hr class="hr-newBlue" />
<div
  *ngIf="loading"
  style="display: flex; flex-direction: column; align-items: center"
>
  <mat-spinner></mat-spinner>
  <p>Attendi..</p>
</div>

<div class="bigusSearchusContainerus" *ngIf="!loading">
  <div class="searchContainer">
    <div class="containerChecks k-display-flex k-justify-content-center">
      <button
        (click)="checkBoolean('1')"
        (click)="checkedOrder = !checkedOrder"
        [disabled]="checkedCliente || checkedNotes || checkedTracking"
        class="k-display-flex mx-3"
        *ngIf="!checkedOrder"
        style="border: none; background: none; padding: 0; color: #017efa"
      >
        <i class="bx bx-circle bx-md mx-1" style="color: #0658a9"></i>
        <p style="margin: 0; font-size: 28px" class="p-style-title">Ordine</p>
      </button>

      <button
        (click)="checkBoolean('1')"
        (click)="checkedOrder = !checkedOrder"
        class="k-display-flex mx-3"
        *ngIf="checkedOrder"
        style="border: none; background: none; padding: 0; color: #017efa"
      >
        <i class="bx bx-check-circle bx-md mx-1" style="color: #0658a9"></i>
        <p style="margin: 0; font-size: 28px" class="p-style-title">Ordine</p>
      </button>

      <button
        (click)="checkBoolean('2')"
        (click)="checkedCliente = !checkedCliente"
        [disabled]="checkedOrder || checkedNotes || checkedTracking"
        class="k-display-flex mx-3"
        *ngIf="!checkedCliente"
        style="border: none; background: none; padding: 0; color: #017efa"
      >
        <i class="bx bx-circle bx-md mx-1" style="color: #0658a9"></i>
        <p style="margin: 0; font-size: 28px" class="p-style-title">Cliente</p>
      </button>
      <button
        (click)="checkBoolean('2')"
        (click)="checkedCliente = !checkedCliente"
        [disabled]="checkedOrder || checkedNotes || checkedTracking"
        class="k-display-flex mx-3"
        *ngIf="checkedCliente"
        style="border: none; background: none; padding: 0; color: #017efa"
      >
        <i class="bx bx-check-circle bx-md mx-1" style="color: #0658a9"></i>
        <p style="margin: 0; font-size: 28px" class="p-style-title">Cliente</p>
      </button>

      <button
        (click)="checkBoolean('3')"
        (click)="checkedNotes = !checkedNotes"
        [disabled]="checkedOrder || checkedCliente || checkedTracking"
        class="k-display-flex mx-3"
        *ngIf="!checkedNotes"
        style="border: none; background: none; padding: 0; color: #017efa"
      >
        <i class="bx bx-circle bx-md mx-1" style="color: #0658a9"></i>
        <p style="margin: 0; font-size: 28px" class="p-style-title">
          Riferimento
        </p>
      </button>
      <button
        (click)="checkBoolean('3')"
        (click)="checkedNotes = !checkedNotes"
        [disabled]="checkedOrder || checkedCliente || checkedTracking"
        class="k-display-flex mx-3"
        *ngIf="checkedNotes"
        style="border: none; background: none; padding: 0; color: #017efa"
      >
        <i class="bx bx-check-circle bx-md mx-1" style="color: #0658a9"></i>
        <p style="margin: 0; font-size: 28px" class="p-style-title">
          Riferimento
        </p>
      </button>

      <button
        (click)="checkBoolean('4')"
        (click)="checkedTracking = !checkedTracking"
        [disabled]="checkedOrder || checkedCliente || checkedNotes"
        class="k-display-flex mx-3"
        *ngIf="!checkedTracking"
        style="border: none; background: none; padding: 0; color: #017efa"
      >
        <i class="bx bx-circle bx-md mx-1" style="color: #0658a9"></i>
        <p style="margin: 0; font-size: 28px" class="p-style-title">Tracking</p>
      </button>
      <button
        (click)="checkBoolean('4')"
        (click)="checkedTracking = !checkedTracking"
        [disabled]="checkedOrder || checkedCliente || checkedNotes"
        class="k-display-flex mx-3"
        *ngIf="checkedTracking"
        style="border: none; background: none; padding: 0; color: #017efa"
      >
        <i class="bx bx-check-circle bx-md mx-1" style="color: #0658a9"></i>
        <p style="margin: 0; font-size: 28px" class="p-style-title">Tracking</p>
      </button>
    </div>
    <div class="k-display-flex my-2" style="width: 20%; margin: 0 auto">
      <h5 class="text-center mx-2" style="width: 130px; margin-top: 3px">
        DAL:
      </h5>
      <kendo-datepicker
        [(ngModel)]="endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
        (valueChange)="checkData()"
      ></kendo-datepicker>
    </div>
    <div
      class="col k-display-flex k-align-items-center"
      style="justify-content: center; border-bottom-left-radius: 10px"
    >
      <input
        [(ngModel)]="search"
        type="text"
        placeholder="Cerca.. "
        rounded="full"
        class="input-kendo-lookalike"
        style="text-align: center"
      />
      <button
        class="btn btn-primary searchButton"
        rounded="full"
        [disabled]="this.formattedDate === ''"
        (click)="searchInfoOrders(this.formattedDate, search)"
      >
        <i class="bx bx-sm bx-search"></i>
        CERCA
      </button>
    </div>
  </div>

  <div class="container text-center mt-4" style="border-radius: 15px">
    <div class="row rowRadius">
      <div class="col colAllign">Ordini da assegnare</div>
      <div
        class="col colAllign"
        style="border-left: 1px solid; border-right: 1px solid"
      >
        Ordini da Preparare:
      </div>
      <div class="col colAllign">Ordini Parziali:</div>
    </div>
    <div class="row">
      <div
        class="col k-display-flex k-align-items-center colInfo"
        style="border-bottom-left-radius: 10px"
      >
        <!-- <div
        *ngIf="loading || orderInfo.t_or_new === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            orderInfo.t_or_new === -1 ? 'Attendi..' : orderInfo.t_or_new
          "
          style="padding-right: 40px"
        />

        <!-- routerLink="/assign-order" -->
        <button
          class="vaiButton"
          rounded="full"
          routerLink="/assign-order"
          [queryParams]="dynamicLink"
          *ngIf="orderInfo.t_or_new !== -1 && orderInfo.t_or_new !== 0"
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>

      <div class="col k-display-flex k-align-items-center colInfo">
        <!-- <div
        *ngIf="loading || orderInfo.t_or_prepare === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            loading || orderInfo.t_or_prepare === -1
              ? 'Attendi..'
              : orderInfo.t_or_prepare
          "
          style="padding-right: 40px"
        />
        <button
          class="vaiButton"
          rounded="full"
          routerLink="/prepare-order"
          [queryParams]="dynamicLink"
          *ngIf="orderInfo.t_or_prepare !== -1 && orderInfo.t_or_prepare !== 0"
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>

      <div
        class="col k-display-flex k-align-items-center colInfo"
        style="border-bottom-right-radius: 10px"
      >
        <!-- <div
        *ngIf="loading || orderInfo.t_or_partial === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            orderInfo.t_or_partial === -1 ? 'Attendi..' : orderInfo.t_or_partial
          "
          style="padding-right: 40px"
        />
        <button
          class="vaiButton"
          rounded="full"
          routerLink="/partial-orders"
          [queryParams]="dynamicLink"
          *ngIf="orderInfo.t_or_partial !== -1 && orderInfo.t_or_partial !== 0"
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>
    </div>
  </div>

  <div class="container text-center mt-4" style="border-radius: 15px">
    <div class="row rowRadius">
      <div class="col colAllign">Ordini PM:</div>
      <div
        class="col colAllign"
        style="border-left: 1px solid; border-right: 1px solid"
      >
        Ordini da Firmare:
      </div>
      <div class="col colAllign">Ordini Completati:</div>
    </div>
    <div class="row">
      <div
        class="col k-display-flex k-align-items-center colInfo"
        style="border-bottom-left-radius: 10px"
      >
        <!-- <div
        *ngIf="loading || orderInfo.t_or_cm === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            orderInfo.t_or_cm === -1 ? 'Attendi..' : orderInfo.t_or_cm
          "
          style="padding-right: 40px"
        />
        <button
          class="vaiButton"
          rounded="full"
          routerLink="/pm-orders"
          [queryParams]="dynamicLink"
          *ngIf="orderInfo.t_or_cm !== -1 && orderInfo.t_or_cm !== 0"
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>

      <div class="col k-display-flex k-align-items-center colInfo">
        <!-- <div
        *ngIf="loading || orderInfo.t_or_to_sign === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            orderInfo.t_or_to_sign === -1 ? 'Attendi..' : orderInfo.t_or_to_sign
          "
          style="padding-right: 40px"
        />
        <button
          class="vaiButton"
          rounded="full"
          routerLink="/firmare"
          [queryParams]="dynamicLink"
          *ngIf="orderInfo.t_or_to_sign !== -1 && orderInfo.t_or_to_sign !== 0"
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>

      <div
        class="col k-display-flex k-align-items-center colInfo"
        style="border-bottom-right-radius: 10px"
      >
        <!-- <div
        *ngIf="loading || orderInfo.t_or_completed === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            orderInfo.t_or_completed === -1
              ? 'Attendi..'
              : orderInfo.t_or_completed
          "
          style="padding-right: 40px"
        />
        <button
          class="vaiButton"
          rounded="full"
          routerLink="/history"
          [queryParams]="dynamicLink"
          *ngIf="
            orderInfo.t_or_completed !== -1 && orderInfo.t_or_completed !== 0
          "
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>
    </div>
  </div>

  <div class="container text-center mt-4" style="border-radius: 15px">
    <div class="row rowRadius">
      <div class="col colAllign">Borderò generati:</div>
      <div
        class="col colAllign"
        style="border-left: 1px solid; border-right: 1px solid"
      >
        Spedizioni:
      </div>
      <div class="col colAllign">Prodotti magazzino:</div>
    </div>
    <div class="row">
      <div
        class="col k-display-flex k-align-items-center colInfo"
        style="border-bottom-left-radius: 10px"
      >
        <!-- <div
        *ngIf="loading || otherInfo.t_or_bordero === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            otherInfo.t_or_bordero === -1 ? 'Attendi..' : otherInfo.t_or_bordero
          "
          style="padding-right: 40px"
        />
        <button
          class="vaiButton"
          rounded="full"
          routerLink="/borderò"
          [queryParams]="dynamicLink"
          *ngIf="otherInfo.t_or_bordero !== -1 && otherInfo.t_or_bordero !== 0"
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>

      <div class="col k-display-flex k-align-items-center colInfo">
        <!-- <div
        *ngIf="loading || orderInfo.t_or_delivery === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            orderInfo.t_or_delivery === -1
              ? 'Attendi..'
              : orderInfo.t_or_delivery
          "
          style="padding-right: 40px"
        />
        <button
          class="vaiButton"
          rounded="full"
          routerLink="/status"
          [queryParams]="dynamicLink"
          *ngIf="
            orderInfo.t_or_delivery !== -1 && orderInfo.t_or_delivery !== 0
          "
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>

      <div
        class="col k-display-flex k-align-items-center colInfo"
        style="border-bottom-right-radius: 10px"
      >
        <!-- <div
        *ngIf="loading || otherInfo.t_products === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            otherInfo.t_products === -1 || otherInfo.t_products === undefined
              ? 'Attendi..'
              : otherInfo.t_products
          "
          style="padding-right: 40px"
        />
        <button
          class="vaiButton"
          rounded="full"
          routerLink="/products"
          [queryParams]="dynamicLink"
          *ngIf="
            otherInfo.t_products !== -1 &&
            otherInfo.t_products !== 0 &&
            otherInfo.t_products !== undefined
          "
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>
    </div>
  </div>

  <div class="container text-center mt-4" style="border-radius: 15px">
    <div class="row rowRadius">
      <div class="col colAllign">Accounts:</div>
      <div
        class="col colAllign"
        style="border-left: 1px solid; border-right: 1px solid"
      >
        Agenti:
      </div>
      <div class="col colAllign">Messaggi:</div>
    </div>
    <div class="row">
      <div
        class="col k-display-flex k-align-items-center colInfo"
        style="border-bottom-left-radius: 10px"
      >
        <!-- <div
        *ngIf="loading || otherInfo.t_account === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            otherInfo.t_account === -1 ? 'Attendi..' : otherInfo.t_account
          "
          style="padding-right: 40px"
        />

        <button
          class="vaiButton"
          rounded="full"
          routerLink="/accounts"
          [queryParams]="dynamicLink"
          *ngIf="otherInfo.t_account !== -1 && otherInfo.t_account !== 0"
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>

      <div
        class="col k-display-flex k-align-items-center colInfo"
        style="border-bottom-right-radius: 10px; position: relative"
      >
        <!-- <div
        *ngIf="loading || otherInfo.t_agents === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            otherInfo.t_agents === -1 ? 'Attendi..' : otherInfo.t_agents
          "
          style="padding-right: 40px"
        />
        <button
          class="vaiButton"
          rounded="full"
          routerLink="/agents"
          [queryParams]="dynamicLink"
          *ngIf="otherInfo.t_agents !== -1 && otherInfo.t_agents !== 0"
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>

      <div
        class="col k-display-flex k-align-items-center colInfo"
        style="border-bottom-right-radius: 10px; position: relative"
      >
        <!-- <div
        *ngIf="loading || otherInfo.t_message === 0"
        style="
          position: absolute;
          left: 10px;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        "
      >
        <mat-spinner diameter="20"></mat-spinner>
      </div> -->
        <input
          *ngIf="!loading"
          type="text"
          rounded="full"
          class="input-kendo-lookalike"
          disabled
          [placeholder]="
            otherInfo.t_message === -1 ? 'Attendi..' : otherInfo.t_message
          "
          style="padding-right: 40px"
        />
        <button
          class="vaiButton"
          rounded="full"
          routerLink="/messages"
          [queryParams]="dynamicLink"
          *ngIf="otherInfo.t_message !== -1 && otherInfo.t_message !== 0"
        >
          <i class="bx bx-sm bxs-right-arrow-circle"></i>
          VAI
        </button>
      </div>
    </div>
  </div>
</div>
