import { Component, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Orders } from 'src/app/models/Orders.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { Shipments } from 'src/app/models/shipment';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { PickerService } from 'src/app/services/picker.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { parseISO } from 'date-fns';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-ordini-da-firmare',
  templateUrl: './ordini-da-firmare.component.html',
  styleUrls: ['./ordini-da-firmare.component.scss'],
})
export class OrdiniDaFirmareComponent {
  public shipments: Shipments.ShipmentList[] = [];
  public filteredShipments: Shipments.ShipmentList[] = null;
  public gridData: Orders.IOrderOpenList[] = [];
  public loading: boolean = false;
  public searchString = 'Cliente';
  public customers: string[];
  public gridView: GridDataResult;
  public value = 0;
  public indeterminate = false;
  public min = -10;
  public max = 10;
  public pageSize = 20;
  public skip = 0;
  public data: unknown[];
  public orders: Orders.OrderSignature[] = [];
  public order: Orders.IOrder[];
  public ordz: Orders.IOrder;
  public setPercentLabel: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center',
  };

  openOrders: Orders.IOrderOpenList[] = [];
  hiddenStyle: boolean = false;
  clickedId: any;
  searchText: string = '';
  filterTerm: string | undefined;
  timer = null;
  refreshInterval: number = 60000;
  chosenCarrier: string = null;
  carriers: string[] = [];
  isOneSelected: boolean = false;
  selectedOrders: Orders.OrderSelectedToSign[] = [];
  orderBordero: Orders.OrderBordero = new Orders.OrderBordero();
  checked: boolean = false;
  selectedRowIndex: number;
  customer: string = '';
  showAll: string = 'Mostra tutti';
  customerIndex: number;
  clickedIdComplete: any;
  courier_name: string = '';
  date: string = '';
  order_carrier: string;
  carrier_id: string;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  triggerSecondTable: boolean = false;
  canSelectAll: boolean = false;
  areSelected: boolean = false;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
  });
  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];
  subscriber: Subscription[] = [];
  searchParam: string = '';
  booleanForSpecialSearch: boolean = false;
  searchTermLocalStorage: string = '';
  filterService = inject(SearchService);
  constructor(
    private openOrdersServ: OrdersService,
    private router: Router,
    public openModal: OpenModalService,
    public pageService: PickerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.filterTerm = this.filterService.getFilterText();
    console.log('Filter is', this.filterTerm);

    // console.log('Filterterm is', this.searchTermLocalStorage);
    if (this.filterTerm) {
      this.searchOrders(this.filterTerm);
    } else {
      this.subscriber.push(
        this.route.queryParams.subscribe((params) => {
          console.log('Params is', params);
          if (Object.keys(params).length === 0) {
            console.log('dodm');
            this.getOpenOrders();
            this.timer = setInterval(() => {
              this.getOpenOrders();
              console.log('Interval set');
            }, this.refreshInterval);
            //this.loading = false;
            console.log('Params is an empty object. Reloaded.');
          } else {
            this.searchParam = params['search'];
            const dateParam = params['data'];
            this.filters.startingDate = dateParam ? parseISO(dateParam) : null;
            console.log('Date param is', dateParam);
            console.log('Param is', this.searchParam);
            this.searchOrders(this.searchParam);
            this.loading = false;
          }
        })
      );
    }
  }

  checkSelectedAll(stepBack = false) {
    this.isOneSelected = this.selectedOrders.some(
      (item) => item.op_checked == true
    );
  }

  handleFilterChange(): void {
    this.filterService.setFilterText(this.filterTerm);
    // Perform filtering logic here
  }

  /**
   * The function `getCarrierList` retrieves a list of unique carrier names from orders data and adds a
   * default option before logging the list and updating the loading status.
   */
  getCarrierList() {
    this.carriers = [
      ...new Set(
        this.orders
          .map((item) => {
            return item.or_carrier_id != null
              ? item.or_carrier_desc
              : item.or_carrier_other;
          })
          .map((item) => {
            return item === null ? '' : item;
          })
      ),
    ];
    this.carriers.unshift(this.showAll);
    console.log('carriers are', this.carriers);
    this.loading = false;
  }

  deepCopy(obj) {
    let copy;
    if (null == obj || 'object' != typeof obj) return obj;
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }
    if (obj instanceof Object) {
      copy = {};
      for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }
    throw new Error('Impossibile copiare obj! Il suo tipo non è supportato.');
  }

  onMessengerNameSelect(name: string, i: number) {
    this.customer = name;
    console.log('I is: ', i);
    if (name === this.showAll) {
      this.customer = null;
      this.chosenCarrier = null;
    }
  }

  seeTheDate(event: any) {
    console.log('Event is ', event);
    event = new Date(event);
    console.log('Event is ', this.filters.startingDate);
    this.filters.startingDate = event;
    console.log('Event is ', this.filters.startingDate);
  }

  getOpenOrders() {
    this.loading = true;
    this.openOrdersServ.getOrdersToSign().subscribe((ordersData) => {
      console.log('Data received: ', ordersData);
      if (!ordersData || ordersData.length === 0) {
        console.log('Caca');
        this.orders = [];
        this.loading = false;
        return;
      }
      this.orders = ordersData;
      console.log('Data and orders are', ordersData, this.orders);
      //console.log('Boxes are', this.orders[0].or_boxes);
      this.getCarrierList();
      this.loading = false;
    });
  }

  clearSearch() {
    this.filterTerm = '';
    this.filters.startingDate = this.startDate;
    this.filterService.clearFilterText();
    this.getOpenOrders();
  }

  searchOrders(searchString?: string) {
    if (searchString) this.filterTerm = searchString;
    if (!this.filterTerm || this.filterTerm == '') {
      this.openOrdersServ
        .searchOrdersToSign()
        .subscribe((openOrdersData: IWsResult) => {
          this.orders = openOrdersData.data;
        });
    }
    this.openOrdersServ
      .searchOrdersToSign(this.filterTerm)
      .subscribe((openOrdersData: IWsResult) => {
        this.orders = openOrdersData.data;
      });
  }

  searchAfterCarrier() {
    this.openOrdersServ
      .searchOrdersToSign(this.filterTerm)
      .subscribe((openOrdersData: IWsResult) => {
        this.orders = openOrdersData.data;
        this.changeCarier(this.chosenCarrier);
      });
  }

  async openConfirmModal(order_id: any) {
    this.clickedIdComplete = order_id;
    const modalRef = this.openModal.onConfirmComplete();
  }

  completeOrder(order_id: any) {
    console.log('Triggered');
    this.clickedIdComplete = order_id;
    this.openOrdersServ
      .completeOrder(this.clickedIdComplete)
      .subscribe((openOrdersData: IWsResult) => {
        console.log(order_id);
        console.log('OPenorders completed', openOrdersData);
        console.log('ID: ', this.clickedIdComplete);

        Swal.fire({
          icon: 'success',
          title: 'Completato!',
          text: 'Ordine completato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        this.getOpenOrders();
      });
    console.log(order_id);
    console.log('ID: ', this.clickedIdComplete);
  }

  changeCarier(e) {
    this.chosenCarrier = e !== null ? e : '';
    e === '' ? null : e;
    console.log('Value is: ', e);
    if (e === this.showAll) {
      this.chosenCarrier = null;
    } else {
      let temp = this.deepCopy(this.orders);
      console.log('Temp is: ', temp);
      this.selectedOrders = temp
        .filter((item) =>
          item?.or_carrier_id
            ? item.or_carrier_desc === this.chosenCarrier
            : item.or_carrier_other === e
        )
        .map((item, index) => {
          let temp: Orders.OrderSelectedToSign = {
            order_id: item.order_id,
            or_customer: item.or_customer,
            op_checked: false,
            indexNo: index + 1,
            or_partial: item.or_partial,
            or_carrier_id: item.or_carrier_id,
            or_carrier_desc: item.or_carrier_desc,
            or_carrier_other: item.or_carrier_other,
            order_number: item.or_order_number,
            or_boxes: item.op_articles_number,
          };
          console.log('temp now is', temp);
          return temp;
        });
    }
  }

  selectAllOrdersPerCarr() {
    this.areSelected = !this.areSelected;
    this.selectedOrders.forEach((item: any) => {
      item.or_carrier_desc === this.chosenCarrier;
      item.op_checked = this.areSelected;
    });
    this.isOneSelected = !this.isOneSelected;
    console.log('The selectedOrders are: ', this.selectedOrders);
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getOpenOrders();
    }
  }

  sign(answer: string) {
    this.courier_name = answer;
    console.log(this.courier_name);
    let signatureToSend: Orders.OrderBordero = new Orders.OrderBordero();
    signatureToSend.order_date = new Date().toISOString();
    signatureToSend.courier_name = this.courier_name;
    console.log('Orders are: ', this.selectedOrders);
    signatureToSend.orders = this.selectedOrders
      .filter((item) => item.op_checked === true)
      .map((item) => {
        console.debug(item);
        return {
          order_id: item.order_id,
          order_number: item.order_number.substring(
            0,
            item.order_number.indexOf('/')
          ),
        };
      });
    this.openOrdersServ
      .getBordero(signatureToSend)
      .subscribe(async (borderoLink) => {
        if (!borderoLink?.success) {
          return;
        }
        this.router.navigate(['/firmare']);
      });
    Swal.fire({
      icon: 'success',
      title: 'Generato!',
      text: 'Borderò generato con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
    this.router.navigate(['/assign-order']);
  }

  async getOrderDate(order_carrier, carrier_id) {
    this.orders.filter((item) => {
      if (item.or_carrier_desc !== null) {
        order_carrier = item.or_carrier_desc;
        carrier_id = item.or_carrier_id;
        this.order_carrier = order_carrier;
        this.carrier_id = carrier_id;
      } else if (item.or_carrier_desc === null) {
        order_carrier = item.or_carrier_other;
        carrier_id = null;
        this.carrier_id = carrier_id;
        this.order_carrier = order_carrier;
      }
    });
    console.log('Order carrier is: ', this.order_carrier);
    console.log('Carrier ID is: ', this.carrier_id);
    this.openModalDamnIt();
  }

  chooseItem(id, event) {
    console.log('This selected orders: ', this.selectedOrders);
    console.log('ID is: ', id);
    event.stopPropagation();
    const index = id - 1;
    this.selectedOrders[index].op_checked =
      !this.selectedOrders[index].op_checked;
    this.checkSelectedAll();
  }

  openModalDamnIt() {
    this.openModal.onBordero();
  }

  gotoDetails(order_id: any) {
    this.clickedId = order_id;
    this.openModal.daFirmareOn();
    console.log('opened!');
  }

  ngOnDestroy() {
    this.subscriber.forEach((subscriber) => subscriber.unsubscribe());
  }
}
