<nav [class.close]="sidebarService.isClosed" class="sidebar dark-background">
  <header>
    <div class="image-text">
      <span class="image">
        <img src="assets/icons/logo.png" alt="logo" />
      </span>
      <div class="text logo-text">
        <span class="name">Diakonix</span>
      </div>
    </div>
    <i class="bx bx-chevron-right toggle" (click)="toggleSidenav()"></i>
    <section>
      <div
        class="main-content"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 10px;
          margin-bottom: -20px;
          padding: 4px;
          color: #fff;
          background: #017EFB;
          border-radius: 8px;
          cursor: pointer;
        "
      >
        <div class="main-content">
          <div
            class="accountName"
            (click)="goToAccDetails()"
            *ngIf="authServ.isUser.value"
          >
            {{ !sidebarService.isClosed?
              account?.ac_first_name + " " + account?.ac_last_name
            : account?.ac_first_name?.substring(0,1) + " " + account?.ac_last_name?.substring(0,1)}}
          </div>
        </div>
      </div>
    </section>
  </header>

  <div class="menu-bar">
    <div class="menu">
      <ul class="menu-links" style="border-top: 1px solid #fff">
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="dashboard">
            <i
              class="bx bx-home icon"
              style="color: rgb(255, 111, 111)"
              title="Assegnazione Ordini"
            ></i>
            <span
              class="text nav-text"
              title="Dashboard"
            >Dashboard
            </span>
          </a>
        </li>
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="assign-order">
            <i
              class="bx bx-box icon"
              style="color: rgb(255, 111, 111)"
              title="Assegnazione Ordini"
            ></i>
            <span
              class="text nav-text"
              title="Assegnazione Ordini"
            >Assegnazione Ordini
            </span>
          </a>
        </li>
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="prepare-order">
            <i
              class="bx bx-package icon"
              style="color: rgb(255, 111, 111)"
              title="Preparazione Ordini"
            ></i>
            <span
              class="text nav-text"
              title="Preparazione Ordini"
            >Preparazione Ordini
            </span>
          </a>
        </li>
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="partial-orders">
            <i
              class="bx bx-moon icon"
              style="color: rgb(255, 111, 111)"
              title="Ordini Parziali"
            ></i>
            <span
              class="text nav-text"
              title="Ordini Parziali"
            >Ordini Parziali
            </span>
          </a>
        </li> 
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="pm-orders">
            <i
              class="bx bx-star icon"
              style="color: rgb(255, 111, 111)"
              title="Ordini PM"
            ></i>
            <span
              class="text nav-text"
              title="Ordini PM"
            >Ordini PM
            </span>
          </a>
        </li>                 
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="firmare">
            <i
              class="bx bx-pen icon"
              style="color: rgb(255, 111, 111)"
              title="Ordini da Firmare"
            ></i>
            <span
              class="text nav-text"
              title="Ordini da Firmare"
            >Ordini da Firmare
            </span>
          </a>
        </li>
      </ul>
      <ul class="menu-links" style="border-top: 1px solid #fff">       
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="borderò">
            <i
              class="bx bx-file icon"
              style="color: rgb(157, 255, 111)"
              title="Borderò"
            ></i>
            <span
              class="text nav-text"
              title="Borderò"
            >Borderò
            </span>
          </a>
        </li>  
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="status">
            <i
              class="bx bx-map icon"
              style="color: rgb(157, 255, 111)"
              title="Status Spedizioni"
            ></i>
            <span
              class="text nav-text"
              title="Status Spedizioni"
            >Status Spedizioni
            </span>
          </a>
        </li>
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="history">
            <i
              class="bx bx-history icon"
              style="color: rgb(157, 255, 111)"
              title="Storico ordini"
            ></i>
            <span
              class="text nav-text"
              title="Storico Ordini"
            >Storico Ordini
            </span>
          </a>
        </li>
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="storico-parziali">
            <i
              class="bx bx-history icon"
              style="color: rgb(157, 255, 111)"
              title="Storico ordini"
            ></i>
            <span
              class="text nav-text"
              title="Storico Ordini Parziali"
            >Storico Ordini Parziali
            </span>
          </a>
        </li>
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="agents">
            <i
              class="bx bxs-user-account icon"
              style="color: rgb(157, 255, 111)"
              title="Agenti"
            ></i>
            <span
              class="text nav-text"
              title="Agenti"
            >Agenti
            </span>
          </a>
        </li>
      </ul>
      <ul class="menu-links" style="border-top: 1px solid #fff">
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="accounts">
            <i
              class="bx bx-universal-access icon"
              style="color: rgb(255, 241, 111)"
              title="Accounts"
            ></i>
            <span
              class="text nav-text"
              title="Accounts"
            >Accounts
            </span>
          </a>
        </li>
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="chat">
            <i
              class="bx bx-message-rounded icon"
              style="color: rgb(255, 241, 111)"
              title="Messaggi"
            ></i>
            <span
              class="text nav-text"
              title="Messaggi"
            >Messaggi
            </span>
          </a>
        </li>
      </ul>
      <ul class="menu-links" style="border-top: 1px solid #fff">
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="products">
            <i
              class="bx bx-table icon"
              style="color: rgb(205, 111, 255)"
              title="Gestione Prodotti"
            ></i>
            <span
              class="text nav-text"
              title="Gestione Prodotti"
            >Gestione Prodotti
            </span>
          </a>
        </li>
        <!--For phase 2-->
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="quality">
            <i
              class="bx bx-analyse icon"
              style="color: rgb(205, 111, 255)"
              title="Controllo Qualità"
            ></i>
            <span
              class="text nav-text"
              title="Controllo Qualità"
            >Controllo Qualità</span>
          </a>
        </li>
        <li class="nav-link" routerLinkActive="active">
          <a routerLink="stats">
            <i
              class="bx bx-line-chart icon"
              style="color: rgb(205, 111, 255)"
              title="Statistiche"
            ></i>
            <span
              class="text nav-text"
              title="Statistiche"
            >Statistiche</span>
          </a>
        </li>
      </ul>
    </div>

    <div
      class="bottom-content"
      style="
        padding-left: 30px;
        border-top: 1px solid #fff
      "
    >
      <li class="nav-link" routerLinkActive="active">
        <a routerLink="settings">
          <i
            class="bx bx-cog icon"
            title="Impostazioni"
          ></i>
          <span
            class="text nav-text"
            title="Impostazioni"
          >Impostazioni
          </span>
        </a>
      </li>
      <li class="mode">
        <div class="sun-moon">
          <i
            class="bx bx-palette icon moon"
            title="Light Mode"
          ></i>
          <i
            class="bx bx-palette icon sun"
            title="Dark Mode"
          ></i>
        </div>
        <span
          class="mode-text text"
          title="Tema"
        >{{ theme }}</span>
        <input
          type="checkbox"
          id="toggle_checkbox"
          (click)="toggleDarkMode()"
        />
        <label for="toggle_checkbox">
          <div id="star">
            <div class="star" id="star-1">★</div>
            <div class="star" id="star-2">★</div>
          </div>
          <div id="moon"></div>
        </label>
      </li>
      <li
        class="nav-logout"
        (click)="this.authServ.logout()"
        style="cursor: pointer"
      >
        <i
          class="bx bx-log-out icon"
          title="Logout"
        ></i>
        <span
          class="text nav-text"
          title="Logout"
        >Logout
        </span>
      </li>
    </div>
  </div>
</nav>

<app-gestione-account-dettagli
  class="background-cl"
  *ngIf="closeSrv.isPhotoAcc"
  [photoAcc]="closeSrv.isPhotoAcc"
  [account_id]="clickedId"
></app-gestione-account-dettagli>
