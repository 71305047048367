<div class="main-div">
  <div class="top-div">
    <h1 class="page-title">
      Lista Ordini di
      {{
        this.agent_order.length === 0
          ? " Agente"
          : " " +
            agent_order[0].ag_first_name +
            " " +
            agent_order[0].ag_last_name
      }}
    </h1>

    <br />

    <div
      class="filterContainer k-display-flex"
      style="flex-direction: row; justify-content: center"
    >
      <form
        class="new-search"
        role="search"
        style="
          display: flex !important;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: center;
        "
      >
        <label for="search"
          ><i
            class="bx bx-search bx-md"
            style="
              margin: 0;
              padding-top: 10px;
              padding-left: 5px;
              transform: scale(0.8);
            "
          >
          </i
        ></label>
        <div
          class="inputSearchContainer"
          style="display: flex; flex-direction: column; align-items: flex-end"
        >
          <i
            *ngIf="filterTerm"
            class="bx bxs-x-circle bx-md clearSearchIcon"
            style="
              transform: scale(0.8);
              color: red;
              /* background: #0c65e9; */
              position: relative;
              top: 42px;
              z-index: 10001;
            "
            (click)="clearSearch()"
          ></i>
          <input
            [(ngModel)]="filterTerm"
            id="search"
            name="search"
            pattern=".*\S.*"
            type="search"
            placeholder="Cerca..."
            autofocus
            required
            (input)="this.onInputChanged($event)"
          />
        </div>
        <button type="submit" (click)="searchOrders()">
          <i
            class="bx bx-search bx-md"
            style="
              margin: 0;
              padding-top: 10px;
              padding-left: 5px;
              transform: scale(0.8);
              color: red;
              background: #0c65e9;
            "
          >
          </i>
        </button>
        <div
          class="containerFlex mt-2"
          style="display: flex; align-items: center; flex-direction: row"
        >
          <p style="margin: 0; padding: 0; font-size: 20px">
            Cerca Storico ordini
          </p>
          <i
            class="bx bx-checkbox bx-md"
            *ngIf="!searchStorico"
            (click)="searchStorico = !searchStorico"
          ></i>
          <i
            class="bx bx-checkbox-checked bx-md"
            (click)="searchStorico = !searchStorico"
            *ngIf="searchStorico"
          ></i>
        </div>
      </form>

      <form
        action=""
        class="search-bar"
        style="
          display: flex !important;
          flex-direction: row !important;
          justify-content: center !important;
          text-align: center !important;
        "
      >
        <h1 class="page-title mx-2 resizeSize" style="color: rgb(13, 11, 11)">
          Ordini totali: {{ this.agent_order.length }}
        </h1>
        <h1
          class="page-title mx-2 resizeSize"
          style="color: rgb(186, 101, 255) !important"
        >
          Ordini Parziali: {{ nrPartial }}
        </h1>
      </form>

      <div
        class="dateContainer k-display-flex justify-content-evenly nonMobile"
      >
        <div class="col mx-2">
          <h5 class="text-center">Mese</h5>
          <select
            [(ngModel)]="selectedMonth"
            (ngModelChange)="MonthSelector($event)"
            class="dropdown-field drop-the-border"
            style="
              background: #ffffff;
              border: 1px solid #e0e0e0;
              border-radius: 45px;
            "
          >
            <!-- Placeholder option -->
            <option disabled selected value="">Seleziona un mese</option>

            <!-- Actual options populated dynamically -->
            <option *ngFor="let month of filter.month" [ngValue]="month.value">
              {{ month.name }}
            </option>
          </select>
        </div>
        <div class="col mx-2">
          <h5 class="text-center">DA:</h5>
          <kendo-datepicker
            [(ngModel)]="filters.startingDate"
            (ngModelChange)="dateSelector($event)"
            class="dropdown-field drop-the-border"
            style="
              background: #ffffff;
              border: 1px solid #e0e0e0;
              border-radius: 45px;
            "
          ></kendo-datepicker>
        </div>
        <div class="col mx-2">
          <h5 class="text-center">A:</h5>
          <kendo-datepicker
            [(ngModel)]="filters.endDate"
            (ngModelChange)="dateSelector($event)"
            class="dropdown-field drop-the-border"
            style="
              background: #ffffff;
              border: 1px solid #e0e0e0;
              border-radius: 45px;
            "
          ></kendo-datepicker>
        </div>
      </div>
    </div>
    <!--<hr class="hr-newBlue" />-->

    <div
      *ngIf="loading === true"
      style="display: flex; flex-direction: column; align-items: center"
    >
      <mat-spinner></mat-spinner>
      <p>Attendi..</p>
    </div>
  </div>
  <div class="desktop bottom-div" #div2 *ngIf="!loading">
    <kendo-grid
      [data]="
        agent_order
          | byToDate
            : [
                filters.startingDate,
                filters.endDate,
                filters.dateOrginizedBy.value
              ]
          | month : [selectedMonth, filters.dateOrginizedBy.value]
      "
      [height]="gridHeight"
      [rowHeight]="36"
      [sortable]="true"
      scrollable="virtual"
      [rowClass]="rowClassPartial"
      [navigable]="true"
      class="table-responsive responsive-table"
    >
      <!-- <div class="grid-list">
        <div *ngFor="let order of agent_order; let i = index">
          <p>{{ order.ag_first_name }}</p>
          <p>{{ order.ag_last_name }}</p>
        </div>
      </div> -->

      <kendo-grid-column
        [width]="150"
        field="ag_last_name"
        title="Agente"
        class="col-name"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
          {{ dataItem.ag_last_name }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="150"
        field="or_order_number"
        title="N° Ordine"
        class="col-name"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
          {{ dataItem.or_order_number }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="150"
        field="or_quote_number"
        title="Preventivo"
        class="col-name"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
          {{ dataItem.or_quote_number }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="150"
        field="or_note"
        title="Riferimento"
        class="col-name"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
          {{ dataItem.or_note }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="500"
        field="or_customer"
        title="Ragione sociale"
        class="col-name"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          style="font-size: 11px; display: flex !important"
        >
          <div class="flex" style="display: flex !important">
            {{ dataItem.or_customer }}
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="150"
        field="or_order_date"
        title="Data ordine"
        class="col-name"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
          {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="150"
        field="or_closing_date"
        title="Data evasione"
        class="col-name"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
          {{
            dataItem.or_closing_date === "0001-01-01T00:00:00"
              ? " "
              : (dataItem.or_closing_date | date : "dd/MM/yyyy")
          }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="200"
        field="tab_order_status_desc"
        title="Stato"
        class="col-name"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
          <!-- {{ dataItem.tab_order_status_desc }} -->
          <button
            *ngIf="
              dataItem.os_tracking_number !== null &&
              dataItem.os_tracking_number !== ''
            "
            kendoGridEditCommand
            rounded="full"
            class="btn-track"
            style="
              margin: 0;
              padding: 4px;
              background-color: #be7e00 !important;
            "
            (click)="
              goToTracking(agent_token, dataItem.order_id, dataItem.or_agent_id)
            "
          >
            <div class="setDisplayToFlexBlue btn-track">
              <i class="bx bx-radar bx-md"> </i>
              <p class="parButton2"></p>
            </div>
          </button>
          <p class="parButton2">{{ dataItem.tab_order_status_desc }}</p>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column
        title="DDT"
        [width]="80"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            href="{{ dataItem?.or_url_ddt }}"
            target="_blank"
            *ngIf="dataItem.or_url_ddt"
          >
            <i class="bx bxs-file-pdf bx-md"></i>
          </a>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-command-column
        title="Dettagli"
        [width]="100"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="
              goToOrderDetails(
                dataItem.or_agent_id,
                dataItem.order_id,
                dataItem.or_gamma_id,
                dataItem.or_assigned_date,
                agent_token,
                riferimento
              )
            "
          >
            <div class="setDisplayToFlexBlue btn-det">
              <i class="bx bx-info-circle bx-md" style="margin: 0; padding: 0">
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div
    class="mobile bomttom-div"
    style="
      overflow: auto;
      max-height: 450px;
      padding: 10px;
      border: 2px solid #017efa;
      border-radius: 8px;
    "
    *ngIf="!loading && agent_order?.length > 0"
  >
    <ion-accordion-group
      [multiple]="true"
      [animated]="true"
      expand="inset"
      *ngIf="!loading && agent_order?.length > 0"
    >
      <ion-accordion
        *ngFor="let dataItem of agent_order"
        style="
          margin-bottom: 10px;
          border: 1px solid light-blue;
          border-radius: 8px;
        "
      >
        <ion-item
          slot="header"
          class="color-light-blue"
          [class.accordionItemPartial]="dataItem.or_partial === 'S'"
          [class.accordionNormal]="dataItem.or_partial !== 'S'"
        >
          <ion-label
            style="
              text-align: center;
              border-radius: 45px;
              font-size: 20px;
              font-weight: 500;
            "
          >
            {{
              dataItem.or_order_number +
                " | " +
                dataItem.tab_order_status_desc +
                " | " +
                dataItem.or_customer
            }}
          </ion-label>
        </ion-item>
        <div
          class="ion-padding"
          slot="content"
          [class.accordionNormal]="dataItem.or_partial !== 'S'"
          [class.accordionItemPartial]="dataItem.or_partial === 'S'"
        >
          <div style="max-height: 500px; overflow-y: auto">
            <div
              class="row"
              style="
                text-align: center;
                padding: 8px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
              "
            >
              <div class="spanus">
                <span
                  style="
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: #0c65e9;
                  "
                  [class.accordionPartial]="dataItem.or_partial === 'S'"
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                    "
                  >
                    <div style="text-align: left">
                      <h4>Preventivo:</h4>
                    </div>
                    <div style="text-align: right; margin-left: 10px">
                      <h4>{{ dataItem.or_quote_number }}</h4>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                    "
                  >
                    <div style="text-align: left">
                      <h4>Riferimento:</h4>
                    </div>
                    <div style="text-align: right; margin-left: 10px">
                      <h4>{{ dataItem.or_note }}</h4>
                      <!-- <h4>{{ dataItem.or_shipping_note }}</h4> -->
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                    "
                  >
                    <div style="text-align: left">
                      <h4>Ragione Sociale:</h4>
                    </div>
                    <div style="text-align: right; margin-left: 10px">
                      <h4>{{ dataItem.or_customer }}</h4>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                    "
                  >
                    <div style="text-align: left">
                      <h4>Data Ordine:</h4>
                    </div>
                    <div style="text-align: right; margin-left: 10px">
                      <h4 *ngIf="dataItem.or_order_date">
                        {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
                      </h4>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                    "
                  >
                    <div style="text-align: left">
                      <h4>Data Evasione:</h4>
                    </div>
                    <div style="text-align: right; margin-left: 10px">
                      <h4 *ngIf="dataItem.or_closing_date">
                        {{
                          dataItem.or_closing_date === "0001-01-01T00:00:00"
                            ? " "
                            : (dataItem.or_closing_date | date : "dd/MM/yyyy")
                        }}
                      </h4>
                    </div>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <div style="text-align: left">
                      <h4>DDT:</h4>
                    </div>
                    <div
                      style="
                        text-align: right;
                        margin-left: 10px;
                        position: relative;
                        bottom: 10px;
                      "
                    >
                      <a
                        href="{{ dataItem?.or_url_ddt }}"
                        target="_blank"
                        *ngIf="dataItem.or_url_ddt"
                      >
                        <i class="bx bxs-file-pdf bx-md"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      margin-top: 5px;
                      margin-bottom: 5px;

                      align-items: flex-end;
                    "
                  >
                    <div style="text-align: left">
                      <h4>Dettagli:</h4>
                    </div>
                    <div style="text-align: right; margin-left: 10px">
                      <i
                        class="bx bx-info-circle bx-md"
                        style="margin: 0; padding: 0"
                        (click)="
                          goToOrderDetails(
                            dataItem.or_agent_id,
                            dataItem.order_id,
                            dataItem.or_gamma_id,
                            dataItem.or_assigned_date,
                            agent_token,
                            riferimento
                          )
                        "
                      >
                      </i>
                    </div>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <div style="text-align: left">
                      <h4>Stato:</h4>
                    </div>
                    <div
                      style="
                        text-align: right;
                        margin-left: 10px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <p class="parButton2" style="font-size: 20px">
                        {{ dataItem.tab_order_status_desc }}
                      </p>

                      <button
                        *ngIf="
                          dataItem.os_tracking_number !== null &&
                          dataItem.os_tracking_number !== ''
                        "
                        rounded="full"
                        class="btn-track"
                        style="margin: 0; background-color: #be7e00 !important"
                        (click)="
                          goToTracking(
                            agent_token,
                            dataItem.order_id,
                            dataItem.or_agent_id
                          )
                        "
                      >
                        <div class="setDisplayToFlexBlue btn-track">
                          <i
                            class="bx bx-radar bx-md"
                            style="margin: 0; padding: 0"
                          >
                          </i>
                          <p class="parButton2"></p>
                        </div>
                      </button>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </div>
</div>

<!-- <div class="mobile bottom-div" #divMob>
    <kendo-grid
      *ngIf="!loading"
      [data]="agent_order"
      [rowHeight]="36"
      [sortable]="true"
      scrollable="virtual"
      [navigable]="true"
      [style]="{ 'margin-left': '7px !important' }"
      [reorderable]="true"
      #gridContainer
    >
      <kendo-grid-column
        field="or_order_number"
        title="Ordini Agente"
        class="col-name"
        [headerStyle]="{ 'justify-content': 'center' }"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          style="font-size: 11px; padding: 0"
        >
          <ion-accordion-group>
            <ion-accordion value="first">
              <ion-item slot="header" color="light">
                <ion-label
                  [title]="
                    dataItem.or_order_number +
                    ' | ' +
                    dataItem.tab_order_status_desc +
                    ' | ' +
                    dataItem.or_customer
                  "
                  [selected]="true"
                  style="text-align: center; border-radius: 45px"
                >
                  {{
                    dataItem.or_order_number +
                      " | " +
                      dataItem.tab_order_status_desc +
                      " | " +
                      dataItem.or_customer
                  }}</ion-label
                >
              </ion-item>
              <div class="ion-padding" slot="content">
                

                
              </div>
            </ion-accordion>
          </ion-accordion-group>
          <kendo-panelbar
            class="agente007"
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;
              border: #fff;
            "
          >
            <kendo-panelbar-item
              [title]="
                dataItem.or_order_number +
                ' | ' +
                dataItem.tab_order_status_desc +
                ' | ' +
                dataItem.or_customer
              "
              [selected]="true"
              style="text-align: center; border-radius: 45px"
            >
              <ng-template kendoPanelBarContent>
                <div style="max-height: 500px; overflow-y: auto">
                  <div
                    class="row"
                    style="
                      text-align: center;
                      padding: 8px;
                      border: 1px solid #47a1fb;
                      display: flex;
                      justify-content: space-evenly;
                      align-items: center;
                    "
                  >
                    <div class="spanus">
                      <span
                        style="
                          font-family: 'Lato';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 14px;
                          line-height: 19px;
                          color: #0c65e9;
                        "
                      >
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <div style="text-align: left">
                            <h4>Preventivo:</h4>
                          </div>
                          <div style="text-align: right; margin-left: 10px">
                            <h4>{{ dataItem.or_quote_number }}</h4>
                          </div>
                        </div>
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <div style="text-align: left">
                            <h4>Riferimento:</h4>
                          </div>
                          <div style="text-align: right; margin-left: 10px">
                            <h4>{{ dataItem.or_note }}</h4>
                            <h4>{{ dataItem.or_shipping_note }}</h4>
                          </div>
                        </div>
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <div style="text-align: left">
                            <h4>Ragione Sociale:</h4>
                          </div>
                          <div style="text-align: right; margin-left: 10px">
                            <h4>{{ dataItem.or_customer }}</h4>
                          </div>
                        </div>
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <div style="text-align: left">
                            <h4>Indirizzo:</h4>
                          </div>
                          <div style="text-align: right; margin-left: 10px">
                            <h4>{{ dataItem.or_shipping_address }}</h4>
                          </div>
                        </div>
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <div style="text-align: left">
                            <h4>Data Ordine:</h4>
                          </div>
                          <div style="text-align: right; margin-left: 10px">
                            <h4 *ngIf="dataItem.or_order_date">
                              {{ dataItem.or_order_date }}
                            </h4>
                          </div>
                        </div>
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <div style="text-align: left">
                            <h4>Data Evasione:</h4>
                          </div>
                          <div style="text-align: right; margin-left: 10px">
                            <h4 *ngIf="dataItem.or_closing_date">
                              {{
                                dataItem.or_closing_date ===
                                "0001-01-01T00:00:00"
                                  ? " "
                                  : (dataItem.or_closing_date
                                    | date : "dd/MM/yyyy")
                              }}
                            </h4>
                          </div>
                        </div>

                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <div style="text-align: left">
                            <h4>DDT:</h4>
                          </div>
                          <div
                            style="
                              text-align: right;
                              margin-left: 10px;
                              position: relative;
                              bottom: 10px;
                            "
                          >
                            <a
                              href="{{ dataItem?.or_url_ddt }}"
                              target="_blank"
                              *ngIf="dataItem.or_url_ddt"
                            >
                              <i class="bx bxs-file-pdf bx-md"></i>
                            </a>
                          </div>
                        </div>
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-top: 5px;
                            margin-bottom: 5px;
                            align-items: center;
                          "
                        >
                          <div style="text-align: left">
                            <h4>Dettagli:</h4>
                          </div>
                          <div style="text-align: right; margin-left: 10px">
                            <button
                              kendoGridEditCommand
                              rounded="full"
                              class="btn-det"
                              style="
                                margin: 0;
                                padding: 0;
                                background-color: #017efa !important;
                              "
                              (click)="
                                goToOrderDetails(
                                  dataItem.or_agent_id,
                                  dataItem.order_id,
                                  dataItem.or_gamma_id,
                                  dataItem.or_assigned_date,
                                  agent_token,
                                  riferimento
                                )
                              "
                            >
                              <div class="setDisplayToFlexBlue btn-det">
                                <i
                                  class="bx bx-info-circle bx-md"
                                  style="margin: 0; padding: 0"
                                >
                                </i>
                                <p class="parButton2"></p>
                              </div>
                            </button>
                          </div>
                        </div>

                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <div style="text-align: left">
                            <h4>Stato:</h4>
                          </div>
                          <div style="text-align: right; margin-left: 10px">
                            <button
                              kendoGridEditCommand
                              *ngIf="
                                dataItem.os_tracking_number !== null &&
                                dataItem.os_tracking_number !== ''
                              "
                              rounded="full"
                              class="btn-track"
                              style="
                                margin: 0;
                                background-color: #be7e00 !important;
                              "
                              (click)="
                                goToTracking(
                                  agent_token,
                                  dataItem.order_id,
                                  dataItem.or_agent_id
                                )
                              "
                            >
                              <div class="setDisplayToFlexBlue btn-track">
                                <i
                                  class="bx bx-radar bx-md"
                                  style="margin: 0; padding: 0"
                                >
                                </i>
                                <p class="parButton2"></p>
                              </div>
                            </button>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-panelbar-item>
          </kendo-panelbar> 
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div> -->

<app-accesso-funzionari-tracking
  [track_ID]="shipment_id"
  [token]="agent_token"
  [agentID]="agentID"
  *ngIf="closeServ.isAgentTrack"
  [newTrack]="closeServ.isAgentTrack"
>
</app-accesso-funzionari-tracking>

<app-ordini-agente-dettaggli
  *ngIf="closeServ.isAgentOrderB"
  [agent_id]="clickedId"
  [token_agent]="agent_token"
  [order_id]="orderId"
  [or_gamma_id]="or_gamma_id"
  [or_assigned_date]="or_assigned_date"
  [openOrderDettagli]="closeServ.isAgentOrderB"
>
</app-ordini-agente-dettaggli>
