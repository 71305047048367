<div *ngIf="openPhoto" class="k-form dark-background pos">
  <kendo-dialog
    class="k-form"
    [formGroup]="addProductPositionForm"
    [minWidth]="250"
    [width]="750"
    [maxHeight]="650"
    [maxWidth]="1050"
    style="left: 6%"
  >
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15px;
        z-index: 1000;
        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="close()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff;
            bottom: 10px;
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button>
    </div>

    <h1 class="page-title">Aggiungi Posizione Prodotto</h1>

    <form [formGroup]="addProductPositionForm" class="k-form dialog-form">
      <div class="container">
        <div class="innerContainer">
          <div class="text-center">
            <label for="" class="custom-file-upload">
              <input
                class="form-control"
                type="file"
                placeholder=".dadsadas"
                (change)="onFileLoad($event)"
              />
              <div class="">
                <i class="bx bxs-camera icon-info-top"></i>
              </div>
            </label>
          </div>

          <div class="col det">
            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label
                [for]="pr_description"
                class="title"
                text="N°/Nome prodotto"
              >
              </kendo-label>
              <input
                formControlName="pr_description"
                kendoTextBox
                #pr_description
                [readonly]="true"
                rounded="full"
                [placeholder]="product.pr_description"
                class="setBorder-Prepp"
              />
            </kendo-formfield>
          </div>

          <div class="row det">
            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label [for]="pr_lane" class="title" text="Corsia">
              </kendo-label>
              <input
                formControlName="pr_lane"
                kendoTextBox
                #pr_lane
                [required]="true"
                rounded="full"
                class="setBorder-Prepp"
              />
            </kendo-formfield>
            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label [for]="pr_shelf" class="title" text="Ripiano">
              </kendo-label>
              <input
                formControlName="pr_shelf"
                kendoTextBox
                #pr_shelf
                [required]="true"
                rounded="full"
                class="setBorder-Prepp"
              />
            </kendo-formfield>
            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label [for]="pr_position" class="title" text="Scaffale">
              </kendo-label>
              <input
                formControlName="pr_position"
                kendoTextBox
                #pr_position
                [required]="true"
                rounded="full"
                class="setBorder-Prepp"
              />
            </kendo-formfield>
            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label [for]="pr_qty" class="title" text="Quantità">
              </kendo-label>
              <input
                formControlName="pr_qty"
                kendoTextBox
                #pr_qty
                [required]="true"
                rounded="full"
                class="setBorder-Prepp"
              />
            </kendo-formfield>
          </div>

          <div class="text-buttons my-5">
            <div class="button-left">
              <button
                class="btn btn3"
                rounded="full"
                style="
                  background-color: #017efa;
                  color: #ffffff;
                  justify-content: center !important;
                  display: flex;
                  width: 192px !important;
                  height: 42px !important
                "
                (click)="addProductPos(product)"
                [disabled]="!addProductPositionForm.value.pr_position"
              >
                <i
                  class="bx bx-plus-circle bx-sm posRelBox2"
                  style="
                    color: #ffffff;
                    top: 0px;
                    right: 6px;
                    bottom: 10px;
                    position: relative;
                  "
                ></i>
                <p class="posRelBox2">AGGIUNGI</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </kendo-dialog>
</div>
