import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { IAgent } from 'src/app/models/IAgent.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { AgentsService } from 'src/app/services/agents.service';
import { EmailComposerService } from 'src/app/services/shared/email-composer.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-funzionari-list',
  templateUrl: './accesso-funzionari-list.component.html',
  styleUrls: ['./accesso-funzionari-list.component.scss'],
})

export class AccessoFunzionariListComponent implements OnInit {

  public agentsData: IAgent[] = [];
  public existAgent: IAgent;
  public loading: boolean = false;
  public agentsUrl: IAgent[] = [];
  public opened = false;
  public pageSize = 20;

  dataItem: any;
  ag_token: string;
  filterTerm: string | undefined;
  clickedID: string;
  domainName: string;
  value: any;
  url_start: string = 'https://' + 'salvatempo.livith.it' + '?token=';
  firstUrl: any;

  constructor(
    public closeServ: OpenModalService,
    private agentsServ: AgentsService,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef,
    private windowRef: EmailComposerService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getAgents();
    this.domainName = window.location.hostname;
    let domaninOrigin = window.location.origin;
    this.url_start = domaninOrigin + '/agentsorders' + '?token=';
  }

  getAgents() {
    this.agentsServ.getAgents().subscribe((agentsData: IWsResult) => {
      if (agentsData.error_message.msg_code === 8) 
        this.agentsData = [];
      else 
        this.agentsData = agentsData.data;
      console.log('Agents data is: ', this.agentsData);
      this.loading = false;
    });
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getAgents();
    }
  }

  clearSearch() {
    this.filterTerm = '';
    this.getAgents();
  }

  copyToClipboard(text: string, agent) {
    let link = this.url_start + encodeURIComponent(agent.ag_url);
    const textarea = this.renderer.createElement('textarea');
    this.renderer.setProperty(textarea, 'value', link);
    this.renderer.appendChild(this.el.nativeElement, textarea);
    console.log('This domain name is: ', this.domainName);
    console.log('The link is: ', link);
    textarea.select();
    console.log(textarea, 'is');
    document.execCommand('copy');
    this.renderer.removeChild(this.el.nativeElement, textarea);
    const email = agent.ag_email;
    const subject = 'Tracking ID';
    const body = link;
    console.log('Email is: ', email);
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    this.windowRef.nativeWindow.open(mailtoLink);
  }

  composeEmail(): void {
    const email = 'recipient@example.com';
    const subject = 'Hello';
    const body = 'Just wanted to say hi!';
  }

  generateUrl(agent_id: string) {
    this.agentsServ.generateURL(agent_id).subscribe((agentsData: IWsResult) => {
      console.debug('Merge?');
      console.debug(agentsData);
      if (agentsData.success) {
        this.getAgents();
      }
      //this.getAgents();
    });
  }

  deleteURL(agent_id: string) {
    this.agentsServ.deleteURL(agent_id).subscribe((agentsData: IWsResult) => {
      console.debug('Merge?');
      console.debug(agentsData);
      //this.getAgents();
    });
  }

  searchAgents() {
    console.log('FilterTerm is: ', this.filterTerm);
    if (!this.filterTerm || this.filterTerm == '') {
      this.agentsServ
        .searchAgents(this.filterTerm)
        .subscribe((openOrdersData: IWsResult) => {
          if (openOrdersData.error_message.msg_code === 8) 
            this.agentsData = [];
          else 
            this.agentsData = openOrdersData.data;
        });
    }
    this.agentsServ
      .searchAgents(this.filterTerm)
      .subscribe((openOrdersData: IWsResult) => {
        if (openOrdersData.error_message.msg_code === 8) this.agentsData = [];
        else this.agentsData = openOrdersData.data;
      });
  }

  goToAgentList(agent_token: any) {
    console.log('data is: ', agent_token);
    this.ag_token = encodeURIComponent(agent_token);
    this.router.navigate(['/agentsorders'], {
      queryParams: { token: agent_token },
    });
    console.log('merge?', this.ag_token);
  }

  goToDetails(id: string, token: string) {
    this.clickedID = id;
    this.closeServ.onDetAgent();
    this.ag_token = encodeURIComponent(token);
    console.log('Agent token is: ', this.ag_token);
  }
  
}
