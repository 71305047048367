import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GridDataResult,
  PageChangeEvent,
  RowClassArgs,
} from '@progress/kendo-angular-grid';
import { IMessageList, Notifications } from 'src/app/models/IMessage.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { MessagesService } from 'src/app/services/messages.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-messaggi-list',
  templateUrl: './messaggi-list.component.html',
  styleUrls: ['./messaggi-list.component.scss'],
})

export class MessaggiListComponent implements OnInit {

  public gridData: IMessageList[] = [];
  public senders: any[];
  public messagerName: string;
  public loading: boolean = false;
  public notification: IMessageList = null;
  public pageSize = 20;
  public skip = 0;
  public users: string[];
  public notifications: Notifications.Notification[] = [];
  public conversations: Notifications.Conversations[] = [];
  public filteredNotifications: Notifications.Notification[] = null;
  public filteredLimitedNotifications: Notifications.Notification[] = null;
  public isSearched: boolean = false;

  user: string = '';
  msgIndex: number;
  msg: string = '';
  clickedMsg: string;
  numbers: number[] = [];
  filterTerm: string | undefined;
  id: number = 0;
  timer = null;
  refreshInterval = 60000;
  senderName: string = '';
  searchString = 'Filtra messaggi per utente';
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  showAll: string = 'Mostra tutti';
  groupedMessages: { [sender: string]: IMessageList[] } = {};
  dateBy: any[] = [
    {
      text: 'Data',
      value: 'ms_datetime',
    },
  ];
  filters = {
    dateOrginizedBy: {
      text: 'Data',
      value: 'ms_datetime',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  limitedNotifications: Notifications.Notification[] = [];  
  isAdmin: boolean = false;  
  notificationsLimit: number = 1;
  updateInifiniteElem: number = 50;

  constructor(
    private messagesServ: MessagesService,
    private router: Router,
    public closeSrv: OpenModalService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.notificationsLimit = this.updateInifiniteElem;
    this.notificationsLimit = this.updateInifiniteElem;
    this.messagesServ.getConversations().subscribe((result) => {
      this.conversations = result;
      console.log('Brossini we got a problem', this.conversations);
    });
    this.getMessages();
    this.timer = setInterval(() => {
      this.getMessages();
    }, this.refreshInterval);
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getMessages();
    }
  }

  getMessages() {
    this.loading = true;
    this.messagesServ.getMessages().subscribe((messagesData: IWsResult) => {
      this.gridData = messagesData.data;
      this.groupMessagesBySender(messagesData.data);
      this.loading = false;
    });
  }

  groupMessagesBySender(messages: IMessageList[]) {
    const groupedMessages: { [sender: string]: IMessageList[] } = {};
    messages.forEach((message) => {
      const sender = message.ms_sender_name;
      if (!groupedMessages[sender]) {
        groupedMessages[sender] = [];
      }
      groupedMessages[sender].push(message);
    });
    this.groupedMessages = groupedMessages;
    this.senders = Object.keys(groupedMessages);
  }

  onMessengerNameSelect(name: string) {
    this.senderName = name;
    return;
    this.messagesServ.getMessages().subscribe((messagesData: IWsResult) => {
      this.gridData = messagesData.data;
      console.log('Searching...');
    });
    console.log('Selected messenger name:', name);
    return;
    this.messagesServ
      .searchMessages(name)
      .subscribe((messagesData: IWsResult) => {
        this.gridData = messagesData.data;
        console.log('Messages sent by', name, ':', messagesData);
        console.log('Searching...');
      });
  }

  onMessengerNameSelectz(name: string, i: number) {
    this.user = this.searchString;
    console.log('I is: ', this.searchString);
    if (name === this.showAll) 
      this.user = null;
  }

  searchMessages() {
    if (!this.filterTerm || this.filterTerm == '') {
      this.messagesServ.getMessages().subscribe((messagesData: IWsResult) => {
        this.gridData = messagesData.data;
        console.log('Searching...');
      });
    }
    this.messagesServ
      .searchMessages(this.filterTerm)
      .subscribe((messagesData: IWsResult) => {
        this.gridData = messagesData.data;
        console.log('Searching...');
      });
  }

  searchByName() {
    if (!this.filterTerm || this.filterTerm == '') {
      this.messagesServ.getMessages().subscribe((messagesData: IWsResult) => {
        this.gridData = messagesData.data;
      });
    }
  }

  gotoDetails(message_id: string) {
    this.clickedMsg = message_id;
    this.closeSrv.onMessID();
  }

  changeProductNumber(n) {
    this.numbers = Array.from({ length: n }, (_element, index) => index + 1);
  }

  onModalClosed() {
    this.getMessages();
    console.log('Modal Closed successfully!!!');
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.or_partial === 'S') {
      return { partialOrderStyle: true };
    }
  };
  
}
