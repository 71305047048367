import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Products } from 'src/app/models/Product.model';
import { ProductsService } from 'src/app/services/products.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})

export class ProductDetailComponent implements OnInit {
  
  @Input() opened = false;
  @Input() order_id: string = '-1';
  @Input() code: any;
  @Input() isProdOpen = false;
  @Input() product_id: string = '-1';

  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 1356;
  public dialogHeight = 1017;

  id: string = '';
  route: string = '';
  pr_desc: string = '';
  pr_position: Products.IProductPosition[] = [];
  pr_lane: string = '';
  pr_qty: number = 0;
  productDetails: Products.IProductDetails;
  product: Products.IProductDetails = null;
  clickedProduct: string;
  isZoomed: boolean = false;
  product_pos_id: string;

  constructor(
    public productSrv: ProductsService,
    public openSrv: OpenModalService,
    private formbuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public closeServ: OpenModalService
  ) { }

  ngOnInit() {
    this.triggerRefresh();
  }

  triggerRefresh() {
    let fromFamily = this.activatedRoute.snapshot.paramMap.get('family');
    let routeId = fromFamily ? 'id' : 'productId';
    if (routeId === undefined) 
      return;
    this.id = this.activatedRoute.snapshot.paramMap.get(routeId);
    const newId = this.id?.substring(this.id.lastIndexOf('-') + 1);
    console.log(' Activated route: ', this.activatedRoute.snapshot.paramMap);
    const route = this.router.url;
    this.route = route.substring(0, route.lastIndexOf('/'));
    this.route = this.route.substring(0, this.route.lastIndexOf('/'));
    this.productSrv
      .getProduct(newId)
      .subscribe((result: Products.IProductDetails) => {
        console.debug('product: ', result);
        this.product = result;
        this.pr_position = this.product.pr_positions;
        console.log('Position is: ', this.pr_position);
      });
  }

  goToDetails(product: Products.IProductDetails) {
    this.product = product;
    this.openSrv.onPos();
    console.debug('MERGE?');
  }

  zoomIn() {
    this.isZoomed = !this.isZoomed;
  }

  goToPosDetails(product_id: string) {
    this.clickedProduct = product_id;
    console.log('Show me the ID: ', this.clickedProduct);
    this.openSrv.onPosDet();
  }

  goBack() {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  deleteProductPosition(pr_id) {
    this.product_pos_id = pr_id;
    this.productSrv
      .deleteProduct(this.product_pos_id)
      .subscribe((result: any) => {
        if (result)
          Swal.fire({
            icon: 'success',
            title: 'Eliminata!',
            text: 'Posizione eliminata con successo!!!',
            timer: 1500,
            timerProgressBar: true,
          });
        this.triggerRefresh();
      });
  }

  close() {
    this.opened = false;
    this.router.navigate(['/products']);
  }
  
}
