import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Boxes } from 'src/app/models/Boxes.models';
import { ICarrier } from 'src/app/models/ICarrier.model';
import { IWorker } from 'src/app/models/IWorker.model';
import { Orders } from 'src/app/models/Orders.model';
import { Priority } from 'src/app/models/Priority.model';
import { NewProduct, Products } from 'src/app/models/Product.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-orderscm-det',
  templateUrl: './orderscm-det.component.html',
  styleUrls: ['./orderscm-det.component.scss'],
})

export class OrderscmDetComponent {

  @Input() opened: boolean = false;
  @Input() order_id: string;
  @Output() pingBackForBoolean: EventEmitter<boolean> =
    new EventEmitter<false>();
  public order: Orders.Order;
  public productsToSend: Products.IProductToSend[] = [];
  public vsDetails: Orders.IVSDetails;
  public boxes: Boxes.BoxOrder[] = [];
  public product: NewProduct[] = [];
  public dataSaved = false;
  public isEditable = false;
  public openeddo = true;
  public assignOrder: Orders.IOrderPrepared[] = [];
  public orderToUpdate: Orders.OrderUpdate;
  public dropProducts: Products.Product[];
  public productToAdd: Products.ProductToAdd[] = [];
  public productsNew: Products.IProductToSend[] = [];
  public orderNew: any;
  public assignOrderForm: FormGroup;
  public priorityOptions: Priority.IPriority[] = [];
  public carriers: ICarrier[] = [];
  public workers: IWorker[] = [];

  orderDetailsForm!: FormGroup;
  formattedDate: string = '-';
  clickedBox: string;
  detailsBox: any;
  defaultItem: any;
  filterTerm: string | undefined;
  productChecked: boolean = false;
  newArrayOfProducts: any[] = [];
  selectedProduct: Products.Product;
  selectedIndex: number;
  productsToAddSolo: Products.ProductToAdd[] = [];
  closeFirstSearchBar: boolean = false;
  openedSearch2: boolean = false;
  searchBool2: boolean = false;
  selectedProductIdToDelete: any;
  searchBool: boolean = false;
  openedSearch: boolean = false;
  selectedIndexPrev: any;
  selectedProductFromNewList: any;
  partialOrder: string = null;
  areSelected: boolean = false;
  clickedProduct: any;
  numbers: number[] = [];

  constructor(
    private ordersServ: OrdersService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    public closeServ: OpenModalService,
    private dropdownServ: DropdownService
  ) { }

  ngOnInit(): void {
    console.log('Ce cacat e aici?');
    this.getProducts();
  }

  public close() {
    this.opened = false;
    this.router.navigate(['/pm-orders']);
  }

  public goBackFunction() {
    this.router.navigate(['/pm-orders']);
  }

  goEditFunction() {
    this.isEditable = !this.isEditable;
  }

  open() {
    this.opened = true;
  }

  edit() {
    this.isEditable = true;
  }

  save(orderDetailsForm: any) {
    console.log('orderDetails: ', orderDetailsForm);
    let details: Orders.IOrderDetails = {
      or_order_number: this.orderDetailsForm.value.or_order_number,
      or_order_date: this.orderDetailsForm.value.or_order_date,
      or_customer: this.orderDetailsForm.value.or_customer,
      or_shipping_address: this.orderDetailsForm.value.or_shipping_address,
      or_note: this.orderDetailsForm.value.or_note,
      or_status: this.orderDetailsForm.value.status,
    };
    console.log('ORDINE: ', details);
    this.ordersServ.saveOrder(details).subscribe((order) => {
      this.vsDetails = order.data;
      this.close();
    });
    console.log('Orders: ', details);
    this.router.navigate(['/pm-orders']);
    Swal.fire({
      icon: 'success',
      title: 'Modificato!',
      text: 'Ordine modificato con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

  goToBox(ts_box_type_id: any) {
    this.clickedBox = ts_box_type_id;
    this.closeServ.openBox();
  }

  goToBoxDetails(box: Boxes.IBoxOrder, boxNo: number) {
    this.detailsBox = box;
    if (box.ts_box_type_id) return;
    this.closeServ.openDetBox();
  }

  async removeBox(id) {
    this.orderToUpdate.boxes.splice(id, 1);
    this.ordersServ.updateOrder(this.orderToUpdate).subscribe();
  }

  addDocuments(id) {}

  removeProduct(id) {
    const index = this.dropProducts.indexOf(id);
    this.newArrayOfProducts = this.dropProducts.splice(index, 1);
    console.log('DropProducts are: ', this.dropProducts);
  }

  opChecked(product: Products.IProductToSend, index: number) {
    this.productsNew[index].op_checked = !this.productsNew[index].op_checked;
    this.partialOrder =
      this.productsNew.every((value) => value.op_checked == true) &&
      this.productsNew.every((value) => +value.op_qta === value.orQtaNeeded)
        ? 'N'
        : 'S';
  }

  addOrderMethod(assignOrderToSendForm: any) {
    let assignOrder: Orders.IOrderToSend = {
      or_assignment_date: this.orderNew.or_order_date,
      or_assigned_id: this.assignOrderForm.value.or_assigned_id,
      or_order_priority_cod: this.assignOrderForm.value.or_order_priority_cod,
      or_carrier_id: this.assignOrderForm.value.carrier_id,
      or_partial: this.partialOrder,
      or_customer_reference: '123',
      products: this.productsNew.map((item) => {
        item.op_qta = item.orQtaNeeded;
        return item;
      }),
      or_order_number: this.assignOrderForm.value.or_order_number,
      or_order_date: this.orderNew.or_order_date,
      or_customer: this.assignOrderForm.value.or_customer,
      or_shipping_address: this.assignOrderForm.value.or_shipping_address,
      or_shipping_city: this.assignOrderForm.value.or_shipping_city,
      or_shipping_prov: this.assignOrderForm.value.or_shipping_prov,
      or_shipping_country: this.assignOrderForm.value.or_shipping_country,
      or_gamma_id: this.orderNew.or_gamma_id,
      or_agent_id: '1',
      or_customer_code: this.orderNew.or_customer_code,
      or_customer_mail: this.orderNew.or_customer_mail,
      or_customer_phone: this.orderNew.or_customer_phone,
      or_shipping_zip: this.assignOrderForm.value.or_shipping_zip,
      or_quote_number: this.orderNew.or_quote_number,
      or_quote_date: this.orderNew.or_quote_date,
      or_subject: this.orderNew.or_subject,
      or_note: this.orderNew.or_note, // '1',
      or_boxes: this.orderNew.or_boxes,
      or_shipping_customer: this.orderNew.or_shipping_customer,
      or_carrier_desc: this.orderNew.or_carrier_desc,
    };
    console.log(assignOrder);
    console.log(this.orderNew);
    this.ordersServ.addOrder(assignOrder).subscribe((ordersData) => {
      if (ordersData.success) {
        this.goBack();
        Swal.fire({
          icon: 'success',
          title: 'Assegnato!',
          text: 'Ordine assegnato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
      } 
      else {
        Swal.fire({
          icon: 'error',
          title: 'Non assegnato!',
          text: 'Si è verificato un problema, riprova!!!',
          timer: 1500,
          timerProgressBar: true,
        });
      }
    });
  }

  async updateProduct(value, i) {
    this.productsNew[i].orQtaNeeded = value;
    this.productsNew[i].op_checked = true;
    this.partialOrder =
      this.productsNew.every((value) => value.op_checked == true) &&
      this.productsNew.every((value) => +value.op_qta === value.orQtaNeeded)
        ? 'N'
        : 'S';
  }

  public goBack() {
    this.router.navigate(['/pm-orders']);
  }

  getNewArrayOfProducts(arrayProducts) {
    if (this.route.snapshot.paramMap.get('id'))
      this.orderDetailsForm = this.formBuilder.group({
        or_order_number: [''],
        or_order_date: [''],
        or_closing_date: [''],
        or_quote_number: [''],
        or_customer: [''],
        or_shipping_address: [''],
        or_note: [''],
        operator: [''],
        status: [''],
        boxes: [''],
        op_articles_number: [''],
        op_weigth: [''],
        tab_order_priority_cod: [''],
      });
    console.log('Params are: ', this.route.snapshot.paramMap);
    this.ordersServ
      .getOrder(this.route.snapshot.paramMap.get('id')!)
      .subscribe((orderData: IWsResult) => {
        this.order = orderData.data;
        this.dropProducts = arrayProducts;
        this.orderToUpdate = new Orders.OrderUpdate(this.order);
        if (this.orderToUpdate.or_carrier_id == 0)
          this.orderToUpdate.or_carrier_id = null;
        this.defaultItem = this.order.products[0];
        this.boxes = this.order.boxes.map((item) => {
          let box: Boxes.BoxOrder = {
            ts_box_type_id: item.ts_box_type_id,
            ts_box_type_desc: item.ts_box_type_desc,
            ts_box_height: item.ts_box_height,
            ts_box_length: item.ts_box_length,
            ts_box_depth: item.ts_box_depth,
            ts_box_weight: item.ts_box_weight,
            ts_documents: 'N',
            ts_screws: 'N',
            checkForNull: function (): boolean {
              throw new Error('Function not implemented!');
            },
          };
          return box;
        });
        this.productsToSend = this.order.products.map((item) => {
          let eineProduct: Products.IProductToSend = {
            op_note: '',
            op_checked: false,
            orQtaNeeded: 0,
            op_code: '',
            op_description: '',
            op_qta: item.op_qta,
            op_weigth: 0,
            op_stock: 0,
            op_qta_ordered: '',
            op_positions: [],
            tab_order_status_cod: [],
          };
          return eineProduct;
        });
        if (this.order.vs_details) 
          this.vsDetails = this.order.vs_details;
        if (this.order.or_order_date)
          this.formattedDate = formatDate(
            this.order.or_order_date,
            'dd/MM/yyyy',
            'it'
          );
        console.debug(this.order);
        this.orderDetailsForm.patchValue({
          or_order_number: this.order.or_order_number,
          or_order_date: this.formattedDate,
          or_closing_date: this.order.or_closing_date,
          or_quote_number: this.order.or_quote_number,
          or_customer: this.order.or_customer,
          or_shipping_address: this.order.or_shipping_address,
          tab_order_priority_cod: this.order.or_order_priority_cod,
          or_note: this.order.or_note,
          operator:
            this.vsDetails.ac_first_name + ' ' + this.vsDetails.ac_last_name,
          status: this.order.status[0].tab_order_status_desc,
          boxes: this.orderToUpdate?.boxes,
          op_articles_number: this.order.vs_details.op_articles_number,
        });
      });
  }

  addBox(dataInfo: any) {
    console.debug('Add box: ', dataInfo);
    for (let i = 0; i < dataInfo.boxesNo; i++) {
      const box: Boxes.IBoxOrder = {
        ts_box_type_id: dataInfo.normal.box_type_id,
        ts_box_type_desc: dataInfo.normal.box_type_desc,
        ts_box_height: dataInfo.custom.ts_box_height,
        ts_box_length: dataInfo.custom.ts_box_length,
        ts_box_depth: dataInfo.custom.ts_box_depth,
        ts_box_weight: dataInfo.custom.ts_box_weight,
        ts_documents: 'N',
        ts_screws: 'N',
      };
      this.orderToUpdate.boxes.push(box);
    }
    console.debug(this.orderToUpdate);
    this.ordersServ.updateOrder(this.orderToUpdate).subscribe();
  }

  addDocumentsToBox(boxNo: number) {
    this.orderToUpdate.boxes[boxNo].ts_documents =
      this.orderToUpdate.boxes[boxNo].ts_documents?.toUpperCase() === 'S'
        ? 'N'
        : 'S';
    this.ordersServ.updateOrder(this.orderToUpdate).subscribe();
  }

  addScrewsToBox(boxNo: number) {
    this.orderToUpdate.boxes[boxNo].ts_screws =
      this.orderToUpdate.boxes[boxNo].ts_screws?.toUpperCase() == 'S'
        ? 'N'
        : 'S';
    this.ordersServ.updateOrder(this.orderToUpdate).subscribe();
  }

  printAllLabels() {
    this.ordersServ
      .getAllBoxLabels(
        this.order.order_id,
        this.order.boxes.map((box, index) => index + 1)
      )
      .subscribe((result) => {
        console.debug('result: ', result);
        if (!result) return;
        window.open(result.box_label_link_pdf);
      });
  }

  selectAllProducts() {
    this.areSelected = !this.areSelected;
    this.productsNew.forEach((item: any) => {
      item.op_checked = this.areSelected;
    });
    console.log('The selected products are: ', this.productsNew);
  }

  getProducts() {
    this.assignOrderForm = this.formBuilder.group({
      or_order_number: [''],
      or_order_date: [''],
      or_order_priority_cod: ['', Validators.required],
      carrier_id: [''],
      or_customer: [''],
      or_shipping_address: [''],
      or_shipping_zip: [''],
      or_shipping_city: [''],
      or_shipping_prov: [''],
      or_shipping_country: [''],
      or_assigned_id: ['', Validators.required],
    });
    this.ordersServ
      .getNewOrder(this.route.snapshot.paramMap.get('id')!)
      .subscribe((orderData: IWsResult) => {
        this.orderNew = orderData.data;
        console.log('Get new order: ', orderData);
        console.log('Quote date is: ', typeof this.orderNew.or_quote_date);
        this.productsNew = this.orderNew.products.map((item) => {
          let product: Products.IProductToSend = {
            op_note: '',
            op_checked: false,
            op_code: item.op_code,
            op_description: item.op_description,
            op_qta: item.op_qta,
            op_weigth: item.op_weigth,
            op_stock: item.op_stock,
            orQtaNeeded: +item.op_qta,
            op_qta_ordered: item.op_qta_ordered,
            op_positions: [],
            tab_order_status_cod: [],
          };
          return product;
        });
        this.dropdownServ
          .getPriorities()
          .subscribe((priorityData: Priority.IPriority[]) => {
            this.priorityOptions = priorityData;
          });
        if (this.orderNew.or_order_date)
          this.formattedDate = formatDate(
            this.orderNew.or_order_date,
            'dd/MM/yyyy',
            'it'
          );
        this.dropdownServ
          .getCarriers()
          .subscribe((carriersData: ICarrier[]) => {
            this.carriers = carriersData;
          });
        this.dropdownServ.getWorkers().subscribe((workersData: IWorker[]) => {
          workersData.forEach(
            (element) =>
              (element.full_name =
                element.ac_first_name + ' ' + element.ac_last_name)
          );
          this.workers = workersData;
        });
        this.assignOrderForm.patchValue({
          or_order_number: this.orderNew.or_order_number,
          or_order_date: this.formattedDate,
          or_order_priority_cod: 'Select',
          carrier_id: this.orderNew?.or_carrier_id,
          or_customer: this.orderNew.or_customer,
          or_shipping_address: this.orderNew.or_shipping_address,
          or_shipping_zip: this.orderNew.or_shipping_zip,
          or_shipping_city: this.orderNew.or_shipping_city,
          or_shipping_prov: this.orderNew.or_shipping_prov,
          or_shipping_country: this.orderNew.or_shipping_country,
        });
        console.log(
          'Data w/o new Date',
          this.assignOrderForm.value.or_quote_date
        );
        console.log(
          'Date is: ',
          new Date(this.assignOrderForm.value.or_quote_date)
        );
        if (!new Date(this.assignOrderForm.value.or_quote_date))
          console.log('It is invalid!');
        if (!new Date(this.assignOrderForm.value.or_order_date))
          console.log('It is invalid!');
      });
  }

  getSimilarProducts() {
    console.log('FilterTerm is: ', this.filterTerm);
    if (this.searchBool !== false) {
      this.filterTerm = this.selectedProduct.op_description;
      this.ordersServ
        .getSimilarProducts(this.filterTerm)
        .subscribe((openOrdersData: IWsResult) => {
          console.log('The filter term is: ', this.filterTerm);
          this.searchBool = false;
          this.productToAdd = openOrdersData.data;
        });
    }
    if (!this.filterTerm || this.filterTerm == '') {
      this.ordersServ
        .getSimilarProducts(this.filterTerm)
        .subscribe((openOrdersData: IWsResult) => {
          this.productToAdd = openOrdersData.data;
        });
    }
    this.ordersServ
      .getSimilarProducts(this.filterTerm)
      .subscribe((openOrdersData: IWsResult) => {
        this.productToAdd = openOrdersData.data;
      });
  }

  getSimilarProductsSolo() {
    if (!this.filterTerm || this.filterTerm == '') {
      this.ordersServ
        .getSimilarProducts(this.filterTerm)
        .subscribe((openOrdersData: IWsResult) => {
          this.productsToAddSolo = openOrdersData.data;
        });
    }
    this.ordersServ
      .getSimilarProducts(this.filterTerm)
      .subscribe((openOrdersData: IWsResult) => {
        this.productsToAddSolo = openOrdersData.data;
      });
  }

  selectProductToAddSolo(i) {
    let selectedProducts = this.productsToAddSolo.filter(
      (product) => product.op_checked
    );
    if (selectedProducts.length > 0) {
      console.log('Warning: More than one product selected!');
    }
    for (let j = 0; j < this.productsToAddSolo.length; j++) {
      if (j !== i) {
        this.productsToAddSolo[j].op_checked = false;
      }
    }
    this.productsToAddSolo[i].op_checked =
      !this.productsToAddSolo[i].op_checked;
    this.selectedProductFromNewList = this.productsToAddSolo[i];
    console.log('SelectedProduct from list: ', this.selectedProductFromNewList);
    console.log('Selected product: ', this.productsToAddSolo[i]);
  }

  deleteProduct() {
    console.log(
      'The first ID to be deleted is: ',
      this.selectedProductIdToDelete
    );
    this.ordersServ
      .deleteProductFromOrder(this.selectedProductIdToDelete)
      .subscribe();
  }

  addProduct(object: any, i: number) {
    let product: Products.ProductToAdd = {
      order_id: this.order.order_id,
      op_code: this.selectedProductFromNewList.pr_code,
      op_description: this.selectedProductFromNewList.pr_description,
      op_remaining_qty: this.selectedProduct.op_qta_stock,
      op_qty: this.selectedProduct.op_qta,
      op_weight: this.selectedProduct.op_weigth,
      op_position: null,
      op_note: this.selectedProduct.op_note,
    };
    console.log('Product is: ', product);
    console.log('Op_code is: ', this.productToAdd[i].op_code);
    this.ordersServ
      .addProductToOrder(product)
      .subscribe((productData: IWsResult) => {
        if (productData.success) {
          console.log(
            'The ID of the product to delete is: ',
            this.selectedProductIdToDelete
          );
          this.deleteProduct();
          this.openedSearch = false;
          this.getProducts();
        }
      });
  }

  addProductSoloConfirm(object: any, i: number) {
    let product: Products.ProductToAdd = {
      order_id: this.order.order_id,
      op_code: this.selectedProductFromNewList.pr_code,
      op_description: this.selectedProductFromNewList.pr_description,
      op_remaining_qty: '5',
      op_qty: 5,
      op_weight: this.selectedProductFromNewList.op_weigth,
      op_position: null,
      op_note: this.selectedProductFromNewList.op_note,
    };
    console.log('Product is: ', product);
    this.ordersServ
      .addProductToOrder(product)
      .subscribe((productData: IWsResult) => {
        if (productData.success) {
          console.log(
            'The ID of the product to delete is: ',
            this.selectedProductIdToDelete
          );
          this.openedSearch = false;
          this.getProducts();
        }
      });
  }
  changeProductNumber(n) {
    this.numbers = Array.from({ length: n }, (_element, index) => index + 1);
  }

  addProductSolo() {
    console.log('Ce dracu ma');
    this.searchBool2 = !this.searchBool2;
    this.openedSearch2 = !this.openedSearch2;
  }

  selectProduct(object: Products.Product, i: number) {
    this.selectedProduct = object;
    this.selectedProductIdToDelete = this.dropProducts[i].products_order_id;
    this.selectedIndexPrev = this.dropProducts[i].op_qta;
    this.dropProducts[i].op_checked = true;
    console.log('This product index is: ', this.selectedProductIdToDelete);
    console.log('This selected product is: ', this.selectedProduct);
    console.log('The selected index: ', this.selectedIndexPrev);
    this.searchBool = !this.searchBool;
    this.openedSearch = !this.openedSearch;
    this.getSimilarProducts();
  }

  selectProductToAdd(i) {
    let selectedProducts = this.productToAdd.filter(
      (product) => product.op_checked
    );
    if (selectedProducts.length > 0) {
      console.log('Warning: More than one product selected!');
    }
    for (let j = 0; j < this.productToAdd.length; j++) {
      if (j !== i) {
        this.productToAdd[j].op_checked = false;
      }
    }
    this.productToAdd[i].op_checked = !this.productToAdd[i].op_checked;
    this.selectedProductFromNewList = this.productToAdd[i];
    console.log('SelectedProduct from list: ', this.selectedProductFromNewList);
    console.log('Selected product: ', this.productToAdd[i]);
  }

  goToProducts() {
    this.closeServ.onProdList();
    console.log('The products are: ', this.dropProducts);
  }

  ngOnDestroy() {
    console.log('Emitted the value');
  }
  
}
