<div class="k-overlay dark-background"></div>

<div class="dark-background k-display-flex justify-content-center">
  <kendo-dialog
    title=""
    (close)="goBackFunction()"
    *ngIf="opened"
    [minWidth]="minWidth"
    [width]="dialogWidth"
    [minHeight]="500"
    [maxWidth]="850"
    [width]="850"
    class="k-form"
    [formGroup]="addAccountForm"
  >
    <div 
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15px;
        z-index: 1000;
        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="close()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >    
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff; 
            bottom: 10px; 
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button> 
    </div>
    <h1 class="page-title">Nuovo Account</h1>
    <hr class="hr-dialog" />
    <form
      class="k-form dialog-form"
      (ngSubmit)="addAgent(addAccountForm)"
      [formGroup]="addAccountForm"
    >
      <div class="row det mb-2">
        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label 
            [for]="ac_first_name"
            class="title" 
            text="Nome"
          ></kendo-label>
          <input            
            formControlName="ac_first_name"
            kendoTextBox
            #ac_first_name
            required
            class="dropdown-field setBorder-Preppp"
            placeholder="Nome account"
          />
          <kendo-formerror
            *ngIf="
              (addAccountForm.get('ac_first_name')?.touched ||
                addAccountForm.get('ac_first_name')?.dirty) &&
              addAccountForm.get('ac_first_name')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>

        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label 
            [for]="ac_last_name"
            class="title" 
            text="Cognome"
          ></kendo-label>
          <input
            formControlName="ac_last_name"
            kendoTextBox
            #ac_last_name
            required
            class="dropdown-field setBorder-Preppp"
            placeholder="Cognome account"
          />
          <kendo-formerror
            *ngIf="
              (addAccountForm.get('ac_last_name')?.touched ||
                addAccountForm.get('ac_last_name')?.dirty) &&
              addAccountForm.get('ac_last_name')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="row det mb-2">
        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label
            class="title"
            text="Data scadenza"
          ></kendo-label>
          <kendo-datepicker
            formControlName="ac_expiration_date"
            #ac_expiration_date
            class="dropdown-field setBorder-Preppp"
            [min]="today"
            required
          ></kendo-datepicker>
          <kendo-formerror
            *ngIf="
              (addAccountForm.get('ac_expiration_date')?.touched ||
                addAccountForm.get('ac_expiration_date')?.dirty) &&
              addAccountForm.get('ac_expiration_date')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>

        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label 
            [for]="ac_user_type_cod"
            class="title" 
            text="Tipologia"
          ></kendo-label>
          <kendo-combobox
            [data]="accountTypesSelect"
            formControlName="ac_user_type_cod"
            kendoTextBox
            #ac_user_type_cod
            (valueChange)="onAccountTypeSelected($event)"
            required
            textField="ac_typo"
            [valuePrimitive]="true"
            valueField="ac_number"
            class="dropdown-field setBorder-Prepp"
            placeholder="Tipo di account"
          >
          </kendo-combobox>
        </kendo-formfield>
      </div>

      <div class="row det">
        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label 
            [for]="ac_phone"
            class="title" 
            text="Telefono"
          ></kendo-label>
          <input
            formControlName="ac_phone"
            kendoTextBox
            #ac_phone
            required
            class="dropdown-field setBorder-Preppp"
            placeholder="Numero di telefono"
          />
          <kendo-formerror
            *ngIf="
              (addAccountForm.get('ac_phone')?.touched ||
                addAccountForm.get('ac_phone')?.dirty) &&
              addAccountForm.get('ac_phone')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>

        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label 
            [for]="ac_email"
            class="title" 
            text="Email"
          ></kendo-label>
          <input            
            formControlName="ac_email"
            kendoTextBox
            #ac_email
            [required]="true"
            class="dropdown-field setBorder-Preppp"
            placeholder="Indirizzo Email"
          />
          <kendo-formerror
            *ngIf="
              (addAccountForm.get('ac_email')?.touched ||
                addAccountForm.get('ac_email')?.dirty) &&
              addAccountForm.get('ac_email')?.errors
            "
          >
            Email non valida!
          </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="row column-secret"></div>

      <div class="text-buttons">
        <div class="button-left">
          <button
            type="submit"
            class="btn btn3"
            rounded="full"
            style="
              background-color: #017efa;
              color: #ffffff;
              justify-content: center !important;
              display: flex;
              width: 192px !important;
              height: 42px !important;
            "
            (click)="addAgent(addAccountForm)"
            [disabled]="addAccountForm.invalid"
          >
            <i
              class="bx bx-plus-circle bx-sm posRelBox2"
              style="
                color: #ffffff;
                top: 0px;
                right: 6px;
                bottom: 10px;
                position: relative;
              "
            ></i>
            <p class="posRelBox2">AGGIUNGI</p>
          </button>
        </div>
      </div>
    </form>
  </kendo-dialog>
</div>