import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subscription } from 'rxjs';
import { GoalService } from 'src/app/services/goal.service';

@Component({
  selector: 'app-fiesta',
  templateUrl: './fiesta.component.html',
  styleUrls: ['./fiesta.component.scss'],
  animations: [
    trigger('scaleUp', [
      state('initial', style({
        transform: 'scale(1)'
      })),
      state('final', style({
        transform: 'scale(1.5)'
      })),
      transition('initial => final', animate('300ms ease-in')),
      transition('final => initial', animate('300ms ease-out'))
    ])
  ]
})

export class FiestaComponent implements OnInit, OnDestroy {
  
  frames: string[] = [
    'assets/animation/animation1.gif',
    'assets/animation/animation2.gif',
    'assets/animation/animation1.gif',
    'assets/animation/animation2.gif'
  ];
  currentFrame: string;
  frameIndex: number = 0;
  intervalId: any;

  animationState = 'initial';
  goalReached = false;
  subscription: Subscription;

  constructor(private goalService: GoalService) {}

  ngOnInit() {
    this.currentFrame = this.frames[this.frameIndex];
    this.startAnimation();
    this.subscription = this.goalService.goalReached$.subscribe(reached => {
      if (reached) {
        this.goalReached = true;
        this.Animation();
      }
    });
  }

  startAnimation() {
    this.intervalId = setInterval(() => {
      this.frameIndex = (this.frameIndex + 1) % this.frames.length;
      this.currentFrame = this.frames[this.frameIndex];
    }, 20000); // Cambia frame ogni 20 secondi
  }

  Animation() {
    this.animationState = 'final';
    setTimeout(() => {
      this.animationState = 'initial';
    }, 300);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    this.subscription.unsubscribe();
  }

}
