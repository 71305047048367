<div class="grid-wrapper" *ngIf="agent_token">
  <h1 class="page-title">
    Lista Ordini di
    {{
      this.agent_order.length === 0
        ? " Agente"
        : " " + agent_order[0].ag_first_name + " " + agent_order[0].ag_last_name
    }}
  </h1>

  <br />

  <div
    class="filterContainer k-display-flex"
    style="flex-direction: row; justify-content: center"
  >
    <form
      class="new-search"
      role="search"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
      "
    >
      <label for="search"
        ><i
          class="bx bx-search bx-md"
          style="
            margin: 0;
            padding-top: 10px;
            padding-left: 5px;
            transform: scale(0.8);
          "
        >
        </i
      ></label>
      <input
        [(ngModel)]="filterTerm"
        id="search"
        name="search"
        pattern=".*\S.*"
        type="search"
        placeholder="Cerca..."
        autofocus
        required
        (input)="this.onInputChanged($event)"
      />
      <button type="submit" (click)="searchOrders()">
        <i
          class="bx bx-search bx-md"
          style="
            margin: 0;
            padding-top: 10px;
            padding-left: 5px;
            transform: scale(0.8);
            color: red;
            background: #0c65e9;
          "
        >
        </i>
      </button>
    </form>

    <form
      *ngIf="!isMobile"
      class="new-search"
      role="search"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        margin-top: -30px !important;
      "
    >
      <label for="search"
        ><i
          class="bx bx-search bx-md"
          style="
            margin: 0;
            padding-top: 10px;
            padding-left: 5px;
            transform: scale(0.8);
          "
        >
        </i
      ></label>
      <input
        [(ngModel)]="filterTerm"
        id="search"
        name="search"
        pattern=".*\S.*"
        type="search"
        placeholder="Cerca..."
        autofocus
        required
        (input)="this.onInputChanged($event)"
      />
      <button type="submit" (click)="searchOrders()">
        <i
          class="bx bx-search bx-md"
          style="
            margin: 0;
            padding-top: 10px;
            padding-left: 5px;
            transform: scale(0.8);
            color: red;
            background: #0c65e9;
          "
        >
        </i>
      </button>
      <button type="submit" (click)="clearSearch()">
        <i
          *ngIf="filterTerm"
          class="bx bx-search bx-md"
          style="
            margin: 0;
            padding-top: 10px;
            padding-left: 5px;
            transform: scale(0.4);
          "
          (click)="clearSearch()"
        >
        </i>
      </button>
    </form>

    <form
      action=""
      class="search-bar"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        text-align: center !important;
      "
    >
      <h1 class="page-title" style="color: rgb(13, 11, 11)">
        Ordini totali: {{ this.agent_order.length }}
      </h1>
    </form>

    <div class="dateContainer k-display-flex justify-content-evenly">
      <div class="col mx-2">
        <h5 class="text-center">DA:</h5>
        <kendo-datepicker
          [(ngModel)]="filters.startingDate"
          class="dropdown-field drop-the-border"
          style="
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 45px;
          "
        ></kendo-datepicker>
      </div>
      <div class="col mx-2">
        <h5 class="text-center">A:</h5>
        <kendo-datepicker
          [(ngModel)]="filters.endDate"
          class="dropdown-field drop-the-border"
          style="
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 45px;
          "
        ></kendo-datepicker>
      </div>
    </div>
  </div>
  <hr class="hr-newBlue" />

  <div
    *ngIf="loading === true"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>
  <kendo-grid
    #scrollContainer
    *ngIf="!loading"
    [data]="agent_order"
    class="mobilez responsive-table"
    [height]="650"
    [rowHeight]="36"
    [sortable]="true"
    scrollable="virtual"
    [navigable]="true"
    [style]="{ 'margin-left': '7px !important' }"
    [reorderable]="true"
    #gridContainer
  >
    <kendo-grid-column
      field="or_order_number"
      title="Ordini Agente"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'text-align': 'center' }"
    >
    </kendo-grid-column>

    <!--PUT OUTSIDE THE KENDO-GRID-COLUMN, SCROLL FIXED, BUT DOUBLE CLICKED REMAINS-->
    <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
      <ion-accordion value="first">
        <ion-item slot="header" color="light">
          <ion-label>First Accordion</ion-label>
        </ion-item>
        <div class="ion-padding" slot="content">First Content</div>
      </ion-accordion>
    </ng-template>
    <ng-template kendoGridNoRecordsTemplate>
      <div class="no-records-message">
        <p>Nessun dato disponibile</p>
      </div>
    </ng-template>
    <!-------------------------------------------------->
  </kendo-grid>

  <kendo-grid
    *ngIf="!isMobile && loading"
    [data]="
      agent_order
        | byToDate
          : [
              filters.startingDate,
              filters.endDate,
              filters.dateOrginizedBy.value
            ]
    "
    [rowHeight]="36"
    [height]="650"
    [sortable]="true"
    scrollable="virtual"
    [navigable]="true"
    class="tab table-responsive tablez responsive-table"
  >
    <!--<div>
      <div *ngFor="let order of agent_order; let i = index">
        <p>{{ order.ag_first_name }}</p>
        <p>{{ order.ag_last_name }}</p>
      </div>
    </div>-->

    <kendo-grid-column
      [width]="150"
      field="ag_last_name"
      title="Agente"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'text-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.ag_last_name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="or_order_number"
      title="N° Ordine"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.or_order_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="or_quote_number"
      title="Preventivo"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.or_quote_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="or_note"
      title="Riferimento"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.or_note }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="500"
      field="or_customer"
      title="Ragione sociale"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        style="font-size: 11px; display: flex !important"
      >
        <div class="flex" style="display: flex !important">
          {{ dataItem.or_customer }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="or_order_date"
      title="Data ordine"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="or_closing_date"
      title="Data evasione"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{
          dataItem.or_closing_date === "0001-01-01T00:00:00"
            ? " "
            : (dataItem.or_closing_date | date : "dd/MM/yyyy")
        }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="200"
      field="tab_order_status_desc"
      title="Stato"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.tab_order_status_desc }}
        <button
          *ngIf="
            dataItem.os_tracking_number !== null &&
            dataItem.os_tracking_number !== ''
          "
          kendoGridEditCommand
          rounded="full"
          class="btn-track"
          style="margin: 0; padding: 4px; background-color: #be7e00 !important"
          (click)="
            goToTracking(agent_token, dataItem.order_id, dataItem.or_agent_id)
          "
        >
          <div class="setDisplayToFlexBlue btn-track">
            <i class="bx bx-radar bx-md"> </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column
      title="DDT"
      [width]="80"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          href="{{ dataItem?.or_url_ddt }}"
          target="_blank"
          *ngIf="dataItem.or_url_ddt"
        >
          <i class="bx bxs-file-pdf bx-md"></i>
        </a>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-command-column
      title="Dettagli"
      [width]="100"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'text-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoGridEditCommand
          rounded="full"
          class="btn-det"
          style="margin: 0; padding: 0; background-color: #017efa !important"
          (click)="
            goToOrderDetails(
              dataItem.or_agent_id,
              dataItem.order_id,
              dataItem.or_gamma_id,
              dataItem.or_assigned_date,
              agent_token,
              riferimento
            )
          "
        >
          <div class="setDisplayToFlexBlue btn-det">
            <i class="bx bx-info-circle bx-md" style="margin: 0; padding: 0">
            </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<app-accesso-funzionari-tracking
  [track_ID]="shipment_id"
  [token]="agent_token"
  [agentID]="agentID"
  *ngIf="closeServ.isAgentTrack"
  [newTrack]="closeServ.isAgentTrack"
>
</app-accesso-funzionari-tracking>

<app-ordini-agente-dettaggli
  *ngIf="closeServ.isAgentOrderB"
  [agent_id]="clickedId"
  [token_agent]="agent_token"
  [order_id]="orderId"
  [or_gamma_id]="or_gamma_id"
  [or_assigned_date]="or_assigned_date"
  [openOrderDettagli]="closeServ.isAgentOrderB"
>
</app-ordini-agente-dettaggli>

<!--
tracking sda/fercam non aggiorna lo stato
ricerca per Riferimento e per stato
test usabilità
creare storico per ordini vecchi
test stato In lavorasione non corrisponde allo stato effettivo del tracking
stato in lavorazione anche se consegnato
(forse: data presunta evasione --- data_consegna su Gamma) 
(da implementare: data evasione --- data_evasione su Gamma) 
grafica diversa se ordine parziale (come versione desktop)
-->
