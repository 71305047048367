import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { formatDate } from '@angular/common';
import { SearchServiceService } from 'src/app/services/shared/search-service.service';
import { Subscription } from 'rxjs';
import { IWsResult } from 'src/app/models/ws_result.model';
import { RowClassArgs } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-storico-parziali',
  templateUrl: './storico-parziali.component.html',
  styleUrls: ['./storico-parziali.component.scss'],
})

export class StoricoParzialiComponent implements OnInit {

  public orderz: any[] = [];
  public customers: any[];
  public dates: any[];
  public opened = false;
  public loading: boolean = false;
  public isSearched: boolean = false;
  public pageSize = 20;
  public searchString = 'Filtra ordini per cliente';
  public searchDate: Date = new Date();
  public toDate = 'al giorno';
  public ordersStock: Orders.IOrderClosed[] = [];
  private subscriptions: Subscription[] = [];

  filterTerm: string | undefined;
  clickedOrder: string;
  clientOrder: string = 'Cliente';
  customer: string = '';
  date: string = '';
  showAll: string = 'Mostra tutti';
  showAll2: string = 'Mostra tutti';
  customerIndex: number;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];
  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };  
  searchService = inject(SearchServiceService);
  searchParam: string = '';

  constructor(
    private orderSrv: OrdersService,
    private router: Router,
    public closeSrv: OpenModalService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getClosedPartialOrders();
  }

  public roWow = (context: RowClassArgs) => {
    if (context.dataItem.or_partial === 'S') {
      return { partialOrderStyle: true };
    }
  };

  public combinedRowClass = (context: RowClassArgs) => {
    let classes = {};
    switch (context.dataItem.tab_order_status_cod) {
      case 1:
        classes['fadeInGreen'] = true;
        break;
      case 2:
      case 3:
      case 4:
      case 5:
      case 10:
        classes['fadeIn'] = true;
        break;
      case 100:
        classes['fadeInGrey'] = true;
        break;

      case 101:
        classes['fadeInRed'] = true;
        break;
    }
    return classes;
  };

  getClosedPartialOrders() {
    this.orderSrv.getClosedOrders().subscribe((ordersData) => {
      this.ordersStock = ordersData;
      console.log('Orders are before filtering', this.ordersStock);
      this.ordersStock = this.ordersStock.filter(
        (order) => order.or_partial === 'S'
      );
      console.log('Orders are filtering', this.ordersStock);
      this.dates = [
        ...new Set(
          ordersData.map((date) => {
            let formattedDate = formatDate(
              date?.or_order_date,
              'dd/MM/yyyy',
              'it'
            );

            return formattedDate;
          })
        ),
      ];
      this.customers = [
        ...new Set(
          ordersData.map((client) => {
            return client.or_customer;
          })
        ),
      ];
      this.customers.unshift(this.showAll);
      this.dates.unshift(this.showAll2);
      console.debug('Orders are: ', this.ordersStock);
      console.debug('From server: ', ordersData);
      this.loading = false;
    });
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getClosedPartialOrders();
    }
  }

  clearSearch() {
    this.filterTerm = '';
    this.filters.startingDate = this.startDate;
    this.getClosedPartialOrders();
  }

  onMessengerNameSelect(name: string, i: number) {
    this.customer = name;
    console.log('I is: ', i);
    if (name === this.showAll) 
      this.customer = null;
  }

  onDateSelect(name: string, i: number) {
    this.date = name;
    console.log('I is: ', i);
    if (name === this.showAll2) 
      this.date = null;
  }

  goToAddOrder() {
    this.router.navigate(['/new-order']);
  }

  getOrders() {
    this.orderSrv.getClosedOrders().subscribe((ordersData) => {
      this.orderz = ordersData;
      this.dates = [
        ...new Set(
          ordersData.map((date) => {
            let formattedDate = formatDate(
              date?.or_order_date,
              'dd/MM/yyyy',
              'it'
            );
            console.log('Formatted date is: ', formattedDate);
            console.log('Date is: ', date?.or_order_date);
            return formattedDate;
          })
        ),
      ];
      this.customers = [
        ...new Set(
          ordersData.map((client) => {
            return client.or_customer;
          })
        ),
      ];
      this.customers.unshift(this.showAll);
      this.dates.unshift(this.showAll2);
      console.debug('Orders are: ', this.orderz);
      console.debug('From server: ', ordersData);
      this.loading = false;
    });
  }

  searchOrders(search?: string) {
    console.log('Search string is', search);
    if (search) 
      this.filterTerm = search;
    if (!this.filterTerm || this.filterTerm == '') {
      this.isSearched = false;
      this.getClosedPartialOrders();
      return;
    }
    this.isSearched = true;
    this.orderSrv
      .searchClosedOrders(this.filterTerm)
      .subscribe((ordersData: IWsResult) => {
        if (ordersData.error_message.msg_code !== 0) {
          this.orderz = [];
          this.ordersStock = [];
          return;
        }
        console.log('OrdersData is', ordersData);
        this.ordersStock = ordersData.data;
        this.ordersStock = this.ordersStock.filter(
          (order) => order.or_partial === 'S'
        );
        console.log('Orders are filtering', this.ordersStock);
        this.dates = [
          ...new Set(
            ordersData.data.map((date) => {
              let formattedDate = formatDate(
                date?.or_order_date,
                'dd/MM/yyyy',
                'it'
              );

              return formattedDate;
            })
          ),
        ];
        this.customers = [
          ...new Set(
            ordersData.data.map((client) => {
              return client.or_customer;
            })
          ),
        ];
        this.customers.unshift(this.showAll);
        this.dates.unshift(this.showAll2);
        console.debug('Orders are: ', this.ordersStock);
        console.debug('From server: ', ordersData);
        this.loading = false;
      });
  }

  goToDetails(order_id: string) {
    this.clickedOrder = order_id;
    this.closeSrv.onStorico();
  }

  startSearch() {
    this.isSearched = true;
  }

  removeData() {
    this.filterTerm = null;
    this.isSearched = false;
    if (!this.filterTerm || this.filterTerm == '') {
      this.getOrders();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item) => item.unsubscribe());
  }
  
}
