import { Component, OnInit, Input, inject } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { GridDataResult } from '@progress/kendo-angular-grid';

import { Orders } from 'src/app/models/Orders.model';
import { IAgent } from 'src/app/models/IAgent.model';
import { IAccount } from 'src/app/models/IAccount.model';
import { Categories } from 'src/app/models/categories';
import { Products } from 'src/app/models/Product.model';
import { IMessageList } from 'src/app/models/IMessage.model';
import { Shipments } from 'src/app/models/shipment';
import { IWsResult } from 'src/app/models/ws_result.model';

import { OrdersService } from 'src/app/services/orders.service';
import { AgentsService } from 'src/app/services/agents.service';
import { AccountsService } from 'src/app/services/accounts.service';
import { ProductsService } from 'src/app/services/products.service';
import { MessagesService } from 'src/app/services/messages.service';
import { SearchServiceService } from 'src/app/services/shared/search-service.service';
import { forkJoin } from 'rxjs';

interface IInforOrders {
  t_or_new: number;
  t_or_partial: number;
  t_or_cm: number;
  t_or_prepare: number;
  t_or_to_sign: number;
  t_or_completed: number;
  t_or_delivery: number;
}

class InfoOrders implements IInforOrders {
  t_or_new: number = -1;
  t_or_partial: number = -1;
  t_or_cm: number = -1;
  t_or_prepare: number = -1;
  t_or_to_sign: number = -1;
  t_or_completed: number = -1;
  t_or_delivery: number = -1;

  constructor(orderInfo: IInforOrders | null) {
    if (!orderInfo) return;
    this.t_or_new = orderInfo.t_or_new;
    this.t_or_partial = orderInfo.t_or_partial;
    this.t_or_cm = orderInfo.t_or_cm;
    this.t_or_prepare = orderInfo.t_or_prepare;
    this.t_or_to_sign = orderInfo.t_or_to_sign;
    this.t_or_completed = orderInfo.t_or_completed;
    this.t_or_delivery = orderInfo.t_or_delivery;
  }
}

interface IInfo {
  t_or_bordero: number;
  t_message: number;
  t_products: number;
  t_account: number;
  t_agents: number;
}

class Info implements IInfo {
  t_or_bordero: number = -1;
  t_message: number = -1;
  t_products: number = -1;
  t_account: number = -1;
  t_agents: number = -1;

  constructor(info: IInfo | null) {
    if (!info) return;
    this.t_or_bordero = info.t_or_bordero;
    this.t_message = info.t_message;
    this.t_products = info.t_products;
    this.t_account = info.t_account;
    this.t_agents = info.t_agents;
  }
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit {

  public stockProducts: Products.StockProducts[] = [];
  public borz: Orders.OrderSignature[] = [];
  public msg: IMessageList[] = [];
  public gridView: GridDataResult;
  public gridData: any[] = [];
  public pageSize = 20;
  public skip = 0;
  public data: unknown[];
  public loading: boolean = false;
  pr_stock: any;
  checkPartial: boolean = false;
  endDate: Date = new Date();
  firstEnteredOnPage: boolean = false;
  orderInfo: InfoOrders = new InfoOrders(null);
  otherInfo: Info = new Info(null);
  search: string = '';
  checkedOrder: boolean = false;
  checkedCliente: boolean = false;
  checkedNotes: boolean = false;
  checkedTracking: boolean = false;
  whichTypeOfBooleanIsThis: string;
  searchType: string = '';
  formattedDate: string = '';  
  dynamicLink: Params = {};
  searchService = inject(SearchServiceService);

  constructor(
    private ordersServ: OrdersService,
    private agentsServ: AgentsService,
    private accountsService: AccountsService,
    private stockSrv: ProductsService,
    public productSrv: ProductsService,
    private messagesServ: MessagesService,
    private router: Router
  ) { }

  ngOnInit() {
    // this.getBorderos();
    // this.getStockProducts();
    // this.getMessages();
    console.log(
      'Initial value of otherInfo.t_message:',
      this.otherInfo.t_message
    );
    this.loading = true;
    console.log('NgOnInit first entered on page is', this.firstEnteredOnPage);
    this.orderInfo = new InfoOrders(null);
    this.otherInfo = new Info(null);
    console.log('OrderInfo is', this.orderInfo);
    console.log('Other info is', this.otherInfo);
    if (this.firstEnteredOnPage === false) {
      forkJoin({
        orders: this.ordersServ.getInfoOrders('5', '2020-12-12T00:00:00', ''),
        dateInfo: this.ordersServ.getInfo('2022-07-01T00:00:00'),
      }).subscribe({
        next: ({ orders, dateInfo }) => {
          if (orders) {
            console.log('Data from getInfoOrders:', orders);
            this.orderInfo = new InfoOrders(orders);
            this.firstEnteredOnPage = !this.firstEnteredOnPage;

            console.log('Updated firstEnteredOnPage:', this.firstEnteredOnPage);
          }
          if (dateInfo) {
            this.otherInfo = new Info(dateInfo);

            console.log('Data from getInfo:', this.otherInfo);
          }
          this.loading = false;
          console.log('Both functions have completed loading');
        },
        error: (error) => {
          this.loading = false;
          console.error('Error loading initial data:', error);
        },
      });
    }
  }

  InitialGetInfoOrders() {
    this.ordersServ.getInfoOrders('5', '2020-12-12T00:00:00', '').subscribe({
      next: (data) => {
        if (data) {
          console.log('Data from getInfoOrders:', data);
          this.orderInfo = new InfoOrders(data);
          this.firstEnteredOnPage = !this.firstEnteredOnPage;
          console.log('Updated firstEnteredOnPage:', this.firstEnteredOnPage);
        }
      },
      error: (error) => {
        console.error('Error fetching info orders:', error);
      },
    });
  }

  getInitialDateInfo() {
    this.ordersServ.getInfo('2022-07-01T00:00:00').subscribe({
      next: (data) => {
        if (data) {
          this.otherInfo = new Info(data);
          console.log('Data from getInfo:', this.otherInfo);
          this.firstEnteredOnPage = !this.firstEnteredOnPage;
          console.log('Updated firstEnteredOnPage:', this.firstEnteredOnPage);
        }
      },
      error: (error) => {
        console.error('Error fetching info orders:', error);
      },
    });
  }

  checkBoolean(searchType: string) {
    console.log('Which value does he assign ?', searchType);
    this.searchType = searchType;
    console.log('Search type is', this.searchType);
  }

  checkData() {
    this.endDate.setHours(0);
    this.endDate.setMinutes(0);
    this.endDate.setSeconds(0);
    this.endDate.setMilliseconds(0);
    const datePipe = new DatePipe('en-US');
    console.log('Date is', datePipe);
    this.formattedDate = datePipe.transform(
      this.endDate,
      'yyyy-MM-ddTHH:mm:ss'
    );
    console.log('Check data', this.formattedDate);
    console.log('Check end data', this.endDate);
  }

  searchInfoOrders(startDate: string, search: string, searchType?: string) {
    this.loading = true;
    this.orderInfo = new InfoOrders(null);
    this.otherInfo = new Info(null);
    console.log('Search is', this.search);
    console.log('Search type is', this.searchType);
    if (search === '' || !this.searchType) 
      this.searchType = '5';
    console.log('Search type is', this.searchType);
    this.ordersServ
      .getInfoOrders(this.searchType, startDate, this.search)
      .subscribe((data) => {
        if (!data) return;
        console.log('Data is', data);
        this.orderInfo = new InfoOrders(data);
        this.searchService.passOrderInfoValue(search);
        this.dynamicLink['search'] = search;
        this.dynamicLink['data'] = this.formattedDate;
        console.log('Dynamic link is', this.dynamicLink);
        console.log('First entered on page is', this.firstEnteredOnPage);
      });
    this.ordersServ.getInfo(startDate).subscribe((data: Info) => {
      if (!data) return (this.loading = false);
      this.otherInfo = new Info(data);
      console.log('Data is', this.otherInfo);
      this.searchService.passInfoValue(search);
    });
    this.loading = false;
  }

  getBorderos() {
    this.ordersServ.getNewBorderos().subscribe((data: any) => {
      if (!data) return;
      this.borz = data.data;
      console.log('Borderos are: ', data);
    });
  }

  getStockProducts() {
    this.loading = true;
    this.stockSrv
      .getProducts()
      .subscribe((stockProductsData: Products.StockProducts[]) => {
        this.stockProducts = stockProductsData;
        this.gridData = this.stockProducts;
        console.debug('Data is: ', this.stockProducts);
        this.pr_stock = this.stockProducts?.map((item) => {
          let newStock: Products.StockProducts = {
            category_id: 0,
            type: '',
            head_family: '',
            sub_family: '',
            head_group: '',
            sub_group: '',
            description: '',
            pr_stock: +item.pr_stock,
          };
          return newStock;
        });
        this.loadProducts();
        console.debug('From server: ', stockProductsData);
        this.loading = false;
      });
  }

  private loadProducts(): void {
    this.loading = true;
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length,
    };
    this.loading = false;
  }

  getMessages() {
    this.loading = true;
    this.messagesServ.getMessages().subscribe((messagesData: IWsResult) => {
      this.gridData = messagesData.data;
      this.groupMessagesBySender(messagesData.data);
      this.loading = false;
    });
  }

  groupMessagesBySender(messages: IMessageList[]) {
    const groupedMessages: { [sender: string]: IMessageList[] } = {};
    messages.forEach((message) => {
      const sender = message.ms_sender_name;
      if (!groupedMessages[sender]) {
        groupedMessages[sender] = [];
      }
      groupedMessages[sender].push(message);
    });
  }
  
}
