import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Boxes } from 'src/app/models/Boxes.models';
import { Orders } from 'src/app/models/Orders.model';

import { Priority } from 'src/app/models/Priority.model';
import { Products } from 'src/app/models/Product.model';
import { Buttons } from 'src/app/models/buttons';
import { Helpers } from 'src/app/models/helpters';
import { Image } from 'src/app/models/image';
import { ProductPosition } from 'src/app/models/productPosition';
import { Shipments } from 'src/app/models/shipment';
import { Status } from 'src/app/models/status';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

class OrderBox {
  box_order_id: number;
  ts_box_type_id: number;
  ts_box_type_desc: string;
  ts_box_height: number;
  ts_box_length: number;
  ts_box_depth: number;
  ts_box_weight: string;
  ts_documents: string;
  ts_screws: string;
}

class OrderUpdate {
  order_id: number;
  or_assignment_date: string;
  or_assigned_id: number;
  or_carrier_id: number;
  or_carrier_other: string;
  or_order_priority_cod: number;
  or_shipping_customer: string;
  or_shipping_note: string;
  or_shipping_address: string;
  or_shipping_city: string;
  or_shipping_prov: string;
  or_shipping_zip: string;
  or_synchronized: string;
  or_shipping_country: string;
  or_note: string;
  or_partial: string;

  boxes: OrderBox[] = [];
  products: Products.Product[] = [];

  constructor(init?: Partial<OrderUpdate>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'app-list-of-products-for-boxes',

  templateUrl: './list-of-products-for-boxes.component.html',
  styleUrls: ['./list-of-products-for-boxes.component.scss'],
})

export class ListOfProductsForBoxesComponent implements OnInit {

  @Input() order: any;
  @Input() openedProductsListBoxes: boolean = false;
  @Input() selectedBoxToAddProducts: any;

  orderToUpdate = new Orders.OrderUpdate(null);
  loading: boolean = false;
  numbers: number[] = [];
  filteredBox: any;
  productsAssigned: any[] = [];
  productsWithMatchingBarcode = [];
  productsWithNoBarcode = [];

  constructor(
    private orderService: OrdersService,
    private toastController: ToastController,
    public closeServ: OpenModalService
  ) { }  

  ngOnInit() {
    this.loading = !this.loading;
    this.orderService.getOrder1(+this.order.order_id).subscribe((data) => {
      console.log('data dimn order', data);
      this.order = data;
      this.reloadFunction();
    });
  }

  initializeOrder() {
    this.orderService
      .getOrder1(+this.order.order_id)
      .subscribe(async (result) => {
        this.order = result;
        console.log('ORDERUS IS', this.order);
        // Map and assign the boxes array to include `isDeleted` property
        this.order.boxes = this.order.boxes.map((item) => {
          let box: any = {
            ...item,
            isDeleted: false,
            ts_documents: 'N', // Ensure these values are explicitly set as required
            ts_screws: 'N',
            checkForNull: function (): boolean {
              throw new Error('Function not implemented!');
            },
          };
          console.log('BOX OBX', box);
          return box;
        });
        // Assign the modified order object to orderToUpdate
        this.orderToUpdate = new Orders.OrderUpdate(this.order);
        console.log('OrderToUpdate is', this.orderToUpdate);
        console.log('Order is', this.order);
        if (this.orderToUpdate.or_carrier_id === 0) {
          this.orderToUpdate.or_carrier_id = null;
        }
      });
  }

  reloadFunction() {
    console.log('MANANC CACAT', this.order);
    console.log('selectedBoxToAddProducts', this.selectedBoxToAddProducts);
    // this.order.products.forEach((product) => (product.op_checked = false));
    this.orderToUpdate = new Orders.OrderUpdate(this.order);
    console.log('Order to update is', this.orderToUpdate);
    let productsValid = [];
    // Filter the boxes to get the filteredBox
    console.log('Filtered boxes:', this.filteredBox);
    console.log('CHECKED ORDERS', this.order);
    this.orderToUpdate.products.forEach((product) => {
      // Filter out barcodes with null box_id
      const validBarcodes = product.barcodes.filter(
        (barcode) => barcode.box_id === null
      );
      product.barcodes.forEach((barcode) => {
        if (barcode.box_id === null) {
          return productsValid.push(product);
        }
      });
      console.log('Valid barcodes:', validBarcodes);
      // Set minQuantity based on the count of valid barcodes
      product.minQuantity = validBarcodes.length;
      product.desiredQuantity = product.minQuantity;
      if (validBarcodes && validBarcodes.length > 0) {
        console.log(
          'Length of valid barcodes',
          product.op_description,
          validBarcodes.length
        );
        this.productsWithNoBarcode.push(product);
      }
    });
    // Create a new array to hold the filtered products
    const filteredProducts = [];
    // Filter out products with matching box_id and populate filteredProducts array
    this.orderToUpdate.products.forEach((product) => {
      const validBarcodes = product.barcodes.filter((barcode) => {
        return (
          barcode.box_id !== null &&
          barcode.box_id === this.selectedBoxToAddProducts.box_order_id
        );
      });
      if (validBarcodes.length > 0) {
        const filteredProduct = { ...product, barcodes: validBarcodes };
        filteredProducts.push(filteredProduct);
      }
    });
    console.log('FILTERED PRODUCTS BROSSINI IS', filteredProducts);
    // Assign filteredProducts array to this.productsWithMatchingBarcode
    this.productsWithMatchingBarcode = filteredProducts;
    this.productsWithMatchingBarcode.forEach((product) => {
      product.minQuantity = product.barcodes.length;
    });
    this.loading = false;
    console.log('Loading is???', this.loading);
    console.log(
      'AFTER FILTERING TO SEE IF THERE ARE BOX-ORDER',
      this.productsWithMatchingBarcode
    );
    console.log('products matched', this.productsWithNoBarcode);
    console.log('Updated updated order', this.orderToUpdate);
    console.log('Valid products:', productsValid);
  }

  changeProductNumber(n) {
    console.log('Product ', n, 'and lentgth', n.minQuantity);
    this.numbers = Array.from({ length: n }, (_element, index) => index + 1);
    console.debug('Go bro!', this.numbers);
  }

  updateSelectedQTA(prod) {
    console.log('Prod qta is', prod);
  }

  selectProducts(prod: any) {
    console.log('Selected product is', prod);
    // Convert op_qta to a number
    const quantity = prod.minQuantity;
    // Assign box_order_id to the number of barcodes specified by op_qta
    let count = 0; // Counter to keep track of the number of barcodes assigned
    for (const barcode of prod.barcodes) {
      // Check if the barcode has no box_id and we haven't reached the selected quantity yet
      if (barcode.box_id === null && count < quantity) {
        barcode.box_id = this.selectedBoxToAddProducts.box_order_id;
        count++; // Increment the counter
      }
    }
    console.log('Count is', count);
    console.log('Prod quantity is', prod.minQuantity);
    // Update minQuantity to reflect the remaining unassigned barcodes
    prod.minQuantity = Math.max(0, prod.desiredQuantity - count);

    for (const updatedProduct of this.productsWithNoBarcode) {
      const index = this.orderToUpdate.products.findIndex(
        (p) => p.product_id === updatedProduct.product_id
      );
      if (index !== -1) {
        this.orderToUpdate.products[index] = updatedProduct;
      }
    }
    console.log("Let's see the barcode", prod.barcodes);
    console.log('Op checked?', prod.op_checked);
    console.log("Let's see the product", prod);
    console.log('UPDATED ORDER IS', this.orderToUpdate);
    this.orderService
      .updateOrder(this.orderToUpdate)
      .subscribe(async (data) => {
        if (data.success) {
          this.toggleSpin();
          // await this.presentToastOnBox(prod);
          console.log('Well done mate');
          this.productsWithNoBarcode = [];
          this.productsWithMatchingBarcode = [];
          this.reloadFunction();
        }
      });
  }

  isSpinning = false;
  isFlipped = false;
  isSpinningReverse = false;

  toggleSpin() {
    this.isSpinning = true;
    setTimeout(() => {
      this.isSpinning = false;
      // Toggle the flipped state
    }, 1000); // Duration of the animation
  }

  toggleReverseSpin() {
    this.isSpinningReverse = true;
    setTimeout(() => {
      this.isSpinningReverse = false;
      // Toggle the flipped state
    }, 1000);
  }

  async presentToastOnBox(prod: any) {
    console.log('triggered toast');
    const toast = await this.toastController.create({
      message: `Successfully added ${prod.op_description} !`,
      cssClass: 'custom-toast',
      header: 'header',
      duration: 3000, // Adjust duration as needed
    });
    // Manually attach the toast to the container
    await toast.present();
  }

  selectMatchingProducts(prod: any) {
    console.log('Selected product from matching products is', prod);
    // Convert op_qta to a number
    let quantity = prod.minQuantity;
    // Iterate over the barcodes of the selected product
    for (const barcode of prod.barcodes) {
      // Check if the quantity assigned to the barcode is within the selected quantity
      if (quantity > 0) {
        // Clear the box_id of the barcode
        barcode.box_id = null;
        // Decrement the quantity
        quantity--;
      } 
      else {
        // If the selected quantity has been exhausted, break the loop
        break;
      }
    }

    // Update the orderToUpdate with the modified products
    for (const updatedProduct of this.productsWithMatchingBarcode) {
      const index = this.orderToUpdate.products.findIndex(
        (p) => p.product_id === updatedProduct.product_id
      );
      if (index !== -1) {
        this.orderToUpdate.products[index] = updatedProduct;
      }
    }
    console.log("Let's see the barcode", prod.barcodes);
    console.log('Op checked?', prod.op_checked);
    console.log("Let's see the product", prod);
    console.log('UPDATED ORDER IS', this.orderToUpdate);
    // Update the order
    this.orderService.updateOrder(this.orderToUpdate).subscribe((data) => {
      if (data.success) {
        console.log('Well done mate');
        this.toggleReverseSpin();
        this.productsWithNoBarcode = [];
        this.productsWithMatchingBarcode = [];
        this.reloadFunction();
      }
    });
  }

  howManyBarcodes(item: any): number {
    return item?.barcodes.filter(
      (barcode) => barcode.barcode_text != null && barcode.barcode_text !== ''
    ).length;
  }

  close() {
    this.closeServ.offListOfProductsBoxes();
  }
  
}
