import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IAgent } from 'src/app/models/IAgent.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { AgentsService } from 'src/app/services/agents.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { IAccount } from 'src/app/models/IAccount.model';
import Swal from 'sweetalert2';
import { SetAgentService } from 'src/app/services/shared/set-agent.service';

@Component({
  selector: 'app-funzionari-dettagli',
  templateUrl: './accesso-funzionari-dettagli.component.html',
  styleUrls: ['./accesso-funzionari-dettagli.component.scss'],
})

export class AccessoFunzionariDettagliComponent implements OnInit {

  @Input() opened = true;
  @Input() ag_token: string;
  @Input() id: string;
  @Output() modalClosed1 = new EventEmitter<void>();

  public existAgent: IAgent;
  public account: IAccount;
  public agent: IAgent;
  public dataSaved = false;
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 1056;
  public dialogHeight = 1017;
  public isEditable = false;

  agentDetailsForm!: FormGroup;
  clickedAgent: string;
  domainName: string;
  addAgentForm!: FormGroup;
  url_start: string = 'https://' + 'salvatempo.livith.it' + '?token=';
  agentToSend: any;
  link: any;

  constructor(
    private route: ActivatedRoute,
    private agentServ: AgentsService,
    private formBuilder: FormBuilder,
    private router: Router,
    public closeServ: OpenModalService,
    private setAgentServ: SetAgentService
  ) { }

  goBackFunction() {
    this.router.navigate(['/agents']);
  }

  goEditFunction() {
    this.isEditable = !this.isEditable;
  }

  open() {
    this.opened = true;
  }

  public close() {
    this.closeServ.offDetAgent();
    this.modalClosed1.emit();
  }

  goToAddAgent(agent_id: string) {
    this.clickedAgent = agent_id;
    this.closeServ.open();
  }

  ngOnInit(): void {
    this.domainName = window.location.hostname;
    let domaninOrigin = window.location.origin;
    console.log('Domain name: ', window.location);
    this.url_start = domaninOrigin + '/agentsorders' + '?token=';
    this.agentDetailsForm = this.formBuilder.group({
      agent_id: [''],
      ag_first_name: [''],
      ag_last_name: [''],
      ag_email: [''],
      ag_mobile: [''],
      ag_phone: [''],
      ag_url: [''],
      ag_note: [''],
    });
    this.agentServ.getAgent(this.id).subscribe((agentData: IWsResult) => {
      this.existAgent = agentData.data;
      this.link = this.url_start + encodeURIComponent(this.existAgent.ag_url);
      this.agentDetailsForm.setValue({
        agent_id: this.existAgent.agent_id,
        ag_first_name: this.existAgent.ag_first_name,
        ag_last_name: this.existAgent.ag_last_name,
        ag_email: this.existAgent.ag_email,
        ag_mobile: this.existAgent.ag_mobile,
        ag_phone: this.existAgent.ag_phone,
        ag_url: this.ag_token,
        ag_note: this.existAgent.ag_note,
      });
    });
  }

  addAgentDialog() {
    this.isEditable = !this.isEditable;
    this.addAgentForm = new FormGroup({
      agent_id: new FormControl(''),
      ag_first_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
        Validators.pattern('[a-zA-Z. ]*'),
      ]),
      ag_last_name: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z. -]*'),
      ]),
      ag_email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,4}$'),
      ]),
      ag_mobile: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ag_phone: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ag_url: new FormControl(''),
      ag_note: new FormControl(''),
    });
  }

  addAgent(addAgentForm: any): void {
    let agent: IAgent = {
      agent_id: this.agentDetailsForm.value.agent_id,
      ag_first_name: this.agentDetailsForm.value.ag_first_name,
      ag_last_name: this.agentDetailsForm.value.ag_last_name,
      ag_email: this.agentDetailsForm.value.ag_email,
      ag_mobile: this.agentDetailsForm.value.ag_mobile,
      ag_phone: this.agentDetailsForm.value.ag_phone,
      ag_url: this.agentDetailsForm.value.ag_url,
      ag_note: this.agentDetailsForm.value.ag_note,
    };
    console.log(agent);
    this.close();
    Swal.fire({
      icon: 'success',
      title: 'Aggiunto!',
      text: 'Agente aggiunto con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

  updateAgent(addAgentForm: any): void {
    this.existAgent.ag_first_name = this.agentDetailsForm.value.ag_first_name;
    this.existAgent.ag_last_name = this.agentDetailsForm.value.ag_last_name;
    this.existAgent.ag_email = this.agentDetailsForm.value.ag_email;
    this.existAgent.ag_mobile = this.agentDetailsForm.value.ag_mobile;
    this.existAgent.ag_phone = this.agentDetailsForm.value.ag_phone;
    this.existAgent.ag_url = this.agentDetailsForm.value.ag_url;
    this.existAgent.ag_note = this.agentDetailsForm.value.ag_note;
    this.agentServ.updateAgent(this.existAgent).subscribe();
    console.log(this.existAgent);
    this.close();
    this.modalClosed1.emit();
    Swal.fire({
      icon: 'success',
      title: 'Modificato!',
      text: 'Agente modificato con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

  modify() {
    this.isEditable = !this.isEditable;
  }

  clearForm(): void {
    this.addAgentForm.reset();
  }

  goToLink(agent_name) {
    this.setAgentServ.setExistAgent(agent_name, () => {
      console.log('Existing agent is: ', agent_name);
      let link = this.url_start + encodeURIComponent(this.existAgent.ag_url);
      window.open(link, '_blank');
    });
  }
  
}
