import { CanDeactivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { SearchService } from '../services/search.service';

export const searchTextGuardGuard: CanDeactivateFn<unknown> = (
  component,
  currentRoute,
  currentState,
  nextState
) => {
  const searchService = inject(SearchService);
  const currentUrl = currentState.url;
  const nextUrl = nextState.url;

  // console.log('nextState is', nextState);
  // console.log('component', component);
  // console.log('currentState', currentState);
  // console.log('currentRoute', currentRoute);

  // Check if the next URL starts with the current URL
  if (!nextUrl.startsWith(currentUrl)) {
    // console.log('nextState2 is', nextState);
    // console.log('component2', component);
    // console.log('currentState2', currentState);
    // console.log('currentRoute2', currentRoute);
    console.log('Clearing the text...');
    searchService.clearFilterText();
  }

  return true;
};
