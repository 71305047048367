import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { formatDate } from '@angular/common';
import { SearchServiceService } from 'src/app/services/shared/search-service.service';
import { Subscription } from 'rxjs';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-storico-ordini',
  templateUrl: './storico-ordini.component.html',
  styleUrls: ['./storico-ordini.component.scss'],
})

export class StoricoOrdiniComponent implements OnInit {

  public ordersStock: Orders.IOrderClosed[] = [];
  public filteredOrder: Orders.IOrderClosed[];
  public customers: string[];
  public dates: string[];
  public opened = false;
  public loading: boolean = false;
  public isSearched: boolean = false;
  public pageSize = 20;
  public searchString = 'Filtra ordini per cliente';
  public searchDate: Date = new Date();
  public toDate = 'al giorno';

  filterTerm: string | undefined;
  clickedOrder: string;
  clientOrder: string = 'Cliente';
  customer: string = '';
  date: string = '';
  showAll: string = 'Mostra tutti';
  showAll2: string = 'Mostra tutti';
  customerIndex: number;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  private subscriptions: Subscription[] = [];
  searchService = inject(SearchServiceService);
  searchParam: string = '';
  netValue: any;

  constructor(
    private orderSrv: OrdersService,
    private route: ActivatedRoute,
    private router: Router,
    public closeSrv: OpenModalService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        if (Object.keys(params).length === 0) {
          this.getOrders();
          //this.loading = false;
        } 
        else {
          this.searchParam = params['search'];
          const dateParam = params['data'];
          this.filters.startingDate = dateParam ? parseISO(dateParam) : null;
          this.searchOrders(this.searchParam);
          this.loading = false;
        }
      })
    );
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getOrders();
    }
  }

  onMessengerNameSelect(name: string, i: number) {
    this.customer = name;
    console.log('I is: ', i);
    if (name === this.showAll) 
      this.customer = null;
  }

  clearSearch() {
    this.filterTerm = '';
    this.filters.startingDate = this.startDate;
    this.getOrders();
  }

  onDateSelect(name: string, i: number) {
    this.date = name;
    console.log('I is: ', i);
    if (name === this.showAll2) 
      this.date = null;
  }

  goToAddOrder() {
    this.router.navigate(['/new-order']);
  }

  getOrders() {
    this.loading = true;
    this.orderSrv.getClosedOrders().subscribe((ordersData) => {
      if (!ordersData) {
        this.loading = false;
        return;
      }
      this.ordersStock = ordersData;
      const findOrder = this.ordersStock.find(
        (order) => order.order_id === 8396
      );
      if (findOrder) {
        console.log('Found the order', findOrder);
        return findOrder;
      } 
      else {
        console.log('Order not found');
        // Return null if no order is found
      }
      this.netValue = 0;
        let gridusData = this.ordersStock.map((net_value) => {
          this.netValue += net_value.or_net_value;
          return net_value.or_net_value;
        });

        console.log('Gridus datas isust', gridusData);
        console.log('Net value is', this.netValue);
      this.dates = [
        ...new Set(
          ordersData.map((date) => {
            let formattedDate = formatDate(
              date?.or_order_date,
              'dd/MM/yyyy',
              'it'
            );
            // console.log('Formatted date is: ', formattedDate);
            // console.log('Date is: ', date?.or_order_date);
            return formattedDate;
          })
        ),
      ];
      this.customers = [
        ...new Set(
          ordersData.map((client) => {
            return client.or_customer;
          })
        ),
      ];
      this.customers.unshift(this.showAll);
      this.dates.unshift(this.showAll2);
      console.debug('Orders are: ', this.ordersStock);
      console.debug('From server: ', ordersData);
      this.loading = false;
    });
  }

  searchOrders(search?: string) {
    console.log('search string', search);
    if (search) 
      this.filterTerm = search;
    if (!this.filterTerm || this.filterTerm == '') {
      this.isSearched = false;
      this.getOrders();
      return;
    }
    this.isSearched = true;
    this.orderSrv
      .searchClosedOrders(this.filterTerm)
      .subscribe((ordersData: IWsResult) => {
        if (ordersData.error_message.msg_code !== 0) {
          this.ordersStock = [];
          return;
        }
        console.log('OrdersData is', ordersData);
        this.ordersStock = ordersData.data;
        console.log('Filter term: ', this.filterTerm);
        console.log('Data: ', this.ordersStock);
      });
  }

  goToDetails(order_id: string) {
    this.clickedOrder = order_id;
    this.closeSrv.onStorico();
  }

  startSearch() {
    this.isSearched = true;
  }

  public roWow = (context) => {
    if (context.dataItem.or_partial === 'S') {
      return 'partialOrderStyle';
    }
    return '';
  };

  removeData() {
    this.filterTerm = null;
    this.isSearched = false;
    if (!this.filterTerm || this.filterTerm == '') {
      this.getOrders();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item) => item.unsubscribe());
  }
  
}
