import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { Products } from 'src/app/models/Product.model';
import { Shipments } from 'src/app/models/shipment';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { AgentsService } from 'src/app/services/agents.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { formatDate } from '@angular/common';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-ordini-agente-dettaggli',
  templateUrl: './ordini-agente-dettaggli.component.html',
  styleUrls: ['./ordini-agente-dettaggli.component.scss'],
})

export class OrdiniAgenteDettaggliComponent {

  @Input() openOrderDettagli = true;
  @Input() agent_id: any;
  @Input() order_id: any;
  @Input() token_agent: string;
  @Input() or_assigned_date: string;

  public shipmentDetails: Shipments.IShipmentList;
  public productsNew: Products.IProductToSend[] = [];
  public agent_order: Orders.IOrder;
  public order: Orders.IOrder;
  public agent_order_new: Orders.IOrderNew;
  public qta_op: Products.IProductToSend[] = [];
  public saySmth: string = 'Vedi Prodotto';
  public formattedDate: string = '-';
  public formattedClosedDate: string = '-';
  public maxlength = 250;
  public minWidth = 250;
  public dialogHeight = 1017;
  agentOrderDetail!: FormGroup;
  pr_code: string;
  linkToDDT: string = null;
  isLinkToDDT: boolean = null;
  popoverController: any;
  isClickedOnIt: boolean = false;
  clickedOnce: boolean = false;

  constructor(
    public closeServ: OpenModalService,
    private agentsServ: AgentsService,
    private orderServ: OrdersService,
    private printerService: PrinterService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    this.agentOrderDetail = this.formBuilder.group({
      or_closing_date: [''],
      or_customer: [''],
      or_order_date: [''],
      or_order_number: [''],
      or_shipping_address: [''],
      or_shipping_city: [''],
      or_shipping_country: [''],
      or_shipping_prov: [''],
      or_shipping_zip: [''],
      status: [{}],
      ac_first_name: [''],
      carrier_desc: [''],
      or_ddt_number: [''],
    });
    if (
      this.or_assigned_date !== null &&
      this.or_assigned_date !== '0001-01-01T00:00:00'
    ) {
      this.agentsServ
        .getAgentOrder(this.order_id, this.agent_id, this.token_agent)
        .subscribe((agentsData: IWsResult) => {
          this.agent_order = agentsData.data;

          // this.agent_order?.or_url_ddt.endsWith('.pdf')
          //   ? console.log('You got a pdf')
          //   : console.log('You lack a pdf');
          this.agent_order.products.forEach((product) => {
            product.op_stockQTA = `${product.op_description} -  ${product.op_qta_stock}pZ `;
          });

          if (this.agent_order.or_order_date)
            this.formattedDate = formatDate(
              this.agent_order.or_order_date,
              'dd/MM/yyyy',
              'it'
            );
          if (this.agent_order.or_closing_date)
            this.formattedClosedDate = formatDate(
              this.agent_order.or_closing_date,
              'dd/MM/yyyy',
              'it'
            );
          if (this.formattedClosedDate === '01/01/0001') {
            this.formattedClosedDate = '-';
          }
          this.agentOrderDetail.patchValue({
            or_agent_id: this.agent_id,
            or_closing_date: this.formattedClosedDate,
            or_customer: this.agent_order.or_customer,
            or_order_date: this.formattedDate,
            or_order_number: this.agent_order.or_order_number,
            carrier_desc: this.agent_order.vs_details.carrier_desc,
            or_shipping_address: this.agent_order.or_shipping_address,
            or_shipping_city: this.agent_order.or_shipping_city,
            or_shipping_country: this.agent_order.or_shipping_country,
            or_shipping_prov: this.agent_order.or_shipping_prov,
            or_shipping_zip: this.agent_order.or_shipping_zip,
            status: this.agent_order.status[0].tab_order_status_desc,
            ac_first_name: this.agent_order.vs_details.ac_first_name,
            or_ddt_number: this.agent_order.or_ddt_number,
          });
          console.log('Agent order is: ', this.agent_order);
        });
    } 
    else {
      {
        this.agentsServ
          .getAgentOrderNew(this.order_id, this.agent_id, this.token_agent)
          .subscribe((agentsData: IWsResult) => {
            this.agent_order_new = agentsData.data;
            this.agent_order_new.products.forEach((product) => {
              product.op_stockQTA = `${product.op_description} -  ${product.op_stock}pZ `;
            });
            if (this.agent_order_new.or_order_date)
              this.formattedDate = formatDate(
                this.agent_order_new.or_order_date,
                'dd/MM/yyyy',
                'it'
              );
            this.formattedClosedDate = '-';
            this.agentOrderDetail.patchValue({
              or_agent_id: this.agent_id,
              or_closing_date: this.formattedClosedDate,
              or_customer: this.agent_order_new.or_customer,
              or_order_date: this.formattedDate,
              or_order_number: this.agent_order_new.or_order_number,
              or_shipping_address: this.agent_order_new.or_shipping_address,
              or_shipping_city: this.agent_order_new.or_shipping_city,
              or_shipping_country: this.agent_order_new.or_shipping_country,
              or_shipping_prov: this.agent_order_new.or_shipping_prov,
              or_shipping_zip: this.agent_order_new.or_shipping_zip,
              status: 'DA ASSEGNARE',
              carrier_desc: '-',
            });
            console.log('Agent order is: ', this.agent_order_new);
          });
      }
    }
  }

  seeDDT: boolean = false;

  printDDT() {
    let link = '';
    this.agent_order.or_url_ddt.endsWith('.pdf')
      ? (link = this.agent_order.or_url_ddt)
      : (link = '');
    link === '' ? (this.seeDDT = true) : (this.seeDDT = false);
    window.open(link, '_blank');
    // const a = document.createElement('a');
    // a.href = link;
    // a.target = '_blank';
    // a.click();
    // console.log('DDT link is: ', link);
  }

  printDDTStamp() {
    console.log('Printer service', this.printerService.printerSelection);
    this.orderServ
      .getGenerateDDT(
        this.agent_order.order_id,
        this.printerService.printerSelection.value.printer_ddt_id
      )
      .subscribe(async (result) => {
        console.log('result', result);
        if (!result) return;
        if (result.error_message.msg_code === 101) {
          console.log('No ENTER');
          const alert = await this.alertController.create({
            header: 'ERROR!!',
            message:
              "Un'altra persona potrebbe averlo generato nello stesso tempo o il server è occupato",
            buttons: [
              {
                text: 'OK',
                handler: () => {
                  // Check if popoverController is defined before calling dismiss
                  if (this.popoverController) {
                    this.popoverController.dismiss();
                  }
                },
              },
            ],
          });
          await alert.present();
        } 
        else if (result.success) {
          this.isLinkToDDT = result.data.or_url_ddt?.indexOf('.pdf') > 0;
          this.linkToDDT = this.isLinkToDDT ? result.data.or_url_ddt : null;
          this.isClickedOnIt = true;
          this.clickedOnce = true;
          window.open(result.data.or_url_ddt);
        }
      });
  }

  getOrderDetail(id: string, order_id: string, token_agent: string) {
    this.agentsServ
      .getAgentOrder(order_id, id, token_agent)
      .subscribe((agentsData: IWsResult) => {
        this.agent_order = agentsData.data;
        console.log('Agent order is: ', this.agent_order);
      });
  }

  goToProductDetails(pr_code) {
    this.pr_code = pr_code;
    console.log('Product id is: ', pr_code);
    this.closeServ.onStoricoProd();
  }

  close() {
    this.closeServ.offOrderA();
  }
  
}
