import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class OpenModalService {

  constructor() { }

  openGeneralSubject() {
    this.generalSubject.next(true);
  }

  closeGeneralSubject() {
    this.generalSubject.next(false);
  }

  public generalSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public openError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public modifyError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public triggerPosition: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public shippy: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public proddi: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public dochy: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public colli: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public boxy: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public compli: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public ordi: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public signy: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public bolla: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public ddt: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public qty: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public qly: BehaviorSubject<Boolean> = new BehaviorSubject(false);

  openModifyError() {
    this.modifyError.next(true);
  }

  closeModifyError() {
    this.modifyError.next(false);
  }

  openErrorModal() {
    this.openError.next(true);
  }

  closeErrorModal() {
    this.openError.next(false);
  }

  private openProdList = false;
  private opened = false;
  public prodiDet = false;
  private accOpn = false;
  private accPageOpen = false;
  private boxOpn = false;
  private passOpn = false;
  public dataSaved = false;
  public isPhotoOn = false;
  public isMsgOn = false;
  public isProdOpen = false;
  public isBoxDet = false;
  public isCheckOn = false;
  public isBoxCheck = false;
  public confirmed = false;
  public confirmed2 = false;
  public openedDoc = false;
  public openShip = false;
  public openCour = false;
  public openOrders = false;
  public prodPos = false;
  public openSignature = false;
  public prodDet = false;
  public photoAcc = false;
  public statusId = false;
  public firmaID = false;
  public storicoID = false;
  public borderòID = false;
  public messDet = false;
  public detAgent = false;
  public agentTrack = false;
  public agentOrderb = false;
  public daFirmare = false;
  public AgentOrderTrack = false;
  public confirmComplete = false;
  public cmOrder = false;
  public bordero = false;
  public prodPosDet = false;
  public storicoProd = false;
  public confirmCancel = false;
  public isBollaOpen = false;
  public isDDTOpen = false;
  public isQtyOpen = false;
  public isQlyOpen = false;
  public seeQRProducts = false;

  public seeListOfProductsForBoxes = false;

  public get isListOfProductsForBoxes() {
    return this.seeListOfProductsForBoxes;
  }

  public onListOfProductsBoxes() {
    this.seeListOfProductsForBoxes = true;
  }

  public offListOfProductsBoxes() {
    this.seeListOfProductsForBoxes = false;
  }

  public get isOpenProdList() {
    return this.openProdList;
  }

  public onProdList() {
    this.openProdList = true;
  }

  public offProdList() {
    this.openProdList = false;
  }

  public get isConfirmCancel() {
    return this.confirmCancel;
  }

  public onConfirmCancel() {
    this.confirmCancel = true;
  }

  public offConfirmCancel() {
    this.confirmCancel = false;
  }
  public get isStoricoProd() {
    return this.storicoProd;
  }

  public onStoricoProd() {
    this.storicoProd = true;
  }

  public offStoricoProd() {
    this.storicoProd = false;
  }

  public get isAgentOrderTrack() {
    return this.AgentOrderTrack;
  }

  public onOrderTrack() {
    this.AgentOrderTrack = true;
  }

  public offOrderTrack() {
    this.AgentOrderTrack = false;
  }

  public get isProdPosDet() {
    return this.prodPosDet;
  }

  public onPosDet() {
    this.prodPosDet = true;
  }

  public offPosDet() {
    this.prodPosDet = true;
  }

  public get isBordero() {
    return this.bordero;
  }

  public onBordero() {
    this.bordero = true;
  }

  public offBordero() {
    this.bordero = false;
  }

  public get isCmOrder() {
    return this.cmOrder;
  }

  public onCmOrder() {
    this.cmOrder = true;
  }

  public offCmOrder() {
    this.cmOrder = false;
  }

  public get isConfirmComplete() {
    return this.confirmComplete;
  }

  public onConfirmComplete() {
    this.confirmComplete = true;
  }

  public offConfirmComplete() {
    this.confirmComplete = false;
  }
  public get isDaFirmare() {
    return this.daFirmare;
  }

  public daFirmareOn() {
    this.daFirmare = true;
  }

  public daFirmareOff() {
    this.daFirmare = false;
  }

  public get isAgentOrderB() {
    return this.agentOrderb;
  }

  public onOrderA() {
    this.agentOrderb = true;
  }

  public offOrderA() {
    this.agentOrderb = false;
  }
  public get isAgentTrack() {
    return this.agentTrack;
  }

  public onTrack() {
    this.agentTrack = true;
  }

  public offTrack() {
    this.agentTrack = false;
  }

  public get isDetAgent() {
    return this.detAgent;
  }

  public onDetAgent() {
    this.detAgent = true;
  }

  public offDetAgent() {
    this.detAgent = false;
  }

  public get isMessID() {
    return this.messDet;
  }

  public onMessID() {
    this.messDet = true;
  }

  public offMessID() {
    this.messDet = false;
  }

  public get isFirma() {
    return this.firmaID;
  }

  public onFirma() {
    this.firmaID = true;
  }

  public offFirma() {
    this.firmaID = false;
  }

  public get isStorico() {
    return this.storicoID;
  }
  public get isAccPageOpen() {
    return this.accPageOpen;
  }
  public openAccPage() {
    this.accPageOpen = true;
  }

  public offAccPage() {
    this.accPageOpen = false;
  }
  public onStorico() {
    this.storicoID = true;
  }

  public offStorico() {
    this.storicoID = false;
  }

  public get isBorderò() {
    return this.borderòID;
  }

  public onBorderò() {
    this.borderòID = true;
  }

  public offBorderò() {
    this.borderòID = false;
  }

  public get isID() {
    return this.statusId;
  }

  public onId() {
    this.statusId = true;
  }

  public offId() {
    this.statusId = false;
  }

  public get isCourOn() {
    return this.openCour;
  }
  public get isPhotoAcc() {
    return this.photoAcc;
  }

  public onPhoto() {
    this.photoAcc = true;
  }

  public offPhoto() {
    this.photoAcc = false;
  }

  public onCour() {
    this.openCour = true;
  }

  public offCour() {
    this.openCour = false;
  }

  public get isPosOn() {
    return this.prodPos;
  }

  public onPos() {
    this.prodPos = true;
  }

  public offPos() {
    this.prodPos = false;
  }

  public get isShipOn() {
    return this.openShip;
  }

  public get isOrderOpen() {
    return this.openOrders;
  }

  public get isSignOn() {
    return this.openSignature;
  }

  public get isProdDetOn() {
    return this.prodDet;
  }

  public prodDetOn() {
    this.prodDet = true;
    // this.prodiDet.next(true);
  }

  public prodDetOff() {
    this.prodDet = false;
    // this.prodiDet.next(false);
  }

  public ordersOn() {
    this.openOrders = true;
    this.ordi.next(true);
  }

  public ordersOff() {
    this.openOrders = false;
    this.ordi.next(false);
  }

  public shipOn() {
    this.openShip = true;
    this.shippy.next(true);
  }

  public shipOff() {
    this.openShip = false;
    this.shippy.next(false);
  }

  public signOn() {
    this.openSignature = true;
    this.signy.next(true);
  }

  public singOff() {
    this.openSignature = false;
    this.signy.next(false);
  }

  public get isOpenedDoc() {
    return this.openedDoc;
  }

  public openDoc() {
    this.openedDoc = true;
    this.dochy.next(true);
  }

  public closeDoc() {
    this.openedDoc = false;
    this.dochy.next(false);
  }

  public get isConfirmed2() {
    return this.confirmed2;
  }

  public isColorConfirmed2() {
    this.confirmed2 = true;
  }

  public get isConfirmed() {
    return this.confirmed;
  }

  public isColorConfirmed() {
    this.confirmed = true;
  }

  public get isBoxCheckOn() {
    return this.isBoxCheck;
  }

  public openCheckBox() {
    this.isBoxCheck = true;
    this.boxy.next(true);
  }

  public closeCheckBox() {
    this.isBoxCheck = false;
    this.boxy.next(false);
  }

  public get isCheck() {
    return this.isCheckOn;
  }

  public closeCheck() {
    this.isCheckOn = false;
    this.proddi.next(false);
  }
  public openCheck() {
    this.isCheckOn = true;
    this.proddi.next(true);
  }

  public get isBoxDetOn() {
    return this.isBoxDet;
  }

  public closeDetBox() {
    this.isBoxDet = false;
  }

  public openDetBox() {
    this.isBoxDet = true;
  }

  public get isBoxOpn() {
    return this.boxOpn;
  }

  public closeBox(dataItem) {
    this.boxOpn = false;
  }

  public openBox() {
    this.boxOpn = true;
  }

  public get isProdopen() {
    return this.isProdOpen;
  }
  public closeProd() {
    this.isProdOpen = false;
  }

  public openProd() {
    this.isProdOpen = true;
  }

  public get isMsgoon() {
    return this.isPhotoOn;
  }

  public get isPhotoon() {
    return this.isPhotoOn;
  }
  // Opens the photo modal so to speak
  public closeMsg() {
    this.isPhotoOn = false;
  }

  public openMsg() {
    this.isPhotoOn = true;
  }
  // Opens the message Modal

  public closePhoto() {
    this.isPhotoOn = false;
  }

  public openPhoto() {
    this.isPhotoOn = true;
  }
  public popover = false;

  public get isPopover() {
    return this.popover;
  }
  public openPopover() {
    this.popover = true;
  }

  public closePopover() {
    this.popover = false;
  }

  public get isPassOpn() {
    return this.passOpn;
  }

  public get isOpened() {
    return this.opened;
  }

  public get isAccOpn() {
    return this.accOpn;
  }

  public get isBollaOOpen() {
    return this.isBollaOpen;
  }
  public closeBolla() {
    this.isBollaOpen = false;
    this.bolla.next(false);
  }
  public openBolla() {
    this.isBollaOpen = true;
    this.bolla.next(true);
  }

  public get isDDTOOpen() {
    return this.isDDTOpen;
  }
  public closeDDT() {
    this.isDDTOpen = false;
    this.ddt.next(false);
  }
  public openDDT() {
    this.isDDTOpen = true;
    this.ddt.next(true);
  }

  public get isQtyOOpen() {
    return this.isQtyOOpen;
  }
  public closeQty() {
    this.isQtyOpen = false;
    this.bolla.next(false);
  }
  public openQty() {
    this.isQtyOpen = true;
    this.bolla.next(true);
  }

  public get isQlyOOpen() {
    return this.isQlyOpen;
  }
  public closeQly() {
    this.isQlyOpen = false;
    this.bolla.next(false);
  }
  public openQly() {
    this.isQlyOpen = true;
    this.bolla.next(true);
  }

  public closePass(): void {
    this.passOpn = false;
  }

  public openPass(): void {
    this.passOpn = true;
  }

  public close(): void {
    this.opened = false;
  }

  public closeAcc(): void {
    this.accOpn = false;
  }

  public openAcc(): void {
    this.accOpn = true;
  }

  public open(): void {
    this.opened = true;
  }

  public get isQRProducts() {
    return this.seeQRProducts;
  }

  public seeQRON() {
    this.seeQRProducts = true;
  }

  public seeQROFF() {
    this.seeQRProducts = false;
  }
  
}
