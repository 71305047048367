import { Pipe, PipeTransform } from '@angular/core';
import { isAfter, isBefore, parseISO, isEqual } from 'date-fns';

@Pipe({
  name: 'byToDate',
})

export class ByToDatePipe implements PipeTransform {

  transform(value: any[], ...args: any): any[] {
    console.log('Value is', value);
    console.log('Args are', ...args);

    args = args[0];
    if (!value || !args || args.length < 3 || !args[0] || !args[1]) {
      return value;
    }

    const startDate = args[0];
    const endDate = args[1];
    const filterType = args[2];

    return value.filter((item) => {
      let itemDate;
      if (filterType === 'or_order_number') {
        itemDate = parseISO(item?.or_order_date);
      } else if (filterType === 'ms_datetime') {
        itemDate = parseISO(item?.ms_datetime);
      }

      if (!itemDate) {
        return false;
      }

      // Log the dates being compared

      // Check for inclusive range
      return (
        (isAfter(itemDate, startDate) || isEqual(itemDate, startDate)) &&
        (isBefore(itemDate, endDate) || isEqual(itemDate, endDate))
      );
    });
  }
  
}
