<h1 class="page-title">Lista ordini da firmare</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <form
    *ngIf="orders && chosenCarrier === null"
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <div
      class="inputSearchContainer"
      style="display: flex; flex-direction: column; align-items: flex-end"
    >
      <i
        *ngIf="filterTerm"
        class="bx bxs-x-circle bx-md clearSearchIcon"
        (ngModelChange)="handleFilterChange()"
        style="
          transform: scale(0.8);
          color: red;
          /* background: #0c65e9; */
          position: relative;
          top: 42px;
          z-index: 10001;
        "
        (click)="clearSearch()"
      ></i>
      <input
        [(ngModel)]="filterTerm"
        id="search"
        name="search"
        pattern=".*\S.*"
        type="search"
        placeholder="Cerca ordine..."
        autofocus
        required
        (input)="this.onInputChanged($event)"
      />
    </div>
    <button type="submit" (click)="searchOrders()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      visibility: hidden !important;
    "
  >
    <button
      class="btn btn-primary msg-btn"
      routerLink="/new-msg"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        visibility: hidden !important;
      "
    >
      + Messaggio
    </button>
  </form>

  <form
    action=""
    class="search-bar activeHover"
    *ngIf="chosenCarrier !== null && selectedOrders"
  >
    <input
      type="text"
      [(ngModel)]="filterTerm"
      name="search"
      pattern=".*\S.*"
      placeholder="cerca ordine.."
      required
      (input)="this.onInputChanged($event)"
    />
    <button class="search-btn" type="submit" (click)="searchAfterCarrier()">
      <span>Cerca</span>
    </button>
  </form>

  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
        (valueChange)="seeTheDate($event)"
        >{{ filters.startingDate | date }}
      </kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
    <div class="col mx-2" style="padding-top: 25px">
      <kendo-dropdownlist
        [data]="carriers"
        class="drop-the-border"
        #dropdownList
        [selectedIndex]="customerIndex"
        style="
          text-align: center;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
        (valueChange)="changeCarier($event)"
        [(ngModel)]="chosenCarrier"
        defaultItem="Seleziona il corriere"
      >
        <ng-template let-dataItem> </ng-template>
      </kendo-dropdownlist>
    </div>
  </div>
</div>

<hr class="hr-newBlue" />

<div class="row mt-2" *ngIf="chosenCarrier !== null && selectedOrders">
  <div class="col" style="display: flex; justify-content: flex-end">
    <button
      class="btn btn3 btn-borderò k-display-flex align-items: flex-end"
      (click)="openModalDamnIt()"
      target="_blank"
      rounded="full"
      [disabled]="!this.isOneSelected"
    >
      <i
        class="bx bxs-file-pdf bx-sm"
        style="
          color: #fff;
          right: 6px;
          bottom: 10px;
          position: relative;
          margin-top: 15px;
        "
      ></i>
      <span style="padding-top: 5px">GENERA BORDERO'</span>
    </button>
    <button
      class="k-display-flex align-items: flex-end"
      (click)="selectAllOrdersPerCarr()"
      class="{{
        areSelected ? 'btn btn3 btn-deselect' : 'btn btn3 btn-select'
      }}"
      rounded="full"
    >
      <i
        class="{{
          areSelected
            ? 'bx bx-block bx-sm posRelBox2'
            : 'bx bx-check-circle bx-sm posRelBox2'
        }}"
        style="
          color: #fff;
          right: 6px;
          bottom: 10px;
          position: relative;
          margin-top: 0px;
        "
      ></i>
      <span style="padding-top: 5px">
        {{ areSelected ? "DESELEZIONA TUTTI" : "SELEZIONA TUTTI" }}
      </span>
    </button>
  </div>
</div>

<div
  class="grid-wrapper"
  style="padding-left: 20px"
  *ngIf="orders && chosenCarrier === null"
>
  <div style="display: flex; flex-direction: row; justify-content: center">
    <h2 class="col-header">Totale ordini da firmare: {{ orders?.length }}</h2>
  </div>

  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <kendo-grid
    [kendoGridBinding]="
      orders
        | byCustomerName : customer
        | byDatePipe : date
        | byToSignDate
          : [
              filters.startingDate,
              filters.endDate,
              filters.dateOrginizedBy.value
            ]
    "
    [skip]="skip"
    [rowHeight]="36"
    [height]="750"
    [pageSize]="pageSize"
    scrollable="virtual"
    [navigable]="true"
    [sortable]="true"
    [reorderable]="true"
  >
    <kendo-grid-column
      [width]="140"
      field="or_assigned_date"
      title="Data evasione"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_assigned_date | date : "dd/MM/yyyy" }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="100"
      field="or_order_number"
      title="N° Ordine"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_order_number }}</ng-template
      >
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="ac_last_name"
      title="Operatore"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.ac_first_name }} {{ dataItem.ac_last_name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="or_carrier_desc"
      title="Corriere"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_carrier_desc }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="450"
      field="or_customer"
      title="Cliente"
      [headerStyle]="{ 'justify-content': 'left' }"
      [style]="{
        display: 'grid',
        'justify-content': 'left',
        'tetx-align': 'left'
      }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_customer }}</ng-template
      >
    </kendo-grid-column>

    <kendo-grid-column
      [width]="100"
      field="or_boxes"
      title="N° Colli"
      [headerStyle]="{ 'justify-content': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_boxes }}</ng-template
      >
    </kendo-grid-column>

    <kendo-grid-column
      [width]="110"
      field="or_order_date"
      title="Data ordine"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}</ng-template
      >
    </kendo-grid-column>

    <kendo-grid-column
      [width]="140"
      field="or_assigned_date"
      title="Data evasione"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_assigned_date | date : "dd/MM/yyyy" }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="180"
      title="Completa"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          class="btn btn3 btn-complete k-display-flex align-items: flex-end"
          style="margin: 0; padding: 0; background-color: #0053b4 !important"
          rounded="full"
          (click)="openConfirmModal(dataItem.order_id)"
        >
          <i
            class="bx bxs-check-shield bx-sm"
            style="
              color: #fff;
              top: -4px;
              right: 6px;
              bottom: 10px;
              position: relative;
              margin-top: 15px;
            "
          ></i>
          <span style="padding-top: 12px">COMPLETA</span>
        </button>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column
      title="Dettagli"
      [width]="80"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoGridEditCommand
          rounded="full"
          class="btn-det"
          style="margin: 0; padding: 0; background-color: #017efa !important"
          (click)="gotoDetails(dataItem.order_id)"
        >
          <div class="setDisplayToFlexBlue btn-det">
            <i class="bx bx-info-circle bx-md" style="margin: 0; padding: 0">
            </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-command-column>

    <ng-template kendoGridNoRecordsTemplate>
      <div class="no-records-message">
        <p>Nessun dato disponibile</p>
      </div>
    </ng-template>
  </kendo-grid>
</div>

<div
  class="container"
  *ngIf="chosenCarrier !== null && selectedOrders"
  style="height: 100%; max-height: 56%; overflow: auto"
>
  <h1
    style="
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */
      color: var(--text-color-k);
    "
  >
    TOTALE ORDINI DA FIRMARE: {{ selectedOrders.length }}
  </h1>
  <kendo-grid
    class="grid-wrapper"
    [kendoGridBinding]="selectedOrders"
    [rowHeight]="12"
    [height]="650"
    [pageSize]="pageSize"
    scrollable="virtual"
    [sortable]="true"
  >
    <kendo-grid-column
      [width]="100"
      title="Seleziona"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <i
          class="bx bx-check-circle bx-md"
          *ngIf="dataItem.op_checked === false"
          (click)="chooseItem(dataItem.indexNo, $event)"
          style="cursor: pointer"
        ></i>
        <i
          class="bx bxs-check-circle bx-md"
          *ngIf="dataItem.op_checked === true"
          (click)="chooseItem(dataItem.indexNo, $event)"
          style="cursor: pointer; color: green"
        ></i>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="100"
      field="or_order_number"
      title="N° ordine"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.order_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="500"
      field="or_or_customer"
      title="Cliente"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_customer }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="100"
      field="or_boxes"
      title="N° pezzi"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'text-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_boxes }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="100"
      title="Dettagli"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoGridEditCommand
          rounded="full"
          class="btn-det"
          style="margin: 0; padding: 0; background-color: #017efa !important"
          (click)="gotoDetails(dataItem.order_id)"
        >
          <div class="setDisplayToFlexBlue btn-det">
            <i class="bx bx-info-circle bx-md" style="margin: 0; padding: 0">
            </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<app-ordini-da-firmare-detaggli
  *ngIf="openModal.isDaFirmare"
  [openedFIRMARE]="openModal.isDaFirmare"
  [order_id]="clickedId"
>
</app-ordini-da-firmare-detaggli>
<app-confirm-complete
  *ngIf="openModal.isConfirmComplete"
  [modalOpened]="openModal.isConfirmComplete"
  [order_id]="clickedIdComplete"
  (modalClosed)="this.completeOrder(clickedIdComplete)"
>
</app-confirm-complete>

<app-bordero-page
  *ngIf="openModal.isBordero"
  [openedBorder]="openModal.isBordero"
  (answerSubmitted)="sign($event)"
>
</app-bordero-page>
