import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { Products } from 'src/app/models/Product.model';
import { Status } from 'src/app/models/status';
import { IWsResult } from 'src/app/models/ws_result.model';
import { IconsService } from 'src/app/services/icons.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PagesService } from 'src/app/services/pages.service';
import { ProductsService } from 'src/app/services/products.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm-products',
  templateUrl: './confirm-products.component.html',
  styleUrls: ['./confirm-products.component.scss'],
})
export class ConfirmProductsComponent implements OnInit {
  @Input() isProdCheck = true;
  @Output() modalClosed1 = new EventEmitter<void>();

  public orderToUpdate: Orders.OrderUpdate;
  public order: Orders.Order;

  similarProducts: Products.ProductSimilar[] = [];
  isAnyChecked: boolean = false;
  productToAdd: Products.ProductToAdd;
  isSimilar: boolean = false;
  quantity: number;
  pr_note: string = '';
  isDDT: boolean = false;
  clickedProduct: string;
  id: string;
  orderToCheck: string = null;
  numbers: number[] = [];
  stepCount: number = 0;
  checkProduct: any;
  back: string = '';
  firstEnteredOnPage: boolean = true;
  confirmed = false;
  selectedProduct: Products.Product;
  selectedProductIdToDelete: any;
  searchBool: boolean = false;
  openedSearch: boolean = false;
  filterTerm: string | undefined;

  constructor(
    private router: Router,
    public orderService: OrdersService,
    private productService: ProductsService,
    private pagesService: PagesService,
    private activatedRoute: ActivatedRoute,
    private iconService: IconsService,
    public closeServ: OpenModalService,
    private dropdownServ: DropdownService
  ) {}

  close() {
    this.closeServ.closeCheck();
    this.confirmed = true;
  }

  ngOnInit(): void {
    this.getProducts();
    this.firstEnteredOnPage = true;
  }

  getProducts() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.back = this.pagesService.getParentUrl();
    this.orderService.getOrder1(+this.id).subscribe(async (result) => {
      this.order = result;
      console.log('Orders are', this.order);
      console.log('Barcodes', this.order.products[0]?.barcodes);
      this.isDDT = result.or_url_ddt?.indexOf('.pdf') > 0;
      // this.isDDT = true;
      this.orderToUpdate = new Orders.OrderUpdate(this.order);
      if (this.orderToUpdate.or_carrier_id == 0)
        this.orderToUpdate.or_carrier_id = null;
      if (this.order.vs_details.carrier_id == 0)
        this.order.vs_details.carrier_id = null;
      this.stepCount = this.order.stepCount;
      console.debug('this.order.stepCount', this.order.stepCount);
      this.orderService.pages.next(this.order.stepCount);
      this.checkSelectedAll();
    });
  }

  async checkProdduti(id: number) {
    this.order.products[id].op_checked = !this.order.products[id].op_checked;
    this.order.orPartial =
      this.order.products.every((value) => value.op_checked == true) &&
      this.order.products.every((value) => value.op_qta == value.orQtaNeeded)
        ? 'N'
        : 'S';
    if (this.order.products[id].op_checked)
      'Hai selezionato ' + this.order.products[id].op_description;
    else
      `Il prodotto ${this.order.products[id].op_description} non è più selezionato`;
    this.order.products.sort(
      (a, b) =>
        +(a.op_checked ? a.op_checked : false) -
        +(b.op_checked ? b.op_checked : false)
    );
    this.checkSelectedAll();
  }

  selectAll() {
    this.order.products.forEach((element) => {
      element.op_checked = true;
      element.orQtaNeeded = element.op_qta;
    });
  }

  async checkSelectedAll(stepBack = false) {
    const stepFrw = 1;
    const stepBck = 0;
    this.orderService.setProductsChecked(this.order);
    console.log('CheckSelect');
    if (
      this.order.status[0].os_status_order_cod > 1 &&
      this.order.status[0].os_status_order_cod < 7 &&
      this.firstEnteredOnPage
    ) {
      this.order.products.forEach((element) => {
        element.op_checked = true;
        element.orQtaNeeded = element.op_qta;
      });
      this.firstEnteredOnPage = false;
    }
    console.log('Status is: ', this.order.products);
    if (this.order.products.every((value) => value.op_checked == true)) {
      this.order.stepCount = stepFrw;
      if (this.orderService.pages.value <= stepFrw)
        this.orderService.pages.next(stepFrw);
    } else {
      this.order.stepCount = stepBck;
      this.orderService.pages.next(stepBck);
      this.order.buttons[stepBck].completed = false;
    }
    console.log('CheckSelect');
  }

  opChecked(product: Products.IProductToSend, index: number) {
    if (this.isDDT) {
      return;
    }
    this.order.products[index].op_checked =
      !this.order.products[index].op_checked;
    this.orderToCheck =
      this.order.products.every((value) => value.op_checked == true) &&
      this.order.products.every((value) => +value.op_qta === value.orQtaNeeded)
        ? 'N'
        : 'S';
    console.log('Merge?', this.orderToCheck);
    console.log('Selected products are', product);
    this.checkSelectedAll();
    this.closeServ.isColorConfirmed();
  }

  async updateProduct(value, i) {
    this.order.products[i].orQtaNeeded = value;
    console.log('Value is: ', value);
    //check in one step
    this.order.products[i].op_checked = true;
    if (this.order.products[i].op_checked)
      'Hai selezionato ' + this.order.products[i].op_description;
    else
      `Il prodotto ${this.order.products[i].op_description} non è più selezionato`;
    this.order.products.sort(
      (a, b) =>
        +(a.op_checked ? a.op_checked : false) -
        +(b.op_checked ? b.op_checked : false)
    );
    this.order.orPartial = this.order.products.every(
      (item) => item.op_qta === item.orQtaNeeded
    )
      ? 'N'
      : 'S';
    let orderToUpdate = new Orders.OrderUpdate(this.order);
    this.orderService.updateOrder(orderToUpdate).subscribe((data) => {});
    this.checkSelectedAll();
  }

  changeProductNumber(n) {
    this.numbers = Array.from({ length: n }, (_element, index) => index + 1);
    console.debug('Go bro!');
  }

  goToProductDetails(product) {
    this.clickedProduct = product;
    console.log('Product ID is: ', product);
    this.closeServ.prodDetOn();
    this.checkSelectedAll();
  }

  saveStep(pageName) {
    let statusNo = 0;
    switch (pageName) {
      case 'products':
        console.log('CheckedProduct');
        statusNo = Status.StatusesUser.step1;
    }
    this.orderToUpdate = new Orders.OrderUpdate(this.order);
    // let today = new Date().toISOString();
    // this.orderToUpdate.or_assignment_date = today;
    // console.log('today is', today);
    console.log('orderToUpdate is', this.orderToUpdate);
    this.orderService
      .updateOrder(this.orderToUpdate)
      .subscribe(async (data) => {
        await this.checkSelectedAll();

        this.orderService
          .setStatus(this.order.order_id, statusNo)
          .subscribe((result: IWsResult) => {
            console.log('result is', result);
            // this.modalClosed1.emit();
            this.close();
            Swal.fire({
              icon: 'success',
              title: 'Aggiunti!',
              text: 'I prodotti selezionati sono stati aggiunti con successo!!!',
              timer: 1500,
              timerProgressBar: true,
            });
          });
      });

    // this.orderService
    //   .setStatus(this.order.order_id, statusNo)
    //   .subscribe((result: IWsResult) => {
    //     this.orderToUpdate = new Orders.OrderUpdate(this.order);
    //     console.log('This orderToUpdate', this.orderToUpdate);
    //     // this.orderToUpdate.products.map((item) => {
    //     //   return {
    //     //     op_checked: true,
    //     //   };
    //     // });
    //     console.log('This orderToUpdate after', this.orderToUpdate);
    //     this.orderService.updateOrder(this.orderToUpdate).subscribe();
    //     this.close();
    //     Swal.fire({
    //       icon: 'success',
    //       title: 'Aggiunti!',
    //       text: 'I prodotti selezionati sono stati aggiunti con successo!!!',
    //       timer: 1500,
    //       timerProgressBar: true,
    //     });
    //   });
  }

  selectProduct(object: Products.Product, i: number) {
    this.isSimilar = true;
    this.selectedProduct = object;
    this.filterTerm = this.selectedProduct.op_description;
    this.selectedProductIdToDelete = object.products_order_id;
    this.order.products[i].op_checked = true;
    console.log('This product index is: ', this.selectedProductIdToDelete);
    console.log('This selected product is: ', this.selectedProduct);
    this.searchBool = !this.searchBool;
    this.openedSearch = !this.openedSearch;
    this.getSimilarProducts();
  }

  getSimilarProducts() {
    console.log('FilterTerm is: ', this.filterTerm);
    if (this.searchBool !== false) {
      this.orderService
        .getSimilarProducts(this.filterTerm)
        .subscribe((openOrdersData: IWsResult) => {
          if (!openOrdersData.success) {
            this.similarProducts = [];
            return;
          }
          console.log('The filter term is: ', this.filterTerm);
          this.searchBool = false;
          this.similarProducts = openOrdersData.data;
        });
    }
    if (!this.filterTerm || this.filterTerm == '') {
      this.orderService
        .getSimilarProducts(this.filterTerm)
        .subscribe((openOrdersData: IWsResult) => {
          if (!openOrdersData.success) {
            this.similarProducts = [];
            return;
          }
          console.log('The filter term is: ', this.filterTerm);
          this.similarProducts = openOrdersData.data;
        });
    }
    this.orderService
      .getSimilarProducts(this.filterTerm)
      .subscribe((openOrdersData: IWsResult) => {
        this.productToAdd = openOrdersData.data;
      });
  }

  addNewProduct() {
    this.isSimilar = false;
    this.openedSearch = true;
    this.getSimilarProducts();
  }

  addProduct() {
    this.productToAdd.order_id = this.order.order_id;
    if (this.isSimilar) this.productToAdd.existingProduct(this.selectedProduct);
    else {
      this.productToAdd.op_remaining_qty = `${this.quantity}`;
      this.productToAdd.op_qty = this.quantity;
      this.productToAdd.op_note = this.pr_note;
    }
    this.productService
      .getProduct(this.productToAdd.op_code)
      .subscribe((result) => {
        if (!result) {
          alert('The product does not exist');
          Swal.fire({
            icon: 'error',
            title: 'Errore!',
            text: 'Il prodotto cercato non esiste!!!',
            timer: 1500,
            timerProgressBar: true,
          });
          return;
        }
        this.productToAdd.addedProduct(result);
        console.log('Product is: ', this.productToAdd);
        console.log('Op_code is: ', this.productToAdd.op_code);
        this.orderService
          .addProductToOrder(this.productToAdd)
          .subscribe((productData: IWsResult) => {
            if (productData.success) {
              console.log(
                'Thie ID of the product to delete is: ',
                this.selectedProductIdToDelete
              );
              if (this.isSimilar) this.deleteProduct();
              this.openedSearch = false;
              this.getProducts();
            }
          });
      });
  }

  deleteProduct(object?: Products.Product, i?: number) {
    if (object !== undefined)
      this.selectedProductIdToDelete = object.products_order_id;
    console.log(
      'The first ID to be deleted is: ',
      this.selectedProductIdToDelete
    );
    this.orderService
      .deleteProductFromOrder(this.selectedProductIdToDelete)
      .subscribe((result) => {
        if (result.success) {
          this.getProducts();
          Swal.fire({
            icon: 'success',
            title: 'Eliminato!',
            text: 'Prodotto rimosso con successo!!!',
            timer: 1500,
            timerProgressBar: true,
          });
        } else
          Swal.fire({
            icon: 'error',
            title: 'Errore!',
            text: 'Il prodotto selezionato non può essere rimosso!!!',
            timer: 1500,
            timerProgressBar: true,
          });
      });
  }

  selectProductToAdd(i) {
    this.isAnyChecked = this.similarProducts[i]?.pr_checked;
    this.similarProducts.map((product) => (product.pr_checked = false));
    this.similarProducts[i].pr_checked = this.isAnyChecked
      ? this.similarProducts[i]?.pr_checked
      : true;
    this.isAnyChecked = this.similarProducts[i]?.pr_checked;
    if (this.similarProducts[i].pr_checked) {
      this.productToAdd = new Products.ProductToAdd(this.similarProducts[i]);
    }
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.filterTerm = inputElement.value;
      this.getSimilarProducts();
    }
  }

  barcodes: Products.Product;

  seeQrCodes(barcode: Products.Product, order: any) {
    console.log('Products are', barcode);
    this.barcodes = barcode;
    this.closeServ.seeQRON();
    console.log('See the order', this.order);
    console.log('Barcodes are', this.barcodes);
  }
}
