<div *ngIf="order" class="order-details">
  <!-- Prima riga -->
  <div class="row det">
    <!-- Data ordine -->
    <div class="col setDisplay-Prep">
      <span class="title">Data ordine:</span><br />
      <strong class="setBorder-Prepp">{{ order.date }}</strong>
    </div>    
    <!-- N° Pezzi -->
    <div class="col setDisplay-Prep">
      <span class="title">N° Pezzi:</span><br />
      <strong class="setBorder-Prepp">{{ order.vs_details.op_articles_number }}</strong>
    </div>    
    <!-- Peso -->
    <div class="col setDisplay-Prep">
      <span class="title">Peso:</span><br />
      <strong class="setBorder-Prepp">{{ order.totalWeight }}</strong>
    </div>    
    <!-- Operatore -->
    <div class="col setDisplay-Prep">
      <span class="title">Operatore:</span><br />
      <div class="operatordiv">
        <strong class="setBorder-Prepp">{{ order.vs_details.ac_last_name }}</strong>
        <button class="btn operator-button"
                (click)="openChangeOperator()"
                [disabled]="order.status[0].os_status_order_cod !== 101">
          <i class="bx bx-down-arrow-circle bx-sm"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- Seconda riga -->
  <div class="row det">
    <!-- Cliente -->
    <div class="col-12 setDisplay-Prep">
      <span class="title">Cliente:</span><br />
      <strong class="setBorder-Prepp">{{ order.or_customer }}</strong>
    </div>
  </div>

  <!-- Terza riga -->
  <div class="row det">
    <!-- Indirizzo -->
    <div class="col-12 setDisplay-Prep">
      <span class="title">Indirizzo:</span><br />
      <strong class="setBorder-Prepp">{{ order.or_shipping_address }}</strong>
    </div>
  </div>

  <!-- Quarta riga -->
  <div class="row det">
    <!-- Dettagli città, CAP, Provincia, Nazione, Corriere -->
    <div class="col setDisplay-Prep">
      <span class="title">Città:</span><br />
      <strong class="setBorder-Prepp" role="button">{{ order.or_shipping_city }}</strong>
    </div>
    <div class="col-2 setDisplay-Prep">
      <span class="title">CAP:</span><br />
      <strong class="setBorder-Prepp">{{ order.or_shipping_zip }}</strong>
    </div>
    <div class="col-2 setDisplay-Prep">
      <span class="title">Provincia:</span><br />
      <strong class="setBorder-Prepp">{{ order.or_shipping_prov }}</strong>
    </div>
    <div class="col-2 setDisplay-Prep">
      <span class="title">Nazione:</span><br />
      <strong class="setBorder-Prepp">{{ order.or_shipping_country }}</strong>
    </div>
    <div class="col-3 setDisplay-Prep">
      <span class="title">Corriere:</span><br />
      <strong class="setBorder-Prepp">
        {{ order.vs_details.carrier_desc ? order.vs_details.carrier_desc : order.vs_details.carrier_other }}
      </strong>
    </div>
  </div>

  <!-- Quinta riga -->
  <div class="row det">
    <!-- Riferimento -->
    <div class="col-12 setDisplay-Prep">
      <span class="title">Riferimento:</span><br />
      <strong class="setBorder-Prepp">{{ order.or_note }}</strong>
    </div>
  </div>

  <!-- Quinta riga -->
  <div class="row det">
    <!-- Stato -->
    <div class="col setDisplay-Prep">
      <span class="title">Stato:</span><br />
      <strong class="status">{{ order.icon?.label }}</strong>
      <div class="progress">
        <ion-progress-bar [value]="+this.perc" style="height: 100%"></ion-progress-bar>
      </div>
    </div>
  </div>  
  
</div>
