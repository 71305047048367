import { Pipe, PipeTransform } from '@angular/core';
import { isAfter, isBefore, parseISO, isEqual, isValid } from 'date-fns';

@Pipe({
  name: 'byBordero',
})

export class byBorderoPipe implements PipeTransform {

  transform(value: any[], ...args: any): any[] {
    args = args[0];
    if (!value || !args || args.length < 3 || !args[0] || !args[1]) {
      return value;
    }

    const startDate = this.parseDate(args[0]);
    const endDate = this.parseDate(args[1]);
    const filterType = args[2];

    if (!isValid(startDate) || !isValid(endDate)) {
      return value;
    }

    return value.filter((item) => {
      let itemDate;
      if (filterType === 'or_order_number' || filterType === 'order_date') {
        itemDate = this.parseDate(item?.order_date);
      }

      if (!isValid(itemDate)) {
        return false;
      }
      
      return (
        (isAfter(itemDate, startDate) || isEqual(itemDate, startDate)) &&
        (isBefore(itemDate, endDate) || isEqual(itemDate, endDate))
      );
    });
  }

  private parseDate(date: string | Date): Date {
    if (date instanceof Date) {
      return date;
    }

    const parsedDate = parseISO(date);
    return isValid(parsedDate) ? parsedDate : new Date(NaN);
  }

}
