import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Orders } from 'src/app/models/Orders.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-base-orderscm-list',
  templateUrl: './base-orderscm-list.component.html',
  styleUrls: ['./base-orderscm-list.component.scss'],
})

export class BaseOrderscmListComponent implements OnInit {

  @Input() data: Orders.ICMOrderList[];
  @Input() days_remaining: Orders.ICMOrderList[];
  @Input() order_date: Orders.ICMOrderList[];
  @Input() filters: any;

  public loading: boolean = false;
  public pageSize = 25;
  public count: number = 20;
  public isSearched: boolean = false;
  public gridView: GridDataResult;
  public skip = 0;

  evasione_date = [];
  clickedID: string;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  maxPriority: number;
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  constructor(
    private ordersServ: OrdersService,
    private router: Router,
    public openModal: OpenModalService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    // if (!this.isNotificationSentToday()) {
    //   // Se non è stata inviata una notifica oggi, invia la notifica e memorizza la data nel Local Storage
    //   this.sendNotification();
    //   this.markNotificationAsSent();
    // }
    // this.loading = false;
  }

}
