import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  HostListener,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { AgentOrders } from 'src/app/models/IAgent.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { AgentsService } from 'src/app/services/agents.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { filter } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';
import { SetAgentService } from 'src/app/services/shared/set-agent.service';
import { isAfter, isBefore, parseISO } from 'date-fns';
import {
  GridComponent,
  EditService,
  RowClassArgs,
} from '@progress/kendo-angular-grid';
import { IonAccordionGroup } from '@ionic/angular';

@Component({
  selector: 'app-agente-list',
  templateUrl: './agente-list.component.html',
  styleUrl: './agente-list.component.scss',
})
export class AgenteListComponent implements OnInit {
  constructor(
    private sanitizer: DomSanitizer,
    public closeServ: OpenModalService,
    private agentsServ: AgentsService,
    private route: ActivatedRoute,
    private setAgentServ: SetAgentService,
    private elementRef: ElementRef
  ) {}

  @ViewChild('accordionGroup', { static: true })
  accordionGroup: IonAccordionGroup;

  @Input() agent_token: string;
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;
  @ViewChild('gridContainer', { read: ElementRef, static: false })
  gridContainer: ElementRef;

  private pageOpenedSubject = new Subject<boolean>();
  public dates: any[];
  public searchDate = 'Filtra ordini per data';
  public searchOrderNumber = ' Filtra ordini per n° ordine';
  public order_numbers: any[];

  pageOpened$ = this.pageOpenedSubject.asObservable();
  track_ID: any;
  public orderr: Orders.Order;
  agent_order: AgentOrders[] = [];
  agent: any;
  filterTerm: string | undefined;
  date: string = '';
  customerIndex: number;
  order_number: string = '';
  showAll: string = 'Mostra tutti';
  showAll2: string = 'Mostra tutti';
  or_assigned_date: string;
  or_gamma_id: number;
  clickedId: any;
  orderId: any;
  token_agent: string;
  token: string;
  shipment_id: string;
  agentID: string;
  riferimento: string;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  isAccordionOpen = false;
  panelOpenState: boolean = false;

  nrPartial: number = 0;
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  filter = {
    month: [
      { name: 'Gennaio', value: '01' },
      { name: 'Febbraio', value: '02' },
      { name: 'Marzo', value: '03' },
      { name: 'Aprile', value: '04' },
      { name: 'Maggio', value: '05' },
      { name: 'Giugno', value: '06' },
      { name: 'Luglio', value: '07' },
      { name: 'Agosto', value: '08' },
      { name: 'Settembre', value: '09' },
      { name: 'Ottobre', value: '10' },
      { name: 'Novembre', value: '11' },
      { name: 'Dicembre', value: '12' },
    ],
  };
  selectedMonth: string = '';
  MonthSelector(selectedValue: string) {
    this.selectedMonth = selectedValue;
    console.log('Value chosen', selectedValue);
  }

  dateSelector(selecteValue: any) {
    if (selecteValue) {
      this.selectedMonth = '';
    }
  }

  searchStorico: boolean = false;
  loading: boolean = false;

  onDateSelect(name: string, i: number) {
    this.date = name;
    console.log('I is: ', i);
    if (name === this.showAll2) this.date = null;
  }

  onOrderNumber(name: string, i: number) {
    this.order_number = name;
    console.log('I is: ', i);
    if (name === this.showAll) this.order_number = null;
  }

  ngOnInit(): void {
    this.reloadFunction();
  }

  reloadFunction() {
    this.adjustGridHeight();

    // this.agent = this.setAgentServ.getExistAgent();
    console.log('The AGENT now is: ', this.agent);

    this.elementRef.nativeElement
      .querySelectorAll('.mobilez .accordion-item')
      .forEach((item) => {
        item.addEventListener('touchstart', (event) => {
          event.preventDefault();
        });
      });
    this.route.queryParams
      .pipe(filter((params) => params['token']))
      .subscribe((params) => {
        console.log('Params is: ', params['token']);
        this.agent_token = params['token'];
        this.getAgentOrders(this.agent_token);
        console.log('LOADING LOADIN LOADING', this.loading);
      });
  }

  public rowClassPartial = (context: RowClassArgs) => {
    if (context.dataItem.or_partial === 'S') return { fadeInPurple: true };
  };

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getAgentOrders(this.agent_token);
    }
  }

  clearSearch() {
    this.filterTerm = '';
    this.filters.startingDate = this.startDate;
    this.reloadFunction();
  }

  onRowSelect() {
    // Imponi uno scroll verso l'alto per riportare la posizione dello scroll alla parte superiore della griglia
    this.gridContainer.nativeElement.scrollTop = 0;
  }

  fromToFilter(by: string, filters: any) {
    console.log('value changed: ', by);
    console.log('value changed: ', filters);
  }

  getAgentOrders(agent_token) {
    this.loading = true;
    this.agentsServ
      .getAgentOrders(agent_token)
      .subscribe((agentsData: AgentOrders[]) => {
        if (agentsData?.length === 0) {
          console.log('Empty array: ');
        } else {
          this.agent_order = agentsData;
          this.nrPartial = this.agent_order.filter(
            (order) => order.or_partial === 'S'
          ).length;

          console.log('Nr partial', this.nrPartial);
          this.agent_order.forEach((element) => {
            switch (this.filters.dateOrginizedBy.value) {
              case 'or_order_number':
                if (
                  isBefore(
                    parseISO(element.or_order_date),
                    this.filters.minDate
                  )
                ) {
                  this.filters.startingDate = parseISO(element.or_order_date);
                  this.filters.minDate = parseISO(element.or_order_date);
                }
                if (
                  isAfter(parseISO(element.or_order_date), this.filters.maxDate)
                ) {
                  this.filters.startingDate = parseISO(element.or_order_date);
                  this.filters.maxDate = parseISO(element.or_order_date);
                }
                break;
            }
          });
          this.agent_order.forEach((data) => {
            data.or_closing_date === '0001-01-01T00:00:00'
              ? ''
              : data.or_closing_date;
          });
          console.log('THE AGENT IS ', this.agent);
          this.dates = [
            ...new Set(
              agentsData.map((date) => {
                let formattedDate = formatDate(
                  date?.or_order_date,
                  'dd/MM/yyyy',
                  'it'
                );
                console.log('Formatted date is: ', formattedDate);
                console.log('Date is: ', date?.or_order_date);
                return formattedDate;
              })
            ),
          ];
          this.order_numbers = [
            ...new Set(
              agentsData.map((client) => {
                return client.or_order_number;
              })
            ),
          ];

          console.log('Data is: ', agentsData);
          this.loading = false;
        }
      });
  }

  goToTracking(token, trackId, agentID) {
    this.shipment_id = trackId;
    this.token = token;
    this.agentID = agentID;
    console.log('ID is: ', trackId);
    console.log('TOKEN is: ', token);
    this.closeServ.onTrack();
  }

  searchOrders() {
    this.loading = true;
    const newToken = this.agent_token;
    if (!this.filterTerm || this.filterTerm == '') {
      this.agentsServ
        .searchAgentOrders(this.filterTerm, newToken)
        .subscribe((agentsOrderData: IWsResult) => {
          this.agent_order = agentsOrderData.data;
          this.loading = false;
        });
    }
    this.agentsServ
      .searchAgentOrders(this.filterTerm, newToken)
      .subscribe((agentsOrderData: IWsResult) => {
        this.agent_order = agentsOrderData.data;
        this.loading = false;
      });
  }

  goToOrderDetails(
    agent_id: any,
    order_id: any,
    or_gamma_id: any,
    or_assigned_date: any,
    token_agent: string,
    ag_note: string
  ) {
    this.clickedId = agent_id;
    this.orderId = order_id;
    this.or_gamma_id = or_gamma_id;
    this.or_assigned_date = or_assigned_date;
    this.token_agent = encodeURIComponent(token_agent);
    this.riferimento = ag_note;
    console.log('Token agent: ', this.agent_token);
    this.closeServ.onOrderA();
  }

  ngOnDestroy(): void {
    this.pageOpenedSubject.next(false);
    this.pageOpenedSubject.complete();
  }

  toggleAccordion = () => {
    const nativeEl = this.accordionGroup;
    if (nativeEl.value === 'second') {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = 'second';
    }
  };

  //for grid height
  public gridHeight: number = 0;

  @ViewChild('div2', { static: false }) div2!: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustGridHeight();
  }

  private adjustGridHeight() {
    if (this.div2?.nativeElement?.clientWidth === 0) return;
    const div1Height = document.querySelector('.top-div')!.clientHeight;
    const containerHeight = window.innerHeight;
    this.gridHeight = containerHeight - div1Height;
  }
}
