<div>
  <h1 class="page-title">Impostazioni</h1>
  <br />
  <hr class="hr-newBlue" />

  <div class="padded rounded-corners print-box">
    <h2 class="col-header">Stampante scelta:</h2>
    <div class="grid-wrapper">
      <kendo-grid
        [kendoGridBinding]="printers"
        [data]="printers"
        [rowHeight]="36"
        scrollable="virtual"
        [navigable]="true"
        [sortable]="true"
        style="height: auto"
      >
        <kendo-grid-column
          [width]="200"
          field="ms_title"
          title="Nome stampante"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'text-align': 'center' }"
          [sortable]="true"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.printer_desc }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [width]="100"
          field="ms_title"
          title="DDT"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'text-align': 'center' }"
          [sortable]="true"
          (click)="changeprinterCustomBorID(dataItem.printer_id, 0)"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <ion-toggle
              [enableOnOffLabels]="true"
              [checked]="dataItem.printer_ddt"
              disabled="false"
              class="--background-white opaicty-full"
              mode="ios"
            ></ion-toggle>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [width]="100"
          field="ms_title"
          title="Scatola"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'text-align': 'center' }"
          [sortable]="true"
          (click)="changeprinterCustomBorID(dataItem.printer_id, 1)"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <ion-toggle
              [enableOnOffLabels]="true"
              [checked]="dataItem.printer_box"
              disabled="false"
              class="--background-white opaicty-full"
              mode="ios"
            ></ion-toggle>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [width]="100"
          field="ms_title"
          title="Corriere"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'text-align': 'center' }"
          [sortable]="true"
          (click)="changeprinterCustomBorID(dataItem.printer_id, 2)"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <ion-toggle
              [enableOnOffLabels]="true"
              [checked]="dataItem.printer_car"
              disabled="false"
              class="--background-white opaicty-full"
              mode="ios"
            ></ion-toggle>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [width]="100"
          field="ms_title"
          title="Borderò"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'text-align': 'center' }"
          [sortable]="true"
          (click)="changeprinterCustomBorID(dataItem.printer_id, 3)"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <ion-toggle
              [enableOnOffLabels]="true"
              [checked]="dataItem.printer_bor"
              disabled="false"
              class="--background-white opaicty-full"
              mode="ios"
            ></ion-toggle>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  <br /><br />

  <div class="padded rounded-corners print-box">
    <div
      class="flex"
      style="display: flex; flex-direction: row; justify-content: space-between"
    >
      <h2 class="col-header" style="justify-content: start">Obiettivo:</h2>
      <div class="col-2" style="justify-content: end; margin-bottom: 10px">
        <kendo-dropdownlist
          [data]="years"
          class="drop-the-border"
          #dropdownList
          [selectedIndex]="index"
          style="
            text-align: center;
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 45px;
          "
          (valueChange)="changeYear($event)"
          [(ngModel)]="chosenYear"
        >
          <ng-template let-dataItem> {{ dataItem }} </ng-template>
        </kendo-dropdownlist>
      </div>
    </div>

    <div class="grid-wrapper mb-5" *ngIf="newObjectivesArray">
      <kendo-grid
        [data]="newObjectivesArray"
        [rowHeight]="36"
        [height]="500"
        scrollable="virtual"
        [navigable]="true"
        [sortable]="true"
        [style]="{ 'text-align': 'center' }"
      >
        <kendo-grid-column
          [width]="100"
          field="monthly_objective"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'text-align': 'center' }"
          [sortable]="true"
          title="Mese"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <input
              [(ngModel)]="daily"
              class="editable-input val"
              type="number"
              [placeholder]="dataItem.correspondingMonth"
              [class.annuale2]="dataItem.correspondingMonth === 'Annuale'"
              readonly
            />
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [width]="100"
          field="net_value"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'text-align': 'center' }"
          [sortable]="true"
          title="Obiettivo mensile"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <input
              class="editable-input val"
              type="text"
              [disabled]="dataItem.correspondingMonth === 'Annuale'"
              [class.annuale]="dataItem.correspondingMonth === 'Annuale'"
              (input)="onInputChange($event, dataItem)"
              (blur)="transformAmount($event)"
              [placeholder]="dataItem.formattedNetValue"
            />
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [width]="100"
          field="working_days"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'text-align': 'center' }"
          [sortable]="true"
          title="Giorni lavorativi"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <input
              [(ngModel)]="dataItem.working_days"
              class="editable-input val"
              type="number"
              [placeholder]="
                dataItem.working_days === null ? 0 : dataItem.working_days
              "
              [disabled]="dataItem.correspondingMonth === 'Annuale'"
              [class.annuale2]="dataItem.correspondingMonth === 'Annuale'"
            />
            <button
              *ngIf="dataItem.correspondingMonth !== 'Annuale'"
              class="obj btn btn-primary"
              style="
                border-radius: 18px;
                background-color: #00cb51;
                border-color: #00cb51;
              "
              (click)="
                dataItem.objective_id === -1
                  ? setGoal(dataItem)
                  : updateGoal(dataItem)
              "
              [disabled]="
                dataItem.working_days === 0 || dataItem.net_value === 0
              "
            >
              <i class="bx bx-badge-check bx-md"></i>
            </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
