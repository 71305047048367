import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Buttons } from 'src/app/models/buttons';
import { ICarrier } from 'src/app/models/ICarrier.model';
import { Orders } from 'src/app/models/Orders.model';
import { Status } from 'src/app/models/status';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { PagesService } from 'src/app/services/pages.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm-shipment',
  templateUrl: './confirm-shipment.component.html',
  styleUrls: ['./confirm-shipment.component.scss'],
})
export class ConfirmShipmentComponent implements OnInit {
  @Input() isShipOn = true;
  @Output() modalClosed1 = new EventEmitter<void>();
  public order: Orders.Order;
  public orderToUpdate: Orders.OrderUpdate;
  public carriers: ICarrier[] = [];

  lettra: boolean = false;
  etichetta: boolean = false;
  isLabel: boolean = false;
  canGenerate: boolean = false;
  correctAddress: boolean = false;
  id: string;
  assignShipmentForm: FormGroup;
  checkCour: any;
  canEdit: boolean = false;
  canEditCor: boolean = false;
  buttons = Buttons.AnswerButtons;
  selected = '----';
  isPickUp: boolean = false;
  isLabelGenerated = false;
  otherCarrier: ICarrier = {
    carrier_id: null,
    carrier_desc: 'Other',
    or_carrier_other: '',
    sp_carrier_id: null,
  };

  constructor(
    private router: Router,
    private orderService: OrdersService,
    public modalService: OpenModalService,
    private pagesService: PagesService,
    private activatedRoute: ActivatedRoute,
    private dropdownServ: DropdownService
  ) {}

  triggerCor() {
    this.canEditCor = !this.canEditCor;
  }

  ngOnInit(): void {
    this.reloadFunction();
  }

  clearComboBox(): void {
    this.orderToUpdate.or_carrier_id = null;
  }

  reloadFunction() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.orderService.getOrder1(+this.id).subscribe((result) => {
      this.dropdownServ.getCarriers().subscribe((carriersData: ICarrier[]) => {
        this.carriers = carriersData;
        this.isLabelGenerated = this.isLabel;
        console.log('This label is: ', this.isLabel);
        console.log('The carriers are: ', this.carriers);
      });
      this.order = result;
      console.debug('orders: ', this.order);
      this.orderToUpdate = new Orders.OrderUpdate(this.order);
      if (this.orderToUpdate.or_carrier_id == 0)
        this.orderToUpdate.or_carrier_id = null;
      if (
        this.order.vs_details.sp_carrier_id == '0' ||
        this.order.vs_details.sp_carrier_id == null
      )
        this.order.vs_details.sp_carrier_id = null;
      if (
        this.order.or_shipping_address &&
        this.order.or_shipping_address.length > 0 &&
        this.order.or_shipping_customer &&
        this.order.or_shipping_address.length > 0 &&
        this.order.or_shipping_city &&
        this.order.or_shipping_address.length > 0 &&
        this.order.or_shipping_prov &&
        this.order.or_shipping_address.length > 0 &&
        this.order.or_shipping_zip &&
        this.order.or_shipping_address.length > 0 &&
        this.order.or_shipping_country &&
        this.order.or_shipping_address.length > 0
      )
        this.correctAddress = true;
      this.canGenerate =
        this.orderToUpdate.or_carrier_id == null ||
        this.order.vs_details.sp_carrier_id == null
          ? false
          : true;
      this.checkSelectedAll();
    });
  }

  check() {
    console.log('This order is: ', this.order);
    if (typeof this.orderToUpdate.or_carrier_id === 'string') {
      this.orderToUpdate.or_carrier_other = this.orderToUpdate.or_carrier_id;
      this.orderToUpdate.or_carrier_id = null;
    }
    this.orderToUpdate.or_carrier_id =
      this.orderToUpdate.or_carrier_id !== null
        ? this.orderToUpdate.or_carrier_id
        : null;
    this.orderToUpdate.or_carrier_other =
      this.orderToUpdate.or_carrier_id !== null
        ? null
        : this.orderToUpdate.or_carrier_other;
    console.log(
      'Original carrier is: ',
      this.orderToUpdate.or_original_carrier
    );
    this.orderService.updateOrder(this.orderToUpdate).subscribe((result) => {
      if (result.success) {
        this.order.or_shipping_address = this.orderToUpdate.or_shipping_address;
        this.order.or_shipping_city = this.orderToUpdate.or_shipping_city;
        this.order.or_shipping_prov = this.orderToUpdate.or_shipping_prov;
        this.order.or_shipping_country = this.orderToUpdate.or_shipping_country;
        this.order.or_shipping_customer =
          this.orderToUpdate.or_shipping_customer;
        this.order.or_shipping_zip = this.orderToUpdate.or_shipping_zip;
        this.order.or_note = this.orderToUpdate.or_note;
        this.order.vs_details.carrier_id = this.orderToUpdate.or_carrier_id;
        this.order.vs_details.carrier_other =
          this.orderToUpdate.or_carrier_other;
        if (
          this.order.or_shipping_address &&
          this.order.or_shipping_address.length > 0 &&
          this.order.or_shipping_customer &&
          this.order.or_shipping_address.length > 0 &&
          this.order.or_shipping_city &&
          this.order.or_shipping_address.length > 0 &&
          this.order.or_shipping_prov &&
          this.order.or_shipping_address.length > 0 &&
          this.order.or_shipping_zip &&
          this.order.or_shipping_address.length > 0 &&
          this.order.or_shipping_country &&
          this.order.or_shipping_address.length > 0
        )
          this.correctAddress = true;
      }
      this.modalService.shipOff();
      this.saveStep('products');

      this.close();
      Swal.fire({
        icon: 'success',
        title: 'Verificato!',
        text: 'Tutte le informazioni sono corrette!!!',
        timer: 1500,
        timerProgressBar: true,
      });

      this.modalClosed1.emit();
    });
  }

  updateCor() {
    console.log('This order is: ', this.order);
    if (typeof this.orderToUpdate.or_carrier_id === 'string') {
      this.orderToUpdate.or_carrier_other = this.orderToUpdate.or_carrier_id;
      this.orderToUpdate.or_carrier_id = null;
    }
    this.orderToUpdate.or_carrier_id =
      this.orderToUpdate.or_carrier_id !== null
        ? this.orderToUpdate.or_carrier_id
        : null;
    this.orderToUpdate.or_carrier_other =
      this.orderToUpdate.or_carrier_id !== null
        ? null
        : this.orderToUpdate.or_carrier_other;
    console.log(
      'Original carrier is: ',
      this.orderToUpdate.or_original_carrier
    );
    this.orderService.updateOrder(this.orderToUpdate).subscribe((result) => {
      if (result.success) {
        this.order.or_shipping_address = this.orderToUpdate.or_shipping_address;
        this.order.or_shipping_city = this.orderToUpdate.or_shipping_city;
        this.order.or_shipping_prov = this.orderToUpdate.or_shipping_prov;
        this.order.or_shipping_country = this.orderToUpdate.or_shipping_country;
        this.order.or_shipping_customer =
          this.orderToUpdate.or_shipping_customer;
        this.order.or_shipping_zip = this.orderToUpdate.or_shipping_zip;
        this.order.or_note = this.orderToUpdate.or_note;
        this.order.vs_details.carrier_id = this.orderToUpdate.or_carrier_id;
        this.order.vs_details.carrier_other =
          this.orderToUpdate.or_carrier_other;
        if (
          this.order.or_shipping_customer &&
          this.order.or_shipping_address.length > 0 &&
          this.order.or_shipping_city &&
          this.order.or_shipping_address.length > 0 &&
          this.order.or_shipping_prov &&
          this.order.or_shipping_address.length > 0 &&
          this.order.or_shipping_zip &&
          this.order.or_shipping_address.length > 0 &&
          this.order.or_shipping_country &&
          this.order.or_shipping_address.length > 0
        )
          this.correctAddress = true;
      }
      this.saveStep('products');
      this.modalService.closeProd();
      this.close();
      Swal.fire({
        icon: 'success',
        title: 'Aggiornato!',
        text: 'Corriere modificato con successo!!!',
        timer: 1500,
        timerProgressBar: true,
      });
    });
  }

  saveStep(pageName) {
    let statusNo = 0;
    switch (pageName) {
      case 'products':
        console.log('CheckedProduct');
        statusNo = Status.StatusesUser.step4;
    }
    this.orderService
      .setStatus(this.order.order_id, statusNo)
      .subscribe((result: IWsResult) => {
        //if(result.success)
      });
  }

  goToCourrier(product_id) {
    this.checkCour = product_id;
    this.modalService.onCour();
  }

  checkSelectedAll(stepBack = false) {
    const stepFrw = 4;
    const stepBck = 3;
    if (!stepBack && (this.isLabel || !this.canGenerate))
      this.orderService.pages.next(stepFrw);
    else {
      this.order.stepCount = stepBck;
      this.orderService.pages.next(stepBck);
      this.order.buttons[stepBck].completed = false;
    }
  }

  triggerEdit() {
    this.canEdit = !this.canEdit;
  }

  close() {
    this.modalService.shipOff();
  }

  generateLabel() {
    this.orderService
      .geShipmentLabel(this.order.order_id)
      .subscribe((result) => {
        if (result?.data[0]?.sm_label_link) {
          this.isLabel = true;
          this.checkSelectedAll();
          window.open(result?.data[0]?.sm_label_link);
          this.isLabelGenerated = true;
        }
      });
  }
}
