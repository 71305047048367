<div class="col" *ngIf="openedBorder">
  <kendo-dialog
    title=""
    class="k-form cacat"
    style="margin-left: 80px"
    [minWidth]="200"
    [width]="450"
    [maxWidth]="450"
    (close)="close()"
  >
    <div class="container">
      <div class="bordero k-display-flex justify-content-center">
        <h1
          class="page-title"
          style="
            margin-bottom: 0 !important; 
            top: 0 !important
          "
        >
          Nome del corriere
        </h1>
      </div>
      <hr class="hr-newBlue" style="bottom: 10px" />
      <div
        class="2ndContainer k-display-flex flex-column justify-content-center"
      >
        <form
          (ngSubmit)="submitForm()"
          class="k-display-flex flex-column justify-content-center"
        >
          <input
            [(ngModel)]="answer"
            name="answer"
            class="setBorder-Prepp"
            style="
              color: black;
              background-color: #ffffff;
              border: 1px solid #e0e0e0;
              text-align: center;
              border-radius: 15px;
              position: relative;
              bottom: 8px;
              text-align: center;
            "
            required="true"
          />
          <div 
            class="text-buttons"
            style="
              margin-top: 2rem !important;
              margin-bottom: 1rem !important;
            "
          >
            <div class="button-left">
              <button 
                class="btn btn3"
                type="submit"
                rounded="full"
                style="
                  background: rgb(0, 146, 73);
                  color: #ffffff;
                  justify-content: center !important;
                  display: flex;
                  width: 192px !important;
                  height: 50px !important;
                "
              >    
                <i
                  class="bx bx-check-circle bx-sm posRelBox2"
                  style="
                    color: #ffffff; 
                    top: 0px; 
                    right: 6px; 
                    bottom: 10px; 
                    position: relative;
                    margin-top: 0px;
                  "
                ></i>
                <p class="posRelBox2">CREA</p>
              </button> 
            </div>            
          </div>
        </form>
      </div>
    </div>
  </kendo-dialog>
</div>
