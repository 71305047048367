import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { isAfter, isBefore, parseISO } from 'date-fns';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/models/helpters';
import { ICarrier } from 'src/app/models/ICarrier.model';
import { IWorker } from 'src/app/models/IWorker.model';
import { Orders } from 'src/app/models/Orders.model';
import { Priority } from 'src/app/models/Priority.model';
import { Products } from 'src/app/models/Product.model';
import { OrdersService } from 'src/app/services/orders.service';
import { SearchService } from 'src/app/services/search.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { SearchServiceService } from 'src/app/services/shared/search-service.service';

@Component({
  selector: 'app-ordini-pm',
  templateUrl: './ordini-pm.component.html',
  styleUrls: ['./ordini-pm.component.scss'],
})
export class OrdiniPMComponent implements OnInit {
  public opened = false;
  public dataSaved = false;
  public assignOrderForm: FormGroup;
  public orderNew: Orders.IOrderNew;
  public productsNew: Products.IProductNew[] = [];
  public orderToSend: Orders.IOrderToSend;
  public formattedDate: string = '-';
  public priorityOptions: Priority.IPriority[] = [];
  public carriers: ICarrier[] = [];
  public workers: IWorker[] = [];
  public staticHelper = Helpers;
  public gridData: Orders.ICMOrderList[] = [];
  public loading: boolean = false;
  public filteredOrder: Orders.OrderList[] = null;
  public isSearched: boolean = false;

  filterTerm: string = '';
  maxPriority: number;
  timer = null;
  refreshInterval: number = 600000;
  text: string;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];
  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };
  searchService = inject(SearchServiceService);
  subscriber: Subscription[] = [];
  searchParam: string = '';
  booleanForSpecialSearch: boolean = false;
  searchTermLocalStorage: string = '';
  filterService = inject(SearchService);

  constructor(
    private ordersServ: OrdersService,
    private route: ActivatedRoute,
    private router: Router,
    private dropdownServ: DropdownService,
    private fb: FormBuilder,
    private openOrdersServ: OrdersService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    if (!this.isNotificationSentToday()) {
      // Se non è stata inviata una notifica oggi, invia la notifica e memorizza la data nel Local Storage
      this.sendNotification();
      this.markNotificationAsSent();
    }

    this.filterTerm = this.filterService.getFilterText();
    console.log('Filter is', this.filterTerm);

    // console.log('Filterterm is', this.searchTermLocalStorage);
    if (this.filterTerm) {
      this.searchOrders(this.filterTerm);
    } else {
      this.reloadFunction();
    }
  }
  handleFilterChange(): void {
    this.filterService.setFilterText(this.filterTerm);
    // Perform filtering logic here
  }
  getCMOrders() {
    this.loading = true;
    this.ordersServ
      .getCMOrders()
      .subscribe((CMOrdersData: Orders.ICMOrderList[]) => {
        this.gridData = CMOrdersData;
        console.debug('Data is: ', this.gridData);
      });
    this.loading = false;
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getCMOrders();
    }
  }

  async clearList(evt) {
    this.isSearched = false;
    this.filteredOrder = null;
    this.filterTerm = null;
  }
  public pageSize = 25;

  reloadFunction() {
    this.loading = true;
    this.subscriber.push(
      this.route.queryParams.subscribe((params) => {
        console.log('Params is', params);
        if (Object.keys(params).length === 0) {
          this.createForm();
          this.getCMOrders();
          //this.loading = false;
          console.log('Params is an empty object. Reloaded.');
        } else {
          this.searchParam = params['search'];
          const dateParam = params['data'];
          this.filters.startingDate = dateParam ? parseISO(dateParam) : null;
          console.log('Date param is', dateParam);
          console.log('Param is', this.searchParam);
          this.searchOrders(this.searchParam);
          this.loading = false;
        }
      })
    );
  }
  clearSearch() {
    this.filterTerm = '';
    this.filters.startingDate = this.startDate;
    this.filterService.clearFilterText();
    this.reloadFunction();
  }
  assignOrder(assignOrderForm: any): void {
    let orderToSend: Orders.IOrderToSend = {
      or_order_number: this.assignOrderForm.value.or_order_number,
      or_order_date: this.orderNew.or_order_date,
      or_quote_date: this.orderNew.or_quote_date,
      or_order_priority_cod: this.assignOrderForm.value.or_order_priority_cod,
      or_carrier_id: this.assignOrderForm.value.or_carrier_id,
      or_carrier_desc: this.orderNew.or_carrier_desc,
      products: this.assignOrderForm.value.products,
      or_customer: this.assignOrderForm.value.or_customer,
      or_shipping_address: this.assignOrderForm.value.or_shipping_address,
      or_assigned_id: this.assignOrderForm.value.or_assigned_id,
      or_assignment_date: new Date().toString(),
      or_partial: '',
      or_customer_reference: '',
      or_gamma_id: this.orderNew.or_gamma_id,
      or_customer_code: this.orderNew.or_customer_code,
      or_customer_mail: this.orderNew.or_customer_mail,
      or_customer_phone: this.orderNew.or_customer_phone,
      or_quote_number: this.orderNew.or_quote_number,
      or_subject: this.orderNew.or_subject,
      or_note: this.orderNew.or_note,
      or_shipping_city: this.orderNew.or_shipping_city,
      or_shipping_prov: this.orderNew.or_shipping_prov,
      or_shipping_zip: this.orderNew.or_shipping_zip,
      or_shipping_country: this.orderNew.or_shipping_country,
      or_shipping_customer: this.orderNew.or_shipping_country,
      or_agent_id: '',
      or_boxes: this.orderNew.or_boxes,
    };
    console.log(orderToSend);
    this.assignOrderForm.markAllAsTouched();
  }

  clearForm(): void {
    this.assignOrderForm.reset();
  }

  createForm() {
    this.assignOrderForm = this.fb.group({
      or_order_number: [''],
      or_order_date: [''],
      or_order_priority_cod: ['', Validators.required],
      or_carrier_id: ['', Validators.required],
      products: {},
      or_customer: [''],
      or_shipping_address: [''],
      or_note: [''],
      or_assigned_id: ['', Validators.required],
    });
  }

  searchOrders(searchString?: string) {
    this.loading = true;
    console.log('Searchstring?', searchString, 'FilterTerm?', this.filterTerm);
    if (searchString) this.filterTerm = searchString;
    if (!this.filterTerm || this.filterTerm == '') {
      this.getCMOrders();
    } else if (this.filterTerm || this.filterTerm !== '') {
      console.log('Searching based on this filterTerm', this.filterTerm);
      this.openOrdersServ
        .searchCmOrder(this.filterTerm)
        .subscribe((openOrdersData: Orders.ICMOrderList[]) => {
          console.log('Open ordersData is', openOrdersData);
          if (Array.isArray(openOrdersData)) {
            console.log('Should be no error');
            this.gridData = openOrdersData;
            localStorage.setItem('query-search', this.filterTerm);
            console.log(
              'SPECIAL BOOLEANUS WHEN GOING TO DETAILS IST EINE',
              this.booleanForSpecialSearch
            );
            this.ordersServ.passFilterValue(this.filterTerm);
            this.gridData.forEach((element) => {
              switch (this.filters.dateOrginizedBy.value) {
                case 'or_order_number':
                  if (
                    isBefore(
                      parseISO(element.or_order_date),
                      this.filters.minDate
                    )
                  ) {
                    this.filters.startingDate = parseISO(element.or_order_date);
                    this.filters.minDate = parseISO(element.or_order_date);
                  }
                  if (
                    isAfter(
                      parseISO(element.or_order_date),
                      this.filters.maxDate
                    )
                  ) {
                    this.filters.startingDate = parseISO(element.or_order_date);
                    this.filters.maxDate = parseISO(element.or_order_date);
                  }
                  break;
              }
            });
          } else {
            console.log('Error');
            this.gridData = [];
            return;
          }
        });
    }

    this.loading = false;
  }

  checkBooleanFromDet(value: any) {
    console.log('Value is', value);
  }

  public skip = 0;

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
  }

  // gotoDetails(order_id: string) {
  //   this.clickedID = order_id;
  //   this.openModal.onCmOrder();
  // }

  assignPM(order_id: number) {
    this.gridData = [];

    this.router.navigate(['/pm-orders', order_id]);
  }

  expirationDate(date: Date): Date {
    const dataScadenza = this.deadline(date);
    dataScadenza.setFullYear(dataScadenza.getFullYear());
    return dataScadenza;
  }

  /*It verifies if the date is expired */
  isExpired(date: Date): boolean {
    const lastDate = this.expirationDate(date);
    const today = new Date();
    return today > lastDate;
  }

  /* I've divided the progress bar into monthly ranges (cause divide it by days would cause too many ranges..*/
  progress(date: Date): number {
    const finalDate = this.deadline(date);
    const startDate = new Date(date);
    const months =
      (finalDate.getFullYear() - startDate.getFullYear()) * 12 +
      (finalDate.getMonth() - startDate.getMonth());
    const progresso = (months / 24) * 100;
    return Math.max(0, Math.min(100, progresso));
  }

  /* It calculate if the month is completed, to set the percentage by month */
  isMonthComplete(date: Date): boolean {
    const startDate = new Date(date);
    const today = new Date();
    const months =
      (today.getFullYear() - startDate.getFullYear()) * 12 +
      today.getMonth() -
      startDate.getMonth();
    const finalDate = new Date(
      startDate.getFullYear() + 2,
      startDate.getMonth(),
      startDate.getDate()
    );
    const lastDate = new Date(finalDate.getTime() + 24 * 60 * 60 * 1000);
    return months <= 24 || today.getTime() <= lastDate.getTime();
  }

  /* It calculates the remaining days to the expiration date */
  daysRemaining(date: any): number {
    const orderDate = new Date(date.or_order_date);
    const daysRemaining = date.or_days_remaining;
    const deadlineDate = new Date(
      orderDate.getTime() + Math.abs(daysRemaining) * 24 * 60 * 60 * 1000
    );
    const today = new Date();
    const remainingDays = Math.ceil(
      (deadlineDate.getTime() - today.getTime()) / (24 * 60 * 60 * 1000)
    );
    return remainingDays > 0 ? remainingDays : 0;
  }

  /* It calculates the expiration date */
  deadline(date: any): Date {
    const orderDate = new Date(date.or_order_date);
    const daysRemaining = date.or_days_remaining;
    const deadlineDate = new Date(
      orderDate.getTime() + Math.abs(daysRemaining) * 24 * 60 * 60 * 1000
    );

    return deadlineDate;
  }

  dayz(dataItem: any): number {
    const orderDate = new Date(dataItem.or_order_date);
    const daysRemaining = dataItem.or_days_remaining;
    const deadlineDate = new Date(
      orderDate.getTime() - daysRemaining * 24 * 60 * 60 * 1000
    );
    const today = new Date();
    const remainingDays = Math.floor(
      (deadlineDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
    );
    return !this.isExpired(dataItem.or_order_date)
      ? Math.abs(remainingDays)
      : remainingDays;
  }

  isNotificationSentToday(): boolean {
    // Recupera la data dell'ultima notifica inviata dal Local Storage
    const lastNotificationDate = localStorage.getItem('lastNotificationDate');
    if (lastNotificationDate) {
      // Verifica se la data dell'ultima notifica è uguale alla data odierna
      const today = new Date();
      const lastNotification = new Date(lastNotificationDate);
      return today.toDateString() === lastNotification.toDateString();
    }
    return false; // Se non è stata inviata alcuna notifica in precedenza, restituisce false
  }

  markNotificationAsSent(): void {
    // Memorizza la data dell'ultima notifica inviata nel Local Storage
    localStorage.setItem('lastNotificationDate', new Date().toISOString());
  }

  sendNotification(): void {
    // Invia la notifica
    alert("Notifica giornaliera: La data di scadenza dell'ordine è imminente!");
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    console.log('I am leaving assegna list');
    console.log('DEINE SPECIAL BOOLEAN IST', this.booleanForSpecialSearch);
    console.log('BOLID IS', this.booleanForSpecialSearch);
    console.log('DEINE SPECIAL BOOLEAN IST', this.booleanForSpecialSearch);
    if (!this.booleanForSpecialSearch) {
      localStorage.removeItem('query-search');
    }
    this.subscriber.forEach((subscriber) => subscriber.unsubscribe());
  }
}
