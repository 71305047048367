<div
  class="buttons-status k-display-flex justify-content-center pt-3"
  style="padding-bottom: 15px"
>
  <div class="button-right">
    <button
      class="btn btn3"
      rounded="full"
      style="
        background-color: #8d8d8d;
        color: #fff;
        top: 2px !important;
        justify-content: center !important;
        display: flex;
        width: 222px !important;
        height: 42px !important;
      "
      (click)="back()"
    >
      <i
        class="bx bx-left-arrow-circle bx-sm posRelBox2"
        style="
          color: #fff;
          top: 2px;
          right: 6px;
          bottom: 10px;
          position: relative;
        "
      ></i>
      <p class="posRelBox2">INDIETRO</p>
    </button>
  </div>

  <div class="buttons-status k-display-flex justify-content-center">
    <div class="button-right">
      <button
        *ngIf="!isDDT"
        class="btn btn3"
        color="red"
        rounded="full"
        id="present-alert"
        style="
          background-color: rgb(95, 234, 95) !important;
          color: #fff;
          top: 2px important;
          justify-content: center !important;
          display: flex !important;

          width: 222px !important;
          height: 42px !important;
        "
        (click)="synchronizeOrder(order.order_id, order.or_gamma_id)"
      >
        <i
          class="bx bx-loader-circle bx-sm posRelBox2"
          style="
            color: #fff;
            top: 2px important;
            right: 6px;
            bottom: 10px;
            position: relative;
          "
        ></i>
        <p class="posRelBox2">SINCRONIZZA</p>
      </button>
    </div>
    <div *ngIf="order && order?.status[0]?.os_status_order_cod == 100">
      <button
        (click)="continue()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #5ec186;
          color: #fff;
          top: 2px important;
          justify-content: center !important;
          display: flex;
          width: 222px !important;
          height: 42px !important;
        "
      >
        <i
          class="bx bx-play-circle bx-sm posRelBox2"
          style="
            color: #fff;
            top: 2px important;
            right: 6px;
            bottom: 10px;
            position: relative;
          "
        ></i>
        <p class="posRelBox2">CONTINUA</p>
      </button>
    </div>

    <div
      *ngIf="
            order &&
            order.status[0].os_status_order_cod !== 1 &&
            order.status[0].os_status_order_cod !== 11 &&
            order.status[0].os_status_order_cod !== 100 &&
            order.status[0].os_status_order_cod !== 101
          "
    >
      <button
        (click)="suspend()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #a90606;
          color: #fff;
          top: 2px important;
          justify-content: center !important;
          display: flex;
          width: 222px !important;
          height: 42px !important;
        "
      >
        <i
          class="bx bx-pause-circle bx-sm posRelBox2"
          style="
            color: #fff;
            top: 0px important;
            right: 6px;
            bottom: 10px;
            position: relative;
          "
        ></i>
        <p class="posRelBox2">SOSPENDI</p>
      </button>
    </div>

    <div
      *ngIf="
            order &&
            order.status[0].os_status_order_cod !== 11 &&
            order.status[0].os_status_order_cod !== 101
          "
    >
      <button
        (click)="block()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #000000;
          color: #fff;
          top: 2px important;
          justify-content: center !important;
          display: flex;
          width: 222px !important;
          height: 42px !important;
        "
        [disabled]="order && order.status[0].os_status_order_cod === 7"
      >
        <i
          class="bx bx-lock bx-sm posRelBox2"
          style="
            color: #fff;
            top: 0px important;
            right: 6px;
            bottom: 10px;
            position: relative;
          "
        ></i>
        <p class="posRelBox2">BLOCCA</p>
      </button>
    </div>
    <div *ngIf="order && order.status[0].os_status_order_cod === 101">
      <button
        (click)="unblock()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #ff8e0d;
          color: #fff;
          top: 2px important;
          justify-content: center !important;
          display: flex;
          width: 222px !important;
          height: 42px !important;
        "
      >
        <i
          class="bx bx-lock-open bx-sm posRelBox2"
          style="
            color: #fff;
            top: 0px important;
            right: 6px;
            bottom: 10px;
            position: relative;
          "
        ></i>
        <p class="posRelBox2">SBLOCCA</p>
      </button>
    </div>

    <button
      *ngIf="
            order &&
            order.status[0].os_status_order_cod > 1 &&
            order.status[0].os_status_order_cod == 5 &&
            order.status[0].os_status_order_cod < 100
          "
      [disabled]="order && order.status[0].os_status_order_cod < 5"
      (click)="done()"
      class="btn btn3"
      rounded="full"
      style="
        background-color: #017efa;
        color: #fff;
        top: 2px important;
        justify-content: center !important;
        display: flex;
        width: 222px !important;
        height: 42px !important;
      "
    >
      <i
        class="bx bx-badge-check bx-sm posRelBox2"
        style="
          color: #fff;
          top: 0px important;
          right: 6px;
          bottom: 10px;
          position: relative;
        "
      ></i>
      <p class="posRelBox2">COMPLETA</p>
    </button>
    <div class="k-display-flex justify-content-center">
      <button
        *ngIf="order && order.status[0].os_status_order_cod == 1"
        (click)="start()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #00c04d;
          color: #fff;
          top: 2px important;
          justify-content: center !important;
          display: flex;
          width: 222px !important;
          height: 42px !important;
        "
      >
        <i
          class="bx bx-run bx-sm posRelBox2"
          style="
            color: #fff;
            top: 0px important;
            right: 6px;
            bottom: 10px;
            position: relative;
          "
        ></i>
        <p class="posRelBox2">INIZIA</p>
      </button>
    </div>
  </div>
</div>
