import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { OrderErrors, Orders } from 'src/app/models/Orders.model';
import { Errors } from 'src/app/models/Orders.model';
import { Products } from 'src/app/models/Product.model';
import { Boxes } from 'src/app/models/Boxes.models';
import { Documents } from 'src/app/models/documents.models';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { ProductsService } from 'src/app/services/products.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-storico-ordini-dettagli',
  templateUrl: './storico-ordini-dettagli.component.html',
  styleUrls: ['./storico-ordini-dettagli.component.scss'],
})
export class StoricoOrdiniDettagliComponent implements OnInit {
  @Input() opened = false;
  @Input() order_id: string = '-1';
  @Input() code: any;
  @Input() isProdOpen = false;
  @Input() product_id: string = '-1';
  errorFormGroup!: FormGroup;

  public goBack = false;
  public dataSaved = false;
  public isEditable: boolean = false;
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 1356;
  public dialogHeight = 1017;
  public documents: Documents.Document[] = [];
  public order: Orders.IOrder;
  public product: Products.Product[] = [];
  public box: Boxes.BoxOrder[] = [];
  groupedBoxes: { ts_box_type_desc: string; quantity: number }[] = [];

  ordersDetailsForm!: FormGroup;
  formattedDate: string = '-';
  linkToDDT: string = null;
  isLinkToDDT: boolean = null;
  clickedProduct: any;
  or_url_box_label: string;
  pr_code: string;
  clickLabel: boolean = false;
  linkAllLabels: any;
  linkCarrier: any;
  linkBordero: any;
  errori: Errors = new Errors(null);
  selectedErrorType: string;
  selectedErrorDescription: string;
  values: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  errorsArray: OrderErrors[] = [];
  typesOfErrorsArray: any[] = [];
  ettArray: {
    scatola: string;
    property2: string;
    property3: string;
    onClick: (index: number) => void;
  }[] = [
    {
      scatola: 'Collo',
      property2: 'value2',
      property3: 'value3',
      onClick: (index) => this.triggerFunction(index),
    },
    {
      scatola: 'Corriere',
      property2: 'value5',
      property3: 'value6',
      onClick: (index) => this.triggerFunction(index),
    },
    {
      scatola: 'Bordero',
      property2: 'value5',
      property3: 'value6',
      onClick: (index) => this.triggerFunction(index),
    },
  ];

  isErrorModalOpen: boolean = false;
  isModifyModalOpen: boolean = false;
  urlServer: string = 'http://diakonix.connecta.ovh:88/api/services';
  environment = environment.urlServer;

  constructor(
    private ordersServ: OrdersService,
    private productServ: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public closeServ: OpenModalService,
    private popoverController: PopoverController,
    private alertController: AlertController
  ) {}

  ngOnInit(): void {
    this.environment === this.urlServer
      ? console.log('Esti boss')
      : console.log('Nu esti boss');
    this.reloadFunction();
    this.groupBoxes();
  }

  reloadFunction() {
    if (this.environment === this.urlServer) {
      this.errorFormGroup = this.formBuilder.group({
        order_id: [''],
        oe_error_type_id: [''],
        oe_error_gravity: [''],
        oe_description: [''],
        order_error_type_description: [''],
        order_error_id: [''],
      });
      this.ordersServ.getTypesOfOrderErrors().subscribe((data) => {
        this.typesOfErrorsArray = data;
        console.log('Types of arrays are', this.typesOfErrorsArray);
      });
      this.closeServ.openError.subscribe((isOpen) => {
        this.isErrorModalOpen = isOpen;
        console.log('BOSS');
      });
      this.closeServ.modifyError.subscribe((isOpen) => {
        this.isModifyModalOpen = isOpen;
        console.log('Zeu');
      });

      this.ordersServ.getOrderErrors(+this.order_id).subscribe((data) => {
        if (!data) return;
        console.log('Errors are', data);
        this.errorsArray = data;
      });
      this.errorFormGroup.setValue({
        order_id: [''],
        oe_error_type_id: [''],
        oe_error_gravity: [''],
        oe_description: [''],
        order_error_type_description: [''],
        order_error_id: [''],
      });
    }

    this.ordersDetailsForm = this.formBuilder.group({
      or_assignment_date: [''],
      or_order_number: [''],
      or_customer: [''],
      or_agent_id: [''],
      or_shipping_address: [''],
      or_closing_date: [''],
      vs_details: [''],
      carrier_desc: [''],
      ac_first_name: [''],
      ac_last_name: [''],
      boxes: [''],
      op_articles_number: [''],
      op_address: [''],
      op_city: [''],
      or_shipping_zip: [''],
      op_prov: [''],
      or_shipping_country: [''],
    });

    this.ordersServ.getOrder1(+this.order_id).subscribe((orderData) => {
      this.order = orderData;
      this.product = orderData.products;
      this.box = orderData.boxes;
      this.or_url_box_label = orderData.or_url_box_label;
      this.linkCarrier = orderData?.shipment.os_label_url[0]?.sm_label_link;
      this.linkBordero = orderData.or_url_box_label;
      this.isLinkToDDT = this.order.or_url_ddt?.indexOf('.pdf') > 0;
      this.linkToDDT = this.isLinkToDDT ? this.order.or_url_ddt : null;
      this.ordersServ.getDocuments(+this.order_id).subscribe(async (result) => {
        this.documents = result;
        await this.ordersServ
          .getOrderDocuments(this.order_id)
          .then((result) => {
            if (result) this.documents = result;
            // this.printAllLabels();
          });
      });
      if (this.order.or_assignment_date)
        this.formattedDate = formatDate(
          this.order.or_assignment_date,
          'dd/MM/yyyy',
          'it'
        );

      this.ordersDetailsForm.setValue({
        or_assignment_date: this.formattedDate,
        or_order_number: this.order.or_order_number,
        or_closing_date: this.order.or_closing_date,
        or_agent_id: this.order.or_agent_id,
        or_customer: this.order.or_customer,
        or_shipping_address: this.order.or_shipping_address,
        vs_details: this.order.vs_details.tab_order_priority_desc,
        carrier_desc: this.order.vs_details.carrier_desc,
        ac_first_name: this.order.vs_details.ac_first_name,
        ac_last_name: this.order.vs_details.ac_last_name,
        boxes: this.order.boxes,
        op_articles_number: this.order.vs_details.op_articles_number,
        op_address: this.order.or_shipping_address,
        op_city: this.order.or_shipping_city,
        or_shipping_zip: this.order.or_shipping_zip,
        op_prov: this.order.or_shipping_prov,
        or_shipping_country: this.order.or_shipping_country,
      });
    });
  }

  goEditFunction() {
    this.isEditable = !this.isEditable;
  }

  productDetails(productId: any) {
    console.debug('Merge?', productId);
    this.productServ
      .getProduct(this.code)
      .subscribe((productData: IWsResult) => {
        this.product = productData.data;
      });
    this.clickedProduct = productId;
    this.closeServ.openProd();
  }

  close() {
    this.closeServ.offStorico();
  }

  goToProductDetails(pr_code) {
    this.pr_code = pr_code;
    console.log('Product ID is: ', pr_code);
    this.closeServ.onStoricoProd();
  }

  groupBoxes(): void {
    const boxMap = new Map<string, number>();
    this.box.forEach((order) => {
      const currentQuantity = boxMap.get(order.ts_box_type_desc) || 0;
      boxMap.set(order.ts_box_type_desc, currentQuantity + 1);
    });
    this.groupedBoxes = Array.from(boxMap.entries()).map(
      ([ts_box_type_desc, quantity]) => ({ ts_box_type_desc, quantity })
    );
  }

  triggerFunction(index: number): void {
    const clickedScatola = this.ettArray[index].scatola;
    switch (clickedScatola) {
      case 'Scatola':
        this.printAllLabels();
        break;
      case 'Corriere':
        this.printCarrier();
        break;
      case 'Bordero':
        this.printBordero();
        break;
      default:
        break;
    }
  }

  printAllLabels() {
    this.ordersServ
      .getAllBoxLabels(
        this.order.order_id,
        this.order.boxes.map((box, index) => index + 1)
      )
      .subscribe((result) => {
        console.debug('result', result);
        if (!result) return;

        this.linkAllLabels = result.box_label_link_pdf;
        console.log('This LinkAllLabels: ', this.linkAllLabels);
      });
  }

  printDDT() {
    const link = this.order?.or_url_ddt;
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.click();
    console.log('DDT link is: ', link);
  }

  printCarrier() {
    const link = this.order?.shipment.os_label_url[0]?.sm_label_link;
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.click();
    console.log('Carrier link is: ', link);
  }

  printBordero() {
    const link = this.or_url_box_label;
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.click();
    console.log('printBordero is: ', link);
  }

  setErrors(error: any) {
    console.log('Error is: ', error);
    this.errori.err_type = error;
    const isErrorValid = this.validateError(error);
    if (isErrorValid) {
      this.ordersServ.setErrors(this.errori).subscribe(async (data: any) => {
        console.log('SUCCESS!');

        // const alert = await this.alertController.create({
        //   header: 'ERROR!!',
        //   message:
        //     "Un'altra persona potrebbe averlo generato nello stesso tempo o il server è occupato",
        //   buttons: [
        //     {
        //       text: 'OK',
        //       handler: () => {
        //         // Check if popoverController is defined before calling dismiss
        //         if (this.popoverController) {
        //           this.popoverController.dismiss();
        //         }
        //       },
        //     },
        //   ],
        // });

        // await alert.present();

        // Swal.fire({
        //   icon: 'success',
        //   title: 'Aggiornato!',
        //   text: 'Errore impostato con successo!!!',
        //   timer: 1500,
        //   timerProgressBar: true,
        // });
        console.log(data);
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Errore!',
        text: 'Errore non impostato, riprova!',
        timer: 3500,
        timerProgressBar: true,
      });
    }
  }

  validateError(error: number): boolean {
    if (error >= 0) return true;
    return false;
  }

  async addError(order_id: number) {
    // this.closeServ.close();
    // this.opened = !this.opened;
    order_id = +this.order_id;
    this.closeServ.openErrorModal();
    //  const popover = await this.popoverController.create({
    //    component: AssignItemComponent,
    //    componentProps: {
    //      itemSelected: item,
    //    },
    //    cssClass: 'color-black assign-popover',
    //  });
    //  console.log('ItemId is', item);
    //  await popover.present();
    //  return await popover.onDidDismiss();
  }

  errorChange: any;

  public valueChange(value: any): void {
    console.log('valueChange', value);
    this.errorChange = value;
  }

  public selectionChange(value: any): void {
    console.log('selectionChange', value);
  }

  enableModifyError(error: any) {
    this.errorChange = error;
    console.log('error to be modified is', error);
    this.closeServ.openModifyError();
  }

  deleteError2(error_order_id) {
    this.ordersServ.deleteError(error_order_id).subscribe(async (data) => {
      if (!data) {
        Swal.fire({
          title: 'Non eliminato!',
          text: 'Errore non eliminato, riprova!',
          icon: 'error',
          timer: 1500,
          timerProgressBar: true,
        });
        /*const alert = await this.alertController.create({
          header: 'Errore!!',
          message: 'Errore non eliminato, riprova!',
          buttons: [
            {
              text: 'OK',
              handler: () => {
                // Check if popoverController is defined before calling dismiss
                if (this.popoverController) {
                  this.popoverController.dismiss();
                }
              },
            },
          ],
        });*/
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Errore!',
        //   customClass: {
        //     popup: 'custom-popup-class',
        //   },
        //   text: 'Errore non eliminato, riprova!',
        //   timer: 3500,
        //   timerProgressBar: true,
        // });
        return;
      } else if (data) {
        Swal.fire({
          title: 'Eliminato!',
          text: 'Errore eliminato con successo!!!',
          icon: 'success',
          timer: 1500,
          timerProgressBar: true,
        });
        const alert = await this.alertController.create({
          header: 'Eliminato!!',
          message: 'Errore eliminato con successo!!!!',
          buttons: [
            {
              text: 'OK',
              handler: () => {
                // Check if popoverController is defined before calling dismiss
                if (this.popoverController) {
                  this.popoverController.dismiss();
                }
              },
            },
          ],
        });
        await alert.present();
        console.log('Data', data);
        // Swal.fire({
        //   icon: 'success',
        //   customClass: {
        //     popup: 'custom-popup-class',
        //   },
        //   title: 'Successo!',
        //   text: 'Errore eliminato con successo!!!!',
        //   timer: 1500,
        //   timerProgressBar: true,
        // });
        this.reloadFunction();
      }
    });
  }

  async deleteError(error_order_id) {
    try {
      const data = await this.ordersServ
        .deleteError(error_order_id)
        .toPromise();
      if (!data) {
        await this.showSwalError();
        return;
      }
      await this.showSwalSuccess();
      //await this.showAlertSuccess();
      console.log('Data', data);
      this.reloadFunction();
    } catch (error) {
      console.error("Errore durante l'eliminazione:", error);
    }
  }

  async showSwalError() {
    Swal.fire({
      title: 'Non eliminato!',
      text: 'Errore non eliminato, riprova!',
      icon: 'error',
      timer: 1500,
      timerProgressBar: true,
    });
    const swalContainer = document.querySelector(
      '.swal2-container'
    ) as HTMLElement;
    if (swalContainer) {
      swalContainer.style.zIndex = '10000';
    }
  }

  async showSwalSuccess() {
    Swal.fire({
      title: 'Eliminato!',
      text: 'Errore eliminato con successo!!!',
      icon: 'success',
      timer: 1500,
      timerProgressBar: true,
    });
    const swalContainer = document.querySelector(
      '.swal2-container'
    ) as HTMLElement;
    if (swalContainer) {
      swalContainer.style.zIndex = '10000';
    }
  }

  async showAlertSuccess() {
    const alert = await this.alertController.create({
      header: 'Eliminato!!',
      message: 'Errore eliminato con successo!!!!',
      buttons: [
        {
          cssClass: 'alert-btn',
          text: 'SALVA',
          handler: () => {
            // Check if popoverController is defined before calling dismiss
            if (this.popoverController) {
              this.popoverController.dismiss();
            }
          },
        },
      ],
    });
    await alert.present();
  }

  ngOnDestroy(): void {
    console.log('Sunt distrus');
  }
}
