<div style="display: flex; flex-direction: column; justify-content: center">
  <h1 class="page-title">Assegna Ordine</h1>
  <form
    action=""
    class="search-bar text-center"
    style="
      display: flex !important;

      justify-content: center !important;
      text-align: center !important;
      width: 200%;
    "
  >
    <div class="boss mx-2">
      <h5 class="col-header">
        Totale Imponibile:<br />
        <p *ngIf="!loadingForObj" style="padding: 0; margin: 0 !important">
          {{ netValue | currency : "EUR" : "symbol" : "1.2-2" }}
        </p>
        <div
          class="spinnerContainer"
          style="display: flex; justify-content: center"
        >
          <mat-spinner *ngIf="loadingForObj" [diameter]="50"></mat-spinner>
        </div>
      </h5>

      <div
        class="progress-bar-div"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          align-content: space-around;
          align-items: center;
        "
      >
        <!--<kendo-progressbar
          [value]="100"
          [label]="setPercentLabel"
          style="width: 200px"
        ></kendo-progressbar>-->
      </div>
    </div>
    <div class="boss mx-2">
      <h5 class="col-header">
        Obiettivo Mese:<br />
        <p *ngIf="!loadingForObj" style="padding: 0; margin: 0 !important">
          {{ objectives.monthly_total | currency : "EUR" : "symbol" : "1.2-2" }}
        </p>
        <div
          class="spinnerContainer"
          style="display: flex; justify-content: center"
        >
          <mat-spinner *ngIf="loadingForObj" [diameter]="50"></mat-spinner>
        </div>
      </h5>
      <div
        class="progress-bar-div"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          align-content: space-around;
          align-items: center;
        "
      >
        <kendo-progressbar
          *ngIf="!loadingForObj"
          [value]="+objectives.monthly_percentage"
          [label]="setPercentLabel"
          style="width: 200px"
        ></kendo-progressbar>
      </div>
    </div>

    <div class="boss mx-2">
      <h5 class="col-header">
        Obiettivo giorno:<br />
        <p *ngIf="!loadingForObj" style="padding: 0; margin: 0 !important">
          {{ objectives.daily_total | currency : "EUR" : "symbol" : "1.2-2" }}
        </p>
        <div
          class="spinnerContainer"
          style="display: flex; justify-content: center"
        >
          <mat-spinner *ngIf="loadingForObj" [diameter]="50"></mat-spinner>
        </div>
      </h5>
      <div
        class="progress-bar-div"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          align-content: space-around;
          align-items: center;
        "
      >
        <kendo-progressbar
          *ngIf="!loadingForObj"
          [value]="+objectives.daily_percentage"
          [label]="setPercentLabel"
          style="width: 200px"
        ></kendo-progressbar>
      </div>
    </div>
  </form>
</div>

<!--<app-fiesta></app-fiesta>-->

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: space-between"
>
  <form
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <div
      class="inputSearchContainer"
      style="display: flex; flex-direction: column; align-items: flex-end"
    >
      <i
        *ngIf="filterTerm"
        class="bx bxs-x-circle bx-md clearSearchIcon"
        style="
          transform: scale(0.8);
          color: red;
          /* background: #0c65e9; */
          position: relative;
          top: 42px;
          z-index: 10001;
        "
        (click)="clearSearch()"
      ></i>
      <input
        [(ngModel)]="filterTerm"
        (ngModelChange)="handleFilterChange()"
        id="search"
        name="search"
        pattern=".*\S.*"
        type="search"
        placeholder="Cerca ordine..."
        autofocus
        required
        (input)="this.onInputChanged($event)"
      />
    </div>
    <button type="submit" (click)="searchOrders()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
          color: red;
          background: #0c65e9;
        "
      >
      </i>
    </button>
  </form>
  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      text-align: center !important;
    "
    *ngIf="obiettivi"
  >
    <h3 class="col-header">
      Obiettivo giorno:<br />
      {{ obiettivi.tp_daily_objective | currency : "EUR" : "symbol" : "1.2-2" }}
    </h3>
    <h3 class="col-header">
      Obiettivo mese:<br />
      {{
        obiettivi.tp_monthly_objective | currency : "EUR" : "symbol" : "1.2-2"
      }}
    </h3>
  </form>
  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
  </div>
</div>

<hr class="hr-newBlue" />

<div class="row k-display-flex justify-content-end" style="padding-left: 20px">
  <div
    style="display: flex; flex-direction: row; justify-content: center"
    *ngIf="!loading"
  >
    <div class="k-display-flex flex flex-row" style="align-items: center">
      <h2 class="col-header" style="margin-bottom: 0">
        Totale ordini da Assegnare: {{ gridData?.length }}
      </h2>
      <h2 class="col-header" style="margin-bottom: 0">
        Totale ordini Parziali: {{ ordiniParziali.length }}
      </h2>

      <i
        class="bx bxs-circle bx-md"
        style="
          color: #8700f5;
          justify-content: end;
          text-align: right;
          display: flex;
          width: 30px;
          cursor: pointer;
          color: #8700f5;
          background: #8700f5;
          text-align: right;
          border-radius: 70px;
          height: 30px;
          padding-left: 0px;
        "
        title="Parziali"
      ></i>

      <div class="deleteButton">
        <i
          class="bx bx-refresh bx-md"
          style="
            cursor: pointer;
            color: rgb(0, 52, 97);
            justify-content: end;
            text-align: right;
          "
          (click)="reloadFunction()"
          title="Aggiorna ordine"
        ></i>
      </div>
    </div>
  </div>

  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <div class="grid-wrapper" *ngIf="!loading">
    <kendo-grid
      [kendoGridBinding]="
        gridData
          | byToDate
            : [
                filters.startingDate,
                filters.endDate,
                filters.dateOrginizedBy.value
              ]
      "
      [skip]="skip"
      [rowHeight]="36"
      [height]="750"
      [pageSize]="pageSize"
      scrollable="virtual"
      (pageChange)="pageChange($event)"
      [navigable]="true"
      [sortable]="true"
      class="maxHeight"
      [reorderable]="true"
      [rowClass]="rowCallback"
    >
      <kendo-grid-column
        [width]="100"
        field="or_order_number"
        title="N° Ordine"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
      </kendo-grid-column>

      <kendo-grid-column
        [width]="390"
        field="or_customer"
        title="Cliente"
        [headerStyle]="{ 'justify-content': 'left' }"
        [style]="{
          'justify-content': 'left',
          'text-align': 'left'
        }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_customer }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="100"
        field="or_net_value"
        title="Totale Importo"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_net_value | currency : "EUR" : "symbol" : "1.2-2" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="100"
        field="or_articles_number"
        title="N° Articoli"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_articles_number }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="120"
        field="or_order_date"
        title="Data ordine"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column
        [width]="100"
        title="Assegna"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0"
            (click)="gotoDetails(dataItem.or_gamma_id)"
          >
            <div class="setDisplayToFlex">
              <i
                class="bx bx-edit bx-md"
                style="margin: 0; padding: 0; transform: scale(0.8)"
              >
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-column
        [width]="120"
        field="or_status"
        title="Blocca/Sblocca"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
        [sortable]="true"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="
              dataItem?.or_status === 'B' ? unblock(dataItem) : block(dataItem)
            "
            title="{{ dataItem?.or_status === 'B' ? 'Sblocca' : 'Blocca' }}"
          >
            <div
              class="{{
                dataItem?.or_status === 'B'
                  ? 'setDisplayToFlex btn-sblocca'
                  : 'setDisplayToFlex btn-blocca'
              }}"
            >
              <i
                [class]="
                  dataItem?.or_status === 'B'
                    ? 'bx bx-lock bx-md'
                    : 'bx bx-lock-open bx-md'
                "
                style="margin: 0; padding: 0; transform: scale(0.8)"
              ></i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridNoRecordsTemplate>
        <div class="no-records-message">
          <p>Nessun dato disponibile</p>
        </div>
      </ng-template>
    </kendo-grid>

    <div *ngIf="loading" class="k-i-loading"></div>
  </div>
</div>
