import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { IWsResult } from 'src/app/models/ws_result.model';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  url: string = environment.urlServer;

  constructor(
    private http: HttpClient,
    private errorHandlerServ: ErrorHandlerService
  ) {}

  getStatisticOrderClosed(id: string | number) {
    return this.http
      .get<IWsResult>(`${this.url}/get_stat_closed_orders/${id}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getStatsOrderClosed')
        )
      );
  }
  getAverageClosedOrdersStat(id: string | number) {
    return this.http
      .get<IWsResult>(`${this.url}/get_stat_average_closed_orders/${id}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getStatsOrderClosed')
        )
      );
  }
  getAverageShipmentsStat(id: string | number) {
    return this.http
      .get<IWsResult>(`${this.url}/get_stat_average_shipments/${id}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getStatsOrderClosed')
        )
      );
  }
  getBoxesStatistic(id: string | number) {
    return this.http
      .get<IWsResult>(`${this.url}/get_stat_boxes_delivered/${id}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getStatsOrderClosed')
        )
      );
  }

  getCompletedOrdersByCarrier(id) {
    return this.http
      .get<IWsResult>(`${this.url}/get_completed_orders_by_carrier/${id}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>(
            'getCompletedOrdersByCarrier'
          )
        )
      );
  }

  getCompletedOrdersByOperator(id) {
    return this.http
      .get<IWsResult>(
        `${this.url}/get_monthly_completed_orders_by_operator/${id}`
      )
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>(
            'getCompletedOrdersByOperator'
          )
        )
      );
  }

  getCompletedOrdersByOperatorNoMonth(id) {
    return this.http
      .get<IWsResult>(`${this.url}/get_completed_orders_by_operator/${id}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>(
            'getCompletedOrdersByOperator'
          )
        )
      );
  }

  getOrdersDividedByCarrier(id) {
    return this.http
      .get<IWsResult>(
        `${this.url}/get_monthly_completed_orders_by_carrier/${id}`
      )
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>(
            'getOrdersDividedByCarrier'
          )
        )
      );
  }

  getGeneralStatistics(id) {
    return this.http
      .get<IWsResult>(`${this.url}/get_stat_general_percentages/${id}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getGeneralStatistics')
        )
      );
  }
}
