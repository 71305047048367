<h1 class="page-title">Lista Agenti</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <form
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <div
      class="inputSearchContainer"
      style="display: flex; flex-direction: column; align-items: flex-end"
    >
      <i
        *ngIf="filterTerm"
        class="bx bxs-x-circle bx-md clearSearchIcon"
        style="
          transform: scale(0.8);
          color: red;
          /* background: #0c65e9; */
          position: relative;
          top: 42px;
          z-index: 10001;
        "
        (click)="clearSearch()"
      ></i>
      <input
        [(ngModel)]="filterTerm"
        id="search"
        name="search"
        pattern=".*\S.*"
        type="search"
        placeholder="Cerca agente..."
        autofocus
        required
        (input)="this.onInputChanged($event)"
      />
    </div>
    <button type="submit" (click)="searchAgents()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>
</div>

<hr class="hr-newBlue" />

<div class="container" style="padding-left: 20px">
  <div style="display: flex; flex-direction: row; justify-content: center">
    <h2 class="col-header">Totale agenti: {{ agentsData.length }}</h2>
  </div>

  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <div class="container" *ngIf="agentsData && !loading">
    <div class="grid-wrapper">
      <kendo-grid
        [kendoGridBinding]="agentsData"
        [rowHeight]="36"
        [height]="750"
        [pageSize]="pageSize"
        scrollable="virtual"
        [navigable]="true"
        [sortable]="true"
        [reorderable]="true"
      >
        <kendo-grid-column
          [width]="180"
          field="ag_last_name"
          title="Nome agente"
          [headerStyle]="{ 'justify-content': 'left' }"
          [style]="{
            display: 'grid',
            'justify-content': 'left',
            'tetx-align': 'left'
          }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.ag_last_name }} {{ dataItem.ag_first_name }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-command-column
          title="Copia URL"
          [width]="100"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'tetx-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              kendoGridEditCommand
              rounded="full"
              class="btn-det"
              [disabled]="!dataItem.ag_url"
              style="margin: 0; padding: 0"
              (click)="copyToClipboard(dataItem.ag_url, dataItem)"
            >
              <div class="setDisplayToFlexBlue btn-copia">
                <i
                  class="bx bx-copy bx-md"
                  style="margin: 0; padding: 0; transform: scale(0.8)"
                >
                </i>
                <p class="parButton2"></p>
              </div>
            </button>
          </ng-template>
        </kendo-grid-command-column>

        <kendo-grid-command-column
          title="Genera URL"
          [width]="100"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'tetx-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              kendoGridEditCommand
              rounded="full"
              class="btn-det"
              style="margin: 0; padding: 0"
              *ngIf="!dataItem.ag_url"
              (click)="generateUrl(dataItem.agent_id)"
            >
              <div class="setDisplayToFlexGold btn-genera">
                <i
                  class="bx bx-link bx-md"
                  style="margin: 0; padding: 0; transform: scale(0.8)"
                >
                </i>
                <p class="parButton2"></p>
              </div>
            </button>
            <button
              kendoGridEditCommand
              rounded="full"
              class="btn-det"
              style="margin: 0; padding: 0"
              *ngIf="dataItem.ag_url"
              (click)="deleteURL(dataItem.agent_id)"
            >
              <div class="setDisplayToFlexRed btn-elimina">
                <i
                  class="bx bx-trash-alt bx-md"
                  style="margin: 0; padding: 0; transform: scale(0.8)"
                >
                </i>
                <p class="parButton2"></p>
              </div>
            </button>
          </ng-template>
        </kendo-grid-command-column>

        <kendo-grid-command-column
          title="Dettagli"
          [width]="100"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'tetx-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              kendoGridEditCommand
              rounded="full"
              class="btn-det"
              style="
                margin: 0;
                padding: 0;
                background-color: #017efa !important;
              "
              (click)="goToDetails(dataItem.agent_id, dataItem.ag_url)"
            >
              <div class="setDisplayToFlexBlue btn-det">
                <i
                  class="bx bx-info-circle bx-md"
                  style="margin: 0; padding: 0"
                >
                </i>
                <p class="parButton2"></p>
              </div>
            </button>
          </ng-template>
        </kendo-grid-command-column>

        <ng-template kendoGridNoRecordsTemplate>
          <div class="no-records-message">
              <p>Nessun dato disponibile</p>
          </div>
        </ng-template>
      </kendo-grid>
    </div>
  </div>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>

<app-ordini-agente-list
  *ngIf="closeServ.isOrderOpen"
  [openOrders]="closeServ.isOrderOpen"
  [agent_token]="ag_token"
>
</app-ordini-agente-list>

<app-funzionari-dettagli
  *ngIf="closeServ.isDetAgent"
  [opened]="closeServ.isDetAgent"
  [id]="clickedID"
  [ag_token]="ag_token"
  (modalClosed1)="this.getAgents()"
>
</app-funzionari-dettagli>
