<div class="order-progress-container">
  <div
      *ngFor="let item of steps; let i = index"
      class="smecher1"
      [class.smecher1Color]="
        order &&
        order.status[0].os_status_order_cod != 10 &&
        order.status[0].os_status_order_cod < item.index &&
        order.status[0].os_status_order_cod != 100 &&
        order.status[0].os_status_order_cod !== 101
      "
      [class.smecher1ColorConfirm]="
        order &&
        order.status[0].os_status_order_cod != 10 &&
        order.status[0].os_status_order_cod > item.index &&
        order.status[0].os_status_order_cod != 100 &&
        order.status[0].os_status_order_cod !== 101
      "
      [class.smecher1ColorNext]="
        order && order.status[0].os_status_order_cod == item.index
      "
    >
      <div
        class="buttonCircle"
        [class.buttonCircleConfirm]="
          order &&
          order.status[0].os_status_order_cod != 10 &&
          order.status[0].os_status_order_cod > item.index &&
          order.status[0].os_status_order_cod != 100 &&
          order.status[0].os_status_order_cod !== 101
        "
        [class.buttonCircleNext]="
          order && order.status[0].os_status_order_cod == item.index
        "
      >
        <h1
          class="numberStyle"
          [class.numberStyleConfirm]="
            order &&
            order.status[0].os_status_order_cod != 10 &&
            order.status[0].os_status_order_cod > item.index &&
            order.status[0].os_status_order_cod != 100 &&
            order.status[0].os_status_order_cod !== 101
          "
          [class.numberStyleNext]="
            order && order.status[0].os_status_order_cod == item.index
          "
          style="text-align: center"
        >
          {{ item.index }}
        </h1>
      </div>

      <div class="setFirstDiv">
        <div
          class="row"
          style="display: flex; flex-direction: column; width: 400px"
        ></div>
      </div>

      <div class="setFirstDiv">
        <div class="row" style="display: flex; flex-direction: column">
          <h1
            class="buttonStyleText"
            [class.numberStyleConfirm]="ordersServ.pages.value > i"
            [class.numberStyleNext]="ordersServ.pages.value === i"
            style="text-align: center"
          >
            {{ item.title }}
          </h1>

          <div
            class="col mt-2"
            *ngIf="
              order &&
              order.status[0].os_status_order_cod != 10 &&
              order.status[0].os_status_order_cod > item.index &&
              order.status[0].os_status_order_cod != 100
            "
          ></div>

          <button
            (click)="item.function()"
            class="buttonBorderStyle"
            [class.buttonBorderStyleConfirm]="ordersServ.pages.value > i"
            [class.buttonBorderStyleNext]="ordersServ.pages.value === i"
          >
            <!-- [disabled]="ordersServ.pages.value < i" -->
            <h1
              class="h1StyleText"
              [class.numberStyleConfirm]="ordersServ.pages.value > i"
              [class.numberStyleNext]="ordersServ.pages.value === i"
              style="text-align: center; padding-top: 5px"
            >
              {{ item.buttonText }}
            </h1>
          </button>
        </div>
      </div>
    </div>

</div>