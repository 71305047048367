<h1 class="page-title">Ordini PM</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <form
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <div
      class="inputSearchContainer"
      style="display: flex; flex-direction: column; align-items: flex-end"
    >
      <i
        *ngIf="filterTerm"
        class="bx bxs-x-circle bx-md clearSearchIcon"
        style="
          transform: scale(0.8);
          color: red;
          /* background: #0c65e9; */
          position: relative;
          top: 42px;
          z-index: 10001;
        "
        (click)="clearSearch()"
      ></i>
      <input
        [(ngModel)]="filterTerm"
        (ngModelChange)="handleFilterChange()"
        id="search"
        name="search"
        pattern=".*\S.*"
        type="search"
        placeholder="Cerca ordine..."
        autofocus
        required
        (input)="this.onInputChanged($event)"
      />
    </div>
    <button type="submit" (click)="searchOrders()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      visibility: hidden !important;
    "
  >
    <button
      class="btn btn-primary msg-btn"
      routerLink="/new-msg"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        visibility: hidden !important;
      "
    >
      + Messaggio
    </button>
  </form>

  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
  </div>
</div>

<hr class="hr-newBlue" />

<div style="display: flex; flex-direction: row; justify-content: center">
  <h2 class="col-header">
    Totale ordini PM: {{ gridData.length }}
  </h2>
</div>

<div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>
<!-- 
<div
  class="row k-display-flex justify-content-end align-items-center"
  style="padding-left: 20px"
>
  <app-base-orderscm-list
    [data]="gridData"
    [filters]="filters"
    [filterTerm]="filterTerm"
  ></app-base-orderscm-list>
</div> -->
<div class="grid-wrapper">
  <kendo-grid
    [kendoGridBinding]="
      gridData
        | byToDate
          : [
              filters.startingDate,
              filters.endDate,
              filters.dateOrginizedBy.value
            ]
    "
    [rowHeight]="36"
    [height]="750"
    [pageSize]="pageSize"
    scrollable="virtual"
    (pageChange)="pageChange($event)"
    [navigable]="true"
    [sortable]="true"
    [reorderable]="true"
  >
    <kendo-grid-column
      [width]="100"
      field="or_order_number"
      title="N° Ordine"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
    </kendo-grid-column>

    <kendo-grid-column
      [width]="600"
      field="or_customer"
      title="Cliente"
      [headerStyle]="{ 'justify-content': 'left' }"
      [style]="{
        display: 'grid',
        'justify-content': 'left',
        'tetx-align': 'left'
      }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_customer }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="120"
      field="or_days_remaining"
      title="Data di scadenza"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'text-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_days_remaining + " " + "giorni" }}
        <span [ngClass]="{ scaduto: !isExpired(dataItem.or_order_date) }">
          ({{ deadline(dataItem) | date : "dd/MM/yyyy" }})
        </span>
        <div class="progress-bar-container">
          <div
            class="progress-bar"
            [style.width.%]="progress(dataItem.or_order_date)"
            [ngClass]="{
              'progress-bar-green':
                isMonthComplete(dataItem.or_days_remaining) &&
                isExpired(dataItem.or_order_date),
              'progress-bar-red': !isExpired(dataItem.or_order_date)
            }"
          ></div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="120"
      field="or_order_date"
      title="Data ordine"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="100"
      field="or_articles_number"
      title="N° Articoli"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_articles_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column
      title="Assegna"
      [width]="100"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoGridEditCommand
          rounded="full"
          class="btn-det"
          style="margin: 0; padding: 0"
          (click)="assignPM(dataItem.or_gamma_id)"
        >
          <div class="setDisplayToFlex">
            <i
              class="bx bx-edit bx-md"
              style="margin: 0; padding: 0; transform: scale(0.8)"
            >
            </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-command-column>

    <ng-template kendoGridNoRecordsTemplate>
      <div class="no-records-message">
        <p>Nessun dato disponibile</p>
      </div>
    </ng-template>
  </kendo-grid>
</div>
