import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ByNamePipe } from './by-name.pipe';
import { MonthPipe } from './month.pipe';

@NgModule({
  exports: [MonthPipe],
  declarations: [MonthPipe],
  imports: [CommonModule],
})
export class MonthModule {
  static forRoot() {
    return {
      ngModule: MonthModule,
      providers: [],
    };
  }
}
