import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { byBorderoPipe } from './by-bordero.pipe';

@NgModule({
  exports: [byBorderoPipe],
  declarations: [byBorderoPipe],
  imports: [CommonModule],
})

export class ByBorderoModule {
  static forRoot() {
    return {
      ngModule: ByBorderoModule,
      providers: [],
    };
  }

}
