<div class="k-overlay dark-background" *ngIf="opened"></div>

<kendo-dialog
  title=""
  *ngIf="opened"
  (close)="close()"
  [minWidth]="minWidth"
  [width]="dialogWidth"
  [minHeight]="500"
  [maxWidth]="1150"
  class="k-form"
  [formGroup]="ordersDetailsForm"
  style="left: 6%;"
>
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: -15px;
      z-index: 1000;
      position: fixed;
      margin-left: -35px;
    "
  >
    <button
      (click)="close()"
      class="btn btn3"
      rounded="full"
      style="
        background-color: #e90000;
        color: #ffffff;
        width: 35px !important;
        height: 35px !important;
      "
    >
      <i
        class="bx bx-x bx-sm posRelBox2"
        style="
          color: #ffffff;
          bottom: 10px;
          position: relative !important;
          left: -80% !important;
          top: -1px !important;
        "
      ></i>
    </button>
  </div>
  <h1 class="page-title">
    {{
      !isEditable ? "Dettagli Ordine Completato" : "Modifica Ordine Completato"
    }}
  </h1>
  <hr class="hr-dialog" />
  <div *ngIf="order" class="container container-ordine">
    <form class="k-form dialog-form marget" [formGroup]="ordersDetailsForm">

      <div class="row det mb-2">
        <div class="col-md setDisplay-Prep" *ngIf="order">
          <kendo-label class="title" text="N° Ordine"></kendo-label>
          <input
            #or_order_number
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="order.or_order_number"
            class="setBorder-Prepp"
          />        </div>

        <div class="col-md setDisplay-Prep">
          <kendo-label class="title" text="Data ordine"></kendo-label>
          <input
            #or_order_date
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="order.or_assignment_date | date : 'dd/MM/yyyy'"
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label class="title" text="Data evasione"></kendo-label>
          <input
            #or_quote_date
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="order.or_closing_date | date : 'dd/MM/yyyy'"
            class="setBorder-Prepp"
          />
        </div>
      </div>

      <div class="row det mb-2">
        <div class="col-md setDisplay-Prep">
          <kendo-label class="title" text="N° Pezzi"></kendo-label>
          <input
            #boxes
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            placeholder="{{ order.products.length }}"
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label
            [for]="boxes"
            class="title"
            text="N° Colli"
          ></kendo-label>
          <input
            #boxes
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            placeholder="{{ order.boxes.length }}"
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label class="title" text="Corriere"></kendo-label>
          <input
            #carrier_desc
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            placeholder="{{ order.vs_details.carrier_desc }}"
            class="setBorder-Prepp"
          />
        </div>
      </div>

      <div class="row det mb-2">
        <div class="col-md setDisplay-Prep">
          <kendo-label class="title" text="Cliente"></kendo-label>
          <input
            #or_customer
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="order.or_customer"
            class="setBorder-Prepp"
          />
        </div>
      </div>

      <div class="row det mb-2">
        <div class="col-md setDisplay-Prep">
          <kendo-label class="title" text="Indirizzo"></kendo-label>
          <input
            #or_shipping_address
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="
              order.or_shipping_address
                ? order.or_shipping_address
                : 'Indirizzo'
            "
            class="setBorder-Prepp"
          />
        </div>
      </div>

      <div class="row det">
        <div class="col-md setDisplay-Prep">
          <kendo-label class="title" text="Città"></kendo-label>
          <input
            #or_shipping_city
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="
              order.or_shipping_city ? order.or_shipping_city : 'Città'
            "
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label class="title" text="CAP"></kendo-label>
          <input
            #or_shipping_zip
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="
              order.or_shipping_zip ? order.or_shipping_zip : 'CAP'
            "
            class="setBorder-Prepp text-center"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label class="title" text="Provincia"></kendo-label>
          <input
            #or_shipping_prov
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="
              order.or_shipping_prov ? order.or_shipping_prov : 'Provincia'
            "
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label class="title" text="Nazione"></kendo-label>
          <input
            #or_shipping_country
            kendoTextBox
            [readonly]="!isEditable"
            rounded="full"
            [placeholder]="
              order.or_shipping_country ? order.or_shipping_country : 'Nazione'
            "
            class="setBorder-Prepp"
          />
        </div>
      </div>

      <div class="row column-secret"></div>
      <br />
    </form>

    <div class="row det cont-dx">
      <div class="row column-secret panelbar-wrapper" style="margin-top: 20px">
        <!-- <div class="panelbar-wrapper" style="margin-top: 10px"> -->
        <kendo-panelbar
          *ngIf="product"
          [animate]="true"
          style="
            text-align: center;
            border: #fff;
          "
        >
          <kendo-panelbar-item
            [title]="'Prodotti'"
            style="
              text-align: center;
              border-radius: 45px
            "
          >
            <ng-template kendoPanelBarContent>
              <div
                style="
                  max-height: 400px;
                  overflow-y: auto
                "
                >
                <div
                  class="row"
                  *ngFor="let prod of product; let i = index"
                  style="
                    text-align: center;
                    padding: 8px;
                    border: 1px solid #47A1FB;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                  "
                >
                  <div class="col-8" style="text-align: left">
                    <span
                      style="
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #0C65E9;
                      "
                      >{{ prod.op_description }}
                    </span>
                  </div>
                  <div class="col-2" style="text-align: right">
                    <span
                      style="
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #0C65E9;
                      "
                      >{{ prod.op_qta + " pz" }}
                    </span>
                  </div>
                  <div class="col-2 buttones" style="text-align: right">
                    <i
                      class="bx bx-info-circle bx-md"
                      style="cursor: pointer;"
                      (click)="goToProductDetails(prod.op_code)"
                    ></i>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
      </div>

      <div class="row column-secret panelbar-wrapper" style="margin-top: 10px">
        <kendo-panelbar
          *ngIf="product"
          [animate]="true"
          style="
            text-align: center;
            border: #fff;
          "
        >
          <kendo-panelbar-item
            [title]="'Documenti'"
            style="
              text-align: center;
              border-radius: 45px
            "
          >
            <ng-template kendoPanelBarContent>
              <div
                style="
                  max-height: 100px;
                  overflow-y: auto
                "
              >
                <div
                  class="row"
                  *ngFor="let doc of documents; let i = index"
                  style="
                    text-align: center;
                    padding: 8px;
                    border: 1px solid #47A1FB;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                  "
                >
                  <div class="spanus">
                    <span
                      style="
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #0C65E9;
                      "
                      >{{ doc.td_document }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
      </div>

      <div class="row column-secret panelbar-wrapper" style="margin-top: 10px">
        <kendo-panelbar
          *ngIf="product"
          [animate]="true"
          style="
            text-align: center;
            border: #fff;
          "
        >
          <kendo-panelbar-item
            [title]="'Etichette'"
            style="
              text-align: center;
              border-radius: 45px
            "
          >
            <ng-template kendoPanelBarContent>
              <div>
                <div
                  class="changeContainer"
                  style="
                    text-align: center;
                    padding: 8px;
                    border: 1px solid #47A1FB;
                    display: flex;
                    justify-content: center !important;
                  "
                >
                  <div class="spanus" style="display: flex">
                    <div
                      class="smallSpanus"
                      style="
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: flex-end;
                        flex-direction: column;
                      "
                    >
                      <div class="2" *ngIf="this.linkBordero">
                        <span
                          style="
                            font-family: 'Lato';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 24px;
                            color: #0C65E9;
                          "
                        >
                          Borderò
                        </span>
                        <a href="{{ this.linkBordero }}" target="_blank"
                          ><i
                            class="bx bxs-file-pdf bx-md"
                            style="cursor: pointer; color: rgb(220, 17, 17)"
                          ></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
      </div>
    </div>
  </div>
</kendo-dialog>

<app-product-detail-modal
  *ngIf="closeServ.isStoricoProd"
  [storicoOn]="closeServ.isStoricoProd"
  [pr_code]="pr_code"
>
</app-product-detail-modal>
