<div *ngIf="opened" class="k-form dark-background">
  <kendo-dialog
    (close)="goBackFunction()"
    *ngIf="opened"
    class="k-form container-dialog-assegna full-width"
  >
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15px;
        z-index: 1000;
        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="goBack()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff;
            bottom: 10px;
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button>
    </div>

    <h1 class="page-title">
      Assegna Ordine
      <span *ngIf="orderNew">{{ orderNew.or_order_number }}</span>
    </h1>

    <hr class="hr-dialog" />
    <div class="container container-ordine">
      <form
        class="k-form white-bg cont-sx"
        [formGroup]="assignOrderForm"
        (ngSubmit)="addOrderMethod(assignOrderForm)"
      >
        <div class="row">
          <div class="col">
            <fieldset class="k-form-fieldset">
              <div class="row det">
                <kendo-formfield class="col-md setDisplay-Prep">
                  <kendo-label
                    [for]="or_order_number"
                    class="title"
                    text="N° Ordine"
                  ></kendo-label>
                  <input
                    formControlName="or_order_number"
                    kendoTextBox
                    #or_order_number
                    [readonly]="!isEditable"
                    rounded="full"
                    class="setBorder-Prepp"
                  />
                </kendo-formfield>
                <kendo-formfield class="col-md setDisplay-Prep">
                  <kendo-label
                    [for]="or_order_date"
                    class="title"
                    text="Data Ordine"
                  ></kendo-label>
                  <input
                    formControlName="or_order_date"
                    kendoTextBox
                    #or_order_date
                    rounded="full"
                    [required]="true"
                    class="setBorder-Prepp"
                  />
                </kendo-formfield>
              </div>
            </fieldset>

            <div class="row det">
              <kendo-formfield class="col-md setDisplay-Prep">
                <kendo-label
                  [for]="or_customer"
                  class="title"
                  text="Cliente"
                ></kendo-label>
                <input
                  formControlName="or_customer"
                  kendoTextBox
                  #or_customer
                  rounded="full"
                  class="setBorder-Prepp"
                />
              </kendo-formfield>
            </div>

            <div class="row det">
              <kendo-formfield class="col-md setDisplay-Prep">
                <kendo-label
                  [for]="or_assigned_id"
                  class="title"
                  text="Operatore *"
                ></kendo-label>
                <kendo-combobox
                  [data]="workers"
                  valueField="account_id"
                  textField="full_name"
                  placeholder="Operatore"
                  rounded="full"
                  formControlName="or_assigned_id"
                  [valuePrimitive]="true"
                  required="true"
                  #or_assigned_id
                  class="setBorder-Prepp"
                >
                </kendo-combobox>
                <kendo-formerror
                  *ngIf="
                    (assignOrderForm.get('or_assigned_id')?.touched ||
                      assignOrderForm.get('or_assigned_id')?.dirty) &&
                    assignOrderForm.get('or_assigned_id')?.errors
                  "
                  >Operatore non impostato!
                </kendo-formerror>
              </kendo-formfield>
              <kendo-formfield class="col-md setDisplay-Prep">
                <kendo-label
                  [for]="or_order_priority_cod"
                  class="title"
                  text="Priorità *"
                ></kendo-label>
                <kendo-combobox
                  [data]="priorityOptions"
                  textField="tab_priority_order_desc"
                  valueField="tab_priority_order_cod"
                  placeholder="Priorità"
                  rounded="full"
                  formControlName="or_order_priority_cod"
                  [valuePrimitive]="true"
                  required="true"
                  #or_order_priority_cod
                  class="setBorder-Prepp"
                >
                </kendo-combobox>
                <kendo-formerror
                  *ngIf="
                    assignOrderForm.get('or_order_priority_cod')?.touched ||
                    assignOrderForm.get('or_order_priority_cod')?.dirty
                  "
                  >Priorità non impostata!
                </kendo-formerror>
              </kendo-formfield>
            </div>

            <div class="row det">
              <kendo-formfield class="col-md setDisplay-Prep">
                <kendo-label
                  [for]="or_shipping_address"
                  class="title"
                  text="Indirizzo di consegna"
                ></kendo-label>
                <input
                  formControlName="or_shipping_address"
                  kendoTextBox
                  #or_shipping_address
                  rounded="full"
                  class="setBorder-Prepp"
                />
              </kendo-formfield>
            </div>

            <div class="row det">
              <kendo-formfield class="col-md setDisplay-Prep">
                <kendo-label
                  [for]="or_shipping_city"
                  class="title"
                  text="Città"
                ></kendo-label>
                <input
                  formControlName="or_shipping_city"
                  kendoTextBox
                  #or_shipping_city
                  rounded="full"
                  class="setBorder-Prepp"
                />
              </kendo-formfield>
              <kendo-formfield class="col-md setDisplay-Prep">
                <kendo-label
                  [for]="or_shipping_zip"
                  class="title"
                  text="CAP"
                ></kendo-label>
                <input
                  formControlName="or_shipping_zip"
                  kendoTextBox
                  #or_shipping_zip
                  rounded="full"
                  class="setBorder-Prepp"
                />
              </kendo-formfield>
              <kendo-formfield class="col-md setDisplay-Prep">
                <kendo-label
                  [for]="or_shipping_prov"
                  class="title"
                  text="Provincia"
                ></kendo-label>
                <input
                  formControlName="or_shipping_prov"
                  kendoTextBox
                  #or_shipping_prov
                  rounded="full"
                  class="setBorder-Prepp"
                />
              </kendo-formfield>
            </div>

            <div class="row">
              <kendo-formfield class="col-md setDisplay-Prep">
                <kendo-label
                  [for]="or_shipping_country"
                  class="title"
                  text="Nazione"
                ></kendo-label>
                <input
                  formControlName="or_shipping_country"
                  kendoTextBox
                  #or_shipping_country
                  rounded="full"
                  class="setBorder-Prepp"
                />
              </kendo-formfield>
              <kendo-formfield class="col-md setDisplay-Prep">
                <kendo-label
                  [for]="carrier_id"
                  class="title"
                  text="Corriere *"
                ></kendo-label>
                <kendo-combobox
                  [data]="carriers"
                  textField="carrier_desc"
                  valueField="carrier_id"
                  placeholder="Corriere"
                  rounded="full"
                  formControlName="carrier_id"
                  [valuePrimitive]="true"
                  required="true"
                  #carrier_id
                  class="setBorder-Prepp"
                >
                </kendo-combobox>
                <kendo-formerror
                  *ngIf="
                    assignOrderForm.get('or_carrier_id')?.touched ||
                    assignOrderForm.get('or_carrier_id')?.dirty
                  "
                  >Corriere non impostato!
                </kendo-formerror>
              </kendo-formfield>
            </div>

            <div class="row">
              <kendo-formfield class="col-md setDisplay-Prep">
                <kendo-label
                  [for]="or_note"
                  class="title"
                  text="Note"
                ></kendo-label>
                <input
                  formControlName="or_note"
                  kendoTextBox
                  #or_note
                  rounded="full"
                  class="setBorder-Prepp"
                />
              </kendo-formfield>
            </div>
            <br />
            <div>
              <span *ngIf="assignOrderForm.invalid" style="color: red"
                >Assicurati che tutti i campi obbligatori siano compilati!</span
              >
            </div>
          </div>
        </div>
      </form>

      <div class="row det cont-dx setDisplay-Prep">
        <button
          class="k-display-flex align-items: flex-end;"
          (click)="selectAllProducts()"
          class="{{
            areSelected ? 'btn btn3 btn-deselect' : 'btn btn3 btn-select'
          }}"
          rounded="full"
        >
          <i
            class="{{
              areSelected
                ? 'bx bx-block bx-sm posRelBox2'
                : 'bx bx-check-circle bx-sm posRelBox2'
            }}"
            style="
              color: #ffffff;
              right: 6px;
              bottom: 10px;
              position: relative;
              margin-top: 2px;
            "
          ></i>
          <span style="padding-top: 5px">
            {{ areSelected ? "DESELEZIONA TUTTI" : "SELEZIONA TUTTI" }}
          </span>
        </button>
        <kendo-label class="tit-prod" text="Elenco prodotti"></kendo-label>
        <div
          class="arrange-container"
          style="
            height: 540px;
            overflow-y: auto;
            position: relative;
            bottom: 10px;
          "
        >
          <div
            class="new-text-2 text-left"
            *ngFor="let prod of productsNew; let i = index"
            [class.hr-newGreen]="prod.op_checked"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            <kendo-label (click)="opChecked(prod, i)" class="changeSize">
              {{ prod.op_code }}
            </kendo-label>
            <kendo-label
              (click)="opChecked(prod, i)"
              class="changeSize"
              style="text-align: left"
            >
              {{ prod.op_description }}
            </kendo-label>
            <div class="example">
              <kendo-dropdownlist
                [data]="numbers"
                (click)="changeProductNumber(prod.op_qta)"
                (ngModelChange)="updateProduct(prod.orQtaNeeded, i)"
                [(ngModel)]="prod.orQtaNeeded"
                [placeholder]="prod.orQtaNeeded / prod.op_qta"
                placeholder="{{ prod.orQtaNeeded / prod.op_qta }}"
                class="drop-the-border"
                [class.setBackGroundToRed]="prod.op_qta > prod.op_stock"
                style="text-align: left; margin-left: 10px"
              >
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                  <div class="row" style="text-align: left; margin-left: 10px">
                    <span class="template"
                      >{{ dataItem }} / {{ prod.op_stock }}</span
                    >
                  </div>
                </ng-template>
              </kendo-dropdownlist>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-buttons" style="margin-top: -10px">
      <div class="button-left">
        <button
          (click)="addOrderMethod(assignOrderForm)"
          class="btn btn3"
          rounded="full"
          type="submit"
          [disabled]="!assignOrderForm.valid"
          style="
            background-color: #017efa;
            color: #fff;
            justify-content: center !important;
            display: flex;
            width: 292px !important;
            height: 42px !important;
          "
          [disabled]="assignOrderForm.invalid"
        >
          <i
            class="bx bx-time-five bx-sm posRelBox2"
            style="color: #fff; top: 2px; right: 6px; bottom: 10px; position: relative"
          ></i>
          <p class="posRelBox2" style="top:2px">PRONTO PER L'OPERATORE</p>
        </button>
      </div>
    </div>
  </kendo-dialog>
</div>

<app-product-detail
  *ngIf="closeServ.isProdopen"
  [opened]="closeServ.isProdopen"
  [product_id]="clickedProduct"
>
</app-product-detail>
