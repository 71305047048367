import { Boxes } from './Boxes.models';
import { Documents } from './documents.models';
import { Products } from './Product.model';
import { Priority } from './Priority.model';
import { Shipments } from './shipment';
import { Status } from './status';
import { Image } from './image';
import { ProductPosition } from './productPosition';
import { Helpers } from './helpters';
import { Buttons } from './buttons';
import { formatDate } from '@angular/common';
import { parseISO } from 'date-fns';
import { ProgressBarCustom } from './progressBarColor';

export namespace Orders {
  export interface IOrderOpenList {
    order_id: number;
    or_assigned_id: string;
    or_order_date: Date;
    or_assigned_date: Date;
    or_order_number: string;
    or_customer: string;
    tab_order_priority_cod: number;
    tab_order_priority_desc: string;
    tab_order_priority_color: string;
    tab_order_status_desc: string;
    tab_order_status_cod: number;
    tab_order_status_perc: number;
    tab_order_status_previous_perc: number;
    ac_first_name: string;
    ac_last_name: string;
    op_articles_number: string;
    or_carrier_desc: string;
    // vs_details?: IVSDetails;
    // products?: Products.Product[];
    // status?: Status.IStatus[];
    // boxes?: Boxes.BoxOrder[];
    // not from server
    order_checked?: boolean;
    or_net_value?: number;
    or_url_ddt: string;
  }

  export interface IOrderSelectedToSign {
    order_id: number;
    or_customer: string;
    op_checked: boolean;
    indexNo: number;
  }

  export interface IOrderBordero {
    //orders: number[];
    courier_name: string;
    // b64_signature: string;
    order_date: string;
    boxes: string;
  }

  export class OrderSelectedToSign implements IOrderSelectedToSign {
    order_id: number;
    order_number: string;
    or_customer: string;
    op_checked: boolean = false;
    indexNo: number;
    or_partial: string;
    or_carrier_id: string;
    or_carrier_desc: string;
    or_carrier_other: string;
    or_boxes: number;

    constructor(order: OrderSelectedToSign) {
      if (!order) return;
      this.order_id = order.order_id;
      this.or_customer = order.or_customer;
      this.op_checked = order.op_checked;
      this.indexNo = order.indexNo;
      this.or_partial = order.or_partial;
      this.or_carrier_id = order.or_carrier_id;
      this.or_carrier_desc = order.or_carrier_desc;
      this.or_carrier_other = order.or_carrier_other;
      this.order_number = order.order_number;
      this.or_boxes = order.or_boxes;
    }
  }

  export class OrderBordero implements IOrderBordero {
    //orders: number[] = [];
    orders: { order_id; order_number }[] = [];
    courier_name: string = null;
    b64_signature: string = null;
    order_date: string = null;
    // carrier_id?: any;
    // carrier_desc: string;
    // carrier_other?: string;
    boxes: string = null;
  }

  export interface IOrderList {
    order_id: number;
    or_assigned_id: number;
    or_order_date: string;
    or_assigned_date: string;
    or_order_number: string;
    or_customer: string;
    tab_order_priority_cod: number;
    tab_priority_order_desc: string;
    tab_priority_order_color: string;
    tab_order_status_cod: number;
    tab_order_status_desc: string;
    tab_order_status_perc: number;
    ac_first_name: string;
    ac_last_name: string;
    op_articles_number: number;
    tab_stati_ordine_precedente_perc: number;
    or_partial: string;

    or_net_value?: number;
  }

  export interface IOrderDetails {
    //order_id: number;
    or_order_number: string;
    or_order_date: string;
    or_customer: string;
    or_shipping_address: string;
    or_note: string;
    or_status: Status.IStatus[];
  }

  export class OrderList implements IOrderList {
    order_id: number;
    or_assigned_id: number;
    or_order_date: string;
    or_assigned_date: string;
    or_order_number: string;
    or_customer: string;
    tab_order_priority_cod: number;
    tab_priority_order_desc: string;
    tab_priority_order_color: string;
    tab_order_status_cod: number;
    tab_order_status_desc: string;
    tab_order_status_perc: number;
    ac_first_name: string;
    ac_last_name: string;
    op_articles_number: number;
    tab_stati_ordine_precedente_perc: number;
    or_partial: string;
    //not from server
    icon: Image.IImage;
    date: string;
    time: string;
    priority: Priority.IPriority;
    or_net_value?: any;

    constructor(orderServer: IOrderList) {
      this.order_id = orderServer.order_id;
      this.or_assigned_id = orderServer.or_assigned_id;
      this.or_order_date = orderServer.or_order_date;
      this.or_assigned_date = orderServer.or_assigned_date;
      this.or_order_number = orderServer.or_order_number;
      this.or_customer = orderServer.or_customer;
      this.tab_priority_order_desc = orderServer.tab_priority_order_desc;
      this.tab_priority_order_color = orderServer.tab_priority_order_color;
      this.tab_order_status_cod = orderServer.tab_order_status_cod;
      this.tab_order_status_desc = orderServer.tab_order_status_desc;
      this.tab_order_status_perc = orderServer.tab_order_status_perc;
      this.tab_stati_ordine_precedente_perc =
        orderServer.tab_stati_ordine_precedente_perc;
      this.ac_first_name = orderServer.ac_first_name;
      this.ac_last_name = orderServer.ac_last_name;
      this.op_articles_number = orderServer.op_articles_number;
      this.or_partial = orderServer.or_partial;
      this.date = Helpers.getDate(orderServer.or_order_date);
      this.time = Helpers.getTime(orderServer.or_order_date);
      //orderServer.or_order_priority_cod
      this.priority = new Priority.Priority(
        orderServer.tab_order_priority_cod,
        orderServer.tab_priority_order_desc,
        orderServer.tab_priority_order_color,
        false,
        this.or_partial?.toLowerCase() === 's' ? true : false
      );
      this.or_net_value = orderServer?.or_net_value;
      switch (orderServer.tab_order_status_cod) {
        case 1:
          this.icon = Helpers.iconTaskNew;
          break;
        case 2:
        case 3:
        case 4:
        case 5:
          this.icon = Helpers.iconTaskWorking;
          break;
        case 6:
          this.icon = Helpers.iconTaskComplete;
          break;
        case 10:
          this.icon = Helpers.iconTaskWorking;
          break;
        case 100:
          this.icon = Helpers.iconTaskSuspended;
          break;
        case 101:
          this.icon = Helpers.iconTaskBlocked;
          break;
        case 102:
          this.icon = Helpers.iconTaskUnBlocked;
          break;

        default:
          this.icon = Helpers.iconTaskNew;
          break;
      }
    }
  }

  export interface IOrderSignature extends IOrderList {
    or_carrier_desc: string;
    or_carrier_id: boolean;
    or_carrier_other: string;
  }

  export class OrderSignature extends OrderList implements IOrderSignature {
    or_carrier_desc: string;
    or_carrier_id: boolean;
    or_carrier_other: string;
    or_boxes: number;
    indexNo?: number;
    op_checked: boolean = false;

    constructor(order) {
      super(order);
      this.or_carrier_desc = order.or_carrier_desc
        ? order.or_carrier_desc
        : null;
      this.or_carrier_id = order.or_carrier_id ? order.or_carrier_id : null;
      this.or_carrier_other = order.or_carrier_other
        ? order.or_carrier_other
        : null;
      this.or_boxes = order.or_boxes ? order.or_boxes : null;
    }
  }

  export interface IOrderClosed extends IOrderList {
    or_closed_date: string;
    // not from server
    status_perc: number;
    complete_state: string;
  }

  export class OrderOpenList implements IOrderOpenList {
    order_id: number;
    or_assigned_id: string;
    or_order_date: Date;
    or_assigned_date: Date;
    or_order_number: string;
    or_customer: string;
    tab_order_priority_cod: number;
    tab_order_priority_desc: string;
    tab_order_priority_color: string;
    tab_order_status_desc: string;
    tab_order_status_cod: number;
    tab_order_status_perc: number;
    tab_order_status_previous_perc: number;
    ac_first_name: string;
    ac_last_name: string;
    op_articles_number: string;
    or_carrier_desc: string;
    progressBarCustom: ProgressBarCustom.IProgresBar;
    status_perc: number; //not from server
    or_net_value?: number;
    order_checked?: boolean;
    or_url_ddt: string;

    constructor(orderOpenServ: IOrderOpenList) {
      if (!orderOpenServ) return;
      this.order_id = orderOpenServ.order_id;
      this.or_assigned_id = orderOpenServ.or_assigned_id;
      this.or_order_date = orderOpenServ.or_order_date;
      this.or_assigned_date = orderOpenServ.or_assigned_date;
      this.or_order_number = orderOpenServ.or_order_number;
      this.or_customer = orderOpenServ.or_customer;
      this.tab_order_priority_cod = orderOpenServ.tab_order_priority_cod;
      this.tab_order_priority_desc = orderOpenServ.tab_order_priority_desc;
      this.tab_order_priority_color = orderOpenServ.tab_order_priority_color;
      this.tab_order_status_desc = orderOpenServ.tab_order_status_desc;
      this.tab_order_status_cod = orderOpenServ.tab_order_status_cod;
      this.tab_order_status_perc = orderOpenServ.tab_order_status_perc;
      this.tab_order_status_previous_perc =
        orderOpenServ.tab_order_status_previous_perc;
      this.ac_first_name = orderOpenServ.ac_first_name;
      this.ac_last_name = orderOpenServ.ac_last_name;
      this.op_articles_number = orderOpenServ.op_articles_number;
      this.or_carrier_desc = orderOpenServ.or_carrier_desc;
      this.or_url_ddt = orderOpenServ?.or_url_ddt;
      // this.carrier_desc = orderOpenServ.carrier_desc;
      this.progressBarCustom = new ProgressBarCustom.Progress(
        orderOpenServ.tab_order_status_cod == 100
          ? orderOpenServ.tab_order_status_previous_perc
          : orderOpenServ.tab_order_status_perc
      );
      this.or_net_value = orderOpenServ.or_net_value;
    }
  }

  //open orders
  export interface IOrder {
    order_id: number;
    or_gamma_id: number;
    or_order_date: string;
    or_order_number: string;
    or_quote_date: string;
    or_quote_number: string;
    or_assignment_date: string;
    or_closing_date: string;
    or_assigned_id: number;
    or_agent_id: string;
    or_order_priority_cod: number;
    or_customer_code: string;
    or_customer: string;
    or_customer_mail: string;
    or_customer_phone: string;
    or_customer_reference: string;
    or_shipping_customer: string;
    or_shipping_address: string;
    or_shipping_prov: string;
    or_shipping_city: string;
    or_shipping_zip: string;
    or_shipping_country: string;
    or_shipping_note: string;
    or_carrier_desc: string;
    or_latitude: string;
    or_longitude: string;
    or_subject: string;
    or_note: string;
    or_partial: string;
    vs_details: IVSDetails;
    products: Products.Product[];
    status: Status.IStatus[];
    boxes: Boxes.BoxOrder[];
    order_desc_prep: IOrderPrepared;
    shipment: Shipments.IShipment;
    or_url_ddt: string;
    or_url_box_label?: string;
    or_url_boxes_label?: string;
    or_status: string;
    or_synchronise: string;
    or_ddt_number: string;
    or_url_pdf_elg?: string;

    or_net_value?: number | string;
    // Not from server
  }

  // export interface IOrder
  //   extends Omit<IOrderToSend, 'products' | 'or_carrier_id'> {
  //   order_id: number;
  //   or_closing_date: string;
  //   or_latitude: string;
  //   or_longitude: string;
  //   vs_details: IVSDetails;
  //   shipment: Shipments.IShipment;
  //   boxes: Boxes.BoxOrder[];
  //   products: Products.Product[];
  //   status: Status.IStatus[];
  // }

  export interface IOrder1
    extends Omit<IOrderToSend, 'products' | 'or_carrier_id'> {
    order_id: number;
    or_closing_date: string;
    or_latitude: string;
    or_longitude: string;
    vs_details: IVSDetails;
    shipment: Shipments.IShipment;
    or_shipping_customer: string;
    boxes: Boxes.BoxOrder[];
    products: Products.Product[];
    status: Status.IStatus[];
  }

  export interface IOrderPrep
    extends Omit<IOrderToSend, 'products' | 'or_carrier_id'> {
    order_id: number;
    or_closing_date: string;
    or_latitude: string;
    or_longitude: string;
    vs_details: IVSDetails;
    shipment: Shipments.IShipment;
    boxes: Boxes.BoxOrder[];
    products: Products.IProduct[];
    status: Status.IStatus[];
  }

  export interface IOrderUpdate {
    order_id: number;
    or_assignment_date: string;
    or_assigned_id: number;
    or_carrier_id: number;
    or_carrier_other: string;
    or_order_priority_cod: number;
    or_shipping_customer: string;
    or_shipping_address: string;
    or_shipping_city: string;
    or_shipping_zip: string;
    or_shipping_prov: string;
    or_shipping_country: string;
    or_shipping_note: string;
    or_note: string;
    or_partial: string;
    boxes: Boxes.IBoxOrder[];
    products: Products.IProductUpdate[];
  }

  export interface IAddOrder {
    or_gamma_id: any;
    or_order_date: string;
    or_order_number: string;
    or_quote_date: string;
    or_closing_date: string;
    or_quote_number: string;
    or_assignment_date: string;
    or_assigned_id: string;
    or_agent_id: string;
    or_carrier_id: string;
    or_order_priority_cod: string;
    or_customer_code: string;
    or_customer: string;
    or_customer_mail: string;
    or_customer_phone: string;
    or_customer_reference: string;
    or_shipping_customer: string;
    or_shipping_address: string;
    or_shipping_city: string;
    or_shipping_prov: string;
    or_shipping_zip: string;
    or_shipping_country: string;
    or_subject: string;
    or_note: string;
    or_partial: string;
    products: Products.IProductToSend[];
  }

  export interface IVSDetails {
    tab_order_priority_cod: number;
    tab_order_priority_desc: string;
    tab_order_priority_color: string;
    tab_order_status_cod: number;
    tab_order_status_desc: string;
    tab_order_status_perc: number;
    ac_first_name: string;
    ac_last_name: string;
    ag_first_name: string;
    ag_last_name: string;
    ag_email: string;
    carrier_desc: string;
    carrier_id: number;
    carrier_other: string;
    sp_carrier_id: string;
    op_articles_number: number;
    changed_carrier_desc?: string;
    or_boxes: string;
  }

  export interface IStatus {
    status_order_id: number;
    os_account_id: string;
    os_status_order_cod: number;
    os_status_date: string;
    os_note: string;
    os_tracking: string;
    ac_first_name: string;
    ac_last_name: string;
    tab_order_previous_perc: number;
    tab_order_status_cod: number;
    tab_order_status_desc: string;
    tab_order_status_perc: number;
  }

  export interface ICMOrderList {
    order_id: number;
    or_articles_number: number;
    or_customer: string;
    or_days_remaining: number;
    or_gamma_id: number;
    or_order_date: string;
    or_order_number: string;
    //not from server
    evasion_date: string;
    tab_order_status_cod: number;
  }

  export interface IOrderPM {
    ag_first_name: string;
    ag_gamma_id: number;
    ag_last_name: string;
    ag_mail: string;
    ag_mobile: string;
    ag_phone: number;
    or_customer: string;
    or_customer_code: string;
    or_customer_mail: string;
    or_customer_phone: number;
    or_gamma_id: number;
    or_note: string;
    or_order_date: string;
    or_order_number: string;
    or_quote_date: string;
    or_quote_number: string;
    or_shipping_address: string;
    or_shipping_city: string;
    or_shipping_country: string;
    or_shipping_customer: string;
    or_shipping_note: string;
    or_shipping_prov: string;
    or_shipping_zip: string;
    or_subject: string;
    products: Products.Product[];
    date: string;
    time: string;
    totalWeight: number;
    status: Status.IStatus[];
  }

  export class OrderPM implements IOrderPM {
    ag_first_name: string;
    ag_gamma_id: number;
    ag_last_name: string;
    ag_mail: string;
    ag_mobile: string;
    ag_phone: number;
    or_customer: string;
    or_customer_code: string;
    or_customer_mail: string;
    or_customer_phone: number;
    or_gamma_id: number;
    or_note: string;
    or_order_date: string;
    or_order_number: string;
    or_quote_date: string;
    or_quote_number: string;
    or_shipping_address: string;
    or_shipping_city: string;
    or_shipping_country: string;
    or_shipping_customer: string;
    or_shipping_note: string;
    or_shipping_prov: string;
    or_shipping_zip: string;
    or_subject: string;
    products: Products.Product[];
    totalWeight: number = 0;
    status: Status.IStatus[];
    date: string;
    time: string;

    constructor(order: IOrderPM) {
      if (!order) return;
      this.ag_first_name = order.ag_first_name;
      this.ag_gamma_id = order.ag_gamma_id;
      this.ag_last_name = order.ag_last_name;
      this.ag_mail = order.ag_mail;
      this.ag_mobile = order.ag_mobile;
      this.ag_phone = order.ag_phone;
      this.or_customer = order.or_customer;
      this.or_customer_code = order.or_customer_code;
      this.or_customer_mail = order.or_customer_mail;
      this.or_customer_phone = order.or_customer_phone;
      this.or_gamma_id = order.or_gamma_id;
      this.or_note = order.or_note;
      this.or_order_date = order.or_order_date;
      this.or_order_number = order.or_order_number;
      this.or_quote_date = order.or_quote_date;
      this.or_quote_number = order.or_quote_number;
      this.or_shipping_address = order.or_shipping_address;
      this.or_shipping_city = order.or_shipping_city;
      this.or_shipping_country = order.or_shipping_country;
      this.or_shipping_customer = order.or_shipping_customer;
      this.or_shipping_note = order.or_shipping_note;
      this.or_shipping_prov = order.or_shipping_prov;
      this.or_shipping_zip = order.or_shipping_zip;
      this.or_subject = order.or_subject;
      this.products = order.products.map((product, index) => {
        product.indexNo = index + 1;
        product.orQtaNeeded = product.op_qta;
        product.full_position = new ProductPosition.Position(
          product.op_positions[0]
        );
        if (product.op_weigth != undefined || product.op_weigth != null)
          this.totalWeight += product.op_weigth;
        return product;
      });
      this.status = order.status;
      this.date = Helpers.getDate(this.or_order_date);
      this.time = Helpers.getTime(this.or_order_date);
    }
  }

  export interface IOrderNewList extends ICMOrderList {
    or_customer: string;
    tab_order_priority_cod: number;
    tab_priority_order_desc: string;
    tab_priority_order_color: string;
    tab_order_status_cod: number;
    tab_order_status_desc: string;
    tab_order_status_perc: number;
    ac_first_name: string;
    ac_last_name: string;
    op_articles_number: number;
    tab_stati_ordine_precedente_perc: number;
    status: IStatus[];
    order_id: number;
    or_gamma_id: number;
    or_customer_code: string;
    or_customer_mail: string;
    or_customer_phone: string;
    or_shipping_address: string;
    or_order_number: string;
    or_order_date: string;
    or_quote_number: string;
    or_quote_date: string;
    products: Products.IProduct[];
    or_assignment_date: string;
    or_closing_date: string;
    or_assigned_id: number;
    or_agent_id: string;
    or_carrier_id: number;
    or_order_priority_cod: number;
    or_customer_reference: string;
    or_latitude: string;
    or_longitude: string;
    or_subject: string;
    or_note: string;
    or_partial: string;
    vs_details: IVSDetails;
    priority: Priority.IPriority;
    //not from server
    formattedDate: any;

    or_net_value?: number;
  }

  export class ICMOrderOpenList implements ICMOrderList {
    order_id: number;
    or_articles_number: number;
    or_customer: string;
    or_days_remaining: number;
    or_gamma_id: number;
    or_order_date: string;
    or_order_number: string;
    //not from server
    evasion_date: string;
    tab_order_status_cod: number;

    constructor(icmOrderOpenServ: ICMOrderList) {
      if (!icmOrderOpenServ) return;
      this.or_articles_number = icmOrderOpenServ.or_articles_number;
      this.or_customer = icmOrderOpenServ.or_customer;
      this.or_days_remaining = icmOrderOpenServ.or_days_remaining;
      this.or_gamma_id = icmOrderOpenServ.or_gamma_id;
      this.or_order_date = icmOrderOpenServ.or_order_date;
      this.or_order_number = icmOrderOpenServ.or_order_number;
    }
  }

  export interface IOrderNew {
    readonly or_gamma_id: number;
    readonly ag_gamma_id: string;
    readonly ag_first_name: string;
    readonly ag_last_name: string;
    readonly or_customer_code: string;
    readonly or_customer: string;
    readonly or_customer_mail: string;
    readonly or_customer_phone: string;
    readonly or_shipping_customer: string;
    readonly or_shipping_address: string;
    readonly or_shipping_city: string;
    readonly or_shipping_prov: string;
    readonly or_shipping_zip: string;
    readonly or_shipping_country: string;
    readonly or_order_number: string;
    readonly or_order_date: string;
    readonly or_quote_number: string;
    readonly or_quote_date: string;
    readonly or_subject: string;
    readonly or_note: string;
    or_carrier_desc: string;
    or_carrier_id?: number;
    or_partial: string;
    or_boxes: string;
    readonly products: Products.IProductNew[];
  }

  export class OrderNew implements IOrderNew {
    or_gamma_id: number;
    or_customer_code: string;
    or_customer: string;
    or_customer_mail: string;
    or_customer_phone: string;
    or_shipping_address: string;
    or_shipping_customer: string;
    or_shipping_city: string;
    or_shipping_prov: string;
    or_shipping_zip: string;
    or_shipping_country: string;
    or_order_number: string;
    or_order_date: string;
    or_quote_number: string;
    or_quote_date: string;
    or_subject: string;
    or_note: string;
    or_agent_id: string;
    ag_gamma_id: string;
    ag_first_name: string;
    ag_last_name: string;
    or_carrier_desc: string;
    products: Products.IProductNew[] = [];
    date: string;
    time: string;
    buttons: Buttons.IButton[] = [];
    totalWeight: number = 0;
    or_partial: string;
    or_boxes: string;

    constructor(order: IOrderNew) {
      if (!order) return;
      this.or_gamma_id = order.or_gamma_id;
      this.or_customer = order.or_customer;
      this.or_customer_code = order.or_customer_code;
      this.or_customer_mail = order.or_customer_mail;
      this.or_customer_phone = order.or_customer_phone;
      this.or_shipping_address = order.or_shipping_address;
      this.or_shipping_customer = order.or_shipping_customer;
      this.or_shipping_city = order.or_shipping_city;
      this.or_shipping_prov = order.or_shipping_prov;
      this.or_shipping_zip = order.or_shipping_zip;
      this.or_shipping_country = order.or_shipping_country;
      this.or_note = order.or_note;
      this.or_order_number = order.or_order_number;
      this.or_order_date = order.or_order_date;
      this.or_quote_number = order.or_quote_number;
      console.log(
        'Quote date is = ',
        parseISO(order.or_quote_date) == new Date(1900, 0, 1)
      );
      this.or_quote_date =
        parseISO(order.or_quote_date) == new Date(1900, 0, 1)
          ? order.or_quote_date
          : null;
      this.or_subject = order.or_subject;
      this.or_note = order.or_note;
      this.or_partial = order.or_partial;
      this.products = order.products?.map((product, index) => {
        if (product.op_weigth != undefined || product.op_weigth != null)
          this.totalWeight += product.op_weigth;
        return product;
      });
      this.ag_gamma_id = order.ag_gamma_id;
      this.or_carrier_desc = order.or_carrier_desc;
      this.ag_first_name = order.ag_first_name;
      this.ag_last_name = order.ag_last_name;

      // this.date = Helpers.getDate(this.or_order_date);
      // this.time = Helpers.getTime(this.or_order_date);
      this.buttons = new Buttons.Buttons().getMobileAdminPrepareOrder();
    }
  }

  //used for add_order
  export interface IOrderToSend
    extends Omit<
      IOrderNew,
      'products' | 'ag_gamma_id' | 'ag_first_name' | 'ag_last_name'
    > {
    or_assignment_date: string;
    or_assigned_id: any;
    or_agent_id: string;
    or_order_priority_cod: number;
    or_carrier_id: number;
    or_customer_reference: string;
    products: Products.IProductToSend[];
  }

  export class OrderToSend implements IOrderToSend {
    //IOrderNew
    or_gamma_id: number;
    or_customer_code: string;
    or_customer: string;
    or_customer_mail: string;
    or_customer_phone: string;
    or_shipping_address: string;
    or_shipping_customer: string;
    or_shipping_city: string;
    or_shipping_prov: string;
    or_shipping_zip: string;
    or_shipping_country: string;
    or_order_number: string;
    or_order_date: string;
    or_quote_number: string;
    or_quote_date: string;
    or_subject: string;
    or_note: string;
    or_boxes: string;
    //IOrderToSend
    or_carrier_desc: string;
    or_agent_id: string;
    or_order_priority_cod: number;
    or_carrier_id: number;
    or_partial: string;
    or_assignment_date: string;
    or_customer_reference: string = null;
    or_assigned_id: number;
    products: Products.IProductToSend[];
    //Personalized
    stepCount: number = 0;
    priorityText: string = '';
    carrierText: string = '';
    operator: string = '';
    messageToWorker: string = '';

    constructor(orderServer: OrderNew) {
      if (!orderServer) return;
      this.or_gamma_id = orderServer.or_gamma_id;
      this.or_agent_id = orderServer.ag_gamma_id;
      this.or_customer = orderServer.or_customer;
      this.or_customer_code = orderServer.or_customer_code;
      this.or_customer_mail = orderServer.or_customer_mail;
      this.or_customer_phone = orderServer.or_customer_phone;
      this.or_shipping_address = orderServer.or_shipping_address;
      this.or_shipping_customer = orderServer.or_shipping_customer;
      this.or_shipping_city = orderServer.or_shipping_city;
      this.or_shipping_prov = orderServer.or_shipping_prov;
      this.or_shipping_zip = orderServer.or_shipping_zip;
      this.or_shipping_country = orderServer.or_shipping_country;
      this.or_order_number = orderServer.or_order_number;
      this.or_order_date = orderServer.or_order_date;
      this.or_quote_number = orderServer.or_quote_number;
      this.or_quote_date = orderServer.or_quote_date;
      this.or_subject = orderServer.or_subject;
      this.or_note = orderServer.or_note;
      this.or_partial = orderServer.or_partial;
      orderServer.products.sort(
        (a, b) => +(+b.op_stock < +b.op_qta) - +(+a.op_stock < +a.op_qta)
      );
      this.products = orderServer.products.map((item) => {
        let product: Products.IProductToSend = {
          op_note: null,
          op_code: item.op_code,
          op_description: item.op_description,
          op_qta: item.op_qta,
          orQtaNeeded: item.op_qta,
          op_qta_ordered: item.op_qta_ordered,
          op_weigth: item.op_weigth,
          op_stock: item.op_stock,
          op_checked: +item.op_stock > +item.op_qta,
          op_positions: item.op_positions,
          tab_order_status_cod: item.tab_order_status_cod,
        };
        return product;
      });
    }
  }

  export interface IOrderPrepared {
    order_id: number;
    or_carrier_id: number;
    or_carrier_other: string;
    or_shipping_customer: string;
    or_shipping_address: string;
    or_shipping_prov: string;
    or_shipping_city: string;
    or_shipping_zip: string;
    or_shipping_country: string;
    documents: Documents.IDocumentId[];
    boxes: Boxes.IBoxOrder[];
  }

  export class OrderUpdate {
    order_id: number;
    or_assignment_date: string;
    or_assigned_id: number;
    or_carrier_id: number;
    or_carrier_other: string;
    or_original_carrier: string;
    or_order_priority_cod: number;
    or_shipping_address: string;
    or_note: string;
    or_shipping_customer: string;
    or_shipping_city: string;
    or_shipping_prov: string;
    or_shipping_zip: string;
    or_shipping_country: string;
    boxes: Boxes.IBoxOrder[];
    products: Products.IProductUpdate[];
    or_partial: string;
    or_synchronise: string;

    //NotFromServer
    minQuantity?: number;

    constructor(order: Order) {
      if (!order) return;
      console.debug('order', order);
      this.order_id = order.order_id;
      this.or_assignment_date = order.or_assignment_date;
      this.or_assigned_id = order.or_assigned_id;
      this.or_carrier_id =
        order.vs_details.carrier_id == 0 ? null : order.vs_details.carrier_id;
      this.or_carrier_other = order.vs_details.carrier_other;
      this.or_order_priority_cod = order.or_order_priority_cod;
      this.or_shipping_address = order.or_shipping_address;
      this.or_shipping_customer = order.or_shipping_customer;
      this.or_shipping_prov = order.or_shipping_prov;
      this.or_shipping_city = order.or_shipping_city;
      this.or_shipping_zip = order.or_shipping_zip;
      this.or_shipping_country = order.or_shipping_country;
      this.or_note = order.or_note;
      this.or_synchronise = order.or_synchronise;
      this.or_partial =
        order.or_partial?.toUpperCase() !== 'S'
          ? order.orPartial
          : order.or_partial;
      order.boxes.forEach(
        (item) =>
          (item.ts_box_type_id =
            item.ts_box_type_id != 0 ? item.ts_box_type_id : null)
      );
      this.boxes = order.boxes;
      this.products = order.products.map((item) => {
        return {
          barcodes: item.barcodes,
          op_description: item.op_description,
          product_id: item.products_order_id,
          op_qta: item.orQtaNeeded,
          op_checked:
            item.op_checked === undefined || !item.op_checked ? false : true,
        };
      });
    }
  }

  export class Order implements IOrder {
    order_id: number;
    or_gamma_id: number;
    or_customer_code: string;
    or_customer: string;
    or_customer_mail: string;
    or_customer_phone: string;
    or_shipping_address: string;
    or_order_number: string;
    or_order_date: string;
    or_quote_number: string;
    or_quote_date: string;
    products: Products.Product[];
    or_assignment_date: string;
    or_closing_date: string;
    or_assigned_id: number;
    or_agent_id: string;
    or_carrier_id: number;
    or_carrier_desc: string;
    or_order_priority_cod: number;
    or_customer_reference: string;
    or_latitude: string;
    or_longitude: string;
    or_subject: string;
    or_note: string;
    or_partial: string;
    vs_details: IVSDetails;
    shipment: Shipments.IShipment;
    status: Status.IStatus[];
    boxes: Boxes.BoxOrder[];
    or_shipping_customer: string;
    or_shipping_city: string;
    or_shipping_prov: string;
    or_shipping_zip: string;
    or_shipping_note: string;
    or_shipping_country: string;
    or_url_ddt: string;
    or_url_box_label?: string;
    or_url_boxes_label?: string;
    or_synchronise: string;
    or_ddt_number: string;
    or_url_pdf_elg?: string;

    or_net_value?: number | string;
    //not from server
    icon: Image.IImage;
    date: string;
    time: string;
    priority: Priority.IPriority;
    buttons: Buttons.IButton[];
    stepCount: number = 0;
    isPacked: boolean = false;
    totalWeight: number = 0;
    orPartial: string;
    progressBarCustom: ProgressBarCustom.IProgresBar;
    tab_order_status_cod: any;

    private getDate() {
      this.date = formatDate(parseISO(this.or_order_date), 'dd/MM/yy', 'it-IT');
    }

    private getTime() {
      this.time = formatDate(parseISO(this.or_order_date), 'HH:mm', 'it-IT');
    }

    constructor(orderServer: IOrder) {
      if (!orderServer) return;
      this.order_id = orderServer.order_id;
      this.or_gamma_id = orderServer.or_gamma_id;
      this.or_customer = orderServer.or_customer;
      this.or_customer_code = orderServer.or_customer_code;
      this.or_customer_mail = orderServer.or_customer_mail;
      this.or_customer_phone = orderServer.or_customer_phone;
      this.or_shipping_address = orderServer.or_shipping_address;
      this.or_shipping_customer = orderServer.or_shipping_customer;
      this.or_shipping_city = orderServer.or_shipping_city;
      this.or_shipping_prov = orderServer.or_shipping_prov;
      this.or_shipping_zip = orderServer.or_shipping_zip;
      this.or_shipping_country = orderServer.or_shipping_country;
      this.or_order_number = orderServer.or_order_number;
      this.or_order_date = orderServer.or_order_date;
      this.or_quote_number = orderServer.or_quote_number;
      this.or_quote_date = orderServer.or_quote_date;
      this.or_assignment_date = orderServer.or_assignment_date;
      this.or_closing_date = orderServer.or_closing_date;
      this.or_assigned_id = orderServer.or_assigned_id;
      this.or_agent_id = orderServer.or_agent_id;
      this.or_order_priority_cod = orderServer.or_order_priority_cod;
      this.or_customer_reference = orderServer.or_customer_reference;
      this.or_latitude = orderServer.or_latitude;
      this.or_longitude = orderServer.or_longitude;
      this.or_subject = orderServer.or_subject;
      this.or_note = orderServer.or_note;
      this.or_partial = orderServer.or_partial;
      this.vs_details = orderServer.vs_details;
      this.status = orderServer.status;
      this.or_carrier_desc = orderServer.or_carrier_desc;
      this.or_url_ddt = orderServer.or_url_ddt;
      this.or_url_box_label = orderServer.or_url_box_label;
      this.or_url_boxes_label = orderServer.or_url_boxes_label;
      this.or_status = orderServer.or_status;
      this.or_synchronise = orderServer.or_synchronise;
      this.or_url_pdf_elg = orderServer.or_url_pdf_elg;
      this.or_net_value = orderServer?.or_net_value;
      // this.tab_order_status_cod = orderServer.status[0].tab_order_status_cod
      this.progressBarCustom = new ProgressBarCustom.Progress(
        orderServer.status[0].tab_order_status_cod == 100
          ? orderServer.status[1].tab_order_previous_perc
          : orderServer.status[0].tab_order_status_perc
      );
      this.products = orderServer.products.map((product, index) => {
        product.indexNo = index + 1;
        product.orQtaNeeded = product.op_qta;
        product.full_position = new ProductPosition.Position(
          product.op_positions[0]
        );
        if (product.op_weigth != undefined || product.op_weigth != null)
          this.totalWeight += product.op_weigth;
        return product;
      });
      this.boxes = orderServer.boxes;
      this.shipment = orderServer.shipment;
      this.getDate();
      this.getTime();
      this.buttons = new Buttons.Buttons().getMobileUserPrepareOrder();
      this.priority = new Priority.Priority(
        orderServer.or_order_priority_cod,
        orderServer.vs_details.tab_order_priority_desc,
        orderServer.vs_details.tab_order_priority_color,
        false,
        this.orPartial?.toLowerCase() === 's' ? true : false
      );
      switch (orderServer.status[0].os_status_order_cod) {
        case 1:
          this.icon = Helpers.iconTaskNew;
          break;
        case 2:
        case 3:
        case 4:
        case 5:
          this.icon = Helpers.iconTaskWorking;
          break;
        case 6:
          this.icon = Helpers.iconTaskComplete;
          break;
        case 7:
          this.icon = Helpers.iconInTransit;
          break;
        case 8:
          this.icon = Helpers.iconOutForDelevery;
          break;
        case 9:
          this.icon = Helpers.iconCarrierDelivered;
          break;
        case 10:
          this.icon = Helpers.iconTaskWorking;
          break;
        case 11:
          this.icon = Helpers.iconSignature;
          break;
        case 100:
          this.icon = Helpers.iconTaskSuspended;
          break;
        case 101:
          this.icon = Helpers.iconTaskBlocked;
          break;
      }

      if (orderServer.status[0].os_status_order_cod == 10) this.stepCount = 0;
      else this.stepCount = orderServer.status[0].os_status_order_cod - 1;

      //this.buttons = new Buttons.Buttons().getMobileAdminPrepareOrder();
    }
    or_status: string;
    order_desc_prep: IOrderPrepared;
  }

  export class OrderPrepared implements IOrderPrepared {
    order_id: number;
    or_carrier_id: number;
    or_carrier_other: string;
    or_shipping_customer: string;
    or_shipping_address: string;
    or_shipping_prov: string;
    or_shipping_city: string;
    or_shipping_zip: string;
    or_shipping_country: string;
    documents: Documents.IDocumentId[];
    boxes: Boxes.IBoxOrder[];
    or_assignment_date?: string;

    constructor(order: Order, documents: Documents.IDocumentId[]) {
      if (!order) return;
      this.order_id = order.order_id;
      this.or_carrier_id =
        order.vs_details.carrier_id === 0 ? null : order.vs_details.carrier_id;
      this.or_carrier_other = order.vs_details.carrier_other;
      this.or_shipping_address = order.or_shipping_address;
      this.or_shipping_customer = order.or_shipping_customer;
      this.or_shipping_prov = order.or_shipping_prov;
      this.or_shipping_city = order.or_shipping_city;
      this.or_shipping_zip = order.or_shipping_zip;
      this.or_shipping_country = order.or_shipping_country;
      this.documents = documents;
      order.boxes.forEach(
        (item) =>
          (item.ts_box_type_id =
            item.ts_box_type_id != 0 ? item.ts_box_type_id : null)
      );
      this.boxes = order.boxes;
      this.or_assignment_date = order.or_assignment_date;
    }
  }

  export interface BaseOrder {
    order_id: string;
    or_gamma_id: string;
    or_order_date: string;
    or_order_number: string;
    or_quote_date: string;
    or_quote_number: string;
    or_assignement_date: string;
    or_closing_date: string;
  }
}

export interface OrderSignature {
  id: number;
  signature_b64?: string;
}

export interface IObjectives {
  tp_daily_objective: number;
  tp_monthly_objective: number;
  tp_yearly_objective: number;
}

export class Objectives implements IObjectives {
  tp_daily_objective: number;
  tp_monthly_objective: number;
  tp_yearly_objective: number;

  constructor(item: IObjectives | null) {
    if (!item) return;
    this.tp_daily_objective = item.tp_daily_objective;
    this.tp_monthly_objective = item.tp_monthly_objective;
    this.tp_yearly_objective = item.tp_yearly_objective;
  }
}

export interface IUpdatedObjectives {
  working_days: number;
  monthly_objective: number;
  yearly_objective: number;
  net_value: number;
  objective_id: number;
}

export class UpdatedObjectives implements IUpdatedObjectives {
  working_days: number = -1;
  monthly_objective: number = -1;
  yearly_objective: number = -1;
  net_value: number = -1;
  objective_id: number = -1;

  //NotFromServer
  correspondingMonth: string;
  formattedNetValue: any;

  constructor(objective: IUpdatedObjectives | null) {
    if (!objective) {
      return;
    }
    this.working_days = objective.working_days;
    this.monthly_objective = objective.monthly_objective;
    this.yearly_objective = objective.yearly_objective;
    this.net_value = objective.net_value;
    this.objective_id = objective.objective_id;
  }
}

export interface IProgressObjective {
  daily_progress: string;
  monthly_progress: string;
  yearly_progress: string;
  daily_total: string;
  monthly_total: string;
  yearly_total: string;
  daily_percentage: string;
  monthly_percentage: string;
}

export class ProgressObjective implements IProgressObjective {
  daily_progress: string = '-1';
  monthly_progress: string = '-1';
  yearly_progress: string = '-1';
  daily_total: string = '-1';
  monthly_total: string = '-1';
  yearly_total: string = '-1';
  daily_percentage: string = '-1';
  monthly_percentage: string = '-1';

  constructor(obj: IProgressObjective | null) {
    if (!obj) return;

    this.daily_progress = obj.daily_progress;
    this.monthly_progress = obj.monthly_progress;
    this.yearly_progress = obj.yearly_progress;
    this.daily_total = obj.daily_total;
    this.monthly_total = obj.monthly_total;
    this.yearly_total = obj.yearly_total;
    this.daily_percentage = obj.daily_percentage;
    this.monthly_percentage = obj.monthly_percentage;
  }
}

export interface ISetUpdatedObjectives {
  working_days: number;
  monthly_objective: number;
  yearly_objective: number;
  net_value: number;
}

export class SetUpdatedObjectives implements ISetUpdatedObjectives {
  working_days: number = -1;
  monthly_objective: number = -1;
  yearly_objective: number = -1;
  net_value: number = -1;

  constructor(obj: ISetUpdatedObjectives | null) {
    if (!obj) return;
    this.working_days = obj.working_days;
    this.monthly_objective = obj.monthly_objective;
    this.yearly_objective = obj.yearly_objective;
    this.net_value = obj.net_value;
  }
}
export interface IErrors {
  err_id: number;
  err_type: string;
  err_description: string;
  err_value: number;
  err_gravity: number;
}

export class Errors implements IErrors {
  err_id: number;
  err_type: string;
  err_description: string;
  err_value: number;
  err_gravity: number;

  constructor(item: IErrors | null) {
    if (!item) return;
    this.err_id = item.err_id;
    this.err_type = item.err_type;
    this.err_description = item.err_description;
    this.err_value = item.err_value;
    this.err_gravity = item.err_gravity;
  }
}

export interface IOrderErrors {
  oe_description: string;
  oe_error_gravity: number;
  oe_error_type_id: number;
  order_error_id?: number;
  order_error_type_description?: string;
  order_id: number;
}

export class OrderErrors implements IOrderErrors {
  oe_description: string;
  oe_error_gravity: number;
  oe_error_type_id: number;
  order_error_id?: number;
  order_error_type_description?: string;
  order_id: number;
  constructor(item: IOrderErrors | null) {
    if (!item) return;
    this.oe_description = item.oe_description;
    this.oe_error_gravity = item.oe_error_gravity;
    this.oe_error_type_id = item.oe_error_type_id;
    this.order_error_id = item?.order_error_id;
    this.order_error_type_description = item?.order_error_type_description;
    this.order_id = item.order_id;
  }
}

export interface ITypesOfErrors {
  error_type_code: number;
  error_type_desc: string;
  error_type_color: string;
}

export class TypesOfErrors implements ITypesOfErrors {
  error_type_code: number;
  error_type_desc: string;
  error_type_color: string;

  constructor(data: ITypesOfErrors) {
    Object.assign(this, data);
  }
}
