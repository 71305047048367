<kendo-dialog
  *ngIf="openedProductsListBoxes"
  [width]="1300"
  (close)="close()"
  style="overflow: hidden !important"
>
  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <div class="loadingContainer" *ngIf="!loading">
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;

        z-index: 1000;
        position: fixed;

        margin-left: -30px;
        margin-top: -20px;
      "
    >
      <button
        (click)="close()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff;
            bottom: 10px;
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button>
    </div>

    <div class="k-display-flex" style="width: 100%" id="header">
      <div
        class="secondContainer k-w-full boxShadowContainer"
        style="
          width: 100%;
          border: 1px solid #dfdfdf;
          margin-top: 10px;
          border-radius: 20px;
          margin-bottom: 15px;
        "
      >
        <h1 class="page-title" style="top: 15px !important">
          Prodotti da aggiungere
        </h1>
        <hr class="hr-newBlue" />
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
          "
          class="my-4"
          *ngFor="let product of this.productsWithNoBarcode; let i = index"
        >
          <div class="buttonesCircle">
            <p
              style="
                text-align: center;
                padding-top: 4px;
                font-size: 25px;
                color: #4790ff;
              "
            >
              {{ i + 1 }}
            </p>
          </div>
          <div
            class="px-6 py-1 rounded overflow-hidden shadow-lg addBoxShadow"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              width: 500px;
              border-radius: 25px !important;
              align-items: center;
              border-top: 1px solid #e3e3e3;
            "
          >
            <div class="k-display-flex col-8">
              <p
                class="text-gray-700 text-base text-center"
                style="padding-top: 10px; white-space: normal; max-width: 280px"
              >
                {{ product.op_description }}
              </p>
            </div>

            <kendo-dropdownlist
              [data]="numbers"
              (click)="changeProductNumber(product.minQuantity)"
              class="col-3 drop-the-border w-50"
              style="width: 80px !important; color: #0060f1 !important"
              [(ngModel)]="product.minQuantity"
              [placeholder]="product.orQtaNeeded / product.op_qta"
              placeholder="{{ product.orQtaNeeded / product.op_qta }}"
              (ngModelChange)="updateSelectedQTA(product)"
            >
            </kendo-dropdownlist>
            <i
              id="headerAnchor"
              class="col-1 bx bx-plus-circle bx-md k-cursor-pointer"
              style="padding-left: 10px"
              (click)="selectProducts(product)"
            ></i>
          </div>
        </div>
      </div>

      <div
        class="inBetweenContainer"
        style="
          display: flex;
          align-items: center;
          margin-left: 10px;
          margin-right: 10px;
        "
      >
        <ion-icon
          name="swap-horizontal-outline"
          [ngClass]="{ 'spin-mirror': isSpinning }"
          [ngClass]="{ 'spin-mirrorReverse': isSpinningReverse }"
          style="
            font-size: 50px;

            border-radius: 40px;
            padding: 5px;
            color: #7fa7f1;
            background-color: #deebff;
          "
        ></ion-icon>
      </div>
      <div
        class="firstContainer boxShadowReverseContainer k-w-full"
        style="
          width: 100%;
          border: 1px solid #dfdfdf;
          margin-top: 10px;
          border-radius: 20px;
          margin-bottom: 15px;
        "
      >
        <h1 class="page-title" style="top: 15px !important">
          Prodotti aggiunti
        </h1>
        <hr class="hr-newBlue" />
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
          "
          class="my-4"
          *ngFor="
            let product of this.productsWithMatchingBarcode;
            let i = index
          "
        >
          <div class="buttonesCircle">
            <p
              style="
                text-align: center;
                padding-top: 4px;
                font-size: 25px;
                color: #4790ff;
              "
            >
              {{ i + 1 }}
            </p>
          </div>
          <div
            class="px-6 py-1 rounded overflow-hidden shadow-lg addBoxShadow"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              width: 500px;
              border-radius: 25px !important;
              align-items: center;
              border-top: 1px solid #e3e3e3;
            "
          >
            <div class="k-display-flex col-8">
              <p
                class="text-gray-700 text-base text-center"
                style="padding-top: 10px; white-space: normal; max-width: 280px"
              >
                {{ product.op_description }}
              </p>
            </div>
            <kendo-dropdownlist
              [data]="numbers"
              (click)="changeProductNumber(product.minQuantity)"
              class="col-3 drop-the-border w-50"
              style="width: 80px !important; color: #0060f1 !important"
              [(ngModel)]="product.minQuantity"
              (ngModelChange)="updateSelectedQTA(product)"
            >
              <ng-template></ng-template>
            </kendo-dropdownlist>

            <i
              class="col-1 bx bx-minus-circle bx-md k-cursor-pointer"
              style="padding-left: 10px"
              (click)="selectMatchingProducts(product)"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>
