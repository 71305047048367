// src/app/services/search-service.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class SearchService {
  
  private searchKey: string = '';
  private filterText: string = '';

  setSearchKey(key: string): void {
    this.searchKey = key;
  }

  getSearchKey(): string {
    return this.searchKey;
  }

  clearSearchKey(): void {
    this.searchKey = '';
  }

  setFilterText(text: string): void {
    this.filterText = text;
    console.log('Set the filterText', this.filterText);
  }

  getFilterText(): string {
    return this.filterText;
  }

  clearFilterText(): void {
    this.filterText = '';
  }

}
