import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { Error } from 'src/app/models/error.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Statistics } from 'src/app/models/stats';
import { Subscription } from 'rxjs';
import { PickerService } from 'src/app/services/picker.service';
import { Chart, registerables } from 'chart.js';
import { ViewportScroller } from '@angular/common';
//@ts-ignore
import charts from './stats.json';
import { isSameYear, parseISO } from 'date-fns';
import { StatisticsService } from 'src/app/services/shared/statistics.service';
import { __assign } from 'tslib';
import {
  ClosedOrdersStatistics,
  GeneralStatistics,
  OrdersByOperator,
  OrdersCompletedByOperator,
  OrdersDividedByCarrier,
  ShippedOrdersClosed,
  StatCompletedOrdersByCarrier,
} from 'src/app/models/Statistics.models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-statistiche',
  templateUrl: './statistiche.component.html',
  styleUrls: ['./statistiche.component.scss'],
})

export class StatisticheComponent implements OnInit {
  
  @ViewChild('totaleOperatoriCanvas')
  private totaleOperatoriCanvas!: ElementRef;
  @ViewChild('totaleCorrieriCanvas') private totaleCorrieriCanvas!: ElementRef;
  @ViewChild('corriere2Canvas') private corriere2Canvas!: ElementRef;
  @ViewChild('corriereCanvas') private corriereCanvas!: ElementRef;
  @ViewChild('operatoreCanvas') private operatoreCanvas!: ElementRef;
  @ViewChild('numeroErroriCanvas') private numeroErroriCanvas!: ElementRef;
  @ViewChild('percentualeErroriCanvas')
  private percentualeErroriCanvas!: ElementRef;
  @ViewChild('ordiniEvasiCanvas') private ordiniEvasiCanvas!: ElementRef;
  @ViewChild('colliSpeditiCanvas') private colliSpeditiCanvas!: ElementRef;
  @ViewChild('giorniLavorativiCanvas')
  private giorniLavorativiCanvas!: ElementRef;
  @ViewChild('mediaSpedizioniCanvas')
  private mediaSpedizioniCanvas!: ElementRef;
  @ViewChild('mediaOrdiniCanvas') private mediaOrdiniCanvas!: ElementRef;

  public ordersStock: Orders.IOrderClosed[] = [];
  public filteredOrder: Orders.IOrderClosed[];
  public customers: string[];
  public dates: string[];
  public opened = false;
  public loading: boolean = false;
  public isSearched: boolean = false;
  public pageSize = 20;
  public searchString = 'Filtra ordini per cliente';
  public searchDate: Date = new Date();
  public toDate = 'al giorno';
  public datasets: any;
  public data: any;
  public salesChart;
  public clicked: boolean = true;
  public clicked1: boolean = false;
  public gridView: GridDataResult;
  public report: Statistics.Report;
  public corrieri: Statistics.OrdiniCorriere;
  public operatori: Statistics.OrdiniOperatore;
  public skip = 0;
  public orders: Orders.OrderSignature[] = [];
  private subscriptions: Subscription[] = [];

  selectedOrders: Orders.OrderSelectedToSign[] = [];
  totaleOperatoriChart: any;
  totaleCorrieriChart: any;
  corriere2: any;
  corriere: any;
  operatore: any;
  numeroErroriChart: any;
  percentualeErroriChart: any;
  ordiniEvasiChart: any;
  colliSpeditiChart: any;
  giorniLavorativiChart: any;
  mediaSpedizioniChart: any;
  mediaOrdiniChart: any;
  filterTerm: string | undefined;
  clickedOrder: string;
  clientOrder: string = 'Cliente';
  customer: string = '';
  date: string = '';
  showAll: string = 'Mostra tutti';
  showAll2: string = 'Mostra tutti';
  customerIndex: number;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  errori: Error.Error = new Error.Error(
    /* code */ 1,
    /* type */ 'Tipo di errore',
    /* description */ "Descrizione dell'errore",
    /* value */ 42,
    /* color */ '#FF0000', // Colore personalizzato
    /* setColor */ true // Imposta il colore sul documento
  );

  ordini: Orders.IOrder;
  chosenYear: string = '2024';
  index: number;
  years: string[] = [];
  monthNames = [
    {
      id: 1,
      name: 'Gennaio',
    },
    {
      id: 2,
      name: 'Febbraio',
    },
    {
      id: 3,
      name: 'Marzo',
    },
    {
      id: 4,
      name: 'Aprile',
    },
    {
      id: 5,
      name: 'Maggio',
    },
    {
      id: 6,
      name: 'Giugno',
    },
    {
      id: 7,
      name: 'Luglio',
    },
    {
      id: 8,
      name: 'Agosto',
    },
    {
      id: 9,
      name: 'Settembreio',
    },
    {
      id: 10,
      name: 'Ottobre',
    },
    {
      id: 11,
      name: 'Novembre',
    },
    {
      id: 12,
      name: 'Dicembre',
    },
  ];

  public closedOrders: Orders.IOrderClosed[] = [];
  public completedOrdersByCarrier: StatCompletedOrdersByCarrier[] = [];

  urlServer: string = 'http://diakonix.connecta.ovh:88/api/services';
  environment = environment.urlServer;

  constructor(
    private orderSrv: OrdersService,
    private route: ActivatedRoute,
    private router: Router,
    public closeSrv: OpenModalService,
    private render: Renderer2,
    private translate: TranslateService,
    private openOrdersServ: OrdersService,
    private viewportScroller: ViewportScroller,
    public openModal: OpenModalService,
    public pageService: PickerService,
    public statistics: StatisticsService
  ) {
    Chart.register(...registerables);
  }

  ngAfterViewInit() {
    // this.corriereMethod();
    // this.totaleOperatori();
    // this.corriere2Method();
    // this.operatoreMethod();
    // this.ordiniEvasi();
    // this.colliSpediti();
    // this.giorniLavorativi();
    // this.ordiniEvasi();
    // this.mediaSpedizioni();
    // this.mediaOrdini();
    // this.numeroErrori();
    // if (charts.percentuale_errori) {
    //   this.percentualeErrori();
    // } else {
    //   console.error('Errore: charts.percentuale_errori è undefined o null');
    // }
    //this.percentualeErrori();
  }

  scroll(id: string): void {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  initializeYears() {
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: 20 }, (_, i) =>
      (currentYear - i).toString()
    );
    console.log('Years are', this.years);
  }

  ngOnInit(): void {
    this.environment === this.urlServer
      ? console.log('Esti boss')
      : console.log('Nu esti boss');
    this.getStatisticOrdersClosed();
    const orderSubscription = this.orderSrv
      .getCombinedOrders()
      .subscribe((data) => {
        console.log('Data is', data);
      });
    this.subscriptions.push(orderSubscription);
    this.getOrders();
    this.initializeYears();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    Chart.unregister();
  }

  updateErroriChart() {
    this.numeroErroriChart.data.datasets[0].data = [this.errori.err_type];
    this.percentualeErroriChart.data.datasets[0].data = [this.errori.err_type];
    this.numeroErroriChart.update();
    this.percentualeErroriChart.update();
  }

  totale_operatori_data = charts.ordini_operatore.data.map((user) => {
    const nome = user.nome;
    const datiTotali = user.dati ? this.calcTot(user.dati) : 0;
    return {
      nome: nome,
      datiTotali: datiTotali,
    };
  });

  calcTot(array: number[]): number {
    return array.reduce((acc, valore) => acc + valore, 0);
  }

  totaleOperatori(labels?: string[], data?: number[]) {
    this.totaleOperatoriChart = new Chart(
      this.totaleOperatoriCanvas.nativeElement,
      {
        type: 'polarArea',
        data: {
          labels: labels,
          datasets: [
            {
              label: '',
              //data: this.totale_operatori_data.map((user) => user.datiTotali),
              data: data,
              backgroundColor: charts.totale_operatori.bkgColor,
              hoverOffset: charts.totale_operatori.hoverOffset,
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: charts.totale_operatori.title,
              font: {
                size: charts.font.sizeM,
                weight: +charts.font.weight,
                family: charts.font.family,
              },
              padding: {
                top: charts.font.top,
                bottom: charts.font.bottom,
              },
            },
            legend: {
              display: true,
              labels: {
                font: {
                  size: charts.font.sizeS,
                  family: charts.font.family,
                },
              },
            },
          },
          scales: {
            r: {
              suggestedMax: charts.font.max,
              suggestedMin: charts.font.min,
            },
          },
        },
      }
    );
  }

  totaleCorrieri(labels?: string[], data?: number[], total?: number) {
    this.totaleCorrieriChart = new Chart(
      this.totaleCorrieriCanvas.nativeElement,
      {
        type: 'polarArea',
        data: {
          labels: charts.totale_corrieri.labels,
          datasets: [
            {
              label: '',
              backgroundColor: charts.totale_corrieri.bkgColor,
              borderWidth: charts.totale_corrieri.borderWidth,
              data: data,
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: this.translate.instant(charts.totale_corrieri.title),
              font: {
                size: charts.font.sizeM,
                weight: +charts.font.weight,
                family: charts.font.family,
              },
              padding: {
                top: charts.font.top,
                bottom: charts.font.bottom,
              },
            },
            legend: {
              display: true,
              labels: {
                font: {
                  size: charts.font.sizeS,
                  family: charts.font.family,
                },
              },
            },
          },
          scales: {
            r: {
              pointLabels: {
                color: 'red',
              },
              suggestedMax: charts.font.max,
              suggestedMin: charts.font.min,
            },
          },
        },
      }
    );
  }

  corriereMethod(carrierDesc?: string[], dataForCarrier?: any[]) {
    this.corriere = new Chart(this.corriereCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: charts.ordini_corriere.labels, // Assuming charts.ordini_corriere.labels is predefined
        datasets: charts.ordini_corriere.datasets.map((dataset, index) => ({
          label: carrierDesc[index], // Dynamically assign label from provided array
          fill: false,
          backgroundColor: dataset.backgroundColor,
          borderColor: dataset.borderColor,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: dataset.borderDashOffset,
          borderJoinStyle: 'miter',
          pointBorderColor: dataset.pointBorderColor,
          pointBackgroundColor: dataset.pointBackgroundColor,
          pointBorderWidth: dataset.pointBorderWidth,
          pointHoverRadius: dataset.pointHoverRadius,
          pointHoverBackgroundColor: dataset.pointHoverBackgroundColor,
          pointHoverBorderColor: dataset.pointHoverBorderColor,
          pointHoverBorderWidth: dataset.pointHoverBorderWidth,
          pointRadius: dataset.pointRadius,
          pointHitRadius: dataset.pointHitRadius,
          data: dataForCarrier[index], // Dynamically assign data from provided array
          spanGaps: false,
          tension: 0.1,
        })),
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: charts.ordini_corriere.subtitle,
            font: {
              size: charts.font.sizeM,
              weight: +charts.font.weight,
              family: charts.font.family,
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom,
            },
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family,
              },
            },
          },
        },
      },
    });
  }

  corriere2Method() {
    this.corriere2 = new Chart(this.corriere2Canvas.nativeElement, {
      type: 'bar',
      data: {
        labels: charts.totale_corriere.labels,
        datasets: charts.ordini_corriere.datasets.map((dataset) => ({
          label: dataset.label,
          data: dataset.data,
          backgroundColor: dataset.backgroundColor,
        })),
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: charts.totale_corriere.subtitle,
            font: {
              size: charts.font.sizeM,
              weight: +charts.font.weight,
              family: charts.font.family,
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom,
            },
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family,
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    });
  }

  operatoreMethod(data?: OrdersCompletedByOperator[]): void {
    console.log('Data coming to operatore method', data);

    const monthNames = [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ];

    const operatorMap = new Map<string, number[]>();

    // Initialize the map with empty arrays for each operator
    data.forEach((order) => {
      const operatorName = `${order.ac_first_name} ${order.ac_last_name}`;
      if (!operatorMap.has(operatorName)) {
        operatorMap.set(operatorName, Array(12).fill(0));
      }
      const monthIndex = monthNames.indexOf(order.stat_month);
      if (monthIndex !== -1) {
        operatorMap.get(operatorName)[monthIndex] = order.nr_orders;
      }
    });

    const labels = monthNames;
    const datasets = Array.from(operatorMap.entries()).map(
      ([operatorName, orders], index) => {
        const colorKey = `color${index + 1}`;
        return {
          label: operatorName,
          backgroundColor: charts.colors[colorKey] || 'rgba(75, 192, 192, 0.2)', // Fallback color
          borderColor: charts.colors[colorKey] || 'rgba(75, 192, 192, 1)', // Fallback color
          data: orders,
        };
      }
    );

    if (this.operatore) {
      this.operatore.destroy();
    }

    this.operatore = new Chart(this.operatoreCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Ordini Completati per Operatore',
            font: {
              size: charts.font.sizeM,
              weight: +charts.font.weight,
              family: charts.font.family,
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom,
            },
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family,
              },
            },
          },
        },
      },
    });
  }

  totaleOrdiniEvasi = charts.ordini_evasi.data.reduce(
    (acc, value) => acc + value,
    0
  );

  ordiniEvasi(labels?: string[], data?: number[], total?: number) {
    this.ordiniEvasiChart = new Chart(this.ordiniEvasiCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: charts.ordini_evasi.labels,
        datasets: [
          {
            label: charts.ordini_evasi.subtitle,
            data: data,
            backgroundColor: charts.ordini_evasi.bkgColor,
            borderColor: charts.ordini_evasi.borderColor,
            borderWidth: charts.ordini_evasi.borderWidth,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          title: {
            display: true,
            text: charts.ordini_evasi.title + `${total}`,
            font: {
              size: charts.font.sizeM,
              weight: +charts.font.weight,
              family: charts.font.family,
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom,
            },
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family,
              },
            },
          },
        },
      },
    });
  }

  totaleColliSpediti = charts.colli_spediti.data.reduce(
    (acc, value) => acc + value,
    0
  );

  colliSpediti(labels?: string[], data?: number[], total?: number) {
    console.log('COLIISPEDITI ALOO');
    this.colliSpeditiChart = new Chart(this.colliSpeditiCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: charts.colli_spediti.labels,
        datasets: [
          {
            label: charts.colli_spediti.subtitle,
            data: data,
            backgroundColor: charts.colli_spediti.bkgColor,
            borderColor: charts.colli_spediti.borderColor,
            borderWidth: charts.colli_spediti.borderWidth,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          title: {
            display: true,
            text: charts.colli_spediti.title + `${total}`,
            font: {
              size: charts.font.sizeM,
              weight: +charts.font.weight,
              family: charts.font.family,
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom,
            },
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family,
              },
            },
          },
        },
      },
    });
  }

  totaleGiorniLavorativi = charts.giorni_lavorativi.data.reduce(
    (acc, value) => acc + value,
    0
  );

  giorniLavorativi() {
    this.giorniLavorativiChart = new Chart(
      this.giorniLavorativiCanvas.nativeElement,
      {
        type: 'bar',
        data: {
          labels: charts.giorni_lavorativi.labels,
          datasets: [
            {
              label: charts.giorni_lavorativi.subtitle,
              data: charts.giorni_lavorativi.data,
              backgroundColor: charts.giorni_lavorativi.bkgColor,
              borderColor: charts.giorni_lavorativi.borderColor,
              borderWidth: charts.giorni_lavorativi.borderWidth,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            title: {
              display: true,
              text:
                charts.giorni_lavorativi.title +
                `${this.totaleGiorniLavorativi}`,
              font: {
                size: charts.font.sizeM,
                weight: +charts.font.weight,
                family: charts.font.family,
              },
              padding: {
                top: charts.font.top,
                bottom: charts.font.bottom,
              },
            },
            legend: {
              display: true,
              labels: {
                font: {
                  size: charts.font.sizeS,
                  family: charts.font.family,
                },
              },
            },
          },
        },
      }
    );
  }

  media_spedizioni = this.calcMedia(
    charts.colli_spediti.data,
    charts.giorni_lavorativi.data
  );
  totaleMediaSpedizioni = this.media_spedizioni.reduce(
    (acc, value) => acc + value,
    0
  );
  mediaMediaSpedizioni =
    this.totaleMediaSpedizioni / this.media_spedizioni.length;

  mediaSpedizioni(labels?: string[], data?: number[], total?: number) {
    this.mediaSpedizioniChart = new Chart(
      this.mediaSpedizioniCanvas.nativeElement,
      {
        type: 'bar',
        data: {
          labels: charts.media_spedizioni.labels,
          datasets: [
            {
              label: charts.media_spedizioni.subtitle,
              data: data,
              backgroundColor: charts.media_spedizioni.bkgColor,
              borderColor: charts.media_spedizioni.borderColor,
              borderWidth: charts.media_spedizioni.borderWidth,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            title: {
              display: true,
              text: charts.media_spedizioni.title + total,
              font: {
                size: charts.font.sizeM,
                weight: +charts.font.weight,
                family: charts.font.family,
              },
              padding: {
                top: charts.font.top,
                bottom: charts.font.bottom,
              },
            },
            legend: {
              display: true,
              labels: {
                font: {
                  size: charts.font.sizeS,
                  family: charts.font.family,
                },
              },
            },
          },
        },
      }
    );
  }

  media_ordini = this.calcMedia(
    charts.ordini_evasi.data,
    charts.giorni_lavorativi.data
  );
  totaleMediaOrdini = this.media_ordini.reduce((acc, value) => acc + value, 0);
  mediaMediaOrdini = this.totaleMediaOrdini / this.media_ordini.length;

  mediaOrdini(labels?: string[], data?: number[], total?: number) {
    this.mediaOrdiniChart = new Chart(this.mediaOrdiniCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: charts.media_ordini.labels,
        datasets: [
          {
            label: charts.media_ordini.subtitle,
            data: data,
            backgroundColor: charts.media_ordini.bkgColor,
            borderColor: charts.media_ordini.borderColor,
            borderWidth: charts.media_ordini.borderWidth,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          title: {
            display: true,
            text: charts.media_ordini.title + total,
            font: {
              size: charts.font.sizeM,
              weight: +charts.font.weight,
              family: charts.font.family,
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom,
            },
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family,
              },
            },
          },
        },
      },
    });
  }

  calcMedia(total, days) {
    return total.map((value, index) => {
      if (index < days.length) {
        return value / days[index];
      } else {
        return 0;
      }
    });
  }

  totaleErrori = charts.numero_errori.data.reduce(
    (acc, value) => acc + value,
    0
  );

  numeroErrori() {
    this.numeroErroriChart = new Chart(this.numeroErroriCanvas.nativeElement, {
      type: 'doughnut',
      data: {
        labels: charts.numero_errori.labels,
        datasets: [
          {
            label: charts.numero_errori.subtitle,
            data: charts.numero_errori.data,
            //data: this.errori.tp_error,
            backgroundColor: charts.numero_errori.bkgColor,
            hoverBackgroundColor: charts.numero_errori.hoverbkgColor,
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: charts.numero_errori.title + `${this.totaleErrori}`,
            font: {
              size: charts.font.sizeM,
              weight: +charts.font.weight,
              family: charts.font.family,
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom,
            },
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family,
              },
            },
          },
        },
      },
    });
  }

  percentualeErrorj = this.calcPercentuale(
    charts.numero_errori.data,
    charts.totale_operatori.data
  );
  totalPercentualeErrori = this.percentualeErrorj.reduce(
    (acc, value) => acc + value,
    0
  );
  mediaPercentualeErrori =
    this.percentualeErrorj.length > 0
      ? (this.totaleErrori / this.totaleOrdiniEvasi) * 100
      : 0;

  percentualeErrori() {
    if (charts.percentuale_errori && charts.percentuale_errori.data) {
      console.log('Il controllo ha avuto successo!!');
      console.log('percentualeErrorj:', this.percentualeErrorj);
      console.log('totalPercentualeErrori:', this.totalPercentualeErrori);
      console.log('mediaPercentualeErrori:', this.mediaPercentualeErrori);
      this.percentualeErroriChart = new Chart(
        this.percentualeErroriCanvas.nativeElement,
        {
          type: 'doughnut',
          data: {
            labels: charts.percentuale_errori.labels,
            datasets: [
              {
                label: charts.percentuale_errori.subtitle,
                data: this.percentualeErrorj,
                backgroundColor: charts.percentuale_errori.bkgColor,
                hoverBackgroundColor: charts.percentuale_errori.hoverbkgColor,
              },
            ],
          },
          options: {
            plugins: {
              title: {
                display: true,
                text:
                  charts.percentuale_errori.title +
                  `${this.mediaPercentualeErrori.toFixed(2)}%`,
                font: {
                  size: charts.font.sizeM,
                  weight: +charts.font.weight,
                  family: charts.font.family,
                },
                padding: {
                  top: charts.font.top,
                  bottom: charts.font.bottom,
                },
              },
              legend: {
                display: true,
                labels: {
                  font: {
                    size: charts.font.sizeS,
                    family: charts.font.family,
                  },
                },
              },
            },
          },
        }
      );
    } else {
      console.error(
        'Errore: charts.percentuale_errori o charts.percentuale_errori.data è undefined o null'
      );
    }
  }

  calcPercentuale(totalErrors, totalOrders) {
    return totalErrors.map((errors, index) => {
      if (index < totalOrders.length && totalOrders[index] !== 0) {
        return (errors / totalOrders[index]) * 100;
      } else {
        return 0;
      }
    });
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  private loadItems(): void {}

  getOrders() {
    this.orderSrv.getClosedOrders().subscribe((ordersData) => {
      this.ordersStock = ordersData;
      this.dates = [
        ...new Set(
          ordersData.map((date) => {
            let formattedDate = formatDate(
              date?.or_order_date,
              'dd/MM/yyyy',
              'it'
            );

            return formattedDate;
          })
        ),
      ];
      this.customers = [
        ...new Set(
          ordersData.map((client) => {
            return client.or_customer;
          })
        ),
      ];
      this.customers.unshift(this.showAll);
      this.dates.unshift(this.showAll2);
      console.debug('Orders are: ', this.ordersStock);
      console.debug('From server: ', ordersData);
      this.ordersStock = this.ordersStock.filter((order) => {
        const parsedClosedDate = parseISO(order.or_closed_date);
        const chosenYear = +this.chosenYear; // Ensure this is a number

        // Compare the year directly
        return parsedClosedDate.getFullYear() === chosenYear;
      });

      console.log('OrdersStock are', this.ordersStock);
      this.loading = false;
    });
  }

  public updateOptions() {
    this.salesChart.data.datasets[0].data = this.data;
    this.salesChart.update();
  }

  changeYear(e) {
    console.log('VEDEM EVENTUl', e);
    this.chosenYear = e;
    this.getStatisticOrdersClosed();
  }

  selectedMonth: string = '';
  monthlyChange(e) {
    console.log('VEDEM EVENTIMENTUL', e);
    this.chosenYear = e;
  }

  downloadChart(canvasId: string, filename: string) {
    const canvas = this[canvasId].nativeElement;
    const width = canvas.width;
    const height = canvas.height;
    const tempCanvas = document.createElement('canvas');
    const tempContext = tempCanvas.getContext('2d');
    tempCanvas.width = width;
    tempCanvas.height = height;
    tempContext.fillStyle = '#ffffff';
    tempContext.fillRect(0, 0, width, height);
    tempContext.drawImage(canvas, 0, 0);
    const url = tempCanvas.toDataURL('image/jpeg', 1.0);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename + ' (' + this.chosenYear + ')' + '.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  ordiniEvasioneMonthly: ClosedOrdersStatistics = new ClosedOrdersStatistics(
    null
  );
  totalClosedOrders: number = 0;
  currentMonth = new Date();
  currentMonthOrdersClosed: any;
  previousMonthOrdersClosed: any;
  percentageForPreviousMonth: number = 0;

  ordiniShipyMonthly: ShippedOrdersClosed = new ShippedOrdersClosed(null);
  currentMonthSpediti: any;
  previousMonthShippingClosed: any;
  percentageShippingPreviousMonth: number = 0;

  ordersCompletedByOperator: OrdersCompletedByOperator[] = [];

  ordersByOperator: OrdersByOperator[] = [];
  ordersDividedByCarrier: OrdersDividedByCarrier[] = [];

  generalStatistics: GeneralStatistics = new GeneralStatistics(null);
  getStatisticOrdersClosed() {
    this.statistics
      .getCompletedOrdersByCarrier(this.chosenYear)
      .subscribe((data) => {
        console.log('Completed orders by carrier', data);
        this.completedOrdersByCarrier = data.data;

        const monthNames = [
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
        ];

        const currentDate = new Date();
        const currentMonthIndex = currentDate.getMonth();
        const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
        const currentMonthName = monthNames[currentMonthIndex];
        const previousMonthName = monthNames[previousMonthIndex];

        console.log('Current month is', currentMonthName);
        console.log('Previous month is', previousMonthName);

        const monthlyData = this.completedOrdersByCarrier.map(
          (order) => order.stat_nr_orders
        );
        const monthlyLabels = this.completedOrdersByCarrier.map(
          (order) => order.carrier_desc
        );

        let totalClosedOrders = monthlyData.reduce((acc, val) => acc + val, 0);
        console.log('Total closed orders', totalClosedOrders);

        this.totaleCorrieri(monthlyLabels, monthlyData, totalClosedOrders);
      });
    this.statistics
      .getAverageClosedOrdersStat(this.chosenYear)
      .subscribe((data) => {
        let mediaOrdini: any;
        let totalMediaOrdini: number = 0;
        if (data) mediaOrdini = data.data;
        console.log('Ordini evas', mediaOrdini);

        const monthlyData = mediaOrdini.stat_monthly_closed_orders.map(
          (order) => order.stat_total_monthly_media_orders
        );
        const monthlyLabels = mediaOrdini.stat_monthly_closed_orders.map(
          (order) => order.stat_month
        );
        totalMediaOrdini = monthlyData.reduce((acc, val) => acc + val, 0);
        console.log('total media orders', totalMediaOrdini);
        // Update the chart
        this.mediaOrdini(monthlyLabels, monthlyData, totalMediaOrdini);
      });

    this.statistics
      .getAverageShipmentsStat(this.chosenYear)
      .subscribe((data) => {
        if (data) this.ordiniShipyMonthly = data.data;
        console.log('Ordini shipped', this.ordiniShipyMonthly);

        const monthNames = [
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
        ];

        const currentDate = new Date();
        const currentMonthIndex = currentDate.getMonth();
        const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
        const currentMonthName = monthNames[currentMonthIndex];
        const previousMonthName = monthNames[previousMonthIndex];

        console.log('Current month is', currentMonthName);
        console.log('Previous month is', previousMonthName);

        this.currentMonthSpediti =
          this.ordiniShipyMonthly.stat_monthly_closed_orders.filter((order) => {
            return order.stat_month === currentMonthName;
          }) || [];

        this.previousMonthShippingClosed =
          this.ordiniEvasioneMonthly.stat_monthly_closed_orders.filter(
            (order) => {
              return order.stat_month === previousMonthName;
            }
          ) || [];

        const currentMonthTotalOrders =
          this.currentMonthSpediti.length > 0
            ? this.currentMonthSpediti[0].stat_total_monthly_media_orders
            : 0;
        const previousMonthTotalOrders =
          this.previousMonthShippingClosed.length > 0
            ? this.previousMonthOrdersClosed[0].stat_total_monthly_media_orders
            : 0;

        let percentagePreviousMonth = 0;
        if (previousMonthTotalOrders > 0) {
          percentagePreviousMonth =
            (currentMonthTotalOrders / previousMonthTotalOrders) * 100;
        }

        console.log('Current month orders closed is', this.currentMonthSpediti);
        console.log(
          'Previous month orders closed is',
          this.previousMonthShippingClosed
        );
        console.log(
          'Percentage for previous month is',
          percentagePreviousMonth
        );
        this.percentageForPreviousMonth = percentagePreviousMonth;

        const monthlyData =
          this.ordiniEvasioneMonthly.stat_monthly_closed_orders.map(
            (order) => order.stat_total_monthly_orders
          );
        const monthlyLabels =
          this.ordiniEvasioneMonthly.stat_monthly_closed_orders.map(
            (order) => order.stat_month
          );

        let totaleShipment = monthlyData.reduce((acc, val) => acc + val, 0);
        console.log('Total closed orders', this.totalClosedOrders);
        // Update the chart
        this.mediaSpedizioni(monthlyLabels, monthlyData, totaleShipment);
      });

    this.statistics.getBoxesStatistic(this.chosenYear).subscribe((data) => {
      let boxesStatistic: any;
      let totaleBoxes: number = 0;
      if (data) boxesStatistic = data.data;
      console.log('BOXES STATISTIC', boxesStatistic);

      const monthlyData = boxesStatistic.stat_monthly_boxes_delivered.map(
        (order) => order.stat_total_monthly_boxes
      );
      const monthlyLabels = boxesStatistic.stat_monthly_boxes_delivered.map(
        (order) => order.stat_month
      );
      totaleBoxes = monthlyData.reduce((acc, val) => acc + val, 0);
      console.log('total boxes orders', totaleBoxes);
      // Update the chart
      this.colliSpediti(monthlyLabels, monthlyData, totaleBoxes);
    });

    this.statistics
      .getStatisticOrderClosed(this.chosenYear)
      .subscribe((data) => {
        if (data) this.ordiniEvasioneMonthly = data.data;
        console.log('Ordini evas', this.ordiniEvasioneMonthly);

        const monthNames = [
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
        ];

        const currentDate = new Date();
        const currentMonthIndex = currentDate.getMonth();
        const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
        const currentMonthName = monthNames[currentMonthIndex];
        const previousMonthName = monthNames[previousMonthIndex];

        console.log('Current month is', currentMonthName);
        console.log('Previous month is', previousMonthName);

        this.currentMonthOrdersClosed =
          this.ordiniEvasioneMonthly.stat_monthly_closed_orders.filter(
            (order) => {
              return order.stat_month === currentMonthName;
            }
          ) || [];

        this.previousMonthOrdersClosed =
          this.ordiniEvasioneMonthly.stat_monthly_closed_orders.filter(
            (order) => {
              return order.stat_month === previousMonthName;
            }
          ) || [];

        const currentMonthTotalOrders =
          this.currentMonthOrdersClosed.length > 0
            ? this.currentMonthOrdersClosed[0].stat_total_monthly_orders
            : 0;
        const previousMonthTotalOrders =
          this.previousMonthOrdersClosed.length > 0
            ? this.previousMonthOrdersClosed[0].stat_total_monthly_orders
            : 0;

        let percentagePreviousMonth = 0;
        if (previousMonthTotalOrders > 0) {
          percentagePreviousMonth =
            (currentMonthTotalOrders / previousMonthTotalOrders) * 100;
        }

        console.log(
          'Current month orders closed is',
          this.currentMonthOrdersClosed
        );
        console.log(
          'Previous month orders closed is',
          this.previousMonthOrdersClosed
        );
        console.log(
          'Percentage for previous month is',
          percentagePreviousMonth
        );
        this.percentageForPreviousMonth = percentagePreviousMonth;

        const monthlyData =
          this.ordiniEvasioneMonthly.stat_monthly_closed_orders.map(
            (order) => order.stat_total_monthly_orders
          );
        const monthlyLabels =
          this.ordiniEvasioneMonthly.stat_monthly_closed_orders.map(
            (order) => order.stat_month
          );

        this.totalClosedOrders = monthlyData.reduce((acc, val) => acc + val, 0);
        console.log('Total closed orders', this.totalClosedOrders);

        // Update the chart
        this.ordiniEvasi(monthlyLabels, monthlyData, this.totalClosedOrders);
      });

    this.statistics
      .getCompletedOrdersByOperator(this.chosenYear)
      .subscribe((data) => {
        console.log('Data is', data);

        this.ordersCompletedByOperator = data.data;
        console.log(
          'ORdersCompletedByOperator? ',
          this.ordersCompletedByOperator
        );
        this.operatoreMethod(this.ordersCompletedByOperator);
      });

    this.statistics
      .getCompletedOrdersByOperatorNoMonth(this.chosenYear)
      .subscribe((data) => {
        console.log('Orders by operator', data);
        if (!data) return;
        this.ordersByOperator = data.data;
        let dataForChart = this.ordersByOperator.map(
          (order) => order.stat_nr_orders
        );
        console.log('Data for chart', dataForChart);
        let operators = this.ordersByOperator.map(
          (order) => order.ac_first_name + ' ' + order.ac_last_name
        );
        console.log('Operators are', operators);
        this.totaleOperatori(operators, dataForChart);
      });

    this.statistics
      .getOrdersDividedByCarrier(this.chosenYear)
      .subscribe((data) => {
        if (!data) return;
        console.log('Data diviso per corriere', data);

        this.ordersDividedByCarrier = data.data;
        console.log('Orders divided by carrier', this.ordersDividedByCarrier);

        const dataForCarriers = this.ordersDividedByCarrier.map((order) =>
          order.stat_completed_orders_by_carriers.map(
            (order) => order.stat_t_orders
          )
        );
        console.log('Data for carriers divida', dataForCarriers);
        const carrierDesc = this.ordersDividedByCarrier.map(
          (order) => order.stat_carrier_desc
        );

        console.log('Data for carrierDescus', carrierDesc);
        this.corriereMethod(carrierDesc, dataForCarriers);
      });

    this.statistics.getGeneralStatistics(this.chosenYear).subscribe((data) => {
      if (!data) return;
      this.generalStatistics = data.data;
      console.log('general statistics', this.generalStatistics);
    });
  }
}
