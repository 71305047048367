import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { Observable, Subscription } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Orders, ProgressObjective } from 'src/app/models/Orders.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { AuthService } from 'src/app/services/auth.service';
import { Objectives } from 'src/app/models/Orders.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { isSameDay, parseISO } from 'date-fns';

@Component({
  selector: 'app-warehouse-orders',
  templateUrl: './warehouse-orders.component.html',
  styleUrls: ['./warehouse-orders.component.scss'],
})
export class WarehouseOrdersComponent {
  public gridView: GridDataResult;
  public today = new Date();
  public setPercentLabel: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center',
  };
  public label: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center',
  };
  //  public labelDaily: LabelSettings = {
  //    visible: true,
  //   format: value => `${this.percentageLavoration.toFixed(2)}%`,
  //   position: 'center',
  //   }
  public label_open: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'end',
  };

  objectivePerday = {
    day: new Date(),
    objective: 0,
    objectiveToday: 0,
  };

  linkURL = environment.production
    ? 'http://salvatempo.livith.it:88/warehouseorders'
    : 'http://salvatempo.connecta.ovh/warehouseorders';
  openOrders: Orders.IOrderOpenList[] = [];
  closedOrders: Orders.IOrderClosed[] = [];
  bigOrdersClosedToday: Orders.IOrderClosed[] = [];
  myDate = new Date();
  setDate: string;
  currentGoalNumber: number;
  currentNumber: 20;
  status_perc: number = 100;
  complete_state: string = 'Complete';
  ipAddress = '127.0.0.1';
  screenUser: string;
  screenPass: string;
  clickedId: any;
  timer = null;
  refreshInterval: number = 120000;
  searchText: string = '';
  filterTerm: string | undefined;
  progressValue: number;
  obiettivi: Objectives;
  objectives: Objectives = new Objectives(null);
  timerSubscription: Subscription;
  tpDailyProgress: number = 0;
  tpMonthlyProgress: number = 0;
  moneyValueToday: any;
  startingDay = 0;
  netValue: any;
  netValueLavoration: number = 0;
  percentageLavoration: number = 0;
  bigOrdersClosed;
  ordersToSign: Orders.OrderSignature[] = [];
  objectiveProgress: ProgressObjective = new ProgressObjective(null);

  constructor(
    private openOrdersServ: OrdersService,
    private router: Router,
    public openModal: OpenModalService,
    public authServ: AuthService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.getOpenOrders();

    this.getObjectiveProgress();
    this.getOrdersToSign();

    console.log('Ojbectives per day are', this.objectivePerday);
    // this.calculateMonthlyObjective();
    clearTimeout(this.timer);
    this.timerSubscription = this.getConfig().subscribe((config) => {
      this.refreshInterval = config.warehouseTimer;
      this.timer = setInterval(() => {
        this.getOpenOrders();

        this.getOrdersToSign();

        this.getObjectiveProgress();
      }, this.refreshInterval);
    });
    // this.openOrdersServ.getObjectives().subscribe((obj: Objectives) => {
    //   this.obiettivi = obj;
    // });
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    this.timerSubscription.unsubscribe();
  }

  // getObjectives() {
  //   this.openOrdersServ.getObjectiveProgress().subscribe((data: any) => {
  //     console.log('Data: ', data);
  //     this.tpDailyProgress =
  //       (data.dailyProgress / this.obiettivi.tp_daily_objective) * 100;
  //     this.tpMonthlyProgress =
  //       (data.monthlyProgress / this.obiettivi.tp_monthly_objective) * 100;
  //   });
  // }

  getfirstObjectives() {
    this.openOrdersServ.getObjectives().subscribe((Data: any) => {
      console.log('Data second: ', Data);
      this.objectives = Data.data;
      this.objectivePerday.objective = this.objectives.tp_monthly_objective;
      console.log('Objectives are', this.objectives);
    });
  }

  getConfig(): Observable<any> {
    return this.http.get<any>('/assets/config.json');
  }

  getOrdersToSign() {
    this.openOrdersServ.getOrdersToSign().subscribe((ordersToSign) => {
      this.ordersToSign = ordersToSign;
      const todayDate = new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate()
      );
      this.ordersToSign = this.ordersToSign.filter((order) => {
        const closedDate = parseISO(order.or_assigned_date);
        const closedDateWithoutTime = new Date(
          closedDate.getFullYear(),
          closedDate.getMonth(),
          closedDate.getDate()
        );
        return isSameDay(closedDateWithoutTime, todayDate);
      });

      console.log('orders to sign', this.ordersToSign);
    });
  }

  getCloseOrders() {
    this.openOrdersServ.getClosedOrders().subscribe((ordersData) => {
      this.closedOrders = ordersData;
      console.log('Orders data is', ordersData);
      console.log('Today is', this.today);
      // this.closedOrders = this.closedOrders.filter((order) => order);
      this.closedOrders.forEach((order) => {
        order.status_perc = this.status_perc;
        order.complete_state = this.complete_state;
      });
      console.log('closed length: ', this.closedOrders);
      const todayDate = new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate()
      );
      this.bigOrdersClosedToday = this.closedOrders.filter((order) => {
        const closedDate = parseISO(order.or_closed_date);
        const closedDateWithoutTime = new Date(
          closedDate.getFullYear(),
          closedDate.getMonth(),
          closedDate.getDate()
        );
        return isSameDay(closedDateWithoutTime, todayDate);
      });
      console.log('Big orders closed today are', this.bigOrdersClosedToday);
      this.bigOrdersClosed = this.bigOrdersClosedToday.reduce(
        (sum, order) => sum + order.or_net_value,
        0
      );
      this.objectivePerday.objectiveToday = this.bigOrdersClosed;
      console.log('Big orders closed sum today is', this.bigOrdersClosed);
    });
  }

  calculatePercentage() {
    if (
      this.objectives &&
      this.objectives.tp_daily_objective > 0 &&
      this.bigOrdersClosed
    ) {
      this.percentageLavoration =
        (this.bigOrdersClosed / this.objectives.tp_daily_objective) * 100;
      console.log('Percentage Lavoration is', this.percentageLavoration);
      console.log('Objectives day', this.objectives.tp_daily_objective);
    } else {
      this.percentageLavoration = 0;
    }
  }

  monthlyObjective: number = 0;
  // calculateMonthlyObjective() {
  //   const currentYear = this.today.getFullYear();
  //   const currentMonth = this.today.getMonth();
  //   const todayOfMonth = this.today.getDate(); // Get current day of the month

  //   const workingDaysInMonth = this.getWorkingDaysInMonth(
  //     currentYear,
  //     currentMonth,
  //     todayOfMonth
  //   );

  //   let objectivePerday = {
  //     day: todayOfMonth,
  //     objective: this.objectives.tp_daily_objective,
  //     objectiveToday: this.bigOrdersClosed,
  //   };
  //   console.log('Working days in month are', workingDaysInMonth);

  //   if (this.objectives && this.bigOrdersClosed > 0) {
  //     this.monthlyObjective = workingDaysInMonth * this.bigOrdersClosed;
  //     console.log('Monthly Objective is', this.monthlyObjective);
  //   } else {
  //     this.monthlyObjective = 0;
  //   }
  // }

  // getWorkingDaysInMonth() {
  //   const currentYear = this.today.getFullYear();
  //   const currentMonth = this.today.getMonth();
  //   const today = this.today.getDate(); // Get current day of the month

  //   //  const workingDaysInMonth = this.getWorkingDaysInMonth(
  //   //    currentYear,
  //   //    currentMonth,
  //   //    todayOfMonth
  //   //  );

  //   console.log('objective per day', objectivePerday);

  //   // let workingDays = 0;
  //   // const daysInMonth = dateFns.eachDayOfInterval({
  //   //   start: new Date(year, month, 1),
  //   //   end: new Date(year, month, todayOfMonth),
  //   // });

  //   // daysInMonth.forEach((day) => {
  //   //   if (
  //   //     !dateFns.isWeekend(day) &&
  //   //     this.bigOrdersClosed >= this.objectives.tp_daily_objective
  //   //   ) {
  //   //     workingDays++;
  //   //   }
  //   // });
  //   // console.log('Working days are', workingDays);

  //   // return workingDays;
  // }

  getOpenOrders() {
    this.openOrdersServ
      .getOpenOrders()
      .subscribe((ordersData: Orders.IOrderOpenList[]) => {
        this.openOrders = ordersData;
        console.log('Open orders are', this.openOrders);

        // this.openOrders = this.openOrders.filter((order) => )
        console.log('CurrentNumber is: ', this.openOrders.length);
        this.getGoalNumber();

        this.openOrders = this.openOrders.filter(
          (order) => order.tab_order_status_desc !== 'Assegnato'
        );
        console.log('ORders after filtering are', this.openOrders);
        console.debug('Data is: ', this.openOrders);
      });
  }

  booleanForObjectives: boolean = false;
  getObjectiveProgress() {
    this.booleanForObjectives = true;
    this.openOrdersServ.getObjectiveProgress().subscribe((data) => {
      console.log('Data is', data);
      if (!data.success) {
        this.objectiveProgress = null;
        this.booleanForObjectives = false;
      }
      this.objectiveProgress = data.data;
      this.objectiveProgress.daily_percentage = this.formatNumber(
        +this.objectiveProgress.daily_percentage
      );
      this.booleanForObjectives = false;
      console.log('This objective progress is', this.objectiveProgress);
    });
  }

  formatNumber(value: number): string {
    return value.toFixed(2); // Adjust decimal places as needed
  }

  getGoalNumber() {
    this.currentGoalNumber = this.openOrders.length - 20;
    console.log('CurrentGoal: ', this.currentGoalNumber);
  }

  gotoDetails(order_id: number) {
    this.router.navigate(['/prepare-order', order_id]);
  }

  searchOrders() {
    if (!this.filterTerm || this.filterTerm == '') {
      this.openOrdersServ
        .searchNewOrders(this.filterTerm)
        .subscribe((openOrdersData: IWsResult) => {
          this.gridView = openOrdersData.data;
        });
      setTimeout(() => {}, 1000);
    }
    this.openOrdersServ
      .searchNewOrders(this.filterTerm)
      .subscribe((openOrdersData: IWsResult) => {
        this.gridView = openOrdersData.data;
      });
  }
}
