<div 
    *ngIf="openTrack || newTrack" 
    class="k-overlay dark-background"
></div>

<kendo-dialog
    title=""
    *ngIf="openTrack || newTrack"
    width="100%"
    [minHeight]="500"
    [maxWidth]="1150"
    class="k-form container-dialog-assegna detz"
    [formGroup]="agentsTrackForm"
    style="width: 100%;"
  >
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15px;
        z-index: 1000;
        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="close()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff;
            bottom: 10px;
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button>
    </div>

    <h1 class="page-title">Stato Tracking</h1>

    <div class="container" *ngIf="orderS">
      <div class="row">
        <div
          class="col text-center k-text-align-center"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
          "
        >
          <span
            style="
              color: black;
              text-align: center;
              padding-left: 10px;
              padding-right: 10px;
              /* border: 1px solid #ced4da; */
              border-radius: 40px;
            "
          >
            <a
              [href]="orderS.sm_ext_link"
              target="“_blank”"
              class="trackingLink"
            >
              {{ orderS.sm_ext_link }}</a
            >
          </span>
          <i class="bx bx-link bx-sm"></i>
        </div>
      </div>
      <div class="row det">
        <div class="col-md-5 setDisplay-Prep">
          <span class="title">N° Ordine</span>
          <span class="title1">{{ orderS.or_order_number }}</span>
        </div>
        <div class="col-md-5 setDisplay-Prep">
          <span class="title">Corriere</span>
          <span class="title1">{{ orderS.sm_carrier_desc }}</span>
        </div>
      </div>
    </div>

    <div *ngIf="orderS?.Details">
      <div
        *ngFor="let item of orderS.DetailsIcons; let i = index"
        class="marge borde padded center-row row-label-for-number-shipping"
        [class.button-new]="shipmentServ.pages.value == i"
        [class.background-shipment-not-delivered]="orderS.sm_status_cod === 4"
        [class.background-shipment-done]="i == 0 && orderS.sm_status_cod === 6"
        [class.background-shipment-not-delivered]="orderS.sm_status_cod == 5"
      >
        <div class="margeb padded">
          <ion-icon
            class="number-shipping font-125 color-light-blue"
            [icon]="item.icon.icon"
            *ngIf="!item.icon.faElement && orderS.sm_status_cod !== 5"
            [class.background-shipment-not-delivered]="
              orderS.sm_status_cod === 4
            "
          ></ion-icon>
          <fa-icon
            class="number-shipping font-125"
            [icon]="item.icon.faElement"
            *ngIf="!item.icon.icon && orderS.sm_status_cod !== 4"
            [class.background-shipment-delivered]="
              i == 0 || orderS.sm_status_cod === 6
            "
          >
          </fa-icon>
          <ion-icon
            class="number-shipping color-shipment-exeption background-shipment-not-delivered"
            name="close-circle"
            *ngIf="orderS.sm_status_cod === 5"
          >
          </ion-icon>
        </div>
        <div class="padded width-max rounded-corners">
          <ion-label class="label-for-number bold">{{
            item.message
          }}</ion-label>
          <div
            class="left-column-full"
            *ngIf="orderS.sm_status_cod < 4 || orderS.sm_status_cod == 5"
          >
            <ion-label class="margeb">{{ item.city }}</ion-label>
            <ion-label>{{ item.date }}</ion-label>
          </div>
          <div
            class="left-column-full"
            *ngIf="orderS.sm_status_cod == 4 || orderS.sm_status_cod == 6"
          >
            <ion-label class="margeb">{{ item.city }}</ion-label>
            <ion-label>{{ item.date }}</ion-label>
          </div>
        </div>
      </div>
    </div>
  </kendo-dialog>
