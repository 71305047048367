<kendo-dialog *ngIf="openedQRS" [minWidth]="250" (close)="close()">
  <h1 class="page-title">QR Codes</h1>
  <div
    class="rounded overflow-hidden shadow-lg k-my-2"
    style="width: 250px"
    *ngFor="
      let barcode of this.order.products[this.index].barcodes;
      let i = index
    "
  >
    <div
      class="px-6 py-4"
      style="display: flex; flex-direction: row; justify-content: space-around"
      *ngIf="barcode.barcode_text !== null"
    >
      <p class="text-gray-700 text-base text-center" style="padding-top: 10px">
        {{ i + 1 + " " + barcode.barcode_text }}
      </p>
      <i
        class="bx bxs-trash bx-md k-cursor-pointer"
        title="Delete barcode"
        (click)="deleteBarcode(barcode)"
      ></i>
    </div>
  </div>
  <div
    class="close k-display-flex k-py-2"
    style="justify-content: space-around"
  >
    <button class="btn btn-primary" (click)="updateOrder()">Salva</button>
    <button
      class="btn btn-primary"
      (click)="close()"
      style="background-color: red; border: red"
    >
      Cancella
    </button>
  </div>
</kendo-dialog>
