<kendo-dialog
  title=""
  (close)="closeBox()"
  *ngIf="openBox"
  [minWidth]="250"
  [width]="600"
  [height]="600"
  [maxWidth]="750"
  class="k-form"
  style="left: 6%"
>
  <div class="titleContainer">
    <h1 class="page-title">Scegli il tipo di scatola</h1>
    <hr class="hr-newBlue" />
  </div>
  <div
    class="accordion"
    id="accordionExample"
    [(ngModel)]="chosenBox.box_type_id"
    ngDefaultControl
    [attr.data-value]="chosenBox.box_type_id"
  >
    <div class="accordion-item">
      <h2
        class="accordion-header"
        id="headingOne"
        toggleIconSlot="start"
        aria-expanded="true"
        *ngIf="boxes"
        value="boxN"
      >
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
          [class.headerSelectedBox]="isThisBoxSelected"
        >
          <p
            style="
              padding: 0 !important;
              width: 100% !important;
              margin: 0 !important;
              font-size: 20px !important;
            "
            [class.selectedHeaderP]="isThisBoxSelected"
            class="k-w-full k-text-center"
          >
            Scatola standard
          </p>
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
        [class.selectedBox]="isThisBoxSelected"
        *ngFor="let box of boxes"
      >
        <div class="accordion-body">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault1"
              id="flexRadioDefault1"
              [value]="box.box_type_id"
              (click)="changeBox(box.box_type_id, box.box_type_desc)"
            />

            <label
              class="form-check-label"
              for="flexRadioDefault1"
              [class.selectedHeaderP]="isThisBoxSelected"
            >
              {{ box.box_type_desc }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="false"
          aria-controls="collapseTwo"
          [class.headerSelectedBox]="!isThisBoxSelected"
        >
          <input
            type="radio"
            [value]="-1"
            name="flexRadioDefault1"
            id="flexRadioDefault1"
            (click)="changeBox(-1)"
          />
          <p
            style="
              padding: 0 !important;
              width: 100% !important;
              margin: 0 !important;
              font-size: 20px !important;
            "
            [class.selectedHeaderP]="!isThisBoxSelected"
            class="k-w-full k-text-center"
          >
            Scatola custom
          </p>
        </button>
      </h2>
      <div
        id="collapseTwo"
        class="accordion-collapse collapse"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
        [class.selectedBox]="!isThisBoxSelected"
      >
        <div class="accordion-body">
          <div
            class="form-check k-display-flex"
            style="padding-left: 0 !important; flex-direction: column"
          >
            <input
              type="text"
              [(ngModel)]="newBox.ts_box_height"
              value="{{ newBox.ts_box_height }}"
              placeholder="Altezza (mm)"
              class="input-box customScatolaInput"
            />
            <input
              type="text"
              [(ngModel)]="newBox.ts_box_length"
              value="{{ newBox.ts_box_length }}"
              placeholder="Lunghezza (mm)"
              class="customScatolaInput"
            />
            <input
              type="text"
              [(ngModel)]="newBox.ts_box_depth"
              value="{{ newBox.ts_box_depth }}"
              placeholder="Profondità (mm)"
              class="customScatolaInput"
            />
            <input
              type="text"
              [(ngModel)]="newBox.ts_box_weight"
              value="{{ newBox.ts_box_weight }}"
              placeholder="Peso (kg)"
              class="customScatolaInput"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="example-wrapper">
    <kendo-dropdownlist
      [data]="numbers"
      [(ngModel)]="selectedNumber"
      placeholder="Seleziona il n° di colli"
    ></kendo-dropdownlist>
  </div>
  <br /><br />
  <div
    class="bigusButton"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div
      class="text-buttons"
      style="justify-content: space-evenly !important; top: 60px"
    >
      <div class="button-right" style="padding-left: 30px; padding-right: 10px">
        <button
          class="btn btn3"
          rounded="full"
          style="
            background-color: #8d8d8d;
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 192px !important;
            height: 45px !important;
          "
          (click)="closeBox()"
        >
          <i
            class="bx bx-left-arrow-circle bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 8px; position: relative"
          ></i>
          <p class="posRelBox2">ANNULLA</p>
        </button>
      </div>
      <div
        class="button-center"
        style="padding-left: 10px; padding-right: 10px"
      >
        <button
          [disabled]="
            !selectedNumber ||
            this.chosenBox.box_type_id == -2 ||
            (this.chosenBox.box_type_id == -1 && this.newBox.checkForNull())
          "
          (click)="validateBox(dataInfo.value)"
          #dataInfo
          class="btn btn3"
          rounded="full"
          style="
            background: rgb(0, 146, 73);
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 192px !important;
            height: 45px !important;
          "
        >
          <i
            class="bx bx-check-circle bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 8px; position: relative"
          ></i>
          <p class="posRelBox2">SALVA</p>
        </button>
      </div>
    </div>
  </div>
</kendo-dialog>
