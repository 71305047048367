import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAccount } from 'src/app/models/IAccount.model';
import { IUserAvatar } from 'src/app/models/IUser.model';
import { Helpers } from 'src/app/models/helpters';
import { IWsResult } from 'src/app/models/ws_result.model';
import { AccountsService } from 'src/app/services/accounts.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gestione-account-dettagli',
  templateUrl: './gestione-account-dettagli.component.html',
  styleUrls: ['./gestione-account-dettagli.component.scss'],
})

export class GestioneAccountDettagliComponent implements OnInit {

  @Input() photoAcc = false;
  @Input() accPageOpen = false;
  @Input() account_id: string = '-1';
  @Output() modalClosed1 = new EventEmitter<void>();

  public account: IAccount;
  public dataSaved = false;
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 1056;
  public dialogHeight = 1017;
  public isEditable = false;  
  public accountTypesSelect: {
    ac_typo: string;
    ac_number: number;
  }[] = [
    {
      ac_typo: 'Admin',
      ac_number: 1,
    },
    {
      ac_typo: 'Operatore',
      ac_number: 2,
    },
  ];
  modifyBtn: string;
  accountDetailsForm!: FormGroup;
  addAccountForm!: FormGroup;
  id: number;
  ac_number_cod: any;
  showUpload: boolean = false;
  newDateValue: any;
  imagePreviewUrl: string | ArrayBuffer | null = null;
  changedPhotoSuccess: boolean = false;
  
  constructor(
    public accountSrv: AccountsService,
    private route: ActivatedRoute,
    public closeSrv: OpenModalService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log('First');
    this.getAccount();
  }

  goToChangePass(account_id: string) {
    this.modifyBtn = account_id;
    this.closeSrv.openPass();
  }

  printNewDate(event: any) {
    console.log('New value date is', event);
    const date = new Date(event);
    const formattedDate = date.toLocaleDateString();
    this.newDateValue = formattedDate;
    console.log('Formmated date value is', this.newDateValue);
  }

  goEditFunction() {
    this.isEditable = !this.isEditable;
  }

  close() {
    this.closeSrv.offPhoto();
    this.closeSrv.offAccPage();
    this.modalClosed1.emit();
  }

  saveAccount(accountDetailForm: any) {
    console.log('AddAccountForm', accountDetailForm);
    // Use the value from the form control for ac_user_type_cod
    let acUserTypeCod = this.ac_number_cod;
    console.log('Ac number cod', this.ac_number_cod);
    if (!acUserTypeCod) 
      acUserTypeCod = this.account.ac_user_type_cod;
    console.log('Ac user type code again is', acUserTypeCod);
    // Set tab_user_type_desc based on acUserTypeCod
    let tabUserTypeDesc = '';
    if (acUserTypeCod === '1') {
      tabUserTypeDesc = 'Admin';
    } 
    else if (acUserTypeCod === '2') {
      tabUserTypeDesc = 'Operatore';
    }
    let saveAccount: IAccount = {
      account_id: +this.account_id,
      ac_first_name: this.accountDetailsForm.value.ac_first_name,
      ac_last_name: this.accountDetailsForm.value.ac_last_name,
      ac_email: this.accountDetailsForm.value.ac_email,
      ac_user_type_cod: acUserTypeCod, // Use the value from the form control
      ac_expiration_date: this.accountDetailsForm.value.ac_expiration_date,
      ac_phone: this.accountDetailsForm.value.ac_phone,
      ac_mobile: this.accountDetailsForm.value.ac_mobile,
      ac_photo_url: this.account.ac_photo_url,
      ac_active: this.account.ac_active,
      ac_note: this.accountDetailsForm.value.ac_note,
      tab_user_type_desc: tabUserTypeDesc, // Set tab_user_type_desc based on acUserTypeCod
    };
    console.log('SAVE ACCOUNT: ', saveAccount);
    this.accountSrv.saveAccount(saveAccount).subscribe((account) => {
      this.saveAccount = account.data;
    });
    this.close();
    this.modalClosed1.emit();
    this.router.navigate(['/accounts']);
    Swal.fire({
      icon: 'success',
      title: 'Modificato!',
      text: 'Utente modificato con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

  editAccount() {
    this.isEditable = true;
    this.changedPhotoSuccess = !this.changedPhotoSuccess;
  }

  clearForm(): void {
    this.addAccountForm.reset();
  }

  getAccount() {
    console.log('yes yes triggered');
    this.accountDetailsForm = new FormGroup({
      account_id: new FormControl(''),
      ac_first_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
        Validators.pattern('[a-zA-Z. ]*'),
      ]),
      ac_last_name: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z. -]*'),
      ]),
      ac_email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,4}$'),
      ]),
      ac_user_type_cod: new FormControl('', [Validators.maxLength(20)]),
      ac_expiration_date: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ac_phone: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ac_mobile: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ac_photo_url: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ac_active: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ac_note: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      tab_user_type_desc: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
    });
    console.debug('Dettagli ID: ' + this.account_id);
    this.accountSrv
      .getAccount(this.account_id)
      .subscribe((accountData: IWsResult) => {
        this.account = accountData.data;
        let formattedDate = new Date(this.account.ac_expiration_date);
        console.log('Date baws is', formattedDate);
        this.accountDetailsForm.patchValue({
          ac_first_name: this.account.ac_first_name,
          ac_last_name: this.account.ac_last_name,
          ac_email: this.account.ac_email,
          ac_photo_url: this.account.ac_photo_url,
          ac_expiration_date: formattedDate,
          ac_phone: this.account.ac_phone,
          ac_mobile: this.account.ac_mobile,
          ac_active: this.account.ac_active,
          tab_user_type_desc: this.account.tab_user_type_desc,
          ac_note: this.account.ac_note,
        });
      });
  }

  ngOnDestroy() {
    this.closeSrv.closeAcc();
    this.closeSrv.offPhoto();
    console.debug('I am destroyed');
  }

  deleteAccount(addAccountForm: any): void {
    this.accountSrv
      .deleteAccount(this.account.account_id.toString())
      .subscribe((accountsData: IWsResult) => {
        if (accountsData.success) {
          this.close();
          this.modalClosed1.emit();
          this.router.navigate(['/accounts']);
          Swal.fire({
            icon: 'success',
            title: 'Eliminato!',
            text: 'Utente eliminato con successo!!!',
            timer: 1500,
            timerProgressBar: true,
          });
        } 
        else {
          Swal.fire({
            icon: 'error',
            title: 'Errore!',
            text: 'Utente non eliminato, riprova!!!',
            timer: 1500,
            timerProgressBar: true,
          });
        }
      });
  }

  updateIMG() {
    this.getAccount();
  }

  openFileInput() {
    // Use JavaScript to trigger the click event of the hidden file input
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      {
        let userAvatar: IUserAvatar = {
          account_id: this.account.account_id,
          ac_avatar: this.account.ac_photo_url,
        };
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e: any) => {
          userAvatar.account_id = this.account.account_id;
          userAvatar.ac_avatar = reader.result?.toString();

          let termination =
            userAvatar.ac_avatar?.indexOf(';') -
            userAvatar.ac_avatar?.indexOf('/') -
            1;
          userAvatar.ac_avatar = userAvatar.ac_avatar?.substring(
            Helpers.base64Length + termination
          );
          console.log('Avatar data is: ', userAvatar.ac_avatar);
          console.log('Original avatar is: ', reader.result?.toString());
          console.log('Name is: ', event.target.files[0].name);
          this.changePhoto(userAvatar);
        };
        reader.onerror = (error) => {};
      }
    }
  }

  changePhoto(userAvatar) {
    this.accountSrv.saveAvatar(userAvatar).subscribe((result) => {
      if (result.success) {
        console.log('Success!');
        this.getAccount();
        this.changedPhotoSuccess = !this.changedPhotoSuccess;
        this.isEditable = !this.isEditable;
        Swal.fire({
          icon: 'success',
          title: 'Modificata!',
          text: "Foto dell'avatar modificata con successo!",
          timer: 1500,
          timerProgressBar: true,
        });
      }
      if (!result.success) {
        Swal.fire({
          icon: 'error',
          title: 'Errore!',
          text: "Impossibile cambiare la foto dell'avatar, riprova!!!",
          timer: 1500,
          timerProgressBar: true,
        });
      }
    });
    console.log('merge?');
  }

  onAccountTypeSelected(value: any) {
    console.log('Selected account type: ', value);
    this.ac_number_cod = value.toString();
    console.log('Account type is: ', this.ac_number_cod);
  }
  
}
