<div class="k-overlay dark-background" *ngIf="openedFIRMARE"></div>

<kendo-dialog
  title=""
  *ngIf="openedFIRMARE && order"
  [minWidth]="minWidth"
  [width]="dialogWidth"
  [minHeight]="540"
  [maxWidth]="1450"
  class="k-form"
  (close)="close()"
  [formGroup]="assignOrderForm"
  style="left: 6%;"
>
  <div 
    style="
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: -15px;
      z-index: 1000;
      position: fixed;
      margin-left: -35px;
    "
  >
    <button
      (click)="close()"
      class="btn btn3"
      rounded="full"
      style="
        background-color: #e90000;
        color: #ffffff;
        width: 35px !important;
        height: 35px !important;
      "
    >    
      <i
        class="bx bx-x bx-sm posRelBox2"
        style="
          color: #ffffff; 
          bottom: 10px; 
          position: relative !important;
          left: -80% !important;
          top: -1px !important;
        "
      ></i>
    </button> 
  </div>
  <h1 class="page-title">Ordini da Firmare</h1>
  <hr class="hr-dialog" />
  <div *ngIf="order" class="container container-ordine">
    <form class="k-form dialog-form marget" [formGroup]="assignOrderForm">
      <div class="row det mb-2">
        <div class="col-md setDisplay-Prep" *ngIf="order">
          <kendo-label 
            class="title" 
            text="N° Ordine"
          ></kendo-label>
          <input
            #or_order_number
            kendoTextBox
            [readonly]
            rounded="full"
            [placeholder]="order.or_order_number"
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Data ordine"
          ></kendo-label>
          <input
            #or_order_date
            kendoTextBox
            [readonly]
            rounded="full"
            [placeholder]="order.or_order_date | date : 'dd/MM/yyyy'"
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Data evasione"
          ></kendo-label>
          <input
            #or_quote_date
            kendoTextBox
            [readonly]
            rounded="full"
            [placeholder]="order.or_assignment_date | date : 'dd/MM/yyyy'"
            class="setBorder-Prepp"
          />
        </div>        
      </div>

      <div class="row det mb-2">
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="N° articoli"
          ></kendo-label>
          <input
            #boxes
            kendoTextBox
            [readonly]
            rounded="full"
            placeholder="{{ order.products.length }}"
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label
            [for]="boxes"
            class="title"
            text="N° colli"
          ></kendo-label>
          <input
            #boxes
            kendoTextBox
            [readonly]
            rounded="full"
            placeholder="{{ order.boxes.length }}"
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Corriere"
          ></kendo-label>
          <input
            #carrier_desc
            kendoTextBox
            [readonly]
            rounded="full"
            placeholder="{{ order.vs_details.carrier_desc }}"
            class="setBorder-Prepp"
          />
        </div>
      </div>

      <div class="row det mb-2">
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title"
            text="Cliente" 
          ></kendo-label>
          <input
            #or_customer
            kendoTextBox
            [readonly]
            rounded="full"
            [placeholder]="order.or_customer"
            class="setBorder-Prepp"
          />
        </div>        
      </div>  
      
      <div class="row det">
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Indirizzo"
          ></kendo-label>
          <input
            #or_shipping_address
            kendoTextBox
            [readonly]
            rounded="full"
            [placeholder]="order.or_shipping_address"
            class="setBorder-Prepp"
          />
        </div>
      </div>

      <div class="row det">        
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Provincia"
          ></kendo-label>
          <input
            #or_shipping_prov
            kendoTextBox
            [readonly]
            rounded="full"
            [placeholder]="order.or_shipping_prov"
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="CAP"
          ></kendo-label>
          <input
            #or_shipping_zip
            kendoTextBox
            [readonly]
            rounded="full"
            [placeholder]="
              order.or_shipping_zip ? order.or_shipping_zip : 'cap'
            "
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Città"
          ></kendo-label>
          <input
            #or_shipping_city
            kendoTextBox
            [readonly]
            rounded="full"
            [placeholder]="order.or_shipping_city"
            class="setBorder-Prepp"
          />
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Nazione"
          ></kendo-label>
          <input
            #or_shipping_country
            kendoTextBox
            [readonly]
            rounded="full"
            [placeholder]="order.or_shipping_country"
            class="setBorder-Prepp"
          />
        </div>
      </div>
    </form>

    <div class="row det cont-dx">
      <div class="row column-secret panelbar-wrapper">
        <kendo-panelbar
            *ngIf="order && products"
            [animate]="true"
            style="
              text-align: center;
              border: #fff;
            "
          >
            <kendo-panelbar-item 
              [title]="'Prodotti'" 
              style="
                text-align: center;
                border-radius: 45px
              "
            >
              <ng-template kendoPanelBarContent>
                <div style="max-height: 100%; margin-left:12px; width:94.8%">
                  <div
                      class="row"
                      *ngFor="let prod of order.products; let i = index"
                      style="
                        text-align: center;
                        padding: 8px;
                        border: 1px solid #47A1FB;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                      "
                    >
                    <div 
                      class="col-10"
                      style="text-align: left"
                    >
                      <span
                        style="
                          font-family: 'Lato';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 19px;
                          color: #0C65E9;
                        "
                        >{{ prod.op_description }}
                      </span>
                    </div>
                    <div 
                      class="col-2"
                      style="text-align:right"
                    >
                      <span
                        style="
                          font-family: 'Lato';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 19px;
                          color: #0C65E9;
                        "
                        >{{ prod.op_qta + " pz" }}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-panelbar-item>
          </kendo-panelbar>
      </div>

      <div class="row column-secret panelbar-wrapper" style="margin-top: 10px">
        <kendo-panelbar
          *ngIf="product"
          [animate]="true"
          style="text-align: center; border: #fff"
        >
          <kendo-panelbar-item
            [title]="'Etichette'"
            style="text-align: center; border-radius: 45px"
          >
            <ng-template kendoPanelBarContent>
              <div>
                <div
                  class="changeContainer"
                  style="
                    text-align: center;
                    padding: 8px;
                    border: 1px solid #47a1fb;
                    display: flex;
                    justify-content: center !important;
                  "
                >
                  <div class="spanus" style="display: flex">
                    <div
                      class="smallSpanus"
                      style="
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: flex-end;
                        flex-direction: column;
                      "
                    >
                      <div class="2">
                        <span
                          style="
                            font-family: 'Lato';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 24px;
                            /* identical to box height */

                            color: #0c65e9;
                          "
                        >
                          Scatola
                        </span>
                        <a href="{{ linkAllLabels }}" target="“_blank”"
                          ><i
                            class="bx bxs-file-pdf bx-md"
                            style="cursor: pointer; color: rgb(220, 17, 17)"
                          ></i
                        ></a>
                      </div>

                      <div class="2" *ngIf="isLinkToDDT">
                        <span
                          style="
                            font-family: 'Lato';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 24px;
                            color: #0c65e9;
                          "
                          >DDT
                        </span>
                        <a href="{{ this.linkToDDT }}" target="_blank"
                          ><i
                            class="bx bxs-file-pdf bx-md"
                            style="cursor: pointer; color: rgb(220, 17, 17)"
                          ></i
                        ></a>
                      </div>

                      <div class="2" *ngIf="this.linkCarrier">
                        <span
                          style="
                            font-family: 'Lato';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 24px;
                            color: #0c65e9;
                          "
                          >Corriere
                        </span>
                        <a href="{{ this.linkCarrier }}" target="_blank"
                          ><i
                            class="bx bxs-file-pdf bx-md"
                            style="cursor: pointer; color: rgb(220, 17, 17)"
                          ></i
                        ></a>
                      </div>

                      <!--<div class="2" *ngIf="this.linkBordero">
                        <span
                          style="
                            font-family: 'Lato';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 24px;
                            color: #0c65e9;
                          "
                        >
                          Borderò
                        </span>
                        <a href="{{ this.linkBordero }}" target="_blank"
                          ><i
                            class="bx bxs-file-pdf bx-md"
                            style="cursor: pointer; color: rgb(220, 17, 17)"
                          ></i
                        ></a>
                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
      </div>
    </div>
  </div>
</kendo-dialog>
