import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Boxes } from 'src/app/models/Boxes.models';
import { Documents } from 'src/app/models/documents.models';
import { Orders } from 'src/app/models/Orders.model';
import { Status } from 'src/app/models/status';
import { IWsResult } from 'src/app/models/ws_result.model';
import { IconsService } from 'src/app/services/icons.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PagesService } from 'src/app/services/pages.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';
import * as _ from 'lodash';

@Component({
  selector: 'app-confirm-boxes',
  templateUrl: './confirm-boxes.component.html',
  styleUrls: ['./confirm-boxes.component.scss'],
})
export class ConfirmBoxesComponent implements OnInit {
  @Input() openedBox = true;
  @ViewChild(GridComponent) grid: GridComponent;
  @Output() modalClosed1 = new EventEmitter<void>();
  public documents: Documents.Document[] = [];
  public boxes: Boxes.BoxOrder[] = [];
  public orderToUpdate: Orders.OrderUpdate = new Orders.OrderUpdate(null);
  public order: Orders.Order;
  public isEditable = false;
  public toggleText = 'Hide';
  public show = false;

  id: string;
  back: string = '';
  boxNu: number;
  firstEnteredOnPage: boolean = true;
  clickedBox: any;
  loading: boolean = false;

  constructor(
    private router: Router,
    public orderService: OrdersService,
    private pagesService: PagesService,
    private activatedRoute: ActivatedRoute,
    private iconService: IconsService,
    public closeServ: OpenModalService,
    private dropdownServ: DropdownService
  ) {}

  ngOnInit(): void {
    this.loading = !this.loading;
    console.log('Loading is', this.loading);
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.back = this.pagesService.getParentUrl();
    this.reloadFunction();
    // this.grid.sortChange.emit([{ field: 'ts_box_type_desc', dir: 'desc' }]);
    // this.orderBoxesByName();
    // this.loading = !this.loading;
  }

  sortChange(sort: { field: string; dir: string }[]): void {
    this.orderToUpdate.boxes = this.orderToUpdate.boxes.sort((a, b) => {
      const dir = sort[0].dir === 'asc' ? 1 : -1;
      if (a[sort[0].field] < b[sort[0].field]) {
        return -1 * dir;
      } else if (a[sort[0].field] > b[sort[0].field]) {
        return 1 * dir;
      } else {
        return 0;
      }
    });
  }

  reloadFunction() {
    this.orderService.getOrder1(+this.id).subscribe(async (result) => {
      this.order = result;
      console.log('ORDERUS IS', this.order);
      // Map and assign the boxes array to include `isDeleted` property
      this.order.boxes = this.order.boxes.map((item) => {
        let box: any = {
          ...item,
          isDeleted: false,
          ts_documents: 'N', // Ensure these values are explicitly set as required
          ts_screws: 'N',
          checkForNull: function (): boolean {
            throw new Error('Function not implemented!');
          },
        };
        console.log('BOX OBX', box);
        return box;
      });
      // Assign the modified order object to orderToUpdate
      this.orderToUpdate = new Orders.OrderUpdate(this.order);
      console.log('OrderToUpdate is', this.orderToUpdate);
      console.log('Order is', this.order);
      if (this.orderToUpdate.or_carrier_id === 0) {
        this.orderToUpdate.or_carrier_id = null;
      }
      this.orderService.getDocuments(+this.id).subscribe(async (result) => {
        this.documents = result;
        await this.orderService.getOrderDocuments(+this.id).then((result) => {
          if (result) this.documents = result;
        });
      });
      this.loading = false;
    });
  }

  public onToggle(): void {
    this.show = !this.show;
    this.toggleText = this.show ? 'Hidе' : 'Show';
  }

  showBoxDetails(box, boxNo) {
    this.clickedBox = box;
    this.boxNu = boxNo;
    this.closeServ.openDetBox();
  }

  async checkDocument(id: number) {
    this.documents[id].checked = !this.documents[id].checked;
    this.documents.sort(
      (a, b) =>
        +(a.checked ? a.checked : false) - +(b.checked ? b.checked : false)
    );
  }

  async removeBox(id) {
    this.loading = !this.loading;
    this.orderToUpdate.boxes[id].isDeleted = true;
    console.log('Order to update', this.orderToUpdate);
    this.orderService.updateOrder(this.orderToUpdate).subscribe((data) => {
      console.log('Pow pow');
      if (data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Rimossa!',
          text: 'La scatola è stata rimossa con successo!',
          timer: 1500,
          timerProgressBar: true,
        });
        this.reloadFunction();
      }
    });
  }

  goToBox(box_type_id: any) {
    this.clickedBox = box_type_id;
    this.closeServ.openBox();
  }

  public goBackFunction() {
    this.router.navigate(['/prepare-order']);
  }

  updateCustomBox(dataInfo: any) {
    console.debug('Custom box: ', dataInfo);
    {
      const box: Boxes.IBoxOrder = {
        ts_box_type_id: null,
        ts_box_type_desc: '',
        ts_box_height: dataInfo.custom.ts_box_height,
        ts_box_length: dataInfo.custom.ts_box_length,
        ts_box_depth: dataInfo.custom.ts_box_depth,
        ts_box_weight: dataInfo.custom.ts_box_weight,
        ts_documents: 'N',
        ts_screws: 'N',
        isDeleted: dataInfo.custom.isDeleted,
      };
      this.orderToUpdate.boxes[dataInfo.boxNo] = box;
    }
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  addDocumentsToBox(boxNo: number) {
    this.orderToUpdate.boxes[boxNo].ts_documents =
      this.orderToUpdate.boxes[boxNo].ts_documents?.toUpperCase() === 'S'
        ? 'N'
        : 'S';
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  addScrewsToBox(boxNo: number) {
    this.orderToUpdate.boxes[boxNo].ts_screws =
      this.orderToUpdate.boxes[boxNo].ts_screws?.toUpperCase() == 'S'
        ? 'N'
        : 'S';
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  printAllLabels() {
    this.orderService
      .getAllBoxLabels(
        this.order.order_id,
        this.order.boxes.map((box, index) => index + 1)
      )
      .subscribe((result) => {
        console.debug('result: ', result);
        if (!result) return;
        window.open(result.box_label_link_pdf);
      });
  }

  addBox(dataInfo: any) {
    console.log('Add box: ', dataInfo);
    for (let i = 0; i < dataInfo.boxesNo; i++) {
      const box: Boxes.IBoxOrder = {
        ts_box_type_id: dataInfo.normal.box_type_id,
        ts_box_type_desc: dataInfo.normal.box_type_desc,
        ts_box_height: dataInfo.custom.ts_box_height,
        ts_box_length: dataInfo.custom.ts_box_length,
        ts_box_depth: dataInfo.custom.ts_box_depth,
        ts_box_weight: dataInfo.custom.ts_box_weight,
        ts_documents: 'N',
        box_order_id: null,
        ts_screws: 'N',
        isDeleted: dataInfo.normal.isDeleted,
      };
      this.orderToUpdate.boxes.push(box);
      console.log('Adding a box inside the order', this.orderToUpdate.boxes);
    }
    console.debug(this.orderToUpdate);
    this.orderService.updateOrder(this.orderToUpdate).subscribe((data) => {
      if (data.success) {
        this.id = this.activatedRoute.snapshot.paramMap.get('id');
        this.back = this.pagesService.getParentUrl();
        this.reloadFunction();
      }
    });
  }

  close() {
    this.closeServ.closeCheckBox();
    this.closeServ.isColorConfirmed2();
  }

  saveStep(pageName) {
    let statusNo = 0;
    switch (pageName) {
      case 'boxes':
        console.log('CheckedProduct');
        statusNo = Status.StatusesUser.step3;
    }
    this.orderService
      .setStatus(this.order.order_id, statusNo)
      .subscribe((result: IWsResult) => {
        this.orderService.updateOrder(this.orderToUpdate).subscribe(() => {
          this.modalClosed1.emit();
          this.close();
          Swal.fire({
            icon: 'success',
            title: 'Aggiunte!',
            text: 'Colli selezionati con successo!!!',
            timer: 1500,
            timerProgressBar: true,
          });
        });
      });
  }

  checkSelectedAll(stepBack = false) {
    const stepFrw = 2;
    const stepBck = 1;
    if (this.documents)
      this.orderService.setDocumentsChecked(
        this.order.order_id,
        this.documents
      );
    if (
      this.order.status[0].os_status_order_cod > 3 &&
      this.order.status[0].os_status_order_cod < 7 &&
      this.firstEnteredOnPage
    ) {
      this.documents.forEach((element) => {
        element.checked = true;
      });
      this.firstEnteredOnPage = false;
    }
  }

  selectedBoxToAddProducts: any;

  seeListOfProducts(box) {
    console.log('triggered', box);
    this.selectedBoxToAddProducts = box;
    console.log('this order id is', this.order.order_id);
    console.log('ordertTOUpdate boxes', this.orderToUpdate.boxes);
    this.closeServ.onListOfProductsBoxes();
  }

  orderBoxesByName() {
    this.orderToUpdate.boxes.sort((a, b) =>
      a.ts_box_type_desc.localeCompare(b.ts_box_type_desc)
    );
  }
}
