<h1 class="page-title">Lista Messaggi</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <form
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <input
      [(ngModel)]="filterTerm"
      id="search"
      name="search"
      pattern=".*\S.*"
      type="search"
      placeholder="Cerca messaggi..."
      autofocus
      required
      (input)="this.onInputChanged($event)"
    />
    <button type="submit" (click)="searchMessages()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <button
      class="btn btn-primary msg-btn"
      routerLink="/new-msg"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
      "
    >
      + Messaggio
    </button>
  </form>

  <div
    class="dateContainer k-display-flex justify-content-evenly"
    style="margin-top: -20px"
  >
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
  </div>

  <div class="col mx-2" style="padding-top: 25px">
    <kendo-dropdownlist
      [data]="users"
      class="drop-the-border"
      #dropdownList
      [selectedIndex]="msgIndex"
      style="
        text-align: center;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 45px;
      "
      (valueChange)="onMessengerNameSelectz($event, msgIndex)"
      [(ngModel)]="searchString"
    >
      <ng-template let-dataItem> </ng-template>
    </kendo-dropdownlist>
  </div>
</div>

<hr class="hr-newBlue" />

<div
  class="row k-display-flex justify-content-end align-items-center"
  style="padding-left: 20px"
>
  <div style="display: flex; flex-direction: row; justify-content: center">
    <h2 class="col-header">Totale messaggi: {{ gridData.length }}</h2>
  </div>

  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <div class="grid-wrapper">
    <kendo-grid
      [kendoGridBinding]="
        gridData
          | byToDate
            : [
                filters.startingDate,
                filters.endDate,
                filters.dateOrginizedBy.value
              ]
      "
      [rowHeight]="36"
      [height]="750"
      [pageSize]="pageSize"
      [scrollable]="'virtual'"
      [skip]="skip"
      [pageSize]="pageSize"
      scrollable="virtual"
      (pageChange)="pageChange($event)"
      [navigable]="true"
      [sortable]="true"
      class="maxHeight"
      [reorderable]="true"
      [rowClass]="rowCallback"
    >
      <kendo-grid-column
        [width]="440"
        field="ms_sender_name"
        title="Mittente"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
      ></kendo-grid-column>

      <kendo-grid-column
        [width]="120"
        field="ms_datetime"
        title="Data"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.ms_datetime | date : "dd/MM/yyyy HH:mm" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="200"
        field="ms_title"
        title="Titolo"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
      ></kendo-grid-column>

      <kendo-grid-column
        [width]="120"
        field="ms_status"
        title="Status"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <i *ngIf="dataItem.ms_status == 'R'" class="bx bx-bell bx-md"></i>
          <i
            *ngIf="dataItem.ms_status == 'U'"
            class="bx bxs-bell bx-md"
            style="color: red"
          ></i>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column
        title="Dettagli"
        [width]="100"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="gotoDetails(dataItem.message_id)"
          >
            <div class="setDisplayToFlexBlue btn-det">
              <i
                class="bx bx-info-circle bx-md"
                style="margin: 0; padding: 0"
              ></i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>

      <ng-template kendoGridNoRecordsTemplate>
        <div class="no-records-message">
            <p>Nessun dato disponibile</p>
        </div>
      </ng-template>
    </kendo-grid>
    <div *ngIf="loading" class="k-i-loading"></div>
  </div>
</div>

<app-messaggi-dettagli
  (modalClosed)="onModalClosed()"
  *ngIf="closeSrv.isMessID"
  [opened]="closeSrv.isMessID"
  [message_id]="clickedMsg"
>
</app-messaggi-dettagli>
