import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { byToSignDatePipe } from './by-to-sign-date.pipe';

@NgModule({
  exports: [byToSignDatePipe],
  declarations: [byToSignDatePipe],
  imports: [CommonModule],
})

export class ByToSignDateModule {
  
  static forRoot() {
    return {
      ngModule: ByToSignDateModule,
      providers: [],
    };
  }

}
