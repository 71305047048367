import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import {
  Objectives,
  SetUpdatedObjectives,
  UpdatedObjectives,
} from 'src/app/models/Orders.model';
import { Printing } from 'src/app/models/printer';
import { OrdersService } from 'src/app/services/orders.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import Swal from 'sweetalert2';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [CurrencyPipe],
})
export class SettingsComponent implements OnInit {
  @Input() value: string = '-1';

  printers: Printing.PrinterOption[] = [];
  printerSelection: Printing.PrinterOptionSelection =
    new Printing.PrinterOptionSelection(null);
  dataItem: any;
  objectivesArray: any[] = [];
  objectives: Objectives = new Objectives(null);
  updatedObjectives: UpdatedObjectives[] = [];
  daily: any;
  monthly: any;
  yearly: any;
  data = [{ value1: 0, value2: 0, value3: 0 }];
  years: string[] = [];
  chosenYear: string = '2024';
  index: number;
  totalYearlyAmount: number = 0;

  newObjectivesArray: UpdatedObjectives[] = [];
  constructor(
    private printerService: PrinterService,
    public orderService: OrdersService,
    public decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe
  ) {
    const savedData = localStorage.getItem('obj');
    if (savedData) {
      this.data = JSON.parse(savedData);
    } else {
      this.data = [
        {
          value1: 10,
          value2: 20,
          value3: 30,
        },
      ];
    }
  }

  async ngOnInit() {
    this.initializeYears();
    this.reloadFunction();
    console.log('Chosen year is', this.chosenYear);
  }

  formatNetValue(value: number): string {
    console.log('value', value);
    return this.decimalPipe.transform(value, '1.0-0') || '0';
  }
  reloadFunction() {
    this.printerService.printers.subscribe((result) => {
      if (result) this.printers = result;
    });
    this.printerService.printerSelection.subscribe((result) => {
      if (result) this.printerSelection = result;
    });
    this.getObjectives();
    this.initializeYears();
    this.chosenYear = new Date().getFullYear().toString();
  }

  initializeYears() {
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: 20 }, (_, i) =>
      (currentYear - i).toString()
    );
    console.log('Years are', this.years);
  }

  async ngOnDestroy() {
    this.printerService.printerSelection.next(this.printerSelection);
    await this.printerService.setPrintersSettings(this.printerSelection);
  }

  getObjectives() {
    this.orderService.getObjectives().subscribe((data) => {
      if (!data.success) {
        this.updatedObjectives = [];
      } else {
        this.updatedObjectives = data.data;
      }

      console.log('Array: ', this.updatedObjectives);
      let seen = {};
      this.updatedObjectives = this.updatedObjectives.filter((obj) => {
        if (seen[obj.monthly_objective]) {
          return false;
        } else {
          seen[obj.monthly_objective] = true;
          return true;
        }
      });

      const monthNames = {
        1: 'Gennaio',
        2: 'Febbraio',
        3: 'Marzo',
        4: 'Aprile',
        5: 'Maggio',
        6: 'Giugno',
        7: 'Luglio',
        8: 'Agosto',
        9: 'Settembre',
        10: 'Ottobre',
        11: 'Novembre',
        12: 'Dicembre',
      };
      this.newObjectivesArray = [];
      for (let month = 1; month <= 12; month++) {
        let objective = this.updatedObjectives.find(
          (obj) => obj.monthly_objective === month
        );
        console.log('Objective is', objective);
        if (objective) {
          console.log('chosen year is', this.chosenYear);
          let newObjective: UpdatedObjectives = new UpdatedObjectives(
            objective
          );
          newObjective.monthly_objective = month;
          newObjective.correspondingMonth = monthNames[month];
          newObjective.yearly_objective = +this.chosenYear;
          this.newObjectivesArray.push(newObjective);
        } else if (!objective) {
          let newObjective: UpdatedObjectives = new UpdatedObjectives(null);
          newObjective.monthly_objective = month;
          newObjective.correspondingMonth = monthNames[month];
          newObjective.yearly_objective = +this.chosenYear;
          this.newObjectivesArray.push(newObjective);
        }
      }

      console.log('New Objectives Array', this.newObjectivesArray);
      // Sorting the updatedObjectives by monthly_objective to maintain order
      this.newObjectivesArray.sort(
        (a, b) => a.monthly_objective - b.monthly_objective
      );

      // Format the net_value and working_days using CurrencyPipe
      this.newObjectivesArray.forEach((obj) => {
        obj.net_value = obj.net_value === -1 ? 0 : obj.net_value;
        obj.working_days = obj.working_days === -1 ? 0 : obj.working_days;
        obj.yearly_objective =
          obj.yearly_objective === -1 ? 0 : obj.yearly_objective;

        // Apply currency formatting for display
        obj.formattedNetValue = this.currencyPipe.transform(
          obj.net_value,
          'EUR'
        );
      });

      console.log('objectives net value are', this.newObjectivesArray);

      this.newObjectivesArray = this.newObjectivesArray.filter(
        (obj) => obj.yearly_objective === +this.chosenYear
      );

      let totalYearlyAmount = this.newObjectivesArray.reduce(
        (total, element) => {
          return total + element.net_value;
        },
        0
      );
      this.totalYearlyAmount = totalYearlyAmount;

      let newObjective: UpdatedObjectives = new UpdatedObjectives(null);
      newObjective.monthly_objective = 13;
      newObjective.correspondingMonth = 'Annuale';
      newObjective.net_value = this.totalYearlyAmount;
      newObjective.yearly_objective = +this.chosenYear;
      newObjective.working_days = this.newObjectivesArray.reduce((t, e) => {
        return t + e.working_days;
      }, 0);
      newObjective.formattedNetValue = this.currencyPipe.transform(
        newObjective.net_value,
        'EUR'
      );
      this.newObjectivesArray.push(newObjective);

      console.log('Total amount for this year', totalYearlyAmount);
      console.log('Data3: ', this.updatedObjectives);
      console.log('Obiettivi: ', data);
    });
  }

  updateGoal(obj) {
    console.log('updategoal', obj);
    // obj = new SetUpdatedObjectives(obj);
    this.orderService.updateObjective(obj).subscribe((data) => {
      if (data) {
        Swal.fire({
          icon: 'success',
          title: 'Aggiornato!',
          text: 'Obiettivo impostato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        this.newObjectivesArray = [];
        this.updatedObjectives = [];
        this.reloadFunction();
      }
    });
  }

  changeprinterCustomBorID(printer_id, checkbox_no) {
    this.printers = this.printers.map((item) => {
      switch (checkbox_no) {
        case 0:
          this.printerSelection.printer_ddt_id = printer_id;
          if (item.printer_id === printer_id) item.printer_ddt = true;
          else item.printer_ddt = false;
          break;
        case 1:
          this.printerSelection.printer_box_id = printer_id;
          if (item.printer_id === printer_id) item.printer_box = true;
          else item.printer_box = false;
          break;
        case 2:
          this.printerSelection.printer_car_id = printer_id;
          if (item.printer_id === printer_id) item.printer_car = true;
          else item.printer_car = false;
          break;
        case 3:
          this.printerSelection.printer_bor_id = printer_id;
          if (item.printer_id === printer_id) item.printer_bor = true;
          else item.printer_bor = false;
          break;
      }
      return item;
    });
  }

  setGoal(objective: UpdatedObjectives) {
    console.log('objective is', objective);
    console.log('Obiettivo giornaliero: ', this.daily);
    let setUpdatedObjective = new SetUpdatedObjectives(objective);
    if (+this.chosenYear === 0) {
      this.chosenYear = new Date().getFullYear().toString();
    }
    setUpdatedObjective.yearly_objective = +this.chosenYear;
    console.log('Setupdated objective', setUpdatedObjective);

    console.log('Setupdated objective is', setUpdatedObjective);
    this.orderService
      .setObjectives(setUpdatedObjective)
      .subscribe((data: any) => {
        console.log('SUCCESS!');
        Swal.fire({
          icon: 'success',
          title: 'Aggiornato!',
          text: 'Obiettivo impostato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        this.newObjectivesArray = [];
        this.updatedObjectives = [];
        this.reloadFunction();
      });
    // this.objectives.tp_daily_objective = this.daily;
    // this.objectives.tp_monthly_objective = this.monthly;
    // this.objectives.tp_yearly_objective = this.yearly;
    // console.log('Obiettivo giornaliero: ', this.objectives.tp_daily_objective);
    // console.log('Obiettivo mensile: ', this.objectives.tp_monthly_objective);
    // console.log('Obiettivo annuale: ', this.objectives.tp_yearly_objective);
    // const isDailyValid = this.validateDay(this.daily, this.monthly);
    // const isMonthlyValid = this.validateMonth(this.monthly, this.yearly);
    // if (isDailyValid && isMonthlyValid) {
    //   this.orderService
    //     .setObjectives(this.objectives)
    //     .subscribe((data: any) => {
    //       console.log('SUCCESS!');
    //       Swal.fire({
    //         icon: 'success',
    //         title: 'Aggiornato!',
    //         text: 'Obiettivo impostato con successo!!!',
    //         timer: 1500,
    //         timerProgressBar: true,
    //       });
    //       this.reloadFunction();
    //     });
    // } else {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Errore, riprova!',
    //     text: this.errorMsg(isDailyValid, isMonthlyValid),
    //     timer: 3000,
    //     timerProgressBar: true,
    //   });
    //   this.reloadFunction();
    // }
  }

  validateDay(day: number, month: number): boolean {
    return day < month;
  }

  validateMonth(month: number, year: number): boolean {
    return month < year;
  }

  errorMsg(isDailyValid: boolean, isMonthlyValid: boolean): string {
    if (!isDailyValid && !isMonthlyValid) {
      Swal.fire({
        icon: 'error',
        title: 'Errore!',
        text: 'Obiettivo giornaliero non può essere superiore a quello mensile e obiettivo mensile non può essere superiore a quello annuale!!! Le basi della matematica su! Non sei stato a scuola?!',
        timer: 1500,
        timerProgressBar: true,
      });
    } else if (!isDailyValid) {
      Swal.fire({
        icon: 'error',
        title: 'Errore!',
        text: 'Obiettivo giornaliero non può essere superiore a quello mensile!! Le basi della matematica su! Non sei stato a scuola?!',
        timer: 1500,
        timerProgressBar: true,
      });
    } else if (!isMonthlyValid) {
      Swal.fire({
        icon: 'error',
        title: 'Errore!',
        text: 'Obiettivo mensile non può essere superiore a quello annuale!! Le basi della matematica su! Non sei stato a scuola?!',
        timer: 1500,
        timerProgressBar: true,
      });
    }
    return 'Errore sconosciuto, ma è sempre un errore..';
  }

  changeYear(e) {
    console.log('Year is', e);
    this.chosenYear = e;
    this.updatedObjectives = [];
    this.newObjectivesArray = [];
    this.orderService.getObjectives().subscribe((data) => {
      if (!data.success) {
        this.updatedObjectives = [];
      } else {
        this.updatedObjectives = data.data;
      }
      this.updatedObjectives = data.data;
      this.newObjectivesArray = this.newObjectivesArray;
      let seen = {};
      console.log('Updated objectives???', this.updatedObjectives);
      this.updatedObjectives = this.updatedObjectives.filter(
        (obj) => obj?.yearly_objective === +this.chosenYear
      );
      console.log('Updated objectives???', this.updatedObjectives);
      this.updatedObjectives = this.updatedObjectives.filter((obj) => {
        if (seen[obj.monthly_objective]) {
          return false;
        } else {
          seen[obj.monthly_objective] = true;
          return true;
        }
      });
      console.log('Updated objectives???', this.updatedObjectives);
      const monthNames = {
        1: 'Gennaio',
        2: 'Febbraio',
        3: 'Marzo',
        4: 'Aprile',
        5: 'Maggio',
        6: 'Giugno',
        7: 'Luglio',
        8: 'Agosto',
        9: 'Settembre',
        10: 'Ottobre',
        11: 'Novembre',
        12: 'Dicembre',
      };

      for (let month = 1; month <= 12; month++) {
        let objective = this.updatedObjectives.find(
          (obj) => obj.monthly_objective === month
        );
        if (!objective) {
          let newObjective: UpdatedObjectives = new UpdatedObjectives(null);
          newObjective.monthly_objective = month;
          newObjective.correspondingMonth = monthNames[month];
          newObjective.yearly_objective = +this.chosenYear;
          newObjective.net_value = 0; // Initialize to 0
          newObjective.working_days = 0; // Initialize to 0
          newObjective.formattedNetValue = this.currencyPipe.transform(
            newObjective.net_value,
            'EUR'
          );
          this.updatedObjectives.push(newObjective);
        } else {
          objective.correspondingMonth = monthNames[month];
          objective.formattedNetValue = this.currencyPipe.transform(
            objective.net_value,
            'EUR'
          );
        }
      }
      console.log('Updated objectives???', this.updatedObjectives);
      // Sorting the updatedObjectives by monthly_objective to maintain order
      this.updatedObjectives.sort(
        (a, b) => a.monthly_objective - b.monthly_objective
      );
      this.updatedObjectives.forEach((obj) => {
        obj.net_value = obj.net_value === -1 ? 0 : obj.net_value;
        obj.working_days = obj.working_days === -1 ? 0 : obj.working_days;
        obj.yearly_objective =
          obj.yearly_objective === -1 ? 0 : obj.yearly_objective;
      });

      let totalYearlyAmount = this.updatedObjectives.reduce(
        (total, element) => {
          return total + element.net_value;
        },
        0
      );
      this.totalYearlyAmount = totalYearlyAmount;
      let newObjective: UpdatedObjectives = new UpdatedObjectives(null);
      newObjective.monthly_objective = 13;
      newObjective.correspondingMonth = 'Annuale';
      newObjective.net_value = this.totalYearlyAmount;
      newObjective.yearly_objective = +this.chosenYear;
      newObjective.working_days = this.updatedObjectives.reduce(
        (total, element) => {
          return total + element.working_days;
        },
        0
      );
      newObjective.formattedNetValue = this.currencyPipe.transform(
        this.totalYearlyAmount,
        'EUR'
      );
      this.updatedObjectives.push(newObjective);
      this.newObjectivesArray = this.updatedObjectives;

      console.log('Total amount for this year', totalYearlyAmount);
      console.log('Updated objectives???', this.updatedObjectives);
    });
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  onInputChange(event: any, dataItem: any) {
    const inputValue = event.target.value;
    console.log('Dataitem is', dataItem);
    this.formattedAmount = inputValue;
    // this.currencyPipe.transform(this.formattedAmount, 'EUR');
    console.log('Formatted ammount is', this.formattedAmount);
    dataItem.net_value = parseFloat(inputValue.replace(/[^0-9.-]/g, ''));
    console.log('Dataitem is 2nd', dataItem);
  }

  formattedAmount: any;

  transformAmount(element) {
    console.log('Elementus is', element.target.value);
    this.formattedAmount = this.currencyPipe.transform(
      this.formattedAmount,
      'EUR'
    );

    element.target.value = this.formattedAmount;
  }
}
