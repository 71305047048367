<h1 class="page-title">Statistiche</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <div class="dateContainer k-display-flex">
    <div class="col mx-2 k-display-flex align-items-center">
      <h5 class="text-center mx-3" style="width: 300px">Seleziona anno:</h5>
      <kendo-dropdownlist
        [data]="years"
        class="drop-the-border"
        #dropdownList
        [selectedIndex]="index"
        style="
          text-align: center;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
        (valueChange)="changeYear($event)"
        [(ngModel)]="chosenYear"
      >
        <ng-template let-dataItem> {{ dataItem }} </ng-template>
      </kendo-dropdownlist>
    </div>
    <div class="col"></div>
  </div>
</div>
<hr class="hr-newBlue" />

<div
  class="header pb-8 pt-3 pt-lg-3 d-flex align-items-center"
  style="
    background-image: url(assets/img/brand/banner.jpg);
    background-size: cover;
    background-position: center top;
  "
>
  <span class="mask bg-gradient-default opacity-8"></span>
  <div class="container-fluid">
    <div class="header-body">
      <div class="row">
        <div class="col-xl-2 col-lg-6">
          <div class="card card-stats mb-2 mb-xl-0">
            <div class="card-body" style="height: 150px; cursor: pointer">
              <div class="row">
                <div class="col">
                  <h5
                    (click)="scroll('ordini')"
                    class="card-title text-uppercase text-muted mb-0"
                    title="Vai al grafico"
                  >
                    N° Ordini evasi
                  </h5>
                  <span
                    class="h2 font-weight-bold mb-0"
                    *ngIf="generalStatistics"
                    >{{ generalStatistics.stat_total_orders }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape text-white shadow d-flex align-items-center justify-content-center"
                    style="
                      width: 25px;
                      height: 25px;
                      border: none;
                      border-radius: 50%;
                      background: rgba(54, 162, 235, 1);
                    "
                  >
                    <i class="fas fa-tasks"></i>
                  </div>
                </div>
              </div>
              <p
                class="mt-3 mb-0 text-muted text-sm"
                *ngIf="generalStatistics && generalStatistics.s_t_orders_perc"
              >
                <span class="text-success mr-2">
                  <i
                    class="fa fa-arrow-down"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.s_t_orders_perc &&
                      generalStatistics.s_t_orders_perc < 0
                    "
                  ></i>
                  <i
                    class="fa fa-arrow-up"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.s_t_orders_perc &&
                      generalStatistics.s_t_orders_perc > 0
                    "
                  ></i>
                  {{ generalStatistics.s_t_orders_perc + "%" }}
                </span>
                <span class="text-nowrap"> dall'ultimo anno</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body" style="height: 150px; cursor: pointer">
              <div class="row">
                <div class="col">
                  <h5
                    (click)="scroll('colli')"
                    class="card-title text-uppercase text-muted mb-0"
                    title="Vai al grafico"
                  >
                    N° Colli spediti
                  </h5>
                  <span
                    class="h2 font-weight-bold mb-0"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.stat_total_boxes_delivered
                    "
                    >{{ generalStatistics.stat_total_boxes_delivered }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape text-white shadow d-flex align-items-center justify-content-center"
                    style="
                      width: 25px;
                      height: 25px;
                      border: none;
                      border-radius: 50%;
                      background: rgba(255, 206, 86, 1);
                    "
                  >
                    <i class="fas fa-box"></i>
                  </div>
                </div>
              </div>
              <p
                class="mt-3 mb-0 text-muted text-sm"
                *ngIf="
                  generalStatistics &&
                  generalStatistics.s_t_boxes_delivered_perc !== undefined
                "
              >
                <span class="text-warning mr-2">
                  <i
                    class="fa fa-arrow-down"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.s_t_boxes_delivered_perc &&
                      generalStatistics.s_t_boxes_delivered_perc < 0
                    "
                  ></i>
                  <i
                    class="fa fa-arrow-up"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.s_t_boxes_delivered_perc
                    "
                  ></i>
                  {{ generalStatistics.s_t_boxes_delivered_perc + "%" }}</span
                >
                <span class="text-nowrap"> dall'ultimo anno</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body" style="height: 150px; cursor: pointer">
              <div class="row">
                <div class="col">
                  <h5
                    (click)="scroll('mediacolli')"
                    class="card-title text-uppercase text-muted mb-0"
                    title="Vai al grafico"
                  >
                    Media colli spediti
                  </h5>
                  <span
                    class="h2 font-weight-bold mb-0"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.stat_daily_avg_boxes_delivered
                    "
                    >{{ generalStatistics.stat_daily_avg_boxes_delivered
                    }}<span style="font-size: 18px">/giorno</span></span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape text-white shadow d-flex align-items-center justify-content-center"
                    style="
                      width: 25px;
                      height: 25px;
                      border: none;
                      border-radius: 50%;
                      background: rgba(75, 192, 192, 1);
                    "
                  >
                    <i class="fas fa-chart-pie"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-danger mr-2"></span>
                <i
                  class="fa fa-arrow-down"
                  *ngIf="
                    generalStatistics &&
                    generalStatistics.s_d_avg_boxes_delivered_perc &&
                    generalStatistics.s_d_avg_boxes_delivered_perc < 0
                  "
                ></i>
                <i
                  class="fa fa-arrow-up"
                  *ngIf="
                    generalStatistics &&
                    generalStatistics.s_d_avg_boxes_delivered_perc > 0
                  "
                ></i>
                {{ generalStatistics.s_d_avg_boxes_delivered_perc + "%" }}

                <span class="text-nowrap"> dall'ultimo anno</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body" style="height: 150px; cursor: pointer">
              <div class="row">
                <div class="col">
                  <h5
                    (click)="scroll('mediaordini')"
                    class="card-title text-uppercase text-muted mb-0"
                    title="Vai al grafico"
                  >
                    Media ordini evasi
                  </h5>
                  <span
                    class="h2 font-weight-bold mb-0"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.stat_daily_avg_orders_completed
                    "
                    >{{ generalStatistics.stat_daily_avg_orders_completed
                    }}<span style="font-size: 18px">/giorno</span></span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape text-white shadow d-flex align-items-center justify-content-center"
                    style="
                      width: 25px;
                      height: 25px;
                      border: none;
                      border-radius: 50%;
                      background: rgba(153, 102, 255, 1);
                    "
                  >
                    <i class="fas fa-chart-bar"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-danger mr-2"
                  ><i
                    class="fa fa-arrow-down"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.s_d_avg_orders_completed_perc &&
                      generalStatistics.s_d_avg_orders_completed_perc < 0
                    "
                  ></i>
                  <i
                    class="fa fa-arrow-up"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.s_d_avg_orders_completed_perc > 0
                    "
                  ></i>
                  {{
                    generalStatistics.s_d_avg_orders_completed_perc + "%"
                  }}</span
                >
                <span class="text-nowrap"> dall'ultimo anno</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-6" *ngIf="environment === urlServer">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body" style="height: 150px; cursor: pointer">
              <div class="row">
                <div class="col">
                  <h5
                    (click)="scroll('error')"
                    class="card-title text-uppercase text-muted mb-0"
                    title="Vai al grafico"
                  >
                    N° errori
                  </h5>
                  <span
                    class="h2 font-weight-bold mb-0"
                    *ngIf="
                      generalStatistics && generalStatistics.stat_total_errors
                    "
                    >{{ generalStatistics.stat_total_errors }}</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape text-white shadow d-flex align-items-center justify-content-center"
                    style="
                      width: 25px;
                      height: 25px;
                      border: none;
                      border-radius: 50%;
                      background: rgb(227, 38, 38);
                    "
                  >
                    <i class="fas fa-exclamation-triangle"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-danger mr-2"
                  ><i
                    class="fa fa-arrow-down"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.s_t_errors_perc &&
                      generalStatistics.s_t_errors_perc < 0
                    "
                  ></i>
                  <i
                    class="fa fa-arrow-up"
                    *ngIf="
                      generalStatistics && generalStatistics.s_t_errors_perc > 0
                    "
                  ></i>
                  {{ generalStatistics.s_t_errors_perc + "%" }}</span
                >
                <span class="text-nowrap"> dall'ultimo anno</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body" style="height: 150px; cursor: pointer">
              <div class="row">
                <div class="col">
                  <h5
                    (click)="scroll('timer')"
                    class="card-title text-uppercase text-muted mb-0"
                  >
                    Preparazione ordine
                  </h5>
                  <span
                    class="h3 font-weight-bold mb-0"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.stat_avg_preparation_time
                    "
                    >{{ generalStatistics.stat_avg_preparation_time
                    }}</span>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape text-white shadow d-flex align-items-center justify-content-center"
                    style="
                      width: 25px;
                      height: 25px;
                      border: none;
                      border-radius: 50%;
                      background: rgb(235, 54, 54);
                    "
                  >
                    <i class="fas fa-clock"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-success mr-2"
                  ><i
                    class="fa fa-arrow-down"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.s_avg_prep_time_perc &&
                      generalStatistics.s_avg_prep_time_perc < 0
                    "
                  ></i>
                  <i
                    class="fa fa-arrow-up"
                    *ngIf="
                      generalStatistics &&
                      generalStatistics.s_avg_prep_time_perc > 0
                    "
                  ></i
                  >{{ generalStatistics.s_avg_prep_time_perc }}</span
                >
                <span class="text-nowrap"> dall'ultimo anno</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container2">
  <!-- TOTALE ORDINI OPERATORE -->
  <div class="left2">
    <ion-card style="height: 646px">
      <ion-card-content>
        <button
          class="btn-down"
          (click)="
            downloadChart('totaleOperatoriCanvas', 'ordini_evasi_per_operatore')
          "
          title="Download grafico"
        >
          <i class="fas fa-download"></i>
        </button>
        <canvas class="polar" #totaleOperatoriCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
  <!-- TOTALE ORDINI OPERATORE/MESE -->
  <div class="right2">
    <ion-card style="height: 646px">
      <ion-card-content>
        <button
          class="btn-down"
          (click)="downloadChart('operatoreCanvas', 'ordini_per_operatore')"
          title="Download grafico"
        >
          <i class="fas fa-download"></i>
        </button>
        <canvas #operatoreCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
</div>

<div class="container2">
  <!-- TOTALE ORDINI CORRIERE -->
  <div class="left2">
    <ion-card style="height: 646px">
      <ion-card-content>
        <button
          class="btn-down"
          (click)="
            downloadChart('totaleCorrieriCanvas', 'colli_spediti_per_corriere')
          "
          title="Download grafico"
        >
          <i class="fas fa-download"></i>
        </button>
        <canvas class="polar" #totaleCorrieriCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
  <!-- TOTALE ORDINI CORRIERE/MESE -->
  <div class="right2">
    <ion-card style="height: 646px">
      <ion-card-content>
        <button
          class="btn-down"
          (click)="downloadChart('corriereCanvas', 'ordini_per_corriere')"
          title="Download grafico"
        >
          <i class="fas fa-download"></i>
        </button>
        <canvas #corriereCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
</div>

<!-- ORDINI PER CORRIERE V1 -->
<!--<ion-card>
  <ion-card-content>
    <canvas #corriereCanvas></canvas>
  </ion-card-content>
</ion-card>
<br />-->
<!-- ORDINI PER CORRIERE V2 -->
<!--<ion-card>
  <ion-card-content>
    <canvas #corriere2Canvas></canvas>
  </ion-card-content>
</ion-card>
<br />-->
<!-- ORDINI PER OPERATORE -->
<!--<ion-card>
  <ion-card-content>
    <canvas #operatoreCanvas></canvas>
  </ion-card-content>
</ion-card>
-->

<div class="container2">
  <!-- NUMERO ORDINI EVASI -->
  <div class="left3">
    <ion-card id="ordini">
      <ion-card-content>
        <button
          class="btn-down"
          (click)="downloadChart('ordiniEvasiCanvas', 'numero_ordini_evasi')"
          title="Download grafico"
        >
          <i class="fas fa-download"></i>
        </button>
        <canvas #ordiniEvasiCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
  <!-- MEDIA GIORNALIERA ORDINI -->
  <div class="right3">
    <ion-card id="mediaordini">
      <ion-card-content>
        <button
          class="btn-down"
          (click)="
            downloadChart('mediaOrdiniCanvas', 'media_giornaliera_ordini_evasi')
          "
          title="Download grafico"
        >
          <i class="fas fa-download"></i>
        </button>
        <canvas #mediaOrdiniCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
</div>
<div class="container2">
  <!-- NUMERO COLLI SPEDITI -->
  <div class="left3">
    <ion-card id="colli">
      <ion-card-content>
        <button
          class="btn-down"
          (click)="downloadChart('colliSpeditiCanvas', 'numero_colli_spediti')"
          title="Download grafico"
        >
          <i class="fas fa-download"></i>
        </button>
        <canvas #colliSpeditiCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
  <!-- MEDIA GIORNALIERA SPEDIZIONI -->
  <div class="right3">
    <ion-card id="mediacolli">
      <ion-card-content>
        <button
          class="btn-down"
          (click)="
            downloadChart(
              'mediaSpedizioniCanvas',
              'media_giornaliera_colli_spediti'
            )
          "
          title="Download grafico"
        >
          <i class="fas fa-download"></i>
        </button>
        <canvas #mediaSpedizioniCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
</div>
<!-- GIORNI LAVORATIVI -->
<!--<ion-card>
  <ion-card-content>
    <canvas #giorniLavorativiCanvas></canvas>
  </ion-card-content>
</ion-card>
<br />-->

<!-- YET TO BE IMPLEMENTED!!! -->
<!-- <div class="container">
  <div class="left"> -->
<!-- NUMERO ERRORI PER OPERATORE -->
<!-- <ion-card id="error">
      <ion-card-content>
        <button
          class="btn-down"
          (click)="
            downloadChart('numeroErroriCanvas', 'numero_errori_per_operatore')
          "
          title="Download grafico"
        >
          <i class="fas fa-download"></i>
        </button>
        <canvas #numeroErroriCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
  <div class="right"> -->
<!-- PERCENTUALE ERRORI PER OPERATORE -->
<!-- <ion-card>
      <ion-card-content>
        <button
          class="btn-down"
          (click)="
            downloadChart(
              'percentualeErroriCanvas',
              'percentuale_errori_per_operatore'
            )
          "
          title="Download grafico"
        >
          <i class="fas fa-download"></i>
        </button>
        <canvas #percentualeErroriCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
</div> -->
