import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Boxes } from 'src/app/models/Boxes.models';
import { Documents } from 'src/app/models/documents.models';
import { Orders } from 'src/app/models/Orders.model';
import { Products } from 'src/app/models/Product.model';
import { OrdersService } from 'src/app/services/orders.service';
import { PagesService } from 'src/app/services/pages.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-box-details',
  templateUrl: './box-details.component.html',
  styleUrls: ['./box-details.component.scss'],
})

export class BoxDetailsComponent implements OnInit {

  @Input() openBox = false;
  @Input() box: Boxes.BoxOrder;
  @Input() boxNo: number;
  @Output() dataInfo = new EventEmitter<Object>();

  public order: Orders.Order;
  public orderToUpdate: Orders.OrderUpdate;
  public documents: Documents.Document[] = [];
  public boxes: Boxes.BoxOrder[] = [];
  public boxini: Boxes.Box[] = [];

  productsToCheck: Products.IProductToSend[] = [];
  id: string;
  back: string = '';
  stepCount: number = 0;
  maxChild: number = 3;
  clickedBox: string;
  detailsBox: any;
  orderBoxDetails!: FormGroup;
  newBox: Boxes.BoxOrder;
  addBoxForm!: FormGroup;
  numbers: number[] = [];
  selectedNumber: number;
  boxDet: boolean = false;
  orderToCheck: string = null;
  text: string = 'details';
  chosenBox: Boxes.Box = {
    box_type_id: -1,
    box_type_desc: '',
  };

  constructor(
    private orderService: OrdersService,
    public openModal: OpenModalService,
    private pagesService: PagesService,
    private activatedRoute: ActivatedRoute
  ) { }

  addDocumentsToBox(boxNo: number) {
    this.order.boxes[boxNo].ts_documents =
      this.order.boxes[boxNo].ts_documents?.toUpperCase() === 'S' ? 'N' : 'S';
    this.orderService.updateOrder(this.orderToUpdate).subscribe();
  }

  goBack() {
    this.openModal.closeDetBox();
  }

  ngOnInit(): void {
    this.newBox = new Boxes.BoxOrder(this.box);
    console.log('new box is', this.newBox);
    this.orderService.getBox().subscribe((result) => {
      this.boxini = result;
    });
    this.numbers = Array.from(
      { length: this.boxNo },
      (_element, index) => index + 1
    );
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.back = this.pagesService.getParentUrl();
    console.debug('back', this.back);
    this.orderService.getOrder1(+this.id).subscribe(async (result) => {
      this.order = result;
      this.orderToUpdate = new Orders.OrderUpdate(this.order);
      if (this.orderToUpdate.or_carrier_id == 0)
        this.orderToUpdate.or_carrier_id = null;
      this.boxes = this.order.boxes.map((item) => {
        let box: Boxes.BoxOrder = {
          ts_box_type_id: item.ts_box_type_id,
          ts_box_type_desc: item.ts_box_type_desc,
          ts_box_height: item.ts_box_height,
          ts_box_length: item.ts_box_length,
          ts_box_depth: item.ts_box_depth,
          ts_box_weight: item.ts_box_weight,
          ts_documents: 'N',
          ts_screws: 'N',
          isDeleted: false,
          checkForNull: function (): boolean {
            throw new Error('Function not implemented.');
          },
        };
        return box;
      });
    });
  }

  changeBox(id, desc = '') {
    this.chosenBox.box_type_desc = desc;
    this.chosenBox.box_type_id = id;
    console.debug('result: ', this.chosenBox);
  }

  validateBox(value: string) {
    this.chosenBox.box_type_id =
      this.chosenBox.box_type_id < 0 ? null : this.chosenBox.box_type_id;
    let dataInfo = {
      custom: this.newBox,
      boxNo: this.boxNo,
    };
    console.log('Output is: ', this.dataInfo);
    console.log('DataInfo is: ', dataInfo);
    this.dataInfo.emit(dataInfo);
    this.openModal.closeBox(dataInfo);
    Swal.fire({
      icon: 'success',
      title: 'Aggiunta!',
      text: 'Scatola aggiunta con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }
  
}
