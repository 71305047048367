<kendo-dialog
  (close)="close()"
  [minWidth]="minWidth"
  [width]="dialogWidth"
  [minHeight]="500"
  [maxWidth]="1150"
  style="left: 6%; padding: 10px"
>
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: -15px;
      z-index: 1000;
      position: fixed;
      margin-left: -35px;
    "
  >
    <button
      (click)="close()"
      class="btn btn3"
      rounded="full"
      style="
        background-color: #e90000;
        color: #fff;
        width: 35px !important;
        height: 35px !important;
      "
    >
      <i
        class="bx bx-x bx-sm posRelBox2"
        style="
          color: #fff;
          bottom: 10px;
          position: relative !important;
          left: -80% !important;
          top: -1px !important;
        "
      ></i>
    </button>
  </div>
  <h1 class="page-title">Dettaglio Prodotto</h1>

  <div class="container-mare">
    <div class="row">
      <div class="col-md">
        <div class="container" *ngIf="product">
          <div class="row det">
            <div class="col-md setDisplay-Prep">
              <span class="title pb-2 pt-3">N°/Nome prodotto</span>
              <span class="setBorder-Prepp">{{ product.pr_code }}</span>
            </div>
            <div class="col-md setDisplay-Prep">
              <span class="title pb-2 pt-3">Peso</span>
              <span class="setBorder-Prepp" type="number" step="0.01">
                {{ product.pr_weight }}
              </span>
            </div>
            <div class="col-md setDisplay-Prep">
              <span class="title pb-2 pt-3">Quantità magazzino</span>
              <span class="setBorder-Prepp" type="number" step="0.01">
                {{ product.pr_stock }}
              </span>
            </div>
          </div>
          <div class="row det">
            <div class="col-md setDisplay-Prep">
              <span class="title pb-2 pt-3">Categoria</span>
              <span class="setBorder-Prepp">
                {{ product.family_desc }}
              </span>
            </div>
            <div class="col-md setDisplay-Prep">
              <span class="title pb-2 pt-3">Modello</span>
              <span class="setBorder-Prepp">
                {{ product.pr_description }}
              </span>
            </div>
            <div class="col-md setDisplay-Prep">
              <span class="title pb-2 pt-3">Marca</span>
              <span class="setBorder-Prepp">
                {{
                  product.group_desc ? product.group_desc : "Da decidere sul DB"
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="hr-newBlue mt-4" />
    <div class="row mt-2">
      <div class="col-md setBorder-Prepp">
        <div
          class="container"
          *ngIf="product"
          style="padding-left: 6px !important"
        >
          <h1 class="page-title">Posizione in magazzino</h1>
          <div 
            class="k-display-flex"
            style="
              display: flex !important;
              flex-direction: row !important;
              justify-content: center !important;
            "
          >
            <button
              (click)="goToDetails(product)"
              class="btn btn3"
              rounded="full"
              style="
                display: flex !important;
                flex-direction: row !important;
                justify-content: center !important;
                background: #0d6efd;
                color: #fff;
                width: 192px !important;
                height: 44px !important;
              "
            >
              <i
                class="bx bx-plus-circle bx-sm posRelBox2"
                style="
                  color: #fff;
                  top: 2px;
                  right: 6px;
                  bottom: 10px;
                  position: relative;
                "
              ></i>
              <p class="posRelBox2">AGGIUNGI</p>
            </button>
          </div>
          <div
            class="row"
            *ngFor="let position of pr_position; let i = index"
            style="padding-left: 10px !important; margin-top: 20px"
          >
            <div class="col pos">
              {{ position.pr_lane }}. {{ position.pr_shelf }}.
              {{ position.pr_position }} | {{ position.pr_qty }}
              <div class="col img-col">
                <img
                  class="img-prod"
                  [ngClass]="{ zoom: isZoomed }"
                  style="height: 45px !important; cursor: pointer"
                  [src]="
                    'data:image/png;base64,' + position?.pr_b64_position_image
                  "
                  alt="Posizione immagine"
                />
              </div>
              <button
                rounded="full"
                (click)="
                  deleteProductPosition(pr_position[i].product_position_id)
                "
                style="margin: 0; padding: 0; border: none"
              >
                <div
                  class="setDisplayToFlexRed btn-cancella"
                  style="margin: 0; padding: 0"
                >
                  <i
                    class="bx bx-trash bx-sm posRelBox2"
                    style="margin-right: 8px; margin-left: 5px"
                  >
                  </i>
                  <p class="parButton2" style="font-size: 16px">ELIMINA</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>

<app-product-position
  *ngIf="openSrv.isPosOn"
  [openPhoto]="openSrv.isPosOn"
  [product]="product"
  (modalClosed)="triggerRefresh()"
></app-product-position>
