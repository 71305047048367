import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { byToClosingDatePipe } from './by-to-closing-date.pipe';

@NgModule({
  exports: [byToClosingDatePipe],
  declarations: [byToClosingDatePipe],
  imports: [CommonModule],
})

export class ByToClosingDateModule {

  static forRoot() {
    return {
      ngModule: ByToClosingDateModule,
      providers: [],
    };
  }
  
}
