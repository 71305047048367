import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  GridDataResult,
  PageChangeEvent,
  RowClassArgs,
} from '@progress/kendo-angular-grid';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { aggregateBy, AggregateResult } from '@progress/kendo-data-query';
import {
  Orders,
  ProgressObjective,
  UpdatedObjectives,
} from 'src/app/models/Orders.model';
import { Objectives } from 'src/app/models/Orders.model';
import { Status } from 'src/app/models/status';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { SettingsService } from 'src/app/services/settings.service';
import { IconsService } from '../../../services/icons.service';
import Swal from 'sweetalert2';
import { SharedDataService } from '../../../services/shared/total.service';
import { SearchServiceService } from 'src/app/services/shared/search-service.service';
import { Subscriber, Subscription } from 'rxjs';
import { parseISO } from 'date-fns';
import { SearchService } from 'src/app/services/search.service';
import { GoalService } from 'src/app/services/goal.service';

@Component({
  selector: 'app-assegna-ordine-list',
  templateUrl: './assegna-ordine-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./assegna-ordine-list.component.scss'],
})

export class AssegnaOrdineListComponent implements OnInit {

  public gridData: Orders.IOrderNewList[] = [];
  public pmOrders: Orders.OrderPM[] = [];
  public partials: Orders.IOrderNewList[] = [];
  public order: Orders.IOrder = null;
  public loading: boolean = false;
  public value = 0;
  public indeterminate = false;
  public min = -10;
  public max = 10;
  public gridView: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  public data: unknown[];
  public setPercentLabel: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center',
  };

  gridDataInfinity: Orders.IOrderNewList[] = [];
  ordiniParziali: any[] = [];
  ordiniPM: any[] = [];
  filteredOrders: Orders.IOrderNewList[] = [];
  searchText: string = '';
  filterTerm: string | undefined;
  sameIndex: boolean = true;
  id: number;
  timer = null;
  refreshInterval: number = 60000;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  obiettivi: Objectives;
  objectives: ProgressObjective = new ProgressObjective(null);
  totalAmount: any = 0;
  totaleImponibile: number = 0;
  totaleImporto = this.gridData.reduce(
    (total, item) => total + item.op_articles_number,
    0
  );
  totz = this.gridData.length;

  // searchService = inject(SearchService);
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  searchService = inject(SearchServiceService);
  filterService = inject(SearchService);
  subscriber: Subscription[] = [];
  searchParam!: string;
  dynamicLink: Params = {};
  booleanForSpecialSearch: boolean = false;
  searchTermLocalStorage: string = '';

  constructor(
    private ordersServ: OrdersService,
    private router: Router,
    private iconService: IconsService,
    private settings: SettingsService,
    private share: SharedDataService,
    private route: ActivatedRoute,
    private goal: GoalService
  ) {
    console.log('Totale imponibile', this.totaleImponibile);
    this.totalAmount = this.gridData.reduce(
      (total, currentItem) => total + currentItem.or_articles_number,
      0
    );
    // this.filterTerm = this.filterService.getFilterText();
    // console.log('filterTerm is', this.filterTerm);
  }

  checkProgress() {
    // Calcola il progresso totale (supponendo che tu abbia una variabile che tiene traccia del progresso)
    let totalProgress = this.calculateTotalProgress();  // Sostituisci con la tua logica per calcolare il progresso

    // Se il progresso è al 100%, notifica GoalService
    if (totalProgress >= 100) {
      this.goal.reachGoal();
    }
  }

  calculateTotalProgress(): number {
    // Implementa la tua logica per calcolare il progresso totale
    return 0;  // Esempio: sostituisci con il calcolo reale del progresso
  }

  updateProgress() {
    // Logica per aggiornare il progresso degli ordini
    // ...

    // Dopo aver aggiornato il progresso, chiama checkProgress per verificare se l'obiettivo è raggiunto
    this.checkProgress();
  }

  handleFilterChange(): void {
    this.filterService.setFilterText(this.filterTerm);
    // Perform filtering logic here
  }

  loadMore() {
    this.gridDataInfinity = [
      ...this.gridDataInfinity,
      ...this.gridData.slice(this.skip, this.skip + this.pageSize),
    ];
    // this.updateGridData();
    this.skip += this.pageSize;
  }

  isOrPartialS(element: any): boolean {
    return element.or_partial === 'S';
  }

  updateGridData() {
    this.gridData = [
      ...this.partials,
      ...this.ordiniPM,
      ...this.gridDataInfinity,
    ];
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.or_partial === 'S') {
      return { partialOrderStyle: true };
    }
  };

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadProducts();
  }

  private loadProducts(): void {
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length,
    };
  }

  searchOrders(searchString?: string) {
    if (searchString) this.filterTerm = searchString;
    if (!this.filterTerm || this.filterTerm == '') {
      this.reloadFunction();
      return;
    }
    this.ordersServ
      .searchNewOrders(this.filterTerm)
      .subscribe((newOrdersData: IWsResult) => {
        if (newOrdersData.error_message.msg_code === 8) this.gridData = [];
        else {
          this.gridData = newOrdersData.data;
          this.skip = 0;
          this.gridDataInfinity = [];
          this.ordiniParziali = this.gridData.filter(
            (orders) => orders.or_partial === 'S'
          );
          console.log('Grid data after search is', this.gridData);
          this.dynamicLink['search'] = this.filterTerm;
          console.log('Dynamic link is', this.dynamicLink);
        }
        // this.updateGridData();
      });
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.filters.startingDate = this.startDate;
      this.getNewOrders();
    }
  }

  clearSearch() {
    this.filterTerm = '';
    this.filters.startingDate = this.startDate;
    this.filterService.clearFilterText();
    // localStorage.removeItem('query-search');
    this.getNewOrders();
  }

  ngOnInit(): void {
    this.loading = true;

    this.filterTerm = this.filterService.getFilterText();
    console.log('Filter is', this.filterTerm);

    console.log('Filterterm is', this.searchTermLocalStorage);
    if (this.filterTerm) {
      // this.booleanForSpecialSearch = !this.booleanForSpecialSearch;

      this.ordersServ
        .searchNewOrders(this.filterTerm)
        .subscribe((newOrdersData: IWsResult) => {
          // this.getOrdineParziali();
          // this.getPMOrders();
          this.getObjectives();
          // this.ordersServ.getObjectives().subscribe((obj: Objectives) => {
          //   this.obiettivi = obj;
          //   // return;
          // });
          if (newOrdersData.error_message.msg_code === 8) this.gridData = [];
          else {
            this.gridData = newOrdersData.data;
            this.ordiniParziali = this.gridData.filter(
              (order) => order.or_partial === 'S'
            );
            this.skip = 0;
            this.gridDataInfinity = [];
            console.log('Grid data after search is', this.gridData);
            // this.dynamicLink['search'] = this.filterTerm;
            // console.log('Dynamic link is', this.dynamicLink);
            this.loading = false;
          }
          // this.updateGridData();
        });
      // this.reloadFunction();
    } else {
      this.reloadFunction();
      this.subscriber.push(
        this.route.queryParams.subscribe((params) => {
          console.log('Params is', params);
          // Check if params is an empty object
          if (Object.keys(params).length === 0) {
            this.reloadFunction();
            console.log('Params is an empty object. Reloaded.');
          } else {
            this.searchParam = params['search'];
            const dateParam = params['data'];
            this.filters.startingDate = dateParam ? parseISO(dateParam) : null;
            console.log('Param is', this.searchParam);
            this.searchOrders(this.searchParam);
            this.loading = false;
          }
        })
      );
    }
  }

  loadingForObj: boolean = false;

  getObjectives() {
    this.loadingForObj = true;
    this.ordersServ.getObjectiveProgress().subscribe((data: any) => {
      console.log('objectivessaasds', data);
      if (!data.success) {
        this.objectives = null;
        this.loadingForObj = false;
      } else {
        this.objectives = data.data;
        this.loadingForObj = false;
      }
      console.log('Objectives are', this.objectives);
    });
  }

  checkPartial: boolean = false;
  netValue: any;

  getNewOrders() {
    this.loading = true;
    this.ordersServ.getNewOrders().subscribe((openOrdersData: any) => {
      if (openOrdersData.data === null) {
        this.loading = false;
        return;
      } else if (openOrdersData.data) {
        this.updateProgress(); 
        console.log('Orders are', openOrdersData.data);
        this.gridData = openOrdersData.data;
        console.log('GridData is', this.gridData);
        this.netValue = 0;
        let gridusData = this.gridData.map((net_value) => {
          this.netValue += net_value.or_net_value;
          return net_value.or_net_value;
        });

        console.log('Gridus datas isust', gridusData);
        console.log('Net value is', this.netValue);
        this.skip = 0;
        this.gridDataInfinity = [];
        // this.loadMore();
        this.checkPartial = false; // Reset to false before checking
        for (const el of this.gridData) {
          if (el.or_partial === 'S') {
            this.checkPartial = true;
            console.log('Check partial is', this.checkPartial); // Break the loop if a match is found
          }
        }
        this.ordiniParziali = this.gridData.filter(
          (order) => order.or_partial === 'S'
        );
        console.log('Partialus orders are', this.ordiniParziali);
        this.loading = false;
        // If you want to set it to false when no match is found, you can add an else block
      }
    });
  }

  getOrdineParziali() {
    this.loading = true;
    this.ordersServ.getNewOrdersPartial().subscribe((openOrdersData) => {
      this.ordiniParziali = openOrdersData.data;
      console.log('Ordini parziali: ', this.ordiniParziali);
      this.skip = 0;
      this.gridDataInfinity = [];
      // this.loadMore();
      // this.loading = false;
    });
  }

  getPMOrders() {
    this.loading = true;
    this.ordersServ.getCMOrders().subscribe((openOrdersData) => {
      this.ordiniPM = openOrdersData;
      console.log('Ordini PM: ', this.ordiniPM);
      this.skip = 0;
      this.gridDataInfinity = [];
      // this.loadMore();
      // this.loading = false;
    });
  }

  gotoDetails(order_id: number) {
    this.gridData = [];
    this.ordiniParziali = [];
    // console.log('Accesing the page with no query param', this.dynamicLink);
    this.router.navigate(['/assign-order', order_id]);
  }

  block(order) {
    this.iconService.changeIconToBlocked(order);
    console.log('BLOCKED!', order);
    this.ordersServ
      .lockNewOrder(order.or_gamma_id)
      .subscribe((result: IWsResult) => {
        if (!result.success) return;
        this.reloadFunction();
        Swal.fire({
          icon: 'success',
          title: 'Bloccato!',
          text: 'Ordine bloccato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        console.log('BLOCKED!');
      });
  }

  async unblock(order) {
    console.log('BLOCKED!', order);
    this.iconService.changeIconToWorking(order);
    this.ordersServ
      .unlockNewOrder(order.or_gamma_id)
      .subscribe((result: IWsResult) => {
        if (!result.success) return;
        this.reloadFunction();
        Swal.fire({
          icon: 'success',
          title: 'Sbloccato!',
          text: 'Ordine sbloccato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        console.debug('This order', order);
        console.log('UNBLOCKED!');
      });
  }

  async isInWork(): Promise<boolean> {
    if (!this.ordersServ.isUser.getValue()) {
      this.sameIndex = false;
      return true;
    }
    let indexToSuspend = this.gridData.findIndex(
      (order) =>
        (order.tab_order_status_cod >= Status.StatusesUser.step1 &&
          order.tab_order_status_cod <= Status.StatusesUser.step4) ||
        order.tab_order_status_cod == Status.StatusesUser.intiated
    );
    console.debug('indexToSuspend', indexToSuspend);
    this.sameIndex =
      indexToSuspend != -1
        ? this.gridData[indexToSuspend].order_id == this.id
        : false;
    console.debug('this.sameIndex', this.sameIndex);
    if (indexToSuspend != -1 && !this.sameIndex) {
      this.ordersServ
        .setStatus(
          this.gridData[indexToSuspend].order_id,
          Status.StatusesUser.blocked
        )
        .subscribe((result: IWsResult) => {
          if (!result.success) {
            Swal.fire({
              icon: 'error',
              title: 'Attenzione!',
              text: 'L`Ordine non può essere sospeso!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            return;
          }
        });
    }
    return true;
  }

  reloadFunction() {
    this.getNewOrders();
    console.log('Check value net value after new orders', this.netValue);
    this.getOrdineParziali();
    this.getPMOrders();
    this.getObjectives();
    console.log('Check value net value', this.netValue);
  }

  ngOnDestroy() {
    console.log('I am leaving assegna list');
    console.log('DEINE SPECIAL BOOLEAN IST', this.booleanForSpecialSearch);

    this.subscriber.forEach((item) => item.unsubscribe());
  }
}
