<div
  class="k-overlay dark-background"
  *ngIf="openOrderDettagli && (agent_order || agent_order_new)"
></div>

<kendo-dialog
  title=""
  *ngIf="openOrderDettagli && (agent_order || agent_order_new)"
  width="100%"
  [minHeight]="500"
  [maxWidth]="1150"
  class="k-form container-dialog-assegna detz"
  [formGroup]="agentOrderDetail"
  style="width: 100%; left: 4%; right: 6%"
>
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: -15px;
      z-index: 1000;
      position: fixed;
      margin-left: -35px;
    "
  >
    <button
      (click)="close()"
      class="btn btn3"
      rounded="full"
      style="
        background-color: #e90000;
        color: #ffffff;
        width: 35px !important;
        height: 35px !important;
      "
    >
      <i
        class="bx bx-x bx-sm posRelBox2"
        style="
          color: #ffffff;
          bottom: 10px;
          position: relative !important;
          left: -80% !important;
          top: -1px !important;
        "
      ></i>
    </button>
  </div>

  <h1 class="page-title">Dettagli Ordine</h1>
  
  <hr class="hr-newBlue mt-4" />
  <div class="container container-ordine detz">
    <form class="k-form dialog-form agent-det" [formGroup]="agentOrderDetail">
      <div class="row det">
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label
            [for]="or_order_number"
            class="title"
            text="N° ordine"
          ></kendo-label>
          <input
            formControlName="or_order_number"
            kendoTextBox
            #or_order_number
            readonly
            class="setBorder-Prepp"
          />
        </kendo-formfield>
      </div>

      <div class="row det">
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label
            [for]="or_order_date"
            class="title"
            text="Data ordine"
          ></kendo-label>
          <input
            formControlName="or_order_date"
            kendoTextBox
            #or_order_date
            readonly
            class="setBorder-Prepp"
          />
        </kendo-formfield>
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label
            [for]="or_closing_date"
            class="title"
            text="Data evasione"
          ></kendo-label>
          <input
            formControlName="or_closing_date"
            kendoTextBox
            #or_closing_date
            readonly
            class="setBorder-Prepp"
          />
        </kendo-formfield>
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label [for]="status" class="title" text="Stato"></kendo-label>
          <input
            formControlName="status"
            kendoTextBox
            #status
            readonly
            class="setBorder-Prepp"
          />
        </kendo-formfield>
      </div>

      <div class="row det">
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label
            [for]="or_customer"
            class="title"
            text="Cliente"
          ></kendo-label>
          <input
            formControlName="or_customer"
            kendoTextBox
            #or_customer
            readonly
            class="setBorder-Prepp"
          />
        </kendo-formfield>
      </div>

      <div class="row det">
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label
            [for]="or_shipping_address"
            class="title"
            text="Indirizzo di spedizione"
          ></kendo-label>
          <input
            formControlName="or_shipping_address"
            kendoTextBox
            #or_shipping_address
            readonly
            class="setBorder-Prepp"
          />
        </kendo-formfield>
      </div>

      <div class="row det">
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label
            [for]="or_shipping_city"
            class="title"
            text="Città"
          ></kendo-label>
          <input
            formControlName="or_shipping_city"
            kendoTextBox
            #or_shipping_city
            readonly
            class="setBorder-Prepp"
          />
        </kendo-formfield>
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label
            [for]="or_shipping_zip"
            class="title"
            text="CAP"
          ></kendo-label>
          <input
            formControlName="or_shipping_zip"
            kendoTextBox
            #or_shipping_zip
            readonly
            class="setBorder-Prepp"
          />
        </kendo-formfield>
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label
            [for]="or_shipping_prov"
            class="title"
            text="Provincia"
          ></kendo-label>
          <input
            formControlName="or_shipping_prov"
            kendoTextBox
            #or_shipping_prov
            readonly
            class="setBorder-Prepp"
          />
        </kendo-formfield>
      </div>

      <div class="row det">
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label
            [for]="or_shipping_country"
            class="title"
            text="Nazione"
          ></kendo-label>
          <input
            formControlName="or_shipping_country"
            kendoTextBox
            #or_shipping_country
            readonly
            class="setBorder-Prepp"
          />
        </kendo-formfield>
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label
            [for]="carrier_desc"
            class="title"
            text="Corriere"
          ></kendo-label>
          <input
            formControlName="carrier_desc"
            kendoTextBox
            #carrier_desc
            readonly
            class="setBorder-Prepp"
          />
        </kendo-formfield>
      </div>
    </form>

    <div class="row det cont-dx" style="margin-left: -33px">
      <div class="row column-secret panelbar-wrapper" style="margin-top: 10px">
        <kendo-panelbar
          *ngIf="agent_order?.products || agent_order_new?.products"
          [animate]="true"
          style="text-align: center; border: #fff"
        >
          <kendo-panelbar-item [title]="'Prodotti'" style="width:260px; border-radius:15px">
            <ng-template kendoPanelBarContent>
              <div
                style="max-height: 535px; overflow-y: auto; border-radius: 15px"
              >
                <div
                  *ngFor="let product of agent_order?.products"
                  class="row"
                  style="
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #0c65e9;
                    margin-bottom: 5px;
                    padding: 5px !important;
                    border-bottom: 1px solid #0c65e9 !important;
                  "
                >
                  <div class="col-8" style="text-align: left">
                    <span
                      style="
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #0c65e9;
                        margin-bottom: 2px;
                      "
                      >{{ product.op_description }}</span
                    >
                  </div>
                  <div class="col-4" style="text-align: right">
                    <span
                      style="
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #0c65e9;
                        margin-bottom: 2px;
                      "
                      >{{ product.op_qta + " pz" }}</span
                    >
                  </div>
                </div>
                <div
                  *ngFor="let product of agent_order_new?.products"
                  class="row"
                  style="
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #0c65e9;
                    margin-bottom: 5px;
                    padding: 5px !important;
                    border-bottom: 1px solid #0c65e9 !important;
                  "
                >
                  <div class="col-8" style="text-align: left">
                    <span
                      style="
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #0c65e9;
                        margin-bottom: 2px;
                      "
                      >{{ product.op_description }}</span
                    >
                  </div>
                  <div class="col-4" style="text-align: right">
                    <span
                      style="
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #0c65e9;
                        margin-bottom: 2px;
                      "
                      >{{ product.op_qta + " pz" }}</span
                    >
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>

        <!--
        <kendo-panelbar
          *ngIf="agent_order?.products || agent_order_new?.products"
          [animate]="true"
          style="text-align: center; border: #fff"
        >
          <kendo-panelbar-item [title]="'DDT'" style="border-radius: 15px">
            <ng-template kendoPanelBarContent>
              <div
                style="max-height: 535px; overflow-y: auto; border-radius: 45px"
              >
                <div
                  *ngIf="agent_order?.or_url_ddt"
                  class="row"
                  style="
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #0c65e9;
                    margin-bottom: 5px;
                    padding: 5px !important;
                    border-bottom: 1px solid #0c65e9 !important;
                  "
                >
                  <div>
                    <span
                      style="
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 24px;
                        color: #0c65e9;
                        margin-bottom: 2px;
                      "
                      >DDT
                    </span>
                    <a
                      *ngIf="seeDDT"
                      (click)="printDDT()"
                      href="{{ agent_order?.or_url_ddt }}"
                      target="_blank"
                    >
                      <i
                        class="bx bxs-file-pdf bx-md"
                        style="cursor: pointer; color: rgb(220, 17, 17) !important"
                      ></i
                    ></a>
                    <a href="{{ agent_order.or_url_ddt }}" target="_blank">
                      <i class="bx bxs-file-pdf bx-md"></i>
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
        -->
      </div>
    </div>
  </div>
</kendo-dialog>
