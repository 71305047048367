<h1 class="page-title">
  Preparazione Ordine
  <span *ngIf="order">{{ order.or_order_number }}</span>
</h1>
<hr class="hr-newBlue" />
<br />

<div class="container" *ngIf="order" style="padding-left: 20px">
  <div class="row det">
    <div class="col-md setDisplay-Prep">
      <span class="title"
        >Data ordine:<br />
        <strong class="setBorder-Prepp">
          {{ order.date }}
        </strong>
      </span>
    </div>
    <div class="col-md setDisplay-Prep">
      <span class="title"
        >Riferimento:<br />
        <strong class="setBorder-Prepp">
          {{ order.or_note }}
        </strong>
      </span>
    </div>
    <div class="col-md setDisplay-Prep">
      <span class="title"
        >N° Colli:<br />
        <strong class="setBorder-Prepp">
          {{ order.boxes?.length }}
        </strong>
      </span>
    </div>
    <div class="col-md setDisplay-Prep">
      <span class="title"
        >N° Pezzi:<br />
        <strong class="setBorder-Prepp">
          {{ order.vs_details.op_articles_number }}
        </strong>
      </span>
    </div>
    <div class="col-md setDisplay-Prep">
      <span class="title"
        >Peso:<br />
        <strong class="setBorder-Prepp">
          {{ order.totalWeight }}
        </strong>
      </span>
    </div>
    <div class="col-md setDisplay-Prep">
      <span class="title">Operatore:<br /> </span>
      <div class="operatordiv" style="display: flex; justify-content: center">
        <strong class="setBorder-Prepp">
          {{ order.vs_details.ac_last_name }}
        </strong>

        <button
          class="btn operator-button"
          (click)="openChangeOperator()"
          [disabled]="order.status[0].os_status_order_cod !== 101"
          style="padding: 0; margin: 0; border: none"
        >
          <i class="bx bx-down-arrow-circle bx-sm"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row det">
    <div class="col-12 setDisplay-Prep">
      <span class="title"
        >Cliente:<br />
        <strong class="setBorder-Prepp">
          {{ order.or_customer }}
        </strong>
      </span>
    </div>
  </div>
  <div class="row det">
    <div class="col-12 setDisplay-Prep">
      <span class="title"
        >Indirizzo:<br />
        <strong class="setBorder-Prepp">
          {{ order.or_shipping_address }}
        </strong>
      </span>
    </div>
  </div>
  <div class="row det">
    <div class="col-12 setDisplay-Prep">
      <span class="title"
        >Riferimento:<br />
        <strong class="setBorder-Prepp">
          {{ order.or_note }}
        </strong>
      </span>
    </div>
  </div>
  <div class="row det">
    <div class="col setDisplay-Prep">
      <span class="title"
        >Città:<br />
        <strong class="setBorder-Prepp" role="button">
          {{ order.or_shipping_city }}
        </strong>
      </span>
    </div>
    <div class="col-2 setDisplay-Prep">
      <span class="title"
        >CAP:<br />
        <strong class="setBorder-Prepp">
          {{ order.or_shipping_zip }}
        </strong>
      </span>
    </div>
    <div class="col-2 setDisplay-Prep">
      <span class="title"
        >Provincia:<br />
        <strong class="setBorder-Prepp">
          {{ order.or_shipping_prov }}
        </strong>
      </span>
    </div>
    <div class="col-2 setDisplay-Prep">
      <span class="title"
        >Nazione:<br />
        <strong class="setBorder-Prepp">
          {{ order.or_shipping_country }}
        </strong>
      </span>
    </div>
    <div class="col-2 setDisplay-Prep">
      <span class="title"
        >Corriere:<br />
        <strong class="setBorder-Prepp">
          {{
            order.vs_details.carrier_desc
              ? order.vs_details.carrier_desc
              : order.vs_details.carrier_other
          }}
        </strong>
      </span>
    </div>
  </div>
  <div class="row det">
    <div class="col setDisplay-Prep">
      <span class="title"
        >Stato:&nbsp;&nbsp;
        <strong class="status">
          {{ order.icon?.label }}
        </strong>
      </span>

      <div class="progress">
        <ion-progress-bar
          [value]="+this.perc"
          style="height: 100%"
        ></ion-progress-bar>
        <!-- <div
          role="progressbar"
          [ngStyle]="{ 'background-color': 'blue !important' }"
          aria-label="Default striped example"
          [ngClass]="order.progressBarCustom.cssClass"
          [style.width.%]="order.progressBarCustom.perc"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div> -->
      </div>
    </div>
  </div>

  <div
    class="buttonsGrouping"
    style="display: flex; flex-wrap: wrap; justify-content: center"
  >
    <div
      *ngFor="let item of steps; let i = index"
      class="smecher1 mx-2"
      [class.smecher1Color]="
        order &&
        order.status[0].os_status_order_cod != 10 &&
        order.status[0].os_status_order_cod < item.index &&
        order.status[0].os_status_order_cod != 100 &&
        order.status[0].os_status_order_cod !== 101
      "
      [class.smecher1ColorConfirm]="
        order &&
        order.status[0].os_status_order_cod != 10 &&
        order.status[0].os_status_order_cod > item.index &&
        order.status[0].os_status_order_cod != 100 &&
        order.status[0].os_status_order_cod !== 101
      "
      [class.smecher1ColorNext]="
        order && order.status[0].os_status_order_cod == item.index
      "
    >
      <div
        class="buttonCircle"
        [class.buttonCircleConfirm]="
          order &&
          order.status[0].os_status_order_cod != 10 &&
          order.status[0].os_status_order_cod > item.index &&
          order.status[0].os_status_order_cod != 100 &&
          order.status[0].os_status_order_cod !== 101
        "
        [class.buttonCircleNext]="
          order && order.status[0].os_status_order_cod == item.index
        "
      >
        <h1
          class="numberStyle"
          [class.numberStyleConfirm]="
            order &&
            order.status[0].os_status_order_cod != 10 &&
            order.status[0].os_status_order_cod > item.index &&
            order.status[0].os_status_order_cod != 100 &&
            order.status[0].os_status_order_cod !== 101
          "
          [class.numberStyleNext]="
            order && order.status[0].os_status_order_cod == item.index
          "
        >
          {{ item.index }}
        </h1>
      </div>

      <div class="setFirstDiv">
        <div
          class="row"
          style="display: flex; flex-direction: column; width: 400px"
        ></div>
      </div>

      <div class="setFirstDiv">
        <div class="row" style="display: flex; flex-direction: column">
          <h1
            class="buttonStyleText"
            [class.numberStyleConfirm]="ordersServ.pages.value > i"
            [class.numberStyleNext]="ordersServ.pages.value === i"
          >
            {{ item.title }}
          </h1>

          <div
            class="col mt-2"
            *ngIf="
              order &&
              order.status[0].os_status_order_cod != 10 &&
              order.status[0].os_status_order_cod > item.index &&
              order.status[0].os_status_order_cod != 100
            "
          ></div>

          <button
            (click)="item.function()"
            class="buttonBorderStyle"
            [class.buttonBorderStyleConfirm]="ordersServ.pages.value > i"
            [class.buttonBorderStyleNext]="ordersServ.pages.value === i"
            [disabled]="
              ordersServ.pages.value < i ||
              (order && order.status[0].os_status_order_cod === 101) ||
              order.status[0].os_status_order_cod === 1 ||
              order.status[0].os_status_order_cod === 100
            "
          >
            <h1
              class="h1StyleText"
              [class.numberStyleConfirm]="ordersServ.pages.value > i"
              style="padding-top: 5px"
              [class.numberStyleNext]="ordersServ.pages.value === i"
            >
              {{ item.buttonText }}
            </h1>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons-status k-display-flex justify-content-center pt-3">
    <div class="button-right">
      <button
        class="btn btn3"
        rounded="full"
        style="
          background-color: #8d8d8d;
          color: #ffffff;
          justify-content: center !important;
          display: flex;
          width: 222px !important;
          height: 42px !important;
        "
        (click)="back()"
      >
        <i
          class="bx bx-left-arrow-circle bx-sm posRelBox2"
          style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
        ></i>
        <p class="posRelBox2">INDIETRO</p>
      </button>
    </div>

    <div class="buttons-status k-display-flex justify-content-center">
      <div class="button-right">
        <button
          *ngIf="!isDDT"
          class="btn btn3"
          color="red"
          rounded="full"
          id="present-alert"
          style="
            background-color: rgb(95, 234, 95) !important;
            color: #ffffff;
            justify-content: center !important;
            display: flex !important;

            width: 222px !important;
            height: 42px !important;
          "
          (click)="synchronizeOrder(order.order_id, order.or_gamma_id)"
        >
          <i
            class="bx bx-loader-circle bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
          ></i>
          <p class="posRelBox2">SINCRONIZZA</p>
        </button>
      </div>
      <div *ngIf="order && order.status[0].os_status_order_cod == 100">
        <button
          (click)="start()"
          class="btn btn3"
          rounded="full"
          style="
            background-color: #5ec186;
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 222px !important;
            height: 42px !important;
          "
        >
          <i
            class="bx bx-play-circle bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
          ></i>
          <p class="posRelBox2">CONTINUA</p>
        </button>
      </div>

      <div
        *ngIf="
          order &&
          order.status[0].os_status_order_cod !== 1 &&
          order.status[0].os_status_order_cod !== 11 &&
          order.status[0].os_status_order_cod !== 100 &&
          order.status[0].os_status_order_cod !== 101
        "
      >
        <button
          (click)="suspend()"
          class="btn btn3"
          rounded="full"
          style="
            background-color: #a90606;
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 222px !important;
            height: 42px !important;
          "
        >
          <i
            class="bx bx-pause-circle bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
          ></i>
          <p class="posRelBox2">SOSPENDI</p>
        </button>
      </div>

      <div
        *ngIf="
          order &&
          order.status[0].os_status_order_cod !== 11 &&
          order.status[0].os_status_order_cod !== 101
        "
      >
        <button
          (click)="block()"
          class="btn btn3"
          rounded="full"
          style="
            background-color: #000000;
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 222px !important;
            height: 42px !important;
          "
          [disabled]="order.status[0].os_status_order_cod === 7"
        >
          <i
            class="bx bx-lock bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
          ></i>
          <p class="posRelBox2">BLOCCA</p>
        </button>
      </div>
      <div *ngIf="order && order.status[0].os_status_order_cod === 101">
        <button
          (click)="unblock()"
          class="btn btn3"
          rounded="full"
          style="
            background-color: #ff8e0d;
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 222px !important;
            height: 42px !important;
          "
        >
          <i
            class="bx bx-lock-open bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
          ></i>
          <p class="posRelBox2">SBLOCCA</p>
        </button>
      </div>

      <button
        *ngIf="
          order &&
          order.status[0].os_status_order_cod > 1 &&
          order.status[0].os_status_order_cod == 5 &&
          order.status[0].os_status_order_cod < 100
        "
        [disabled]="order.status[0].os_status_order_cod < 5"
        (click)="done()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #017efa;
          color: #ffffff;
          justify-content: center !important;
          display: flex;
          width: 222px !important;
          height: 42px !important;
        "
      >
        <i
          class="bx bx-badge-check bx-sm posRelBox2"
          style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
        ></i>
        <p class="posRelBox2">COMPLETA</p>
      </button>
      <div class="k-display-flex justify-content-center">
        <button
          *ngIf="order.status[0].os_status_order_cod == 1"
          (click)="start()"
          class="btn btn3"
          rounded="full"
          style="
            background-color: #00c04d;
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 222px !important;
            height: 42px !important;
          "
        >
          <i
            class="bx bx-run bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
          ></i>
          <p class="posRelBox2">INIZIA</p>
        </button>
      </div>
    </div>
  </div>

  <app-product-detail-modal
    *ngIf="closeServ.isProdopen"
    [opened]="closeServ.isProdopen"
    [product_id]="clickedProduct"
  >
  </app-product-detail-modal>

  <app-confirm-products
    *ngIf="closeServ.isCheckOn"
    [opened]="closeServ.isCheckOn"
    [product_id]="checkProduct"
    (modalClosed1)="reloadFunction()"
  >
  </app-confirm-products>
  <app-confirm-boxes
    *ngIf="closeServ.isBoxCheck"
    [opened]="closeServ.isBoxCheck"
    [product_id]="isCheckBox"
    (modalClosed1)="reloadFunction()"
  >
  </app-confirm-boxes>

  <app-confirm-documents
    *ngIf="closeServ.isOpenedDoc"
    [opened]="closeServ.isOpenedDoc"
    [product_id]="checkDoc"
    (modalClosed1)="reloadFunction()"
  >
  </app-confirm-documents>

  <app-confirm-shipment
    *ngIf="closeServ.isShipOn"
    [opened]="closeServ.isShipOn"
    [product_id]="checkShip"
    (modalClosed1)="reloadFunction()"
  ></app-confirm-shipment>

  <div
    class="example"
    *ngIf="order && order.status[0].tab_order_status_perc == 100"
  ></div>

  <app-popover-warning
    *ngIf="openModal.isPopover"
    [openedWarningMessage]="openModal.isPopover"
    (modalClosed1)="this.synchroneOrder(order.order_id, order.or_gamma_id)"
  ></app-popover-warning>
</div>

<app-test-dialog *ngIf="generalBehaviour"></app-test-dialog>
