<div style="display: flex; flex-direction: row; justify-content: center">
  <h2 class="col-header">Totale ordini PM: {{ data.length }}</h2>
</div>

<div
  *ngIf="loading"
  style="display: flex; flex-direction: column; align-items: center"
>
  <mat-spinner></mat-spinner>
  <p>Attendi..</p>
</div>

<!-- <div class="grid-wrapper">
  <kendo-grid
    [kendoGridBinding]="
      data
        | byToDate
          : [
              filters.startingDate,
              filters.endDate,
              filters.dateOrginizedBy.value
            ]
    "
    [rowHeight]="36"
    [height]="750"
    [pageSize]="pageSize"
    scrollable="virtual"
    (pageChange)="pageChange($event)"
    [navigable]="true"
    [sortable]="true"
    [reorderable]="true"
  >
    <kendo-grid-column
      [width]="100"
      field="or_order_number"
      title="N° Ordine"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
    </kendo-grid-column>

    <kendo-grid-column
      [width]="600"
      field="or_customer"
      title="Cliente"
      [headerStyle]="{ 'justify-content': 'left' }"
      [style]="{
        display: 'grid',
        'justify-content': 'left',
        'tetx-align': 'left'
      }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_customer }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="120"
      field="or_days_remaining"
      title="Data di scadenza"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'text-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_days_remaining + " " + "giorni" }}
        <span [ngClass]="{ scaduto: !isExpired(dataItem.or_order_date) }">
          ({{ deadline(dataItem) | date : "dd/MM/yyyy" }})
        </span>
        <div class="progress-bar-container">
          <div
            class="progress-bar"
            [style.width.%]="progress(dataItem.or_order_date)"
            [ngClass]="{
              'progress-bar-green':
                isMonthComplete(dataItem.or_days_remaining) &&
                isExpired(dataItem.or_order_date),
              'progress-bar-red': !isExpired(dataItem.or_order_date)
            }"
          ></div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="120"
      field="or_order_date"
      title="Data ordine"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="100"
      field="or_articles_number"
      title="N° Articoli"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_articles_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column
      title="Assegna"
      [width]="100"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoGridEditCommand
          rounded="full"
          class="btn-det"
          style="margin: 0; padding: 0"
          (click)="assignPM(dataItem.or_gamma_id)"
        >
          <div class="setDisplayToFlex">
            <i
              class="bx bx-edit bx-md"
              style="margin: 0; padding: 0; transform: scale(0.8)"
            >
            </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div> -->

<app-orderscm-det
  *ngIf="openModal.isCmOrder"
  [opened]="openModal.isCmOrder"
  [order_id]="clickedID"
>
</app-orderscm-det>
