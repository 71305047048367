export interface IClosedOrdersStatistics {
  stat_monthly_closed_orders: {
    stat_month: string;
    stat_total_monthly_orders: number;
  }[];
  stat_total_yearly_orders: number;
}

export class ClosedOrdersStatistics implements IClosedOrdersStatistics {
  stat_monthly_closed_orders: {
    stat_month: string;
    stat_total_monthly_orders: number;
  }[] = [];
  stat_total_yearly_orders: number = -1;

  constructor(stat: IClosedOrdersStatistics | null) {
    if (!stat) return;

    this.stat_monthly_closed_orders = stat.stat_monthly_closed_orders;
    this.stat_total_yearly_orders = stat.stat_total_yearly_orders;
  }
}

export interface IShippedOrdersClosed {
  stat_monthly_closed_orders: {
    stat_month: string;
    stat_total_monthly_media_orders: number;
  }[];
  stat_total_yearly_orders: number;
}

export class ShippedOrdersClosed implements IShippedOrdersClosed {
  stat_monthly_closed_orders: {
    stat_month: string;
    stat_total_monthly_media_orders: number;
  }[] = [];
  stat_total_yearly_orders: number = -1;

  constructor(stat: IShippedOrdersClosed | null) {
    if (!stat) return;

    this.stat_monthly_closed_orders = stat.stat_monthly_closed_orders;
    this.stat_total_yearly_orders = stat.stat_total_yearly_orders;
  }
}

export interface IStatCompletedOrdersByCarrier {
  carrier_desc: string;
  stat_nr_orders: number;
}

export class StatCompletedOrdersByCarrier
  implements IStatCompletedOrdersByCarrier
{
  carrier_desc: string = '';
  stat_nr_orders: number = -1;
  constructor(stats: IStatCompletedOrdersByCarrier | null) {
    if (!stats) return;
    this.carrier_desc = stats.carrier_desc;
    this.stat_nr_orders = stats.stat_nr_orders;
  }
}

export interface IOrdersCompletedByOperator {
  ac_first_name: string;
  ac_last_name: string;
  nr_orders: number;
  stat_month: string;
}

export class OrdersCompletedByOperator implements IOrdersCompletedByOperator {
  ac_first_name: string;
  ac_last_name: string;
  nr_orders: number;
  stat_month: string;

  constructor(order: IOrdersCompletedByOperator | null) {
    if (!order) return;
    this.ac_first_name = order.ac_first_name;
    this.ac_last_name = order.ac_last_name;
    this.nr_orders = order.nr_orders;
    this.stat_month = order.stat_month;
  }
}

export interface IOrdersByOperator {
  ac_first_name: string;
  ac_last_name: string;
  stat_nr_orders: number;
}

export class OrdersByOperator implements IOrdersByOperator {
  ac_first_name: string;
  ac_last_name: string;
  stat_nr_orders: number;

  constructor(order: IOrdersByOperator | null) {
    if (!order) return;
    this.ac_first_name = order.ac_first_name;
    this.ac_last_name = order.ac_last_name;
    this.stat_nr_orders = order.stat_nr_orders;
  }
}

export interface IOrdersDividedByCarrier {
  stat_carrier_desc: string;
  stat_completed_orders_by_carriers: {
    stat_t_orders: number;
    stat_month: string;
  }[];
}

export class OrdersDividedByCarrier implements IOrdersDividedByCarrier {
  stat_carrier_desc: string;
  stat_completed_orders_by_carriers: {
    stat_t_orders: number;
    stat_month: string;
  }[];
  constructor(order: IOrdersDividedByCarrier | null) {
    if (!order) return;
    this.stat_carrier_desc = order.stat_carrier_desc;
    this.stat_completed_orders_by_carriers =
      order.stat_completed_orders_by_carriers;
  }
}

export interface IGeneralStatistics {
  s_avg_prep_time_perc: number;
  s_d_avg_boxes_delivered_perc: number;
  s_d_avg_orders_completed_perc: number;
  s_t_boxes_delivered_perc: number;
  s_t_errors_perc: number;
  s_t_orders_perc: number;
  stat_avg_preparation_time: number;
  stat_daily_avg_boxes_delivered: number;
  stat_daily_avg_orders_completed: number;
  stat_total_boxes_delivered: number;
  stat_total_errors: number;
  stat_total_orders: number;
}

export class GeneralStatistics implements IGeneralStatistics {
  s_avg_prep_time_perc: number;
  s_d_avg_boxes_delivered_perc: number;
  s_d_avg_orders_completed_perc: number;
  s_t_boxes_delivered_perc: number;
  s_t_errors_perc: number;
  s_t_orders_perc: number;
  stat_avg_preparation_time: number;
  stat_daily_avg_boxes_delivered: number;
  stat_daily_avg_orders_completed: number;
  stat_total_boxes_delivered: number;
  stat_total_errors: number;
  stat_total_orders: number;

  constructor(stat: IGeneralStatistics | null) {
    if (!stat) return;
    (this.s_avg_prep_time_perc = stat.s_avg_prep_time_perc),
      (this.s_d_avg_boxes_delivered_perc = stat.s_d_avg_boxes_delivered_perc);
    this.s_d_avg_orders_completed_perc = stat.s_d_avg_orders_completed_perc;
    this.s_t_boxes_delivered_perc = stat.s_t_boxes_delivered_perc;
    this.s_t_errors_perc = stat.s_t_errors_perc;
    this.s_t_orders_perc = stat.s_t_orders_perc;
    this.stat_avg_preparation_time = stat.stat_avg_preparation_time;
    this.stat_daily_avg_boxes_delivered = stat.stat_daily_avg_boxes_delivered;
    this.stat_daily_avg_orders_completed = stat.stat_daily_avg_orders_completed;
    this.stat_total_boxes_delivered = stat.stat_total_boxes_delivered;
    this.stat_total_errors = stat.stat_total_errors;
    this.stat_total_orders = stat.stat_total_orders;
  }
}
