import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Categories } from 'src/app/models/categories';
import { Products } from 'src/app/models/Product.model';
import { ProductsService } from 'src/app/services/products.service';
import { PagesService } from 'src/app/services/pages.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-gestione-prodotti',
  templateUrl: './gestione-prodotti-list.component.html',
  styleUrls: ['./gestione-prodotti-list.component.scss'],
})

export class GestioneProdottiListComponent implements OnInit {

  @Input() opened = false;

  public stockProducts: Products.StockProducts[] = [];
  public productPosition: Products.IProductPosition;
  public stockProduct: Products.StockProducts;
  public gridView: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  public data: unknown[];
  public assignOrderForm: FormGroup;
  public allCategories: Categories.ICategory[];
  public categories: Categories.Family[] = null;
  public isSearched: boolean = false;
  public loading: boolean = false;
  public filteredCategories: Categories.Family[] = null;
  public gridData: any[] = [];

  product: string = '';
  pr_stock: any;
  clickedPr: string;
  filterTerm: string;
  triggerProductsBool: boolean = true;
  subFamily: any;
  clickedProduct: number;
  startDate: Date = new Date();
  endDate: Date = new Date();
  clickedId: string;
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];
  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'pr_coder',
    },
    startingDate: new Date(),
    endDate: new Date(),
    minDate: new Date(),
    maxDate: new Date(),
  };

  constructor(
    private stockSrv: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    public closeSrv: OpenModalService,
    private activatedRoute: ActivatedRoute,
    public productSrv: ProductsService,
    public pagesServ: PagesService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getStockProducts();
    this.getCategories();
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getCategories();
    }
  }

  clearSearch() {
    this.filterTerm = '';
    this.filters.startingDate = this.startDate;
    this.getStockProducts();
    this.getCategories();
  }
  getCategories() {
    this.stockSrv.getCategories().subscribe((result) => {
      console.debug('results: ', result);
      if (!result) return;
      this.allCategories = result;
      this.subFamily = this.allCategories;
      this.categories = new Categories.Categories(result).families;
      this.subFamily = new Categories.Categories(result).categoriesComplete;
      console.log('Subfamily is: ', this.subFamily);
      console.debug('categories: ', new Categories.Categories(result));
      this.loading = false;
    });
  }

  async triggerProducts() {
    try {
      const productsGet = await this.getStockProducts();
      console.log('Products to get are: ', productsGet);
      // ...
    } catch (error) {
      // Handle the error here if necessary
    }
    this.triggerProductsBool = !this.triggerProductsBool;
  }

  getSubFamilies() {
    let subFamilies: Categories.ISubFamily[] = [];
    for (let family of this.categories) {
      if (family.subFamilies && family.subFamilies.length > 0) {
        subFamilies = family.subFamilies;
      }
    }
    this.subFamily = subFamilies;
    console.log('SUBFAMILY IS: ', this.subFamily);
  }

  getStockProducts() {
    this.loading = true;
    this.stockSrv
      .getProducts()
      .subscribe((stockProductsData: Products.StockProducts[]) => {
        this.stockProducts = stockProductsData;
        this.gridData = this.stockProducts;
        console.debug('Data is: ', this.stockProducts);
        this.pr_stock = this.stockProducts?.map((item) => {
          let newStock: Products.StockProducts = {
            category_id: 0,
            type: '',
            head_family: '',
            sub_family: '',
            head_group: '',
            sub_group: '',
            description: '',
            pr_stock: +item.pr_stock,
          };
          let newPos: Products.IProductPosition = {
            pr_description: '',
            product_position_id: '0',
            pr_code: '',
            product_id: '0',
            pr_image_position: '',
            pr_b64_image_position: '',
            pr_lane: this.productPosition?.pr_lane,
            pr_shelf: this.productPosition?.pr_shelf,
            pr_position: this.productPosition?.pr_position,
            pr_qty: 0,
          };
          return newStock && newPos;
        });
        this.loadProducts();
        console.debug('From server: ', stockProductsData);
        this.loading = false;
      });
  }

  goToProducts(
    family: string = 'A',
    subFamily: string = 'A',
    group: string = 'A',
    subGroup: string = 'A'
  ) {
    console.log('Family is: ', family);
    console.log('Sub-family is: ', subFamily);
    console.log('Group is: ', group);
    console.log('Sub-group is: ', subGroup);
    this.router.navigate(
      [
        `./${family.trim()}/${subFamily.trim()}/${group.trim()}/${subGroup.trim()}`,
      ],
      { relativeTo: this.activatedRoute }
    );
  }

  async searchProducts(evt) {
    this.isSearched = true;
    if (!this.filterTerm || this.filterTerm == '') {
      this.isSearched = false;
      this.getStockProducts();
      return;
    }
    this.stockSrv
      .searchProducts(this.filterTerm)
      .subscribe((stockProductsData: Products.StockProducts[]) => {
        this.stockProducts = stockProductsData;
        this.gridData = this.stockProducts;
        console.debug('Data is: ', this.stockProducts);
        this.pr_stock = this.stockProducts?.map((item) => {
          let newStock: Products.StockProducts = {
            category_id: 0,
            type: '',
            head_family: '',
            sub_family: '',
            head_group: '',
            sub_group: '',
            description: '',
            pr_stock: +item.pr_stock,
          };
          return newStock;
        });
        this.loadProducts();
        console.debug('From server: ', stockProductsData);
      });
    this.loadProducts();
  }

  gotoDetails(products_order_id: number) {
    this.clickedProduct = products_order_id;
    this.closeSrv.open();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadProducts();
  }

  private loadProducts(): void {
    this.loading = true;
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length,
    };
    this.loading = false;
  }

  async filterList(evt) {
    const searchTerm = evt.srcElement.value;
    this.filterTerm = searchTerm;
    this.isSearched = true;
    console.log('filterTerm: ', this.filterTerm);
    console.log('isSearched: ', this.isSearched);
    if (!this.filterTerm || this.filterTerm == '') {
      this.isSearched = false;
      this.filteredCategories = null;
      return;
    }
    this.productSrv.getCategories().subscribe((result) => {
      console.log('result', result);
      if (!result) {
        return;
      }
      this.categories = new Categories.Categories(result).families;
      this.filteredCategories = Categories.filterFamily(
        this.filterTerm,
        this.categories
      ).filtered;
      console.log('filtered categories: ', this.filteredCategories);
      console.log('categories: ', new Categories.Categories(result));
    });
  }

  showDetails(productId: string, productDesc: string) {
    console.debug('productId');
    this.clickedId = productId;
    console.log('This ID is: ', this.clickedId);
    productDesc = productDesc.trim();
    productDesc = this.pagesServ.replaceAll(productDesc, ' ', '-');
    this.router.navigate([`${productDesc.trim()}-${productId.trim()}`], {
      relativeTo: this.activatedRoute,
    });
  }

  showDetailsWithouthCat(productId: string, productDesc: string) {
    console.debug('productId');
    this.clickedId = productId;
    console.log('This ID is: ', this.clickedId);
    productDesc = productDesc.trim();
    productDesc = this.pagesServ.replaceAll(productDesc, ' ', '-');
    this.router.navigate([`${productDesc.trim()}-${productId.trim()}`], {
      relativeTo: this.activatedRoute,
    });
  }
  
}
