import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-details-list',
  templateUrl: './product-details-list.component.html',
  styleUrls: ['./product-details-list.component.scss'],
})

export class ProductDetailsListComponent implements OnInit {

  @Input() products: any;
  @Input() isList: boolean = false;
  @Output() modalClosed1 = new EventEmitter<void>();

  constructor(
    private ordersServ: OrdersService,
    public closeServ: OpenModalService
  ) { }

  ngOnInit(): void {
    console.log('The products now are: ', this.products);
    console.log('Snap snap!');
  }

  deleteProduct(product: any) {
    this.ordersServ
      .deleteProductFromOrder(product.products_order_id)
      .subscribe();
    console.log('Deleted product is: ', product);
    this.modalClosed1.emit();
    Swal.fire({
      icon: 'success',
      title: 'Eliminato!',
      text: 'Il prodotto è stato eliminato con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

  close() {
    this.closeServ.offProdList();
  }

}
