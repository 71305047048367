import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GoalService {
    
  private goalReachedSource = new BehaviorSubject<boolean>(false);
  goalReached$ = this.goalReachedSource.asObservable();

  reachGoal() {
    this.goalReachedSource.next(true);
  }

  resetGoal() {
    this.goalReachedSource.next(false);
  }

}
