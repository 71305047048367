<h1 class="page-title">
  {{ triggerProductsBool ? "Gestione Prodotti " : "Gestione Categorie " }}
</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <form
    *ngIf="triggerProductsBool"
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <div
      class="inputSearchContainer"
      style="display: flex; flex-direction: column; align-items: flex-end"
    >
      <i
        *ngIf="filterTerm"
        class="bx bxs-x-circle bx-md clearSearchIcon"
        style="
          transform: scale(0.8);
          color: red;
          /* background: #0c65e9; */
          position: relative;
          top: 42px;
          z-index: 10001;
        "
        (click)="clearSearch()"
      ></i>
      <input
        [(ngModel)]="filterTerm"
        id="search"
        name="search"
        pattern=".*\S.*"
        type="search"
        placeholder="Cerca prodotto..."
        autofocus
        required
        (input)="this.onInputChanged($event)"
      />
    </div>
    <button type="submit" (click)="searchProducts($event)">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      visibility: hidden !important;
    "
  >
    <button
      class="btn btn-primary msg-btn"
      routerLink="/new-msg"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        visibility: hidden !important;
      "
    >
      + Messaggio
    </button>
  </form>

  <div
    class="buttonContainerCat"
    style="display: flex; flex-direction: row; justify-content: center"
  >
    <button
      class="btn btn-primary"
      style="margin-bottom: 0px"
      (click)="triggerProducts()"
    >
      <p>
        {{ triggerProductsBool ? "VISTA CATEGORIE" : "VISTA PRODOTTI" }}
      </p>
      <i
        class="{{
          triggerProductsBool
            ? 'bx bx-right-arrow-circle bx-sm'
            : 'bx bx-left-arrow-circle bx-sm'
        }}"
        style="margin: 0; padding: 0"
      >
      </i>
    </button>
  </div>
</div>

<hr class="hr-newBlue" />

<div style="display: flex; flex-direction: row; justify-content: center">
  <h2 class="col-header">
    {{
      triggerProductsBool
        ? "Totale prodotti: " + stockProducts?.length
        : "Totale categorie: " + categories?.length
    }}
  </h2>
</div>

<div
  *ngIf="loading"
  style="display: flex; flex-direction: column; align-items: center"
>
  <mat-spinner></mat-spinner>
  <p>Attendi..</p>
</div>

<div class="grid-wrapper" *ngIf="triggerProductsBool">
  <kendo-grid
    [data]="
      stockProducts
        | byToDate
          : [
              filters.startingDate,
              filters.endDate,
              filters.dateOrginizedBy.value
            ]
    "
    [data]="gridView"
    [skip]="skip"
    [height]="750"
    [rowHeight]="36"
    class="maxHeight"
    [pageSize]="pageSize"
    scrollable="virtual"
    (pageChange)="pageChange($event)"
    [navigable]="true"
    [sortable]="true"
    [reorderable]="true"
  >
    <kendo-grid-column
      [width]="160"
      field="pr_code"
      title="Codice articolo"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.pr_code }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="400"
      field="pr_description"
      title="Descrizione"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.pr_description }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="190"
      field="pr_stock"
      title="Quantità magazzino"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.pr_stock }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="190"
      field="pr_stock"
      title="Quantità impegnata"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.pr_stock }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="190"
      field="pr_stock"
      title="Quantità disponibile"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.pr_stock }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="140"
      field="pr_stock"
      title="Posizione"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.pr_lane }}.{{ dataItem.pr_shelf }}.{{
          dataItem.pr_position
        }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column
      [width]="80"
      title="Dettagli"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoGridEditCommand
          [primary]="true"
          rounded="full"
          style="margin: 0; padding: 0; background-color: #017efa !important"
          (click)="
            showDetailsWithouthCat(dataItem.pr_code, dataItem.pr_description)
          "
        >
          <div class="setDisplayToFlexBlue btn-det">
            <i class="bx bx-info-circle bx-md" style="margin: 0; padding: 0">
            </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-command-column>

    <ng-template kendoGridNoRecordsTemplate>
      <div class="no-records-message">
          <p>Nessun dato disponibile</p>
      </div>
    </ng-template>
  </kendo-grid>
</div>

<div *ngIf="categories && !triggerProductsBool">
  <ion-accordion-group
    *ngFor="
      let family of !isSearched ? categories : filteredCategories;
      let i = index
    "
    class="main-accordion-group"
    multiple="true"
    [value]="isSearched ? family.head_family : null"
  >
    <ion-accordion
      *ngIf="family.subFamilies"
      class="accordion-box"
      toggleIconSlot="start"
      value="{{ family.head_family }}"
    >
      <ion-item slot="header" class="accordion-default background-family">
        <ion-label class="bold-text">
          {{ family.description }}
        </ion-label>
      </ion-item>

      <ion-grid slot="content">
        <ion-accordion-group
          *ngFor="let subfamily of family.subFamilies; let j = index"
          multiple="true"
          [value]="isSearched ? subfamily.sub_family : null"
        >
          <ion-accordion
            *ngIf="subfamily.groups && subfamily.groups?.length > 0"
            class="margin-bottom accordion-box"
            toggleIconSlot="start"
            aria-expanded="true"
            expand="inset"
            value="{{ subfamily.sub_family }}"
          >
            <ion-item
              slot="header"
              class="accordion-default background-subfamily"
            >
              <ion-label class="bold-text">
                {{ subfamily.description }}
              </ion-label>
            </ion-item>

            <ion-grid slot="content">
              <ion-accordion-group
                *ngFor="let group of subfamily.groups; let k = index"
                multiple="true"
                [value]="isSearched ? group.head_group : null"
              >
                <ion-accordion
                  *ngIf="group.subgroups && group.subgroups?.length > 0"
                  class="margin-bottom accordion-box"
                  toggleIconSlot="start"
                  aria-expanded="true"
                  expand="inset"
                  value="{{ group.head_group }}"
                >
                  <ion-item
                    slot="header"
                    class="accordion-default background-group"
                  >
                    <ion-label class="bold-text">
                      {{ group.description }}
                    </ion-label>
                  </ion-item>

                  <ion-grid slot="content">
                    <ion-row
                      *ngFor="let subgroup of group.subgroups; let j = index"
                      class="row-no-icon margined"
                      (click)="
                        goToProducts(
                          family.head_family,
                          subfamily.sub_family,
                          group.head_group,
                          subgroup.sub_group
                        )
                      "
                    >
                      <ion-col class="centered justify-content-left">
                        {{ subgroup.description }}
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-accordion>

                <ion-grid
                  *ngIf="!group.subgroups || group.subgroups?.length <= 0"
                >
                  <ion-row
                    class="row-no-icon"
                    (click)="
                      goToProducts(
                        family.head_family,
                        subfamily.sub_family,
                        group.head_group
                      )
                    "
                  >
                    <ion-col class="centered justify-content-left">
                      {{ group.description }}
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-accordion-group>
            </ion-grid>
          </ion-accordion>

          <ion-grid *ngIf="!subfamily.groups || subfamily.groups?.length <= 0">
            <ion-row
              class="row-no-icon"
              (click)="goToProducts(family.head_family, subfamily.sub_family)"
            >
              <ion-col class="centered justify-content-left">
                {{ subfamily.description }}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-accordion-group>
      </ion-grid>
    </ion-accordion>

    <ion-grid *ngIf="!family.subFamilies || family.subFamilies?.length <= 0">
      <ion-row class="row-no-icon" (click)="goToProducts(family.head_family)">
        <ion-col class="centered justify-content-left">
          {{ family.description }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-accordion-group>
</div>
